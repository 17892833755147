// eslint-disable-next-line import/no-cycle
import {
  LACKCOMPLETION_GET_LIST,
  LACKCOMPLETION_GET_LIST_SUCCESS,
  LACKCOMPLETION_GET_LIST_ERROR,
  LACKCOMPLETION_SET_SELECTED,
  LACKCOMPLETION_UPDATE_SELECTED,
  LACKCOMPLETION_SET_SELECTED_SUCCESS,
  LACKCOMPLETION_SET_SELECTED_ERROR,
  LACKCOMPLETION_ADD_RECORD_SUCCESS,
  LACKCOMPLETION_ADD_RECORD_ERROR,
  LACKCOMPLETION_ADD_RECORD,
  LACKCOMPLETION_UPDATE_RECORD_SUCCESS,
  LACKCOMPLETION_UPDATE_RECORD_ERROR,
  LACKCOMPLETION_UPDATE_RECORD,
  LACKCOMPLETION_DELETE_RECORD,
  LACKCOMPLETION_DELETE_RECORD_SUCCESS,
  LACKCOMPLETION_DELETE_RECORD_ERROR,
  LACKCOMPLETION_RESET_STATUSES,
  LACKCOMPLETION_ISACTIVECHANGE_SUCCESS,
  LACKCOMPLETION_ISACTIVECHANGE_ERROR,
  LACKCOMPLETION_ISACTIVECHANGE,
  LACKCOMPLETION_BYNUTRIENTID,
  LACKCOMPLETION_BYNUTRIENTID_SUCCESS,
  LACKCOMPLETION_BYNUTRIENTID_ERROR,
  LACKCOMPLETION_UPSERT_RECORD,
  LACKCOMPLETION_UPSERT_RECORD_SUCCESS,
  LACKCOMPLETION_UPSERT_RECORD_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getLackCompletionList = (pageDetail) => ({
  type: LACKCOMPLETION_GET_LIST,
  payload: pageDetail,
});

export const getLackCompletionListSuccess = (items) => ({
  type: LACKCOMPLETION_GET_LIST_SUCCESS,
  payload: items,
});

export const getLackCompletionListError = (error) => ({
  type: LACKCOMPLETION_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setLackCompletionSelectedRecord = (recordId) => ({
  type: LACKCOMPLETION_SET_SELECTED,
  payload: recordId,
});

export const setLackCompletionSelectedRecordSuccess = (record) => ({
  type: LACKCOMPLETION_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setLackCompletionSelectedRecordError = (error) => ({
  type: LACKCOMPLETION_SET_SELECTED_ERROR,
  payload: error,
});

export const updateLackCompletionSelectedRecord = (record) => ({
  type: LACKCOMPLETION_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addLackCompletionRecord = (record) => ({
  type: LACKCOMPLETION_ADD_RECORD,
  payload: record,
});

export const addLackCompletionRecordSuccess = (msg) => ({
  type: LACKCOMPLETION_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addLackCompletionRecordError = (error) => ({
  type: LACKCOMPLETION_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateLackCompletionRecords = (record) => ({
  type: LACKCOMPLETION_UPDATE_RECORD,
  payload: record,
});

export const updateLackCompletionRecordSuccess = (msg) => ({
  type: LACKCOMPLETION_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateLackCompletionRecordError = (error) => ({
  type: LACKCOMPLETION_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const deleteLackCompletionRecords = (record) => ({
  type: LACKCOMPLETION_DELETE_RECORD,
  payload: record,
});

export const deleteLackCompletionRecordSuccess = (msg) => ({
  type: LACKCOMPLETION_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteLackCompletionRecordError = (error) => ({
  type: LACKCOMPLETION_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeLackCompletion = (record) => ({
  type: LACKCOMPLETION_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeLackCompletionSuccess = (msg) => ({
  type: LACKCOMPLETION_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeLackCompletionError = (error) => ({
  type: LACKCOMPLETION_ISACTIVECHANGE_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const LackCompletionByNutrientId = (record) => ({
  type: LACKCOMPLETION_BYNUTRIENTID,
  payload: record,
});

export const LackCompletionByNutrientIdSuccess = (msg) => ({
  type: LACKCOMPLETION_BYNUTRIENTID_SUCCESS,
  payload: msg,
});

export const LackCompletionByNutrientIdError = (error) => ({
  type: LACKCOMPLETION_BYNUTRIENTID_ERROR,
  payload: error,
});

export const upsertLackCompletionRecords = (records) => ({
  type: LACKCOMPLETION_UPSERT_RECORD,
  payload: records,
});

export const upsertLackCompletionRecordsSuccess = (items) => ({
  type: LACKCOMPLETION_UPSERT_RECORD_SUCCESS,
  payload: items,
});
export const upsertLackCompletionRecordsError = (error) => ({
  type: LACKCOMPLETION_UPSERT_RECORD_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const resetLackCompletionStatuses = () => ({
  type: LACKCOMPLETION_RESET_STATUSES,
});
