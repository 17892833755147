// eslint-disable-next-line import/no-cycle
import {
  SELECTED_USER_SEND_NOTIFICATION,
  SELECTED_ALL_USER_SEND_NOTIFICATION,
  SELECTED_MULTI_USER_SEND_NOTIFICATION,
  SELECTED_USERPLAN_SEND_NOTIFICATION,
  NOTIFICATION_GET_LIST,
  NOTIFICATION_GET_LIST_SUCCESS,
  NOTIFICATION_GET_LIST_ERROR,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_ERROR,
  UPDATE_NOTIFICATION_SUCCESS,
} from '../../actions';

export const getNotificationList = (pageDetail) => ({
  type: NOTIFICATION_GET_LIST,
  payload: pageDetail,
});

export const getNotificationListSuccess = (items) => ({
  type: NOTIFICATION_GET_LIST_SUCCESS,
  payload: items,
});

export const getNotificationListError = (error) => ({
  type: NOTIFICATION_GET_LIST_ERROR,
  payload: error,
});
export const selectedUserPlanSendNotification = (error) => ({
  type: SELECTED_USERPLAN_SEND_NOTIFICATION,
  payload: error,
});

export const selectedUserSendNotification = (error) => ({
  type: SELECTED_USER_SEND_NOTIFICATION,
  payload: error,
});

export const selectedMultiUserSendNotification = (error) => ({
  type: SELECTED_MULTI_USER_SEND_NOTIFICATION,
  payload: error,
});

export const selectedAllUserSendNotification = (error) => ({
  type: SELECTED_ALL_USER_SEND_NOTIFICATION,
  payload: error,
});
export const updateNotification = (pageDetail) => ({
  type: UPDATE_NOTIFICATION,
  payload: pageDetail,
});

export const updateNotificationSuccess = (items) => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: items,
});

export const updateNotificationError = (error) => ({
  type: UPDATE_NOTIFICATION_ERROR,
  payload: error,
});
