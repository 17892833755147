/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  USERPREFERENCE_ADD_RECORD,
  USERPREFERENCE_UPDATE_RECORD,
  USERPREFERENCE_GET_LIST,
  USERPREFERENCE_SET_SELECTED,
  USERPREFERENCE_DELETE_RECORD,
  deleteUserPreferenceRecordSuccess,
  deleteUserPreferenceRecordError,
  USERPREFERENCE_BYUSERID_SET_SELECTED,
} from '../../actions';

import {
  getUserPreferenceListSuccess,
  getUserPreferenceListError,
  setUserPreferenceSelectedRecordSuccess,
  setUserPreferenceSelectedRecordError,
  addUserPreferenceRecordSuccess,
  addUserPreferenceRecordError,
  updateUserPreferenceRecordSuccess,
  updateUserPreferenceRecordError,
  setUserPreferenceByUserIdSuccess,
  setUserPreferenceByUserIdError,
} from './actions';
import { setCurrentUserPreference } from '../../../helpers/Utils';

//---------------------------------------------------------------------
const getUserPreferenceListRequest = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userpreference`)
    .then((response) => response.data.data);
};

function* getUserPreferenceListItems() {
  try {
    const response = yield call(getUserPreferenceListRequest);
    yield put(getUserPreferenceListSuccess(response));
  } catch (error) {
    yield put(getUserPreferenceListError(error));
  }
}
//----------------------------------------------------------------------------------
const setUserPreferenceSelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userpreference/${id}`)
    .then((response) => response.data.singleData);
};

function* setUserPreferenceSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setUserPreferenceSelectedRecordRequest,
      payload
    );
    yield put(setUserPreferenceSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setUserPreferenceSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addUserPreferenceRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/userpreference`, record)
    .then((response) => response.data);
};

function* addUserPreferenceRecords({ payload }) {
  try {
    yield call(addUserPreferenceRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addUserPreferenceRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    // yield call(getUserPreferenceListItems);
  } catch (error) {
    yield put(
      addUserPreferenceRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateUserPreferenceRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/userpreference`, record)
    .then((response) => response.data);
};

function* updateUserPreferenceRecords({ payload }) {
  try {
    const response = yield call(updateUserPreferenceRecordRequest, payload);
    if (response.singleData) {
      setCurrentUserPreference(response.singleData);
    }
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateUserPreferenceRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    // yield call(getUserPreferenceListItems);
  } catch (error) {
    yield put(
      updateUserPreferenceRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteUserPreferenceRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/userpreference/${recordId}`)
    .then((response) => response.data);
};

function* deleteUserPreferenceRecords({ payload }) {
  try {
    yield call(deleteUserPreferenceRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteUserPreferenceRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    // yield call(getUserPreferenceListItems);
  } catch (error) {
    yield put(
      deleteUserPreferenceRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const setUserPreferenceByUserIdRecordRequest = async (recordId) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userpreference/byUserId/${recordId}`)
    .then((response) => response.data);
};

function* setUserPreferenceByUserIdRecords({ payload }) {
  try {
    const response = yield call(
      setUserPreferenceByUserIdRecordRequest,
      payload
    );
    if (response.singleData) {
      setCurrentUserPreference(response.singleData);
    }
    yield put(setUserPreferenceByUserIdSuccess(response));
  } catch (error) {
    yield put(
      setUserPreferenceByUserIdError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

//----------------------------------------------------------------------------------

//----------------------------------------------------------------------------------

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(USERPREFERENCE_GET_LIST, getUserPreferenceListItems);
}

export function* watchSetSelected() {
  yield takeEvery(USERPREFERENCE_SET_SELECTED, setUserPreferenceSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(USERPREFERENCE_ADD_RECORD, addUserPreferenceRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(USERPREFERENCE_UPDATE_RECORD, updateUserPreferenceRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(USERPREFERENCE_DELETE_RECORD, deleteUserPreferenceRecords);
}
export function* watchUserPreferenceByUserId() {
  yield takeEvery(
    USERPREFERENCE_BYUSERID_SET_SELECTED,
    setUserPreferenceByUserIdRecords
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(watchUserPreferenceByUserId),
  ]);
}
