/* eslint-disable prettier/prettier */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  race,
  delay,
  debounce,
  takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { speciallyWithId } from '../planning/actions';
import {
  PLANREPASTFOODPORTION_ADD_RECORD,
  PLANREPASTFOODPORTION_UPDATE_RECORD,
  PLANREPASTFOODPORTION_GET_LIST,
  PLANREPASTFOODPORTION_SET_SELECTED,
  PLANREPASTFOODPORTION_DELETE_RECORD,
  deletePlanRepastFoodPortionRecordSuccess,
  deletePlanRepastFoodPortionRecordError,
  PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD,
} from '../../actions';

import {
  getPlanRepastFoodPortionListSuccess,
  getPlanRepastFoodPortionListError,
  setPlanRepastFoodPortionSelectedRecordSuccess,
  setPlanRepastFoodPortionSelectedRecordError,
  addPlanRepastFoodPortionRecordSuccess,
  addPlanRepastFoodPortionRecordError,
  updatePlanRepastFoodPortionRecordSuccess,
  updatePlanRepastFoodPortionRecordError,
  updatePlanRepastFoodPortionDefaultValueSuccess,
} from './actions';

//---------------------------------------------------------------------
const getPlanRepastFoodPortionListRequest = (pageDetail) => {
  if (pageDetail) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/planrepastfoodportion?ps=${pageDetail.pageSize}&pn=${pageDetail.pageNumber}`
      )
      .then((response) => response.data);
  }
  // eslint-disable-next-line no-else-return
  else {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/planrepastfoodportion`)
      .then((response) => response.data);
  }
};

function* getPlanRepastFoodPortionListItems({ payload }) {
  try {
    let pageSize = yield select((state) => state.settings.pageRecordCount);
    // eslint-disable-next-line no-param-reassign
    payload.pageSize = pageSize;
    const response = yield call(getPlanRepastFoodPortionListRequest, payload);
    yield put(getPlanRepastFoodPortionListSuccess(response));
  } catch (error) {
    yield put(getPlanRepastFoodPortionListError(error));
  }
}
//----------------------------------------------------------------------------------
const setPlanRepastFoodPortionSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/planrepastfoodportion/${id}`)
    .then((response) => response.data.data);

function* setPlanRepastFoodPortionSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setPlanRepastFoodPortionSelectedRecordRequest,
      payload
    );
    yield put(setPlanRepastFoodPortionSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setPlanRepastFoodPortionSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addPlanRepastFoodPortionRecordRequest = (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/planrepastfoodportion`, record)
    .then((response) => response.data);

function* addPlanRepastFoodPortionRecords({ payload }) {
  try {
    const response = yield call(addPlanRepastFoodPortionRecordRequest, payload);

    let planRepast = yield select((state) => state.planRepast);
    yield put(
      addPlanRepastFoodPortionRecordSuccess({
        planRepast: planRepast,
        singleData: response.singleData,
      })
    );
    yield call(getPlanRepastFoodPortionListItems);
    const planSelectedRecord = yield select(
      (state) => state.plan.selectedRecord
    );

    yield put(speciallyWithId(planSelectedRecord.id));
  } catch (error) {
    yield put(
      addPlanRepastFoodPortionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updatePlanRepastFoodPortionRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/planrepastfoodportion`, record)
    .then((response) => response.data);

function* updatePlanRepastFoodPortionRecords({ payload }) {
  try {
    yield call(updatePlanRepastFoodPortionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updatePlanRepastFoodPortionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    //yield call(getPlanRepastFoodPortionListItems);
    const planSelectedRecord = yield select(
      (state) => state.plan.selectedRecord
    );

    yield put(speciallyWithId(planSelectedRecord.id));
  } catch (error) {
    yield put(
      updatePlanRepastFoodPortionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

const updatePlanRepastFoodPortionDefaultValueRequest = (record) =>
  axios
    .put(
      `${process.env.REACT_APP_API_URL}/planrepastfoodportion/defaultValueUpdate`,
      record
    )
    .then((response) => response.data);

function* updatePlanRepastFoodPortionDefaultValue({ payload }) {
  try {
    yield call(updatePlanRepastFoodPortionDefaultValueRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updatePlanRepastFoodPortionDefaultValueSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    const planSelectedRecord = yield select(
      (state) => state.plan.selectedRecord
    );

    yield put(speciallyWithId(planSelectedRecord.id));
  } catch (error) {
    yield put(
      updatePlanRepastFoodPortionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

const deletePlanRepastFoodPortionRecordRequest = (recordId) =>
  axios
    .delete(
      `${process.env.REACT_APP_API_URL}/planrepastfoodportion/${recordId}`
    )
    .then((response) => response.data);

function* deletePlanRepastFoodPortionRecords({ payload }) {
  try {
    yield call(deletePlanRepastFoodPortionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deletePlanRepastFoodPortionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getPlanRepastFoodPortionListItems);
  } catch (error) {
    yield put(
      deletePlanRepastFoodPortionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(
    PLANREPASTFOODPORTION_GET_LIST,
    getPlanRepastFoodPortionListItems
  );
}

export function* watchSetSelected() {
  yield takeEvery(
    PLANREPASTFOODPORTION_SET_SELECTED,
    setPlanRepastFoodPortionSelectedRecord
  );
}
export function* wathcAddItem() {
  yield takeEvery(
    PLANREPASTFOODPORTION_ADD_RECORD,
    addPlanRepastFoodPortionRecords
  );
}
export function* wathcUpdateItem() {
  yield takeEvery(
    PLANREPASTFOODPORTION_UPDATE_RECORD,
    updatePlanRepastFoodPortionRecords
  );
}
export function* wathcUpdateDefaultValue() {
  yield debounce(
    800,
    PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD,
    updatePlanRepastFoodPortionDefaultValue
  );
}

export function* wathcDeleteItem() {
  yield takeEvery(
    PLANREPASTFOODPORTION_DELETE_RECORD,
    deletePlanRepastFoodPortionRecords
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcUpdateDefaultValue),
    fork(wathcDeleteItem),
  ]);
}
