/* eslint-disable global-require */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const createdStore = { store: null };
// eslint-disable-next-line import/prefer-default-export
function configureStore(initialState) {
  const store = createStore(
    reducers,
    /* preloadedState, */
    initialState,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }
  sagaMiddleware.run(sagas);
  createdStore.store = store;
  return store;
}
export { configureStore, createdStore };
