/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  BODYMASSINDEXRANGE_ADD_RECORD,
  BODYMASSINDEXRANGE_UPDATE_RECORD,
  BODYMASSINDEXRANGE_GET_LIST,
  BODYMASSINDEXRANGE_SET_SELECTED,
  BODYMASSINDEXRANGE_DELETE_RECORD,
} from '../../actions';

import {
  getBodyMassIndexRangeListSuccess,
  getBodyMassIndexRangeListError,
  setBodyMassIndexRangeSelectedRecordSuccess,
  setBodyMassIndexRangeSelectedRecordError,
  addBodyMassIndexRangeRecordSuccess,
  addBodyMassIndexRangeRecordError,
  updateBodyMassIndexRangeRecordSuccess,
  updateBodyMassIndexRangeRecordError,
  deleteBodyMassIndexRangeRecordSuccess,
  deleteBodyMassIndexRangeRecordError,
} from './actions';

//---------------------------------------------------------------------
const getBodyMassIndexRangeListRequest = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/bodymassindexrange`)
    .then((response) => response.data.data);
};

function* getBodyMassIndexRangeListItems() {
  try {
    const response = yield call(getBodyMassIndexRangeListRequest);
    yield put(getBodyMassIndexRangeListSuccess(response));
  } catch (error) {
    yield put(getBodyMassIndexRangeListError(error));
  }
}
//----------------------------------------------------------------------------------
const setBodyMassIndexRangeSelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/bodymassindexrange/${id}`)
    .then((response) => response.data.singleData);
};

function* setBodyMassIndexRangeSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setBodyMassIndexRangeSelectedRecordRequest,
      payload
    );
    yield put(setBodyMassIndexRangeSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setBodyMassIndexRangeSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addBodyMassIndexRangeRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/bodymassindexrange`, record)
    .then((response) => response.data);
};

function* addBodyMassIndexRangeRecords({ payload }) {
  try {
    yield call(addBodyMassIndexRangeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addBodyMassIndexRangeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getBodyMassIndexRangeListItems);
  } catch (error) {
    yield put(
      addBodyMassIndexRangeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateBodyMassIndexRangeRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/bodymassindexrange`, record)
    .then((response) => response.data);
};

function* updateBodyMassIndexRangeRecords({ payload }) {
  try {
    yield call(updateBodyMassIndexRangeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateBodyMassIndexRangeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getBodyMassIndexRangeListItems);
  } catch (error) {
    yield put(
      updateBodyMassIndexRangeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteBodyMassIndexRangeRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/bodymassindexrange/${recordId}`)
    .then((response) => response.data);
};

function* deleteBodyMassIndexRangeRecords({ payload }) {
  try {
    yield call(deleteBodyMassIndexRangeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteBodyMassIndexRangeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getBodyMassIndexRangeListItems);
  } catch (error) {
    yield put(
      deleteBodyMassIndexRangeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(BODYMASSINDEXRANGE_GET_LIST, getBodyMassIndexRangeListItems);
}

export function* watchSetSelected() {
  yield takeEvery(
    BODYMASSINDEXRANGE_SET_SELECTED,
    setBodyMassIndexRangeSelectedRecord
  );
}
export function* wathcAddItem() {
  yield takeEvery(BODYMASSINDEXRANGE_ADD_RECORD, addBodyMassIndexRangeRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(
    BODYMASSINDEXRANGE_UPDATE_RECORD,
    updateBodyMassIndexRangeRecords
  );
}

export function* wathcDeleteItem() {
  yield takeEvery(
    BODYMASSINDEXRANGE_DELETE_RECORD,
    deleteBodyMassIndexRangeRecords
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
