/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  ROLE_ADD_RECORD,
  ROLE_UPDATE_RECORD,
  ROLE_GET_LIST,
  ROLE_SET_SELECTED,
  ROLE_DELETE_RECORD,
  ROLE_FULLEXISTROLE,
  ROLE_ISACTIVECHANGE,
  ROLE_PAGEGETROLES,
} from '../../actions';

import {
  getRoleListSuccess,
  getRoleListError,
  setRoleSelectedRecordSuccess,
  setRoleSelectedRecordError,
  addRoleRecordSuccess,
  addRoleRecordError,
  updateRoleRecordSuccess,
  deleteRoleRecordSuccess,
  deleteRoleRecordError,
  updateRoleRecordError,
  getFullExistRoleSuccess,
  getFullExistRoleError,
  isActiveChangeRoleSuccess,
  isActiveChangeRoleError,
  getPageGetRolesSuccess,
  getPageGetRolesError,
  getRoleList,
} from './actions';

//---------------------------------------------------------------------
const getRoleListRequest = async (pageDetail) => {
  if (pageDetail) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/role?ps=${pageDetail.pageSize}&pn=${pageDetail.pageNumber}`
      )
      .then((response) => response.data);
  }
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/role`)
    .then((response) => response.data.data);
};

function* getRoleListItems({ payload }) {
  try {
    if (payload) {
      let pageSize = yield select((state) => state.settings.pageRecordCount);
      payload.pageSize = pageSize;
    }
    const response = yield call(getRoleListRequest, payload);
    yield put(getRoleListSuccess(response));
  } catch (error) {
    yield put(getRoleListError(error));
  }
}
//----------------------------------------------------------------------------------
const setRoleSelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/role/${id}`)
    .then((response) => response.data.singleData);
};

function* setRoleSelectedRecord({ payload }) {
  try {
    const response = yield call(setRoleSelectedRecordRequest, payload);
    yield put(setRoleSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setRoleSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addRoleRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/role`, record)
    .then((response) => response.data);
};

function* addRoleRecords({ payload }) {
  try {
    yield call(addRoleRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addRoleRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getRoleListItems);
  } catch (error) {
    yield put(
      addRoleRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateRoleRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/role`, record)
    .then((response) => response.data);
};

function* updateRoleRecords({ payload }) {
  try {
    yield call(updateRoleRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateRoleRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield put(getRoleList, { pageNumber: 0 });
  } catch (error) {
    yield put(
      updateRoleRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeRoleRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/role/isactivechange/${recordId}`)
    .then((response) => response.data.singleData);

function* isActiveChangeRole({ payload }) {
  try {
    const response = yield call(isActiveChangeRoleRequest, payload);
    yield put(isActiveChangeRoleSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeRoleError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const deleteRoleRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/role/${recordId}`)
    .then((response) => response.data);
};

function* deleteRoleRecords({ payload }) {
  try {
    yield call(deleteRoleRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteRoleRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getRoleListItems);
  } catch (error) {
    yield put(
      deleteRoleRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const fullExistRoleRequest = async (roleControlModel) => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/role/fullroleexist?dn=${roleControlModel.displayName}&rv=${roleControlModel.roleValue}&id=${roleControlModel.id}`
    )
    .then((response) => response.data);
};

function* fullExistRoleRecords({ payload }) {
  try {
    const response = yield call(fullExistRoleRequest, payload);
    yield put(getFullExistRoleSuccess(response));
    yield call(getRoleListItems);
  } catch (error) {
    yield put(
      getFullExistRoleError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const PageGetRolesRequest = async (roleControlModel) => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/role/pageroles?page=${
        roleControlModel.page
      }${
        roleControlModel.fullsearch !== undefined
          ? `&fullsearch=${roleControlModel.fullsearch}`
          : ''
      }`
    )
    .then((response) => response.data);
};

function* PageGetRolesRecords({ payload }) {
  try {
    const response = yield call(PageGetRolesRequest, payload);
    yield put(getPageGetRolesSuccess(response));
  } catch (error) {
    yield put(
      getPageGetRolesError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(ROLE_GET_LIST, getRoleListItems);
}

export function* watchSetSelected() {
  yield takeEvery(ROLE_SET_SELECTED, setRoleSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(ROLE_ADD_RECORD, addRoleRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(ROLE_UPDATE_RECORD, updateRoleRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(ROLE_DELETE_RECORD, deleteRoleRecords);
}
export function* wathcFullRoleExist() {
  yield takeEvery(ROLE_FULLEXISTROLE, fullExistRoleRecords);
}
export function* watchPageGetRoles() {
  yield takeEvery(ROLE_PAGEGETROLES, PageGetRolesRecords);
}
export function* watchIsActiveChangeItem() {
  yield takeEvery(ROLE_ISACTIVECHANGE, isActiveChangeRole);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(wathcFullRoleExist),
    fork(watchPageGetRoles),
    fork(watchIsActiveChangeItem),
  ]);
}
