/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  MEAL_ADD_RECORD,
  MEAL_UPDATE_RECORD,
  MEAL_GET_LIST,
  MEAL_SET_SELECTED,
  MEAL_DELETE_RECORD,
  deleteMealRecordSuccess,
  deleteMealRecordError,
  MEAL_ISACTIVECHANGE,
  MEAL_GET_PENDING_LIST,
  MEAL_GET_SHARED_LIST,
  MEAL_SHARE,
  MEAL_CONFIRM,
  MEAL_IMPORT_FOR_ME,
} from '../../actions';

import {
  getMealListSuccess,
  getMealListError,
  setMealSelectedRecordSuccess,
  setMealSelectedRecordError,
  addMealRecordSuccess,
  addMealRecordError,
  updateMealRecordSuccess,
  updateMealRecordError,
  isActiveChangeMealSuccess,
  isActiveChangeMealError,
  getMealPendingListError,
  getMealPendingListSuccess,
  getMealSharedListError,
  getMealSharedListSuccess,
  mealShareSuccess,
  mealShareError,
  mealConfirmSuccess,
  mealConfirmError,
  importForMeMealSuccess,
  importForMeMealError,
} from './actions';
import { setFoodSelectedRecordSuccess } from '../food/actions';
//---------------------------------------------------------------------
const getMealListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/meal${url || ''}`)
    .then((response) => response.data);
};

function* getMealListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      if ((payload.filter && payload.filter !== '') || payload.sort) {
        payload.includes = [
          'MealFoods',
          'MealFoods.Food',
          'MealFoods.Food.FoodNutrients',
          'MealFoods.Food.FoodNutrients.Nutrient',
        ];
      }

      const response = yield call(getMealListRequest, filterBuilder(payload));
      yield put(getMealListSuccess(response));
      yield put(setFoodSelectedRecordSuccess(null));
    } else {
      const response = yield call(getMealListRequest);
      yield put(getMealListSuccess(response));
    }
  } catch (error) {
    yield put(getMealListError(error));
  }
}
//----------------------------------------------------------------------------------

const getMealPendingListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/meal/pending${url || ''}`)
    .then((response) => response.data);
};

function* getMealPendingListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      if ((payload.filter && payload.filter !== '') || payload.sort) {
        payload.includes = [
          'MealFoods',
          'MealFoods.Food',
          'MealFoods.Food.FoodNutrients',
          'MealFoods.Food.FoodNutrients.Nutrient',
        ];
      }

      const response = yield call(
        getMealPendingListRequest,
        filterBuilder(payload)
      );
      yield put(getMealPendingListSuccess(response));
    } else {
      const response = yield call(getMealPendingListRequest);
      yield put(getMealPendingListSuccess(response));
    }
  } catch (error) {
    yield put(getMealPendingListError(error));
  }
}
//----------------------------------------------------------------------------------
const getMealSharedListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/meal/shared${url || ''}`)
    .then((response) => response.data);
};

function* getMealSharedListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      if ((payload.filter && payload.filter !== '') || payload.sort) {
        payload.includes = [
          'MealFoods',
          'MealFoods.Food',
          'MealFoods.Food.FoodNutrients',
          'MealFoods.Food.FoodNutrients.Nutrient',
        ];
      }

      const response = yield call(
        getMealSharedListRequest,
        filterBuilder(payload)
      );
      yield put(getMealSharedListSuccess(response));
    } else {
      const response = yield call(getMealSharedListRequest);
      yield put(getMealSharedListSuccess(response));
    }
  } catch (error) {
    yield put(getMealSharedListError(error));
  }
}
//----------------------------------------------------------------------------------
const mealShareRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/meal/mealShare/${recordId}`)
    .then((response) => response.data);

function* mealShare({ payload }) {
  try {
    const response = yield call(mealShareRequest, payload);
    yield put(mealShareSuccess(response));
  } catch (error) {
    yield put(
      mealShareError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const mealConfirmRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/meal/mealConfirm/${recordId}`)
    .then((response) => response.data);

function* mealConfirm({ payload }) {
  try {
    const response = yield call(mealConfirmRequest, payload);
    yield put(mealConfirmSuccess(response));
  } catch (error) {
    yield put(
      mealConfirmError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const mealImportForMeRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/meal/mealImportForMe/${recordId}`)
    .then((response) => response.data);

function* mealImportForMe({ payload }) {
  try {
    const response = yield call(mealImportForMeRequest, payload);
    yield put(importForMeMealSuccess(response));
  } catch (error) {
    yield put(
      importForMeMealError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const setMealSelectedRecordRequest = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/meal/${id}`)
    .then((response) => response.data.singleData);
};

function* setMealSelectedRecord({ payload }) {
  try {
    const response = yield call(setMealSelectedRecordRequest, payload);
    yield put(setMealSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setMealSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addMealRecordRequest = (record) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/meal`, record)
    .then((response) => response.data);
};

function* addMealRecords({ payload }) {
  try {
    yield call(addMealRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addMealRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getMealListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addMealRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateMealRecordRequest = (record) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}/meal`, record)
    .then((response) => response.data);
};

function* updateMealRecords({ payload }) {
  try {
    const response = yield call(updateMealRecordRequest, payload);
    yield put(updateMealRecordSuccess(response));
    // yield call(getMealListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateMealRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeMealRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/meal/isactivechange/${recordId}`)
    .then((response) => response.data);

function* isActiveChangeMeal({ payload }) {
  try {
    const response = yield call(isActiveChangeMealRequest, payload);
    yield put(isActiveChangeMealSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeMealError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const deleteMealRecordRequest = (recordId) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/meal/${recordId}`)
    .then((response) => response.data);
};

function* deleteMealRecords({ payload }) {
  try {
    yield call(deleteMealRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteMealRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getMealListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteMealRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

export function* watchGetList() {
  yield debounce(200, MEAL_GET_LIST, getMealListItems);
}
export function* watchGetPendingList() {
  yield debounce(200, MEAL_GET_PENDING_LIST, getMealPendingListItems);
}
export function* watchGetSharedList() {
  yield debounce(200, MEAL_GET_SHARED_LIST, getMealSharedListItems);
}
export function* watchShare() {
  yield takeEvery(MEAL_SHARE, mealShare);
}
export function* watchConfirm() {
  yield takeEvery(MEAL_CONFIRM, mealConfirm);
}
export function* watchImportForMe() {
  yield takeEvery(MEAL_IMPORT_FOR_ME, mealImportForMe);
}
export function* watchSetSelected() {
  yield takeEvery(MEAL_SET_SELECTED, setMealSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(MEAL_ADD_RECORD, addMealRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(MEAL_UPDATE_RECORD, updateMealRecords);
}
export function* wathcDeleteItem() {
  yield takeEvery(MEAL_DELETE_RECORD, deleteMealRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(MEAL_ISACTIVECHANGE, isActiveChangeMeal);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetPendingList),
    fork(watchGetSharedList),
    fork(watchShare),
    fork(watchConfirm),
    fork(watchImportForMe),
    fork(watchSetSelected),
    fork(wathcIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
