// eslint-disable-next-line import/no-cycle
import {
  ACTIVITYTYPE_GET_LIST,
  ACTIVITYTYPE_GET_LIST_SUCCESS,
  ACTIVITYTYPE_GET_LIST_ERROR,
  ACTIVITYTYPE_GET_GROUPED_LIST,
  ACTIVITYTYPE_GET_GROUPED_LIST_SUCCESS,
  ACTIVITYTYPE_GET_GROUPED_LIST_ERROR,
  ACTIVITYTYPE_ADD_RECORD,
  ACTIVITYTYPE_ADD_RECORD_ERROR,
  ACTIVITYTYPE_ADD_RECORD_SUCCESS,
  ACTIVITYTYPE_DELETE_RECORD,
  ACTIVITYTYPE_DELETE_RECORD_ERROR,
  ACTIVITYTYPE_DELETE_RECORD_SUCCESS,
  ACTIVITYTYPE_RESET_STATUSES,
  ACTIVITYTYPE_SET_SELECTED,
  ACTIVITYTYPE_SET_SELECTED_ERROR,
  ACTIVITYTYPE_SET_SELECTED_SUCCESS,
  ACTIVITYTYPE_UPDATE_RECORD,
  ACTIVITYTYPE_UPDATE_RECORD_ERROR,
  ACTIVITYTYPE_UPDATE_RECORD_SUCCESS,
  ACTIVITYTYPE_UPDATE_SELECTED,
  ACTIVITYTYPE_ISACTIVECHANGE,
  ACTIVITYTYPE_ISACTIVECHANGE_ERROR,
  ACTIVITYTYPE_ISACTIVECHANGE_SUCCESS,
} from '../../actions';

//-------------------------------------------------------------------------
export const getActivityTypeList = (record) => ({
  type: ACTIVITYTYPE_GET_LIST,
  payload: record,
});

export const getActivityTypeListSuccess = (items) => ({
  type: ACTIVITYTYPE_GET_LIST_SUCCESS,
  payload: items,
});

export const getActivityTypeListError = (error) => ({
  type: ACTIVITYTYPE_GET_LIST_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const getActivityTypeGroupedList = () => ({
  type: ACTIVITYTYPE_GET_GROUPED_LIST,
});

export const getActivityTypeGroupedListSuccess = (items) => ({
  type: ACTIVITYTYPE_GET_GROUPED_LIST_SUCCESS,
  payload: items,
});

export const getActivityTypeGroupedListError = (error) => ({
  type: ACTIVITYTYPE_GET_GROUPED_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setActivityTypeSelectedRecord = (recordId) => ({
  type: ACTIVITYTYPE_SET_SELECTED,
  payload: recordId,
});

export const setActivityTypeSelectedRecordSuccess = (record) => ({
  type: ACTIVITYTYPE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setActivityTypeSelectedRecordError = (error) => ({
  type: ACTIVITYTYPE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateActivityTypeSelectedRecord = (record) => ({
  type: ACTIVITYTYPE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addActivityTypeRecord = (record) => ({
  type: ACTIVITYTYPE_ADD_RECORD,
  payload: record,
});

export const addActivityTypeRecordSuccess = (msg) => ({
  type: ACTIVITYTYPE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addActivityTypeRecordError = (error) => ({
  type: ACTIVITYTYPE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateActivityTypeRecords = (record) => ({
  type: ACTIVITYTYPE_UPDATE_RECORD,
  payload: record,
});

export const updateActivityTypeRecordSuccess = (msg) => ({
  type: ACTIVITYTYPE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateActivityTypeRecordError = (error) => ({
  type: ACTIVITYTYPE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteActivityTypeRecords = (record) => ({
  type: ACTIVITYTYPE_DELETE_RECORD,
  payload: record,
});

export const deleteActivityTypeRecordSuccess = (msg) => ({
  type: ACTIVITYTYPE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteActivityTypeRecordError = (error) => ({
  type: ACTIVITYTYPE_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeActivityType = (record) => ({
  type: ACTIVITYTYPE_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeActivityTypeSuccess = (msg) => ({
  type: ACTIVITYTYPE_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeActivityTypeError = (error) => ({
  type: ACTIVITYTYPE_ISACTIVECHANGE_ERROR,
  payload: error,
});
export const resetActivityTypeStatuses = () => ({
  type: ACTIVITYTYPE_RESET_STATUSES,
});
//-------------------------------------------------------------------------
