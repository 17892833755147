/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  FOODGROUP_ADD_RECORD,
  FOODGROUP_UPDATE_RECORD,
  FOODGROUP_GET_LIST,
  FOODGROUP_GET_DROPDOWN_LIST,
  FOODGROUP_SET_SELECTED,
  FOODGROUP_DELETE_RECORD,
  FOODGROUP_ISACTIVECHANGE,
} from '../../actions';

import {
  getFoodGroupListSuccess,
  getFoodGroupListError,
  getFoodGroupDropDownListSuccess,
  getFoodGroupDropDownListError,
  setFoodGroupSelectedRecordSuccess,
  setFoodGroupSelectedRecordError,
  addFoodGroupRecordSuccess,
  addFoodGroupRecordError,
  updateFoodGroupRecordSuccess,
  updateFoodGroupRecordError,
  isActiveChangeFoodGroupSuccess,
  isActiveChangeFoodGroupError,
  deleteFoodGroupRecordSuccess,
  deleteFoodGroupRecordError,
} from './actions';

//---------------------------------------------------------------------
const getFoodGroupListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/foodgroup${url || ''}`)
    .then((response) => response.data);
};

function* getFoodGroupListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getFoodGroupListRequest,
        filterBuilder(payload)
      );
      yield put(getFoodGroupListSuccess(response));
    } else {
      const response = yield call(getFoodGroupListRequest);
      yield put(getFoodGroupListSuccess(response));
    }
  } catch (error) {
    yield put(getFoodGroupListError(error));
  }
}
//----------------------------------------------------------------------------------
const getFoodGroupDropDownListRequest = ({ payload }) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/foodgroup/fordropdown${
        payload ? '' : '/withsubitems'
      }`
    )
    .then((response) => response.data);
};

function* getFoodGroupDropDownListItems({ payload }) {
  try {
    const response = yield call(getFoodGroupDropDownListRequest, payload);
    yield put(getFoodGroupDropDownListSuccess(response));
  } catch (error) {
    yield put(getFoodGroupDropDownListError(error));
  }
}
//----------------------------------------------------------------------------------
const setFoodGroupSelectedRecordRequest = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/foodgroup/${id}`)
    .then((response) => response.data.singleData);
};

function* setFoodGroupSelectedRecord({ payload }) {
  try {
    const response = yield call(setFoodGroupSelectedRecordRequest, payload);
    yield put(setFoodGroupSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setFoodGroupSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addFoodGroupRecordRequest = (record) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/foodgroup`, record)
    .then((response) => response.data);
};

function* addFoodGroupRecords({ payload }) {
  try {
    yield call(addFoodGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addFoodGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getFoodGroupListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addFoodGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateFoodGroupRecordRequest = (record) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}/foodgroup`, record)
    .then((response) => response.data);
};

function* updateFoodGroupRecords({ payload }) {
  try {
    yield call(updateFoodGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateFoodGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getFoodGroupListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateFoodGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//--------------------------------------------------------------------
const isActiveChangeFoodGroupRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/foodgroup/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeFoodGroup({ payload }) {
  try {
    const response = yield call(isActiveChangeFoodGroupRequest, payload);
    yield put(isActiveChangeFoodGroupSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeFoodGroupError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const deleteFoodGroupRecordRequest = (recordId) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/foodgroup/${recordId}`)
    .then((response) => response.data);
};

function* deleteFoodGroupRecords({ payload }) {
  try {
    yield call(deleteFoodGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteFoodGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getFoodGroupListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteFoodGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

export function* watchGetList() {
  yield debounce(800, FOODGROUP_GET_LIST, getFoodGroupListItems);
}

export function* watchGetDropDownList() {
  yield takeEvery(FOODGROUP_GET_DROPDOWN_LIST, getFoodGroupDropDownListItems);
}

export function* watchSetSelected() {
  yield takeEvery(FOODGROUP_SET_SELECTED, setFoodGroupSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(FOODGROUP_ADD_RECORD, addFoodGroupRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(FOODGROUP_UPDATE_RECORD, updateFoodGroupRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(FOODGROUP_DELETE_RECORD, deleteFoodGroupRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(FOODGROUP_ISACTIVECHANGE, isActiveChangeFoodGroup);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetDropDownList),
    fork(watchSetSelected),
    fork(wathcIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
