/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  COUPON_ADD_RECORD,
  COUPON_UPDATE_RECORD,
  COUPON_GET_LIST,
  COUPON_SET_SELECTED,
  COUPON_DELETE_RECORD,
  deleteCouponRecordSuccess,
  deleteCouponRecordError,
  COUPON_ISACTIVECHANGE,
} from '../../actions';

import {
  getCouponListSuccess,
  getCouponListError,
  setCouponSelectedRecordSuccess,
  setCouponSelectedRecordError,
  addCouponRecordSuccess,
  addCouponRecordError,
  updateCouponRecordSuccess,
  updateCouponRecordError,
  isActiveChangeCouponSuccess,
  isActiveChangeCouponError,
} from './actions';

//---------------------------------------------------------------------
const getCouponListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/coupon${url || ''}`)
    .then((response) => response.data);
};

function* getCouponListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(getCouponListRequest, filterBuilder(payload));
      yield put(getCouponListSuccess(response));
    } else {
      const response = yield call(getCouponListRequest);
      yield put(getCouponListSuccess(response));
    }
  } catch (error) {
    yield put(getCouponListError(error));
  }
}
//----------------------------------------------------------------------------------
const setCouponSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/coupon/${id}`)
    .then((response) => response.data.singleData);

function* setCouponSelectedRecord({ payload }) {
  try {
    const response = yield call(setCouponSelectedRecordRequest, payload);
    yield put(setCouponSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setCouponSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addCouponRecordRequest = async (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/coupon`, record)
    .then((response) => response.data);

function* addCouponRecords({ payload }) {
  try {
    yield call(addCouponRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addCouponRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getCouponListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addCouponRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateCouponRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/coupon`, record)
    .then((response) => response.data);

function* updateCouponRecords({ payload }) {
  try {
    yield call(updateCouponRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateCouponRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getCouponListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateCouponRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const isActiveChangeCouponRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/coupon/isactivechange/${recordId}`)
    .then((response) => response.data);

function* isActiveChangeCoupon({ payload }) {
  try {
    const response = yield call(isActiveChangeCouponRequest, payload);
    yield put(isActiveChangeCouponSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeCouponError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

//----------------------------------------------------------------------------------
const deleteCouponRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/coupon/${recordId}`)
    .then((response) => response.data);

function* deleteCouponRecords({ payload }) {
  try {
    yield call(deleteCouponRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteCouponRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getCouponListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteCouponRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

export function* watchGetList() {
  yield debounce(800, COUPON_GET_LIST, getCouponListItems);
}

export function* watchSetSelected() {
  yield takeEvery(COUPON_SET_SELECTED, setCouponSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(COUPON_ADD_RECORD, addCouponRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(COUPON_UPDATE_RECORD, updateCouponRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(COUPON_DELETE_RECORD, deleteCouponRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(COUPON_ISACTIVECHANGE, isActiveChangeCoupon);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcIsActiveChangeItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
