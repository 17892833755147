// eslint-disable-next-line import/no-cycle
import {
  FORMULA_GET_LIST,
  FORMULA_GET_LIST_SUCCESS,
  FORMULA_GET_LIST_ERROR,
  FORMULA_ADD_RECORD,
  FORMULA_ADD_RECORD_ERROR,
  FORMULA_ADD_RECORD_SUCCESS,
  FORMULA_DELETE_RECORD,
  FORMULA_DELETE_RECORD_ERROR,
  FORMULA_DELETE_RECORD_SUCCESS,
  FORMULA_RESET_STATUSES,
  FORMULA_SET_SELECTED,
  FORMULA_SET_SELECTED_ERROR,
  FORMULA_SET_SELECTED_SUCCESS,
  FORMULA_UPDATE_RECORD,
  FORMULA_UPDATE_RECORD_ERROR,
  FORMULA_UPDATE_RECORD_SUCCESS,
  FORMULA_UPDATE_SELECTED,
} from '../../actions';

//-------------------------------------------------------------------------
export const getFormulaList = () => ({
  type: FORMULA_GET_LIST,
});

export const getFormulaListSuccess = (items) => ({
  type: FORMULA_GET_LIST_SUCCESS,
  payload: items,
});

export const getFormulaListError = (error) => ({
  type: FORMULA_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setFormulaSelectedRecord = (recordId) => ({
  type: FORMULA_SET_SELECTED,
  payload: recordId,
});

export const setFormulaSelectedRecordSuccess = (record) => ({
  type: FORMULA_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setFormulaSelectedRecordError = (error) => ({
  type: FORMULA_SET_SELECTED_ERROR,
  payload: error,
});

export const updateFormulaSelectedRecord = (record) => ({
  type: FORMULA_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addFormulaRecord = (record) => ({
  type: FORMULA_ADD_RECORD,
  payload: record,
});

export const addFormulaRecordSuccess = (msg) => ({
  type: FORMULA_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addFormulaRecordError = (error) => ({
  type: FORMULA_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateFormulaRecords = (record) => ({
  type: FORMULA_UPDATE_RECORD,
  payload: record,
});

export const updateFormulaRecordSuccess = (msg) => ({
  type: FORMULA_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateFormulaRecordError = (error) => ({
  type: FORMULA_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteFormulaRecords = (record) => ({
  type: FORMULA_DELETE_RECORD,
  payload: record,
});

export const deleteFormulaRecordSuccess = (msg) => ({
  type: FORMULA_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteFormulaRecordError = (error) => ({
  type: FORMULA_DELETE_RECORD_ERROR,
  payload: error,
});

export const resetFormulaStatuses = () => ({ type: FORMULA_RESET_STATUSES });
//-------------------------------------------------------------------------
