/* eslint-disable object-shorthand */
// eslint-disable-next-line import/no-cycle
import {
  FOODPORTION_GET_LIST,
  FOODPORTION_GET_LIST_SUCCESS,
  FOODPORTION_GET_LIST_ERROR,
  FOODPORTION_SET_SELECTED,
  FOODPORTION_UPDATE_SELECTED,
  FOODPORTION_SET_SELECTED_SUCCESS,
  FOODPORTION_SET_SELECTED_ERROR,
  FOODPORTION_ADD_RECORD_SUCCESS,
  FOODPORTION_ADD_RECORD_ERROR,
  FOODPORTION_ADD_RECORD,
  FOODPORTION_UPDATE_RECORD_SUCCESS,
  FOODPORTION_UPDATE_RECORD_ERROR,
  FOODPORTION_UPDATE_RECORD,
  FOODPORTION_DELETE_RECORD,
  FOODPORTION_DELETE_RECORD_SUCCESS,
  FOODPORTION_DELETE_RECORD_ERROR,
  FOODPORTION_RESET_STATUSES,
  FOODPORTION_FILTER,
} from '../../actions';

//-------------------------------------------------------------------------
export const getFoodPortionList = (pageDetail) => ({
  type: FOODPORTION_GET_LIST,
  payload: pageDetail,
});

export const getFoodPortionListSuccess = (items) => ({
  type: FOODPORTION_GET_LIST_SUCCESS,
  payload: items,
});

export const getFoodPortionListError = (error) => ({
  type: FOODPORTION_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setFoodPortionSelectedRecord = (recordId) => ({
  type: FOODPORTION_SET_SELECTED,
  payload: recordId,
});

export const setFoodPortionSelectedRecordSuccess = (record) => ({
  type: FOODPORTION_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setFoodPortionSelectedRecordError = (error) => ({
  type: FOODPORTION_SET_SELECTED_ERROR,
  payload: error,
});

export const updateFoodPortionSelectedRecord = (record) => ({
  type: FOODPORTION_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addFoodPortionRecord = (record) => ({
  type: FOODPORTION_ADD_RECORD,
  payload: record,
});

export const addFoodPortionRecordSuccess = (msg) => ({
  type: FOODPORTION_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addFoodPortionRecordError = (error) => ({
  type: FOODPORTION_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateFoodPortionRecords = (record) => ({
  type: FOODPORTION_UPDATE_RECORD,
  payload: record,
});

export const updateFoodPortionRecordSuccess = (msg) => ({
  type: FOODPORTION_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateFoodPortionRecordError = (error) => ({
  type: FOODPORTION_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteFoodPortionRecords = (id, secondId) => ({
  type: FOODPORTION_DELETE_RECORD,
  payload: { id: id, secondId: secondId },
});

export const deleteFoodPortionRecordSuccess = (msg) => ({
  type: FOODPORTION_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteFoodPortionRecordError = (error) => ({
  type: FOODPORTION_DELETE_RECORD_ERROR,
  payload: error,
});
export const filterFoodPortion = (id, secondId) => ({
  type: FOODPORTION_FILTER,
  payload: { id: id, secondId: secondId },
});

//-------------------------------------------------------------------------
export const resetFoodPortionStatuses = () => ({
  type: FOODPORTION_RESET_STATUSES,
});
