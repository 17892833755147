/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  RECIPE_GET_LIST,
  RECIPE_GET_LIST_SUCCESS,
  RECIPE_GET_LIST_ERROR,
  RECIPE_SET_SELECTED,
  RECIPE_UPDATE_SELECTED,
  RECIPE_SET_SELECTED_SUCCESS,
  RECIPE_SET_SELECTED_ERROR,
  RECIPE_ADD_RECORD_SUCCESS,
  RECIPE_ADD_RECORD_ERROR,
  RECIPE_ADD_RECORD,
  RECIPE_UPDATE_RECORD_SUCCESS,
  RECIPE_UPDATE_RECORD_ERROR,
  RECIPE_UPDATE_RECORD,
  RECIPE_DELETE_RECORD,
  RECIPE_DELETE_RECORD_SUCCESS,
  RECIPE_DELETE_RECORD_ERROR,
  RECIPE_RESET_STATUSES,
  RECIPE_ISACTIVECHANGE,
  RECIPE_ISACTIVECHANGE_SUCCESS,
  RECIPE_ISACTIVECHANGE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getRecipeList = (pageDetail) => ({
  type: RECIPE_GET_LIST,
  payload: pageDetail,
});

export const getRecipeListSuccess = (items) => ({
  type: RECIPE_GET_LIST_SUCCESS,
  payload: items,
});

export const getRecipeListError = (error) => ({
  type: RECIPE_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setRecipeSelectedRecord = (recordId) => ({
  type: RECIPE_SET_SELECTED,
  payload: recordId,
});

export const setRecipeSelectedRecordSuccess = (record) => ({
  type: RECIPE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setRecipeSelectedRecordError = (error) => ({
  type: RECIPE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateRecipeSelectedRecord = (record) => ({
  type: RECIPE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addRecipeRecord = (record) => ({
  type: RECIPE_ADD_RECORD,
  payload: record,
});

export const addRecipeRecordSuccess = (msg) => ({
  type: RECIPE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addRecipeRecordError = (error) => ({
  type: RECIPE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateRecipeRecords = (record) => ({
  type: RECIPE_UPDATE_RECORD,
  payload: record,
});

export const updateRecipeRecordSuccess = (msg) => ({
  type: RECIPE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateRecipeRecordError = (error) => ({
  type: RECIPE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteRecipeRecords = (record) => ({
  type: RECIPE_DELETE_RECORD,
  payload: record,
});

export const deleteRecipeRecordSuccess = (msg) => ({
  type: RECIPE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteRecipeRecordError = (error) => ({
  type: RECIPE_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeRecipe = (record) => ({
  type: RECIPE_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeRecipeSuccess = (msg) => ({
  type: RECIPE_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeRecipeError = (error) => ({
  type: RECIPE_ISACTIVECHANGE_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const resetRecipeStatuses = () => ({ type: RECIPE_RESET_STATUSES });
