// eslint-disable-next-line import/no-cycle
import {
  BODYMASSINDEXRANGE_GET_LIST,
  BODYMASSINDEXRANGE_GET_LIST_SUCCESS,
  BODYMASSINDEXRANGE_GET_LIST_ERROR,
  BODYMASSINDEXRANGE_ADD_RECORD,
  BODYMASSINDEXRANGE_ADD_RECORD_ERROR,
  BODYMASSINDEXRANGE_ADD_RECORD_SUCCESS,
  BODYMASSINDEXRANGE_DELETE_RECORD,
  BODYMASSINDEXRANGE_DELETE_RECORD_ERROR,
  BODYMASSINDEXRANGE_DELETE_RECORD_SUCCESS,
  BODYMASSINDEXRANGE_RESET_STATUSES,
  BODYMASSINDEXRANGE_SET_SELECTED,
  BODYMASSINDEXRANGE_SET_SELECTED_ERROR,
  BODYMASSINDEXRANGE_SET_SELECTED_SUCCESS,
  BODYMASSINDEXRANGE_UPDATE_RECORD,
  BODYMASSINDEXRANGE_UPDATE_RECORD_ERROR,
  BODYMASSINDEXRANGE_UPDATE_RECORD_SUCCESS,
  BODYMASSINDEXRANGE_UPDATE_SELECTED,
} from '../../actions';

//-------------------------------------------------------------------------
export const getBodyMassIndexRangeList = () => ({
  type: BODYMASSINDEXRANGE_GET_LIST,
});

export const getBodyMassIndexRangeListSuccess = (items) => ({
  type: BODYMASSINDEXRANGE_GET_LIST_SUCCESS,
  payload: items,
});

export const getBodyMassIndexRangeListError = (error) => ({
  type: BODYMASSINDEXRANGE_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setBodyMassIndexRangeSelectedRecord = (recordId) => ({
  type: BODYMASSINDEXRANGE_SET_SELECTED,
  payload: recordId,
});

export const setBodyMassIndexRangeSelectedRecordSuccess = (record) => ({
  type: BODYMASSINDEXRANGE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setBodyMassIndexRangeSelectedRecordError = (error) => ({
  type: BODYMASSINDEXRANGE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateBodyMassIndexRangeSelectedRecord = (record) => ({
  type: BODYMASSINDEXRANGE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addBodyMassIndexRangeRecord = (record) => ({
  type: BODYMASSINDEXRANGE_ADD_RECORD,
  payload: record,
});

export const addBodyMassIndexRangeRecordSuccess = (msg) => ({
  type: BODYMASSINDEXRANGE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addBodyMassIndexRangeRecordError = (error) => ({
  type: BODYMASSINDEXRANGE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateBodyMassIndexRangeRecords = (record) => ({
  type: BODYMASSINDEXRANGE_UPDATE_RECORD,
  payload: record,
});

export const updateBodyMassIndexRangeRecordSuccess = (msg) => ({
  type: BODYMASSINDEXRANGE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateBodyMassIndexRangeRecordError = (error) => ({
  type: BODYMASSINDEXRANGE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteBodyMassIndexRangeRecords = (record) => ({
  type: BODYMASSINDEXRANGE_DELETE_RECORD,
  payload: record,
});

export const deleteBodyMassIndexRangeRecordSuccess = (msg) => ({
  type: BODYMASSINDEXRANGE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteBodyMassIndexRangeRecordError = (error) => ({
  type: BODYMASSINDEXRANGE_DELETE_RECORD_ERROR,
  payload: error,
});

export const resetBodyMassIndexRangeStatuses = () => ({
  type: BODYMASSINDEXRANGE_RESET_STATUSES,
});
//-------------------------------------------------------------------------
