// eslint-disable-next-line import/no-cycle
import {
  USERROLE_GET_LIST,
  USERROLE_GET_LIST_SUCCESS,
  USERROLE_GET_LIST_ERROR,
  USERROLE_ADD_RECORD,
  USERROLE_ADD_RECORD_ERROR,
  USERROLE_ADD_RECORD_SUCCESS,
  USERROLE_DELETE_RECORD,
  USERROLE_DELETE_RECORD_ERROR,
  USERROLE_DELETE_RECORD_SUCCESS,
  USERROLE_RESET_STATUSES,
  USERROLE_SET_SELECTED,
  USERROLE_SET_SELECTED_ERROR,
  USERROLE_SET_SELECTED_SUCCESS,
  USERROLE_UPDATE_RECORD,
  USERROLE_UPDATE_RECORD_ERROR,
  USERROLE_UPDATE_RECORD_SUCCESS,
  USERROLE_UPDATE_SELECTED,
  USERROLE_BYUSERID,
  USERROLE_BYUSERID_SUCCESS,
  USERROLE_BYUSERID_ERROR,
  USERROLE_UPDATE_SELECTED_RECORDS,
  USERROLE_UPSERT_RECORD,
  USERROLE_UPSERT_RECORD_SUCCESS,
  USERROLE_UPSERT_RECORD_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getUserRoleList = () => ({
  type: USERROLE_GET_LIST,
});

export const getUserRoleListSuccess = (items) => ({
  type: USERROLE_GET_LIST_SUCCESS,
  payload: items,
});

export const getUserRoleListError = (error) => ({
  type: USERROLE_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setUserRoleSelectedRecord = (recordId) => ({
  type: USERROLE_SET_SELECTED,
  payload: recordId,
});

export const setUserRoleSelectedRecordSuccess = (record) => ({
  type: USERROLE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setUserRoleSelectedRecordError = (error) => ({
  type: USERROLE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateUserRoleSelectedRecord = (record) => ({
  type: USERROLE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addUserRoleRecord = (record) => ({
  type: USERROLE_ADD_RECORD,
  payload: record,
});

export const addUserRoleRecordSuccess = (msg) => ({
  type: USERROLE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addUserRoleRecordError = (error) => ({
  type: USERROLE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateUserRoleRecords = (record) => ({
  type: USERROLE_UPDATE_RECORD,
  payload: record,
});

export const updateUserRoleRecordSuccess = (msg) => ({
  type: USERROLE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateUserRoleRecordError = (error) => ({
  type: USERROLE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteUserRoleRecords = (record) => ({
  type: USERROLE_DELETE_RECORD,
  payload: record,
});

export const deleteUserRoleRecordSuccess = (msg) => ({
  type: USERROLE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteUserRoleRecordError = (error) => ({
  type: USERROLE_DELETE_RECORD_ERROR,
  payload: error,
});

export const getUserRoleByUserList = (recordId) => ({
  type: USERROLE_BYUSERID,
  payload: recordId,
});

export const getUserRoleByUserListSuccess = (items) => ({
  type: USERROLE_BYUSERID_SUCCESS,
  payload: items,
});

export const getUserRoleByUserListError = (error) => ({
  type: USERROLE_BYUSERID_ERROR,
  payload: error,
});

export const updateUserRoleSelectedRecords = (records) => ({
  type: USERROLE_UPDATE_SELECTED_RECORDS,
  payload: records,
});

export const upsertUserRoleRecords = (records) => ({
  type: USERROLE_UPSERT_RECORD,
  payload: records,
});

export const upsertUserRoleRecordsSuccess = (items) => ({
  type: USERROLE_UPSERT_RECORD_SUCCESS,
  payload: items,
});
export const upsertUserRoleRecordsError = (error) => ({
  type: USERROLE_UPSERT_RECORD_ERROR,
  payload: error,
});

export const resetUserRoleStatuses = () => ({
  type: USERROLE_RESET_STATUSES,
});
//-------------------------------------------------------------------------
