/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  PORTIONPICTURE_GET_LIST,
  PORTIONPICTURE_GET_LIST_SUCCESS,
  PORTIONPICTURE_GET_LIST_ERROR,
  PORTIONPICTURE_SET_SELECTED,
  PORTIONPICTURE_UPDATE_SELECTED,
  PORTIONPICTURE_SET_SELECTED_SUCCESS,
  PORTIONPICTURE_SET_SELECTED_ERROR,
  PORTIONPICTURE_ADD_RECORD_SUCCESS,
  PORTIONPICTURE_ADD_RECORD_ERROR,
  PORTIONPICTURE_ADD_RECORD,
  PORTIONPICTURE_UPDATE_RECORD_SUCCESS,
  PORTIONPICTURE_UPDATE_RECORD_ERROR,
  PORTIONPICTURE_UPDATE_RECORD,
  PORTIONPICTURE_DELETE_RECORD,
  PORTIONPICTURE_DELETE_RECORD_SUCCESS,
  PORTIONPICTURE_DELETE_RECORD_ERROR,
  PORTIONPICTURE_RESET_STATUSES,
} from '../../actions';

//-------------------------------------------------------------------------
export const getPortionPictureList = (pageDetail) => ({
  type: PORTIONPICTURE_GET_LIST,
  payload: pageDetail,
});

export const getPortionPictureListSuccess = (items) => ({
  type: PORTIONPICTURE_GET_LIST_SUCCESS,
  payload: items,
});

export const getPortionPictureListError = (error) => ({
  type: PORTIONPICTURE_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setPortionPictureSelectedRecord = (recordId) => ({
  type: PORTIONPICTURE_SET_SELECTED,
  payload: recordId,
});

export const setPortionPictureSelectedRecordSuccess = (record) => ({
  type: PORTIONPICTURE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setPortionPictureSelectedRecordError = (error) => ({
  type: PORTIONPICTURE_SET_SELECTED_ERROR,
  payload: error,
});

export const updatePortionPictureSelectedRecord = (record) => ({
  type: PORTIONPICTURE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addPortionPictureRecord = (record) => ({
  type: PORTIONPICTURE_ADD_RECORD,
  payload: record,
});

export const addPortionPictureRecordSuccess = (msg) => ({
  type: PORTIONPICTURE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addPortionPictureRecordError = (error) => ({
  type: PORTIONPICTURE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updatePortionPictureRecords = (record) => ({
  type: PORTIONPICTURE_UPDATE_RECORD,
  payload: record,
});

export const updatePortionPictureRecordSuccess = (msg) => ({
  type: PORTIONPICTURE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updatePortionPictureRecordError = (error) => ({
  type: PORTIONPICTURE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deletePortionPictureRecords = (record) => ({
  type: PORTIONPICTURE_DELETE_RECORD,
  payload: record,
});

export const deletePortionPictureRecordSuccess = (msg) => ({
  type: PORTIONPICTURE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deletePortionPictureRecordError = (error) => ({
  type: PORTIONPICTURE_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const resetPortionPictureStatuses = () => ({
  type: PORTIONPICTURE_RESET_STATUSES,
});
