/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  MEALGROUP_GET_LIST,
  MEALGROUP_GET_LIST_SUCCESS,
  MEALGROUP_GET_LIST_ERROR,
  MEALGROUP_SET_SELECTED,
  MEALGROUP_UPDATE_SELECTED,
  MEALGROUP_SET_SELECTED_SUCCESS,
  MEALGROUP_SET_SELECTED_ERROR,
  MEALGROUP_ADD_RECORD_SUCCESS,
  MEALGROUP_ADD_RECORD_ERROR,
  MEALGROUP_ADD_RECORD,
  MEALGROUP_UPDATE_RECORD_SUCCESS,
  MEALGROUP_UPDATE_RECORD_ERROR,
  MEALGROUP_UPDATE_RECORD,
  MEALGROUP_DELETE_RECORD,
  MEALGROUP_DELETE_RECORD_SUCCESS,
  MEALGROUP_DELETE_RECORD_ERROR,
  MEALGROUP_RESET_STATUSES,
  MEALGROUP_ISACTIVECHANGE,
  MEALGROUP_ISACTIVECHANGE_SUCCESS,
  MEALGROUP_ISACTIVECHANGE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getMealGroupList = (pageDetail) => ({
  type: MEALGROUP_GET_LIST,
  payload: pageDetail,
});

export const getMealGroupListSuccess = (items) => ({
  type: MEALGROUP_GET_LIST_SUCCESS,
  payload: items,
});

export const getMealGroupListError = (error) => ({
  type: MEALGROUP_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setMealGroupSelectedRecord = (recordId) => ({
  type: MEALGROUP_SET_SELECTED,
  payload: recordId,
});

export const setMealGroupSelectedRecordSuccess = (record) => ({
  type: MEALGROUP_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setMealGroupSelectedRecordError = (error) => ({
  type: MEALGROUP_SET_SELECTED_ERROR,
  payload: error,
});

export const updateMealGroupSelectedRecord = (record) => ({
  type: MEALGROUP_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addMealGroupRecord = (record) => ({
  type: MEALGROUP_ADD_RECORD,
  payload: record,
});

export const addMealGroupRecordSuccess = (msg) => ({
  type: MEALGROUP_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addMealGroupRecordError = (error) => ({
  type: MEALGROUP_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateMealGroupRecords = (record) => ({
  type: MEALGROUP_UPDATE_RECORD,
  payload: record,
});

export const updateMealGroupRecordSuccess = (msg) => ({
  type: MEALGROUP_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateMealGroupRecordError = (error) => ({
  type: MEALGROUP_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteMealGroupRecords = (record) => ({
  type: MEALGROUP_DELETE_RECORD,
  payload: record,
});

export const deleteMealGroupRecordSuccess = (msg) => ({
  type: MEALGROUP_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteMealGroupRecordError = (error) => ({
  type: MEALGROUP_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeMealGroup = (record) => ({
  type: MEALGROUP_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeMealGroupSuccess = (msg) => ({
  type: MEALGROUP_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeMealGroupError = (error) => ({
  type: MEALGROUP_ISACTIVECHANGE_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const resetMealGroupStatuses = () => ({
  type: MEALGROUP_RESET_STATUSES,
});
