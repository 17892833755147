/* eslint-disable import/no-cycle */
import {
  NUTRIENT_GET_LIST,
  NUTRIENT_GET_LIST_SUCCESS,
  NUTRIENT_GET_LIST_ERROR,
  NUTRIENT_SET_SELECTED,
  NUTRIENT_UPDATE_SELECTED,
  NUTRIENT_SET_SELECTED_SUCCESS,
  NUTRIENT_SET_SELECTED_ERROR,
  NUTRIENT_ADD_RECORD_SUCCESS,
  NUTRIENT_ADD_RECORD_ERROR,
  NUTRIENT_ADD_RECORD,
  NUTRIENT_UPDATE_RECORD_SUCCESS,
  NUTRIENT_UPDATE_RECORD_ERROR,
  NUTRIENT_UPDATE_RECORD,
  NUTRIENT_DELETE_RECORD,
  NUTRIENT_DELETE_RECORD_SUCCESS,
  NUTRIENT_DELETE_RECORD_ERROR,
  NUTRIENT_RESET_STATUSES,
  NUTRIENT_GETNAMEWITHID_LIST,
  NUTRIENT_GETNAMEWITHID_LIST_SUCCESS,
  NUTRIENT_GETNAMEWITHID_LIST_ERROR,
  NUTRIENT_GETNAMEWITHID_ONERECORD,
  NUTRIENT_GETNAMEWITHID_ONERECORD_SUCCESS,
  NUTRIENT_GETNAMEWITHID_ONERECORD_ERROR,
  NUTRIENT_ISACTIVECHANGE_ERROR,
  NUTRIENT_ISACTIVECHANGE_SUCCESS,
  NUTRIENT_ISACTIVECHANGE,
  NUTRIENT_CALCULATE,
  NUTRIENT_CALCULATE_SUCCESS,
  NUTRIENT_CALCULATE_ERROR,
  NUTRIENT_GROUPED,
  NUTRIENT_GROUPED_SUCCESS,
  NUTRIENT_GROUPED_ERROR,
  NUTRIENTTOFOODWORDLIST,
  NUTRIENTTOFOODWORDLIST_SUCCESS,
  NUTRIENTTOFOODWORDLIST_ERROR,
  NUTRIENTTOFOODWORDLIST_CLEAR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getNutrientList = (pageDetail) => ({
  type: NUTRIENT_GET_LIST,
  payload: pageDetail,
});

export const getNutrientListSuccess = (items) => ({
  type: NUTRIENT_GET_LIST_SUCCESS,
  payload: items,
});

export const getNutrientListError = (error) => ({
  type: NUTRIENT_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------

//-------------------------------------------------------------------------
export const getNutrientNameWithIdList = (pageDetail) => ({
  type: NUTRIENT_GETNAMEWITHID_LIST,
  payload: pageDetail,
});

export const getNutrientNameWithIdSuccess = (items) => ({
  type: NUTRIENT_GETNAMEWITHID_LIST_SUCCESS,
  payload: items,
});

export const getNutrientNameWithIdError = (error) => ({
  type: NUTRIENT_GETNAMEWITHID_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------

export const getNutrientNameWithIdOneRecord = (pageDetail) => ({
  type: NUTRIENT_GETNAMEWITHID_ONERECORD,
  payload: pageDetail,
});

export const getNutrientNameWithIdOneRecordSuccess = (items) => ({
  type: NUTRIENT_GETNAMEWITHID_ONERECORD_SUCCESS,
  payload: items,
});

export const getNutrientNameWithIdOneRecordError = (error) => ({
  type: NUTRIENT_GETNAMEWITHID_ONERECORD_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------

export const setNutrientSelectedRecord = (recordId) => ({
  type: NUTRIENT_SET_SELECTED,
  payload: recordId,
});

export const setNutrientSelectedRecordSuccess = (record) => ({
  type: NUTRIENT_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setNutrientSelectedRecordError = (error) => ({
  type: NUTRIENT_SET_SELECTED_ERROR,
  payload: error,
});

export const updateNutrientSelectedRecord = (record) => ({
  type: NUTRIENT_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addNutrientRecord = (record) => ({
  type: NUTRIENT_ADD_RECORD,
  payload: record,
});

export const addNutrientRecordSuccess = (msg) => ({
  type: NUTRIENT_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addNutrientRecordError = (error) => ({
  type: NUTRIENT_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateNutrientRecords = (record) => ({
  type: NUTRIENT_UPDATE_RECORD,
  payload: record,
});

export const updateNutrientRecordSuccess = (msg) => ({
  type: NUTRIENT_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateNutrientRecordError = (error) => ({
  type: NUTRIENT_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteNutrientRecords = (record) => ({
  type: NUTRIENT_DELETE_RECORD,
  payload: record,
});

export const deleteNutrientRecordSuccess = (msg) => ({
  type: NUTRIENT_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteNutrientRecordError = (error) => ({
  type: NUTRIENT_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeNutrient = (record) => ({
  type: NUTRIENT_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeNutrientSuccess = (msg) => ({
  type: NUTRIENT_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeNutrientError = (error) => ({
  type: NUTRIENT_ISACTIVECHANGE_ERROR,
  payload: error,
});
export const getNutrientGrouped = (record) => ({
  type: NUTRIENT_GROUPED,
  payload: record,
});

export const getNutrientGroupedSuccess = (msg) => ({
  type: NUTRIENT_GROUPED_SUCCESS,
  payload: msg,
});

export const getNutrientGroupedError = (error) => ({
  type: NUTRIENT_GROUPED_ERROR,
  payload: error,
});

export const nutrientCalculate = (record) => ({
  type: NUTRIENT_CALCULATE,
  payload: record,
});

export const nutrientCalculateSuccess = (msg) => ({
  type: NUTRIENT_CALCULATE_SUCCESS,
  payload: msg,
});

export const nutrientCalculateError = (error) => ({
  type: NUTRIENT_CALCULATE_ERROR,
  payload: error,
});

export const NutrientToFoodWordList = (record) => ({
  type: NUTRIENTTOFOODWORDLIST,
  payload: record,
});

export const NutrientToFoodWordListSuccess = (msg) => ({
  type: NUTRIENTTOFOODWORDLIST_SUCCESS,
  payload: msg,
});

export const NutrientToFoodWordListError = (error) => ({
  type: NUTRIENTTOFOODWORDLIST_ERROR,
  payload: error,
});
export const NutrientToFoodWordListClear = (error) => ({
  type: NUTRIENTTOFOODWORDLIST_CLEAR,
  payload: error,
});
//-------------------------------------------------------------------------
export const resetNutrientStatuses = () => ({ type: NUTRIENT_RESET_STATUSES });
