// eslint-disable-next-line import/no-cycle
import {
  NUTRIENTCOMMENT_GET_LIST,
  NUTRIENTCOMMENT_GET_LIST_SUCCESS,
  NUTRIENTCOMMENT_GET_LIST_ERROR,
  NUTRIENTCOMMENT_SET_SELECTED,
  NUTRIENTCOMMENT_UPDATE_SELECTED,
  NUTRIENTCOMMENT_SET_SELECTED_SUCCESS,
  NUTRIENTCOMMENT_SET_SELECTED_ERROR,
  NUTRIENTCOMMENT_ADD_RECORD_SUCCESS,
  NUTRIENTCOMMENT_ADD_RECORD_ERROR,
  NUTRIENTCOMMENT_ADD_RECORD,
  NUTRIENTCOMMENT_UPDATE_RECORD_SUCCESS,
  NUTRIENTCOMMENT_UPDATE_RECORD_ERROR,
  NUTRIENTCOMMENT_UPDATE_RECORD,
  NUTRIENTCOMMENT_DELETE_RECORD,
  NUTRIENTCOMMENT_DELETE_RECORD_SUCCESS,
  NUTRIENTCOMMENT_DELETE_RECORD_ERROR,
  NUTRIENTCOMMENT_RESET_STATUSES,
  NUTRIENTCOMMENT_ISACTIVECHANGE_SUCCESS,
  NUTRIENTCOMMENT_ISACTIVECHANGE_ERROR,
  NUTRIENTCOMMENT_ISACTIVECHANGE,
  NUTRIENTCOMMENT_BYFILTER,
  NUTRIENTCOMMENT_BYFILTER_SUCCESS,
  NUTRIENTCOMMENT_BYFILTER_ERROR,
  NUTRIENTCOMMENT_UPSERT_RECORD,
  NUTRIENTCOMMENT_UPSERT_RECORD_SUCCESS,
  NUTRIENTCOMMENT_UPSERT_RECORD_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getNutrientCommentList = (pageDetail) => ({
  type: NUTRIENTCOMMENT_GET_LIST,
  payload: pageDetail,
});

export const getNutrientCommentListSuccess = (items) => ({
  type: NUTRIENTCOMMENT_GET_LIST_SUCCESS,
  payload: items,
});

export const getNutrientCommentListError = (error) => ({
  type: NUTRIENTCOMMENT_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setNutrientCommentSelectedRecord = (recordId) => ({
  type: NUTRIENTCOMMENT_SET_SELECTED,
  payload: recordId,
});

export const setNutrientCommentSelectedRecordSuccess = (record) => ({
  type: NUTRIENTCOMMENT_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setNutrientCommentSelectedRecordError = (error) => ({
  type: NUTRIENTCOMMENT_SET_SELECTED_ERROR,
  payload: error,
});

export const updateNutrientCommentSelectedRecord = (record) => ({
  type: NUTRIENTCOMMENT_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addNutrientCommentRecord = (record) => ({
  type: NUTRIENTCOMMENT_ADD_RECORD,
  payload: record,
});

export const addNutrientCommentRecordSuccess = (msg) => ({
  type: NUTRIENTCOMMENT_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addNutrientCommentRecordError = (error) => ({
  type: NUTRIENTCOMMENT_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateNutrientCommentRecords = (record) => ({
  type: NUTRIENTCOMMENT_UPDATE_RECORD,
  payload: record,
});

export const updateNutrientCommentRecordSuccess = (msg) => ({
  type: NUTRIENTCOMMENT_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateNutrientCommentRecordError = (error) => ({
  type: NUTRIENTCOMMENT_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const deleteNutrientCommentRecords = (record) => ({
  type: NUTRIENTCOMMENT_DELETE_RECORD,
  payload: record,
});

export const deleteNutrientCommentRecordSuccess = (msg) => ({
  type: NUTRIENTCOMMENT_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteNutrientCommentRecordError = (error) => ({
  type: NUTRIENTCOMMENT_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeNutrientComment = (record) => ({
  type: NUTRIENTCOMMENT_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeNutrientCommentSuccess = (msg) => ({
  type: NUTRIENTCOMMENT_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeNutrientCommentError = (error) => ({
  type: NUTRIENTCOMMENT_ISACTIVECHANGE_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const NutrientCommentByFilter = (record) => ({
  type: NUTRIENTCOMMENT_BYFILTER,
  payload: record,
});

export const NutrientCommentByFilterSuccess = (msg) => ({
  type: NUTRIENTCOMMENT_BYFILTER_SUCCESS,
  payload: msg,
});

export const NutrientCommentByFilterError = (error) => ({
  type: NUTRIENTCOMMENT_BYFILTER_ERROR,
  payload: error,
});

export const upsertNutrientCommentRecords = (records) => ({
  type: NUTRIENTCOMMENT_UPSERT_RECORD,
  payload: records,
});

export const upsertNutrientCommentRecordsSuccess = (items) => ({
  type: NUTRIENTCOMMENT_UPSERT_RECORD_SUCCESS,
  payload: items,
});
export const upsertNutrientCommentRecordsError = (error) => ({
  type: NUTRIENTCOMMENT_UPSERT_RECORD_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const resetNutrientCommentStatuses = () => ({
  type: NUTRIENTCOMMENT_RESET_STATUSES,
});
