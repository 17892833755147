/* eslint-disable consistent-return */
/* eslint-disable no-return-await */
/* eslint-disable no-use-before-define */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { refreshTimer } from 'helpers/tokenHelpers';
import { auth } from '../../helpers/Firebase';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  setUserPreferenceByUserId,
  changeCurrentUser,
  REFRESH_USER_TOKEN,
  CLOSE_TAB_USER,
  APPROVAL_FILE,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  refreshUserToken,
  closeTabUserSuccess,
  closeTabUserError,
  approvalFileUserError,
  approvalFileUserSuccess,
} from './actions';

import { applicationRoot } from '../../constants/defaultValues';
import {
  setCurrentUser,
  setCurrentUserPreference,
  setToken,
} from '../../helpers/Utils';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = (Username, Password) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/auth`,
      JSON.stringify({ Username, Password }),
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((response) => response.data)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (loginUser.token != null && loginUser.token !== '') {
      setCurrentUser(loginUser.data);
      setToken(loginUser.token);
      yield put(loginUserSuccess(loginUser.data));
      yield put(setUserPreferenceByUserId(loginUser.data.id));
      yield put(changeCurrentUser(loginUser.data));
      if (loginUser.data.isPayment === false)
        history.push(`${applicationRoot}/profile/settings`);
      else history.push(applicationRoot);
    } else {
      if (loginUser.data && loginUser.data.isPayment === false) {
        setCurrentUser(loginUser.data);
        yield put(loginUserSuccess(loginUser.data));
        history.push(`${applicationRoot}/user/approval`);
        return;
      }
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(applicationRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  history.push(applicationRoot);
};

function* logout({ payload }) {
  axios
    .post(`${process.env.REACT_APP_API_URL}/auth/logout`, { value: false })
    .then((response) => response.data)
    .catch((error) => error)
    .finally(() => {
      setToken(null);
      setCurrentUser(null);
      setCurrentUserPreference();
    });
  const { history } = payload;
  yield call(logoutAsync, history);
}

export function* watchCloseTabUser() {
  yield takeEvery(CLOSE_TAB_USER, closeTabUser);
}

function* closeTabUser({ payload }) {
  try {
    const result = yield call(closeTabUserRequest, payload);

    yield put(closeTabUserSuccess(result));
  } catch (error) {
    yield put(closeTabUserError(error));
  }
}
async function closeTabUserRequest({ payload }) {
  await axios
    .post(`${process.env.REACT_APP_API_URL}/auth/logout`, payload)
    .then((response) => response.data)
    .catch((error) => error);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  await axios
    .put(
      `${process.env.REACT_APP_API_URL}/auth/forgotten`,
      JSON.stringify({ email }),
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((response) => response.data)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  const { history } = payload;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
      history.push(applicationRoot);
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (password, repassword, key) => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  await axios
    .put(
      `${process.env.REACT_APP_API_URL}/auth/updatepass`,
      JSON.stringify({ key, password, repassword }),
      requestOptions
    )
    .then((response) => response.data)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { password, repassword, key } = payload;
  const { history } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      password,
      repassword,
      key
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
      history.push(applicationRoot);
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchRefreshToken() {
  yield takeEvery(REFRESH_USER_TOKEN, refreshUserTokenRecords);
}

const refreshUserTokenAsync = async ({ url }) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/auth/${url || 'refreshToken'}`)
    .then((response) => response.data)
    .catch((error) => error);
};

const refreshTimerInterval = async () => {
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      const refresh = refreshTimer();
      if (refresh) {
        resolve(true);
        clearInterval(interval);
      }
    }, 5000);
  });
};

function* refreshUserTokenRecords() {
  const refresh = yield refreshTimerInterval();
  if (!refresh) return;
  const response = yield call(refreshUserTokenAsync, { url: 'refreshToken' });
  if (response.token) {
    setToken(response.token);
    yield put(refreshUserToken());
  }
}

export function* watchApprovalFile() {
  yield takeEvery(APPROVAL_FILE, approvalFileRecords);
}

const approvalFileRequest = async (record) => {
  const formData = new FormData();
  formData.append('file', record.value.file);
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/user/approvalFile/${record.value.id}`,
      formData,
      {
        headers: { 'content-type': 'multipart/form-data;' },
      }
    )
    .then((response) => response.data);
};

function* approvalFileRecords({ payload }) {
  try {
    const { history } = payload.value;
    const response = yield call(approvalFileRequest, payload);
    if (response) {
      yield put(
        approvalFileUserSuccess('Başarılı Dosya işlemi Onay bekleyiniz')
      );
      setCurrentUser(null);
      setToken(null);
      yield put(loginUserSuccess(null));
      history.push(`${applicationRoot}/user/login`);
    }
  } catch (error) {
    yield put(approvalFileUserError('Dosya Yüklemede hata oluştu'));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchRefreshToken),
    fork(watchCloseTabUser),
    fork(watchApprovalFile),
  ]);
}
