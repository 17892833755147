/* eslint-disable no-const-assign */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder, getCurrentUser, setCurrentUser } from 'helpers/Utils';
import { changeCurrentUser } from '../../settings/actions';
import {
  USER_ADD_RECORD,
  USER_UPDATE_RECORD,
  USER_GET_LIST,
  USER_SET_SELECTED,
  USER_DELETE_RECORD,
  deleteUserRecordSuccess,
  deleteUserRecordError,
  USER_PROFILE_PICTURE_UPDATE,
  USER_CHANGEPASSWORD_UPDATE,
  USER_DELETEPICTURE,
  USER_PENDINGAPPROVAL,
  USER_PENDINGAPPROVAL_CHANGE,
  USER_PAYMENT,
  USER_PREVIEWPDF,
  USER_PAYMENTINTERNAL,
  USER_ISPAYMENTCONTROL,
  USER_PERIOD,
  USER_GETPROFILE_PICTURE,
  USER_ISACTIVECHANGE,
  USER_CHANGE_PASSWORD,
  USERPLAN_PLANANDUSER,
} from '../../actions';

import {
  getUserListSuccess,
  getUserListError,
  setUserSelectedRecordSuccess,
  setUserSelectedRecordError,
  addUserRecordSuccess,
  addUserRecordError,
  updateUserRecordSuccess,
  updateUserRecordError,
  updateUserProfilePictureRecordError,
  updateUserProfilePictureRecordSuccess,
  updateUserChangePasswordUpdateSuccess,
  updateUserChangePasswordUpdateError,
  userDeletePictureSuccess,
  userDeletePictureError,
  userPendingApprovalSuccess,
  userPendingApprovalError,
  userPendingApprovalChangeSuccess,
  userPendingApprovalChangeError,
  userPaymentSuccess,
  userPaymentError,
  userPreviewPdfSuccess,
  userPreviewPdfError,
  userPaymentClearModal,
  userIsPaymentControlError,
  getUserPeriodSuccess,
  getUserPeriodError,
  getProfilePictureSuccess,
  getProfilePictureError,
  isActiveChangeUserSuccess,
  isActiveChangeUserError,
  userChangePasswordSuccess,
  userChangePasswordError,
  userPlanAndUserError,
  userPlanAndUserSuccess,
} from './actions';

//---------------------------------------------------------------------
const getUserListRequest = async (url) => {
  if (url) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/user${url || ''}`)
      .then((response) => response.data);
  }
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/user`)
    .then((response) => response.data.data);
};

function* getUserListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;
      if ((payload.filter && payload.filter !== '') || payload.sort) {
        payload.includes = ['UserPlan'];
      }
      const response = yield call(getUserListRequest, filterBuilder(payload));
      yield put(getUserListSuccess(response));
    } else {
      const response = yield call(getUserListRequest);
      yield put(getUserListSuccess(response));
    }
  } catch (error) {
    yield put(getUserListError(error));
  }
}
//----------------------------------------------------------------------------------
const setUserSelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/user/${id}`)
    .then((response) => response.data.singleData);
};

function* setUserSelectedRecord({ payload }) {
  try {
    const response = yield call(setUserSelectedRecordRequest, payload);
    yield put(setUserSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setUserSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addUserRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/user`, record)
    .then((response) => response.data);
};

function* addUserRecords({ payload }) {
  try {
    yield call(addUserRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addUserRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getUserListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addUserRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateUserRecordRequest = async (record) => {
  if (!record.excludingFields) {
    return await axios
      .put(`${process.env.REACT_APP_API_URL}/user`, record)
      .then((response) => response.data);
  }
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/user/excludingUpdate`,
    record.values
  );
};

function* updateUserRecords({ payload }) {
  try {
    const response = yield call(updateUserRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateUserRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    if (!payload.profileEdit)
      yield call(getUserListItems, { payload: { pageNumber: 0 } });
    else {
      const currentUser = getCurrentUser();
      if (payload.id === currentUser.id) {
        yield put(
          changeCurrentUser({
            ...currentUser,
            firstName: response.singleData.firstName,
            lastName: response.singleData.lastName,
          })
        );
      }
    }
  } catch (error) {
    yield put(
      updateUserRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteUserRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/user/${recordId}`)
    .then((response) => response.data);
};

function* deleteUserRecords({ payload }) {
  try {
    yield call(deleteUserRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteUserRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getUserListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteUserRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const updateUserProfilePictureRequest = async (record) => {
  // eslint-disable-next-line prefer-const
  let formData = new FormData();
  formData.set('id', record.id);
  formData.set('file', record.file);
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/user/profilepicture`, formData, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    })
    .then((response) => response.data);
};

function* updateUserProfilePictureRecords({ payload }) {
  try {
    yield call(updateUserProfilePictureRequest, payload);
    yield put(
      updateUserProfilePictureRecordSuccess({
        timestamp: Date.now(),
        name: 'Success',
        message: 'Record file upload successfully.',
      })
    );
  } catch (error) {
    yield put(
      updateUserProfilePictureRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const updateUserChangePasswordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/auth/changepass`, record)
    .then((response) => response.data);
};

function* updateUserChangePasswordRecords({ payload }) {
  try {
    yield call(updateUserChangePasswordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateUserChangePasswordUpdateSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
  } catch (error) {
    yield put(
      updateUserChangePasswordUpdateError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const userDeletePictureRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/user/deletepic/${record}`)
    .then((response) => response.data);
};

function* userDeletePictureRecords({ payload }) {
  try {
    yield call(userDeletePictureRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      userDeletePictureSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
  } catch (error) {
    yield put(
      userDeletePictureError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const userPendigApprovalListRequest = async (url, isApproval = false) => {
  if (url.includes('filter')) {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/user/approval${
          url || ''
        }&b=${isApproval}`
      )
      .then((response) => response.data);
    // eslint-disable-next-line no-else-return
  } else {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/user/approval/filter${
          url || ''
        }&b=${isApproval}`
      )
      .then((response) => response.data);
  }
};

function* userPendingApprovalRecords({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;
      payload.includes = ['UserPlan', 'UserPeriods'];
      const response = yield call(
        userPendigApprovalListRequest,
        filterBuilder(payload),
        payload.isApproval
      );

      yield put(userPendingApprovalSuccess(response));
    } else {
      const response = yield call(userPendigApprovalListRequest);
      yield put(userPendingApprovalSuccess(response));
    }
  } catch (error) {
    yield put(
      userPendingApprovalError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const userPendigApprovalChangeListRequest = async ({ id, status }) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/user/changeapproval/${id}/${status}`)
    .then((response) => response.data);
};

function* userPendingApprovalChangeRecords({ payload }) {
  try {
    yield call(userPendigApprovalChangeListRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      userPendingApprovalChangeSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(userPendingApprovalRecords, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      userPendingApprovalChangeError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const userPaymentRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/payment`, record)
    .then((response) => response.data);
};

function* userPaymentRecords({ payload }) {
  try {
    const response = yield call(userPaymentRequest, payload);
    yield put(userPaymentSuccess(response));
  } catch (error) {
    yield put(
      userPaymentError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
const userPreviewPdfRecord = async (id) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/user/userPdf/${id}`)
    .then((response) => response.data);
};

function* previewPdfRecord({ payload }) {
  try {
    let response = yield call(userPreviewPdfRecord, payload);
    response = response.replace('\\', '');
    var binary_string = window.atob(response);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    var file = new Blob([bytes.buffer], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    yield put(userPreviewPdfSuccess(bytes.buffer));
  } catch (error) {
    yield put(
      userPreviewPdfError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
const userPaymentInternal = async (record) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/payment/internal`)
    .then((response) => response.data);
};

function* userPaymentInternalRecords({ payload }) {
  try {
    const response = yield call(userPaymentInternal, payload);
    if (response !== 0) {
      yield put(userPaymentClearModal(response));
    }
    if (response === 1) {
      setTimeout(() => {
        setCurrentUser({ ...getCurrentUser(), isPayment: true });
        window.location.href = '/';
      }, 1500);
    }
    // yield put(userPaymentSuccess(response));
  } catch (error) {
    yield put(
      userPaymentError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
const userIsPaymentControlRequest = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/user/ispayment`)
    .then((response) => response.data);
};

function* userIsPaymentControlRecords({ payload }) {
  try {
    const response = yield call(userIsPaymentControlRequest, payload);

    if (response === false) {
      setCurrentUser({ ...getCurrentUser(), isPayment: false });
      window.location.href = '/';
    }
  } catch (error) {
    yield put(
      userIsPaymentControlError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
const userGetProfilePictureRequest = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/user/getprofilepicture`)
    .then((response) => response.data);
};

function* userGetProfilePictureRecords() {
  try {
    const response = yield call(userGetProfilePictureRequest);
    yield put(getProfilePictureSuccess(response));
  } catch (error) {
    yield put(
      getProfilePictureError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

const userPeriodRequest = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userperiod/period`)
    .then((response) => response.data);
};

function* userPeriodRecords() {
  try {
    const response = yield call(userPeriodRequest);
    yield put(getUserPeriodSuccess(response));
  } catch (error) {
    yield put(
      getUserPeriodError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const isActiveChangeUserRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/user/isactivechange/${recordId}`)
    .then((response) => response.data);

function* isActiveChangeUserRecords({ payload }) {
  try {
    const response = yield call(isActiveChangeUserRequest, payload);
    yield put(isActiveChangeUserSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeUserError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const userChangePasswordRequest = ({ email, newPassword }) =>
  axios
    .put(
      `${process.env.REACT_APP_API_URL}/user/changePassword/${email}/${newPassword}`
    )
    .then((response) => response.data.singleData);

function* userChangePasswordRecords({ payload }) {
  try {
    yield call(userChangePasswordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      userChangePasswordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
  } catch (error) {
    yield put(
      userChangePasswordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const userPlan_PlanAndUserRequest = () =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/userplan/getPlanAndUser`)
    .then((response) => response.data);

function* userPlan_PlanAndUser({ payload }) {
  try {
    const response = yield call(userPlan_PlanAndUserRequest, payload);
    yield put(userPlanAndUserSuccess(response));
  } catch (error) {
    yield put(
      userPlanAndUserError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

export function* watchGetList() {
  yield takeEvery(USER_GET_LIST, getUserListItems);
}

export function* watchSetSelected() {
  yield takeEvery(USER_SET_SELECTED, setUserSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(USER_ADD_RECORD, addUserRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(USER_UPDATE_RECORD, updateUserRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(USER_DELETE_RECORD, deleteUserRecords);
}

export function* wathhUpdateProfilePicture() {
  yield takeEvery(USER_PROFILE_PICTURE_UPDATE, updateUserProfilePictureRecords);
}
export function* wathcUpdateChangePassword() {
  yield takeEvery(USER_CHANGEPASSWORD_UPDATE, updateUserChangePasswordRecords);
}

export function* wathcDeletePicture() {
  yield takeEvery(USER_DELETEPICTURE, userDeletePictureRecords);
}
export function* watchPendingApprovalList() {
  yield takeEvery(USER_PENDINGAPPROVAL, userPendingApprovalRecords);
}
export function* watchPendingApprovalChange() {
  yield takeEvery(
    USER_PENDINGAPPROVAL_CHANGE,
    userPendingApprovalChangeRecords
  );
}
export function* watchPayment() {
  yield takeEvery(USER_PAYMENT, userPaymentRecords);
}
export function* watchPreviewPdf() {
  yield takeEvery(USER_PREVIEWPDF, previewPdfRecord);
}
export function* watchPaymentInternal() {
  yield takeEvery(USER_PAYMENTINTERNAL, userPaymentInternalRecords);
}
export function* watchIsPaymentControl() {
  yield takeEvery(USER_ISPAYMENTCONTROL, userIsPaymentControlRecords);
}
export function* watchUserPeriod() {
  yield takeEvery(USER_PERIOD, userPeriodRecords);
}
export function* watchGetProfilePicture() {
  yield takeEvery(USER_GETPROFILE_PICTURE, userGetProfilePictureRecords);
}

export function* watchIsActiveChangeItem() {
  yield takeEvery(USER_ISACTIVECHANGE, isActiveChangeUserRecords);
}

export function* watchChangePassword() {
  yield takeEvery(USER_CHANGE_PASSWORD, userChangePasswordRecords);
}
export function* watchPlanAndUser() {
  yield takeEvery(USERPLAN_PLANANDUSER, userPlan_PlanAndUser);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(wathhUpdateProfilePicture),
    fork(wathcUpdateChangePassword),
    fork(wathcDeletePicture),
    fork(watchPendingApprovalList),
    fork(watchPendingApprovalChange),
    fork(watchPayment),
    fork(watchPreviewPdf),
    fork(watchPaymentInternal),
    fork(watchIsPaymentControl),
    fork(watchUserPeriod),
    fork(watchGetProfilePicture),
    fork(watchIsActiveChangeItem),
    fork(watchChangePassword),
    fork(watchPlanAndUser),
  ]);
}
