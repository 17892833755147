/* eslint-disable import/no-cycle */
import {
  NUTRIENTGROUP_GET_LIST,
  NUTRIENTGROUP_GET_LIST_SUCCESS,
  NUTRIENTGROUP_GET_LIST_ERROR,
  NUTRIENTGROUP_SET_SELECTED,
  NUTRIENTGROUP_UPDATE_SELECTED,
  NUTRIENTGROUP_SET_SELECTED_SUCCESS,
  NUTRIENTGROUP_SET_SELECTED_ERROR,
  NUTRIENTGROUP_ADD_RECORD_SUCCESS,
  NUTRIENTGROUP_ADD_RECORD_ERROR,
  NUTRIENTGROUP_ADD_RECORD,
  NUTRIENTGROUP_UPDATE_RECORD_SUCCESS,
  NUTRIENTGROUP_UPDATE_RECORD_ERROR,
  NUTRIENTGROUP_UPDATE_RECORD,
  NUTRIENTGROUP_DELETE_RECORD,
  NUTRIENTGROUP_DELETE_RECORD_SUCCESS,
  NUTRIENTGROUP_DELETE_RECORD_ERROR,
  NUTRIENTGROUP_RESET_STATUSES,
  NUTRIENTGROUP_ISACTIVECHANGE,
  NUTRIENTGROUP_ISACTIVECHANGE_SUCCESS,
  NUTRIENTGROUP_ISACTIVECHANGE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getNutrientGroupList = (pageDetail) => ({
  type: NUTRIENTGROUP_GET_LIST,
  payload: pageDetail,
});

export const getNutrientGroupListSuccess = (items) => ({
  type: NUTRIENTGROUP_GET_LIST_SUCCESS,
  payload: items,
});

export const getNutrientGroupListError = (error) => ({
  type: NUTRIENTGROUP_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setNutrientGroupSelectedRecord = (recordId) => ({
  type: NUTRIENTGROUP_SET_SELECTED,
  payload: recordId,
});

export const setNutrientGroupSelectedRecordSuccess = (record) => ({
  type: NUTRIENTGROUP_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setNutrientGroupSelectedRecordError = (error) => ({
  type: NUTRIENTGROUP_SET_SELECTED_ERROR,
  payload: error,
});

export const updateNutrientGroupSelectedRecord = (record) => ({
  type: NUTRIENTGROUP_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addNutrientGroupRecord = (record) => ({
  type: NUTRIENTGROUP_ADD_RECORD,
  payload: record,
});

export const addNutrientGroupRecordSuccess = (msg) => ({
  type: NUTRIENTGROUP_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addNutrientGroupRecordError = (error) => ({
  type: NUTRIENTGROUP_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateNutrientGroupRecords = (record) => ({
  type: NUTRIENTGROUP_UPDATE_RECORD,
  payload: record,
});

export const updateNutrientGroupRecordSuccess = (msg) => ({
  type: NUTRIENTGROUP_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateNutrientGroupRecordError = (error) => ({
  type: NUTRIENTGROUP_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteNutrientGroupRecords = (record) => ({
  type: NUTRIENTGROUP_DELETE_RECORD,
  payload: record,
});

export const deleteNutrientGroupRecordSuccess = (msg) => ({
  type: NUTRIENTGROUP_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteNutrientGroupRecordError = (error) => ({
  type: NUTRIENTGROUP_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeNutrientGroup = (record) => ({
  type: NUTRIENTGROUP_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeNutrientGroupSuccess = (msg) => ({
  type: NUTRIENTGROUP_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeNutrientGroupError = (error) => ({
  type: NUTRIENTGROUP_ISACTIVECHANGE_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const resetNutrientGroupStatuses = () => ({
  type: NUTRIENTGROUP_RESET_STATUSES,
});
