/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  USERPLANROLE_ADD_RECORD,
  USERPLANROLE_UPDATE_RECORD,
  USERPLANROLE_GET_LIST,
  USERPLANROLE_SET_SELECTED,
  USERPLANROLE_DELETE_RECORD,
  USERPLANROLE_BYUSERID,
  USERPLANROLE_UPSERT_RECORD,
} from '../../actions';

import {
  getUserPlanRoleListSuccess,
  getUserPlanRoleListError,
  setUserPlanRoleSelectedRecordSuccess,
  setUserPlanRoleSelectedRecordError,
  addUserPlanRoleRecordSuccess,
  addUserPlanRoleRecordError,
  updateUserPlanRoleRecordSuccess,
  deleteUserPlanRoleRecordSuccess,
  deleteUserPlanRoleRecordError,
  updateUserPlanRoleRecordError,
  getUserRoleByUserPlanListSuccess,
  getUserRoleByUserPlanListError,
  upsertUserPlanRoleRecordsSuccess,
  upsertUserPlanRoleRecordsError,
} from './actions';
import { getUserPlanList } from '../userplan/actions';

//---------------------------------------------------------------------
const getUserPlanRoleListRequest = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userplanrole`)
    .then((response) => response.data.data);
};

function* getUserPlanRoleListItems() {
  try {
    const response = yield call(getUserPlanRoleListRequest);
    yield put(getUserPlanRoleListSuccess(response));
  } catch (error) {
    yield put(getUserPlanRoleListError(error));
  }
}
//----------------------------------------------------------------------------------
const setUserPlanRoleSelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userplanrole/${id}`)
    .then((response) => response.data.singleData);
};

function* setUserPlanRoleSelectedRecord({ payload }) {
  try {
    const response = yield call(setUserPlanRoleSelectedRecordRequest, payload);
    yield put(setUserPlanRoleSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setUserPlanRoleSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addUserPlanRoleRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/userplanrole`, record)
    .then((response) => response.data);
};

function* addUserPlanRoleRecords({ payload }) {
  try {
    yield call(addUserPlanRoleRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addUserPlanRoleRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getUserPlanRoleListItems);
  } catch (error) {
    yield put(
      addUserPlanRoleRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateUserPlanRoleRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/userplanrole`, record)
    .then((response) => response.data);
};

function* updateUserPlanRoleRecords({ payload }) {
  try {
    yield call(updateUserPlanRoleRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateUserPlanRoleRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getUserPlanRoleListItems);
  } catch (error) {
    yield put(
      updateUserPlanRoleRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteUserPlanRoleRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/userplanrole/${recordId}`)
    .then((response) => response.data);
};

function* deleteUserPlanRoleRecords({ payload }) {
  try {
    yield call(deleteUserPlanRoleRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteUserPlanRoleRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getUserPlanRoleListItems);
  } catch (error) {
    yield put(
      deleteUserPlanRoleRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const getUserPlanRoleByUserPlanIdListRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userplanrole/byPlan/${id}`)
    .then((response) => response.data.data);
};

function* getUserPlanRoleByUserPlanIdListItems({ payload }) {
  try {
    const response = yield call(
      getUserPlanRoleByUserPlanIdListRequest,
      payload
    );
    yield put(getUserRoleByUserPlanListSuccess(response));
  } catch (error) {
    yield put(getUserRoleByUserPlanListError(error));
  }
}
//--------------------------------------------------------------------
const upsertUserPlanRoleRecordRequest = async (records) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/userplanrole/upsert`, records)
    .then((response) => response.data);
};

function* upsertUserPlanRoleRecord({ payload }) {
  try {
    const response = yield call(upsertUserPlanRoleRecordRequest, payload);
    yield put(getUserPlanList({ pageNumber: 0 }));
    if (response) {
      const messageType = yield select((state) =>
        state.settings.notificationMessages.find(
          (x) => x.type === NotificationType.Success
        )
      );
      yield put(
        upsertUserPlanRoleRecordsSuccess(
          NotificationHelpers(messageType, NotificationSubType.Add)
        )
      );
    }
  } catch (error) {
    yield put(upsertUserPlanRoleRecordsError(error));
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(USERPLANROLE_GET_LIST, getUserPlanRoleListItems);
}

export function* watchSetSelected() {
  yield takeEvery(USERPLANROLE_SET_SELECTED, setUserPlanRoleSelectedRecord);
}
export function* watchAddItem() {
  yield takeEvery(USERPLANROLE_ADD_RECORD, addUserPlanRoleRecords);
}
export function* watchUpdateItem() {
  yield takeEvery(USERPLANROLE_UPDATE_RECORD, updateUserPlanRoleRecords);
}

export function* watchDeleteItem() {
  yield takeEvery(USERPLANROLE_DELETE_RECORD, deleteUserPlanRoleRecords);
}
export function* watchByUserPlanIdList() {
  yield takeEvery(USERPLANROLE_BYUSERID, getUserPlanRoleByUserPlanIdListItems);
}

export function* watchUpsert() {
  yield takeEvery(USERPLANROLE_UPSERT_RECORD, upsertUserPlanRoleRecord);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(watchAddItem),
    fork(watchUpdateItem),
    fork(watchDeleteItem),
    fork(watchByUserPlanIdList),
    fork(watchUpsert),
  ]);
}
