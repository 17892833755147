/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  FOODPORTION_ADD_RECORD,
  FOODPORTION_UPDATE_RECORD,
  FOODPORTION_GET_LIST,
  FOODPORTION_SET_SELECTED,
  FOODPORTION_DELETE_RECORD,
  deleteFoodPortionRecordSuccess,
  deleteFoodPortionRecordError,
} from '../../actions';

import {
  getFoodPortionListSuccess,
  getFoodPortionListError,
  setFoodPortionSelectedRecordSuccess,
  setFoodPortionSelectedRecordError,
  addFoodPortionRecordSuccess,
  addFoodPortionRecordError,
  updateFoodPortionRecordSuccess,
  updateFoodPortionRecordError,
} from './actions';

//---------------------------------------------------------------------
const getFoodPortionListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/foodportion${url || ''}`)
    .then((response) => response.data);
};

function* getFoodPortionListItems({ payload }) {
  try {
    if (payload) {
      let pageSize = yield select((state) => state.settings.pageRecordCount);
      payload.pageSize = pageSize;
      if ((payload.filter && payload.filter !== '') || payload.sort) {
        payload.includes = ['Food', 'Portion'];
      }
      const response = yield call(
        getFoodPortionListRequest,
        filterBuilder(payload)
      );
      yield put(getFoodPortionListSuccess(response));
    } else {
      const response = yield call(getFoodPortionListRequest);
      yield put(getFoodPortionListSuccess(response));
    }
  } catch (error) {
    yield put(getFoodPortionListError(error));
  }
}
//----------------------------------------------------------------------------------
const setFoodPortionSelectedRecordRequest = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/foodportion/${id}`)
    .then((response) => response.data.data);
};

function* setFoodPortionSelectedRecord({ payload }) {
  try {
    let pageSize = yield select((state) => state.settings.pageRecordCount);
    payload.pageSize = pageSize;
    const response = yield call(setFoodPortionSelectedRecordRequest, payload);
    yield put(setFoodPortionSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setFoodPortionSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addFoodPortionRecordRequest = (record) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/foodportion`, record)
    .then((response) => response.data);
};

function* addFoodPortionRecords({ payload }) {
  try {
    yield call(addFoodPortionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addFoodPortionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getFoodPortionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addFoodPortionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateFoodPortionRecordRequest = async (record) => {
  let formData = new FormData();

  formData.append('id', record[0].id);

  formData.append('pictureFile', record[0].pictureFile);
  formData.append('pictureFileContentType', record[0].pictureFileContentType);
  formData.append('picturePath', record[0].picturePath);
  formData.append('secondId', record[0].secondId);

  return axios
    .put(`${process.env.REACT_APP_API_URL}/foodportion`, formData, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    })
    .then((response) => response.data);
};

function* updateFoodPortionRecords({ payload }) {
  try {
    yield call(updateFoodPortionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateFoodPortionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getFoodPortionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateFoodPortionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteFoodPortionRecordRequest = (payload) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/foodportion/${payload.id}@${payload.secondId}`
    )
    .then((response) => response.data);
};

function* deleteFoodPortionRecords({ payload }) {
  try {
    yield call(deleteFoodPortionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteFoodPortionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getFoodPortionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteFoodPortionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(FOODPORTION_GET_LIST, getFoodPortionListItems);
}

export function* watchSetSelected() {
  yield takeEvery(FOODPORTION_SET_SELECTED, setFoodPortionSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(FOODPORTION_ADD_RECORD, addFoodPortionRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(FOODPORTION_UPDATE_RECORD, updateFoodPortionRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(FOODPORTION_DELETE_RECORD, deleteFoodPortionRecords);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
