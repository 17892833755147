// eslint-disable-next-line import/no-cycle
import {
  PLANREPASTFOOD_GET_LIST,
  PLANREPASTFOOD_GET_LIST_SUCCESS,
  PLANREPASTFOOD_GET_LIST_ERROR,
  PLANREPASTFOOD_SET_SELECTED,
  PLANREPASTFOOD_UPDATE_SELECTED,
  PLANREPASTFOOD_SET_SELECTED_SUCCESS,
  PLANREPASTFOOD_SET_SELECTED_ERROR,
  PLANREPASTFOOD_ADD_RECORD_SUCCESS,
  PLANREPASTFOOD_ADD_RECORD_ERROR,
  PLANREPASTFOOD_ADD_RECORD,
  PLANREPASTFOOD_UPDATE_RECORD_SUCCESS,
  PLANREPASTFOOD_UPDATE_RECORD_ERROR,
  PLANREPASTFOOD_UPDATE_RECORD,
  PLANREPASTFOOD_DELETE_RECORD,
  PLANREPASTFOOD_DELETE_RECORD_SUCCESS,
  PLANREPASTFOOD_DELETE_RECORD_ERROR,
  PLANREPASTFOOD_RESET_STATUSES,
  PLANREPASTFOOD_UPDATE_BATCHRECORD,
  PLANREPASTFOOD_UPDATE_BATCHRECORD_ERROR,
  PLANREPASTFOOD_UPDATE_BATCHRECORD_SUCCESS,
  PLANREPASTFOOD_MEALFOODTRANFER,
  PLANREPASTFOOD_MEALFOODTRANFER_SUCCESS,
  PLANREPASTFOOD_MEALFOODTRANFER_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getPlanRepastFoodList = (pageDetail) => ({
  type: PLANREPASTFOOD_GET_LIST,
  payload: pageDetail,
});

export const getPlanRepastFoodListSuccess = (items) => ({
  type: PLANREPASTFOOD_GET_LIST_SUCCESS,
  payload: items,
});

export const getPlanRepastFoodListError = (error) => ({
  type: PLANREPASTFOOD_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setPlanRepastFoodSelectedRecord = (recordId) => ({
  type: PLANREPASTFOOD_SET_SELECTED,
  payload: recordId,
});

export const setPlanRepastFoodSelectedRecordSuccess = (record) => ({
  type: PLANREPASTFOOD_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setPlanRepastFoodSelectedRecordError = (error) => ({
  type: PLANREPASTFOOD_SET_SELECTED_ERROR,
  payload: error,
});

export const updatePlanRepastFoodSelectedRecord = (record) => ({
  type: PLANREPASTFOOD_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addPlanRepastFoodRecord = (record) => ({
  type: PLANREPASTFOOD_ADD_RECORD,
  payload: record,
});

export const addPlanRepastFoodRecordSuccess = (msg) => ({
  type: PLANREPASTFOOD_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addPlanRepastFoodRecordError = (error) => ({
  type: PLANREPASTFOOD_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updatePlanRepastFoodRecords = (record) => ({
  type: PLANREPASTFOOD_UPDATE_RECORD,
  payload: record,
});

export const updatePlanRepastFoodRecordSuccess = (msg) => ({
  type: PLANREPASTFOOD_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updatePlanRepastFoodRecordError = (error) => ({
  type: PLANREPASTFOOD_UPDATE_RECORD_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------

export const updatePlanRepastFoodBatchRecords = (records) => ({
  type: PLANREPASTFOOD_UPDATE_BATCHRECORD,
  payload: records,
});
export const updatePlanRepastFoodBatchRecordSuccess = (msg) => ({
  type: PLANREPASTFOOD_UPDATE_BATCHRECORD_SUCCESS,
  payload: msg,
});

export const updatePlanRepastFoodBatchRecordError = (error) => ({
  type: PLANREPASTFOOD_UPDATE_BATCHRECORD_ERROR,
  payload: error,
});

export const deletePlanRepastFoodRecords = (record) => ({
  type: PLANREPASTFOOD_DELETE_RECORD,
  payload: record,
});

export const deletePlanRepastFoodRecordSuccess = (msg) => ({
  type: PLANREPASTFOOD_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deletePlanRepastFoodRecordError = (error) => ({
  type: PLANREPASTFOOD_DELETE_RECORD_ERROR,
  payload: error,
});

export const planRepastFoodMealFoodTranfer = (record) => ({
  type: PLANREPASTFOOD_MEALFOODTRANFER,
  payload: record,
});

export const planRepastFoodMealFoodTranferSuccess = (msg) => ({
  type: PLANREPASTFOOD_MEALFOODTRANFER_SUCCESS,
  payload: msg,
});

export const planRepastFoodMealFoodTranferError = (error) => ({
  type: PLANREPASTFOOD_MEALFOODTRANFER_ERROR,
  payload: error,
});

export const resetPlanRepastFoodStatuses = () => ({
  type: PLANREPASTFOOD_RESET_STATUSES,
});
//-------------------------------------------------------------------------
