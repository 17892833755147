/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  debounce,
} from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
// import * as XLSX from 'xlsx';

import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import excelHelper from 'helpers/excelHelper';
import NutrientValueCalculate from 'helpers/nutrientValueCalculate';
import PlanToolExportHelper from 'helpers/planToolExportHelper';
import {
  PLAN_ADD_RECORD,
  PLAN_UPDATE_RECORD,
  PLAN_GET_LIST,
  PLAN_SET_SELECTED,
  PLAN_DELETE_RECORD,
  deletePlanRecordSuccess,
  deletePlanRecordError,
  PLAN_CLONE_SET_SELECTED,
  PLAN_ISACTIVECHANGE,
  PLAN_SPECIALLY_WITH_ID,
  PLAN_ANALYSIS,
  PLAN_IMPORT,
  PLAN_ANALYSIS_REFERENCE_NUTRIENT,
  PLAN_FOODGROUP_GROUPING,
  PLAN_NUTRIENTSORTING,
  PLAN_NUTRIENTTRANSFER,
  SHAREDPLAN_GET_LIST,
  SHAREDPLAN_IMPORT_FOR_ME,
  SHAREDPLAN_EXPORT,
  SHAREDPLAN_PENDING_LIST,
  SHAREDPLAN_CONFIRM,
  PLAN_LACKCOMPLETION,
  PLAN_COMMENTT,
} from '../../actions';

import {
  getPlanListSuccess,
  getPlanListError,
  setPlanSelectedRecordSuccess,
  setPlanSelectedRecordError,
  addPlanRecordSuccess,
  addPlanRecordError,
  updatePlanRecordSuccess,
  updatePlanRecordError,
  setPlanCloneSelectedRecordSuccess,
  setPlanCloneSelectedRecordError,
  isActiveChangePlanSuccess,
  isActiveChangePlanError,
  speciallyWithIdError,
  speciallyWithIdSuccess,
  planAnalysisSuccess,
  planAnalysisError,
  planImportSuccess,
  planImportError,
  planAnalysisReferenceNutrientSuccess,
  planAnalysisReferenceNutrientError,
  planFoodGroupGroupingSuccess,
  planFoodGroupGroupingError,
  planNutrientSortingSuccess,
  planNutrientSortingError,
  planNutrientTransferSuccess,
  planNutrientTransferError,
  sharedPlanImportForMeError,
  sharedPlanImportForMeSuccess,
  sharedPlanExportSuccess,
  sharedPlanExportError,
  getSharedPlanPendingListSuccess,
  getSharedPlanPendingListError,
  sharedPlanConfirmSuccess,
  sharedPlanConfirmError,
  planLackCompletionSuccess,
  planLackCompletionError,
  planCommentSuccess,
  planCommentError,
  getSharedPlanListSuccess,
  getSharedPlanListError,
} from './actions';
import { getCurrentUserPreference } from '../../../helpers/Utils';
import { getAntropometryByCoacheeId } from '../antropometry/actions';
//---------------------------------------------------------------------
const getPlanListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/plan${url || ''}`)
    .then((response) => response.data);
};

function* getPlanListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(getPlanListRequest, filterBuilder(payload));
      yield put(getPlanListSuccess(response));
    } else {
      const response = yield call(getPlanListRequest);
      yield put(getPlanListSuccess(response));
    }
  } catch (error) {
    yield put(getPlanListError(error));
  }
}
//----------------------------------------------------------------------------------
const getSharedPlanListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/plan/planshared${url || ''}`)
    .then((response) => response.data);
};

function* getSharedPlanListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getSharedPlanListRequest,
        filterBuilder(payload)
      );
      yield put(getSharedPlanListSuccess(response));
    } else {
      const response = yield call(getSharedPlanListRequest);
      yield put(getSharedPlanListSuccess(response));
    }
  } catch (error) {
    yield put(getSharedPlanListError(error));
  }
}
//----------------------------------------------------------------------------------
const getSharedPlanPendingListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/plan/sharedPlanPending${url || ''}`)
    .then((response) => response.data);
};

function* getSharedPlanPendingList({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getSharedPlanPendingListRequest,
        filterBuilder(payload)
      );
      yield put(getSharedPlanPendingListSuccess(response));
    } else {
      const response = yield call(getSharedPlanListRequest);
      yield put(getSharedPlanPendingListError(response));
    }
  } catch (error) {
    yield put(getSharedPlanListError(error));
  }
}
//----------------------------------------------------------------------------------

const sharedPlanExport = (id) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/plan/sharedPlanExport/${id}`)
    .then((response) => response.data);
};

function* setSharedPlanExport({ payload }) {
  try {
    const response = yield call(sharedPlanExport, payload);
    yield put(sharedPlanExportSuccess(response));
  } catch (error) {
    yield put(sharedPlanExportError(error));
  }
}
//----------------------------------------------------------------------------------

const sharedPlanImportForMe = (id) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/plan/sharedPlanImportForMe/${id}`)
    .then((response) => response.data);
};

function* setSharedPlanImportForMe({ payload }) {
  try {
    const response = yield call(sharedPlanImportForMe, payload);
    yield put(sharedPlanImportForMeSuccess(response));
  } catch (error) {
    yield put(sharedPlanImportForMeError(error));
  }
}
//----------------------------------------------------------------------------------
const sharedPlanConfirm = (id) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/plan/sharedPlanConfirm/${id}`)
    .then((response) => response.data);
};

function* setSharedPlanConfirm({ payload }) {
  try {
    const response = yield call(sharedPlanConfirm, payload);
    yield put(sharedPlanConfirmSuccess(response));
  } catch (error) {
    yield put(sharedPlanConfirmError(error));
  }
}
//----------------------------------------------------------------------------------

const setPlanSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/plan/${id}`)
    .then((response) => response.data.data[0]);

function* setPlanSelectedRecord({ payload }) {
  try {
    const response = yield call(setPlanSelectedRecordRequest, payload);
    yield put(setPlanSelectedRecordSuccess(response));
    if (response.coacheeId)
      yield put(getAntropometryByCoacheeId(response.coacheeId));
  } catch (error) {
    yield put(
      setPlanSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addPlanRecordRequest = (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/plan`, record)
    .then((response) => response.data);

function* addPlanRecords({ payload }) {
  try {
    if (payload.isPreferenceRepast === true) {
      const userRepasts = getCurrentUserPreference()?.userPreferenceRepasts;
      if (userRepasts && userRepasts.length > 0) {
        payload.planRepasts =
          getCurrentUserPreference()?.userPreferenceRepasts?.map((upr) => {
            return {
              planId: '00000000-0000-0000-0000-000000000000',
              repastId: upr.secondId,
              displayOrder: upr.displayOrder,
            };
          });
      } else payload.planRepasts = [];
    } else {
      payload.planRepasts = [];
    }
    yield call(addPlanRecordRequest, payload);
    let messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addPlanRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getPlanListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addPlanRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deletePlanRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/plan/${recordId}`)
    .then((response) => response.data);

function* deletePlanRecords({ payload }) {
  try {
    yield call(deletePlanRecordRequest, payload);
    let messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deletePlanRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getPlanListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deletePlanRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const isActiveChangePlanRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/plan/isactivechange/${recordId}`)
    .then((response) => response.data);

function* isActiveChangePlan({ payload }) {
  try {
    const response = yield call(isActiveChangePlanRequest, payload);
    yield put(isActiveChangePlanSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangePlanError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const planCloneRecordRequest = (plan) => {
  if (plan.coacheeId) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/plan/clone/${plan.id}/${plan.coacheeId}/${plan.planName}`
      )
      .then((response) => response.data);
  }
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/plan/clone/${plan.id}/${plan.planName}`
    )
    .then((response) => response.data);
};
function* planCloneRecord({ payload }) {
  try {
    yield call(planCloneRecordRequest, payload);
    let messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      setPlanCloneSelectedRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getPlanListItems, {  payload: { pageNumber: 0 }  });
  } catch (error) {
    yield put(
      setPlanCloneSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const planAnalysisReferenceNutrientRequest = ({ id, ids }) => {
  let url = '';
  if (ids)
    ids.forEach((element) => {
      url += `ids=${element}&`;
    });
  return axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }/plan/planAnalysisReferenceNutrients?id=${id}${
        url !== '' ? '&' + url : ''
      }`
    )
    .then((response) => response.data);
};

function* planAnalysisReferenceNutrientRecords({ payload }) {
  try {
    const response = yield call(planAnalysisReferenceNutrientRequest, payload);
    yield put(planAnalysisReferenceNutrientSuccess(response));
  } catch (error) {
    yield put(planAnalysisReferenceNutrientError(error));
  }
}

const planSpeciallyWithIdRequest = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/plan/planspecially/${id}`)
    .then((response) => response.data);
};

function* planSpeciallyWithId({ payload }) {
  try {
    const response = yield call(planSpeciallyWithIdRequest, payload);
    yield put(speciallyWithIdSuccess(response));
    yield call(planAnalysisReferenceNutrientRecords, {
      payload: { id: response.id },
    });
  } catch (error) {
    yield put(speciallyWithIdError(error));
  }
}
//----------------------------------------------------------------------------------
const updatePlanRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/plan`, record)
    .then((response) => response.data);

function* updatePlanRecords({ payload }) {
  try {
    const response = yield call(updatePlanRecordRequest, payload);
    if (response.singleData.dayUpdate === true) {
      const planId = yield select((state) => state.plan?.selectedRecord.id);
      yield call(planAnalysisReferenceNutrientRecords, {
        payload: { id: planId },
      });
    } else {
      let messages = yield select(
        (state) => state.settings.notificationMessages
      );
      let messageType = messages.find(
        (x) => x.type === NotificationType.Success
      ); // type filtresi ile
      yield put(
        updatePlanRecordSuccess(
          NotificationHelpers(messageType, NotificationSubType.Edit)
        )
      );
      yield call(getPlanListItems, { payload: { pageNumber: 0 } });
    }
  } catch (error) {
    console.error(error,error.message);
    yield put(
      updatePlanRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------
const planAnalysisRequest = (obj, tool) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/plan/plansAnalysis${
        tool ? '/tools/' : ''
      }?${obj}`
    )
    .then((response) => response.data);
};

function* planAnalysis({ payload }) {
  try {
    // eslint-disable-next-line no-unused-vars
    let url = '';
    let tool = false;
    payload.ids.forEach((element) => {
      url += `id=${element.id}&`;
    });
    const energyValue = yield select((state) => state.settings.energyValue);
    if (payload.tools) {
      tool = true;
      url += `tool=${JSON.stringify(payload.tools)}`;
      const response = yield call(planAnalysisRequest, url, tool);
      if (payload.tools.type !== 2)
        PlanToolExportHelper(response, payload.tools, energyValue);
      else {
        yield put(planAnalysisSuccess(response));
      }
      return;
    }
    const response = yield call(planAnalysisRequest, url, tool);
    const result = [];
    response.forEach((element) => {
      const rowData = {
        'Plan Adı': element.name || '',
        Gün: element.day || 1,
      };
      element.planTotal.forEach((total) => {
        // rowData[total.header] = (
        //   total.value / (total.header === 'Enerji' ? 4.184 : 1)
        // ).toFixed(3);
        rowData[total.header] = NutrientValueCalculate(
          total.header,
          total.value,
          energyValue,
          total.commaNumber
        );
      });
      rowData.Ad = `${element.coachee?.name || ''} ${
        element.coachee?.surname || ''
      }`;
      rowData['Yaş '] = element?.antropometry?.age || '';
      rowData['Boy(cm)'] = element?.antropometry?.stature || '';
      rowData['Ağırlık(kg)'] = element?.antropometry?.weight || '';
      if (element?.antropometry?.rangeType)
        rowData.Cinsiyet =
          (element?.antropometry?.rangeType === 2 ? 'Erkek' : 'Kadın') || '';
      else if (element.coachee?.rRangeType)
        rowData.Cinsiyet =
          element.coachee?.rRangeType === 2 ? 'Erkek' : 'Kadın';
      else rowData.Cinsiyet = '';
      rowData['Bazal metab. (kcal)'] =
        element?.antropometry?.basalMetabolicRate || '';
      rowData['Aktivite metab. (kcal)'] =
        element?.antropometry?.totalMetabolicRate -
          element?.antropometry?.basalMetabolicRate || '';
      rowData['Toplam (kcal)'] =
        element?.antropometry?.totalMetabolicRate || '';
      result.push(rowData);
    });

    excelHelper(result);
    yield put(planAnalysisSuccess(result));
  } catch (error) {
    yield put(planAnalysisError(error));
  }
}

//--------------------------------------------------------------------

const planImportRequest = (obj) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/import/planImport`, JSON.parse(obj))
    .then((response) => response.data);
};

function* planImport({ payload }) {
  try {
    const response = yield call(planImportRequest, payload);
    yield put(planImportSuccess(response));
  } catch (error) {
    yield put(planImportError(error));
  }
}

//--------------------------------------------------------------------

const planFoodGroupGroupingRequest = ({
  selectedPlanId,
  subFoodGroup,
  subFood,
}) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/plan/foodgroupgrouping/${selectedPlanId}/${subFoodGroup}/${subFood}`
    )
    .then((response) => response.data);
};

function* planFoodGroupGroupingRecords({ payload }) {
  try {
    const response = yield call(planFoodGroupGroupingRequest, payload);
    const result = [];
    response.foodGroups.forEach((foodGroup) => {
      const foodGroupRowData = {
        ' ': foodGroup.groupName,
        '': 'Miktar',
      };
      response.nutrientHeaders.forEach((header) => {
        foodGroupRowData[header.header] = header.header;
      });
      result.push(foodGroupRowData);
      result.push({});
      if (payload.subFoodGroup) {
        foodGroup.subFoodGroups.forEach((subFoodGroup) => {
          const subFoodGroupRowData = {
            ' ': '  ' + subFoodGroup.subGroupName,
            '': `${subFoodGroup.subFoodGroupTotalGram || 0} gr`,
          };
          response.nutrientHeaders.forEach((header) => {
            subFoodGroupRowData[header.header] = `${
              subFoodGroup.subGroupNutrientValues.find(
                (x) => x.nutrientId === header.nutrientId
              )?.oran || 0
            } %`;
          });
          result.push(subFoodGroupRowData);
          if (payload.subFood) {
            result.push({});
            subFoodGroup.subFoodGroupFoods.forEach((subFood) => {
              const subFoodRowData = {
                ' ': '      ' + subFood.foodName,
                '': `${subFood.foodGram || 0} gr`,
              };
              response.nutrientHeaders.forEach((header) => {
                subFoodRowData[header.header] = `${
                  subFood.foodNutrientValues.find(
                    (x) => x.nutrientId === header.nutrientId
                  )?.oran || 0
                } %`;
              });
              result.push(subFoodRowData);
            });
          }
          result.push({});
        });
      }

      let foodGroupTotalRowData = {
        ' ': foodGroup.groupName + ' Grup Toplamı:',
        '': `${foodGroup.foodGroupTotalGram || 0} gr`,
      };

      response.nutrientHeaders.forEach((header) => {
        foodGroupTotalRowData[header.header] = `${
          foodGroup.groupValues.find((x) => x.nutrientId === header.nutrientId)
            ?.oran || 0
        } %`;
      });
      result.push(foodGroupTotalRowData);
      result.push({});
    });
    excelHelper(result);
    yield put(planFoodGroupGroupingSuccess(response));
  } catch (error) {
    yield put(planFoodGroupGroupingError(error));
  }
}

//--------------------------------------------------------------------

const planNutrientSortingRequest = (payload) => {
  const url = `?${payload.nutrients
    .map((x) => 'nids=' + x)
    .join('&')}&${payload.ids.map((x) => 'ids=' + x).join('&')}`;
  return axios
    .get(`${process.env.REACT_APP_API_URL}/plan/plannutrientsort/${url}`)
    .then((response) => response.data);
};

function* planNutrientSortingRecords({ payload }) {
  try {
    const response = yield call(planNutrientSortingRequest, payload);
    yield put(planNutrientSortingSuccess(response));
  } catch (error) {
    yield put(planNutrientSortingError(error));
  }
}

const planNutrientTransferRequest = (payload) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/plan/plannutrienttransfer/${payload.planId}/${payload.nutrientId}/${payload.value}`
    )
    .then((response) => response.data);
};

function* planNutrientTransferRecords({ payload }) {
  try {
    const response = yield call(planNutrientTransferRequest, payload);
    let messages = yield select((state) => state.settings.notificationMessages);
    let messageType = messages.find((x) => x.type === NotificationType.Success); // type filtresi ile
    yield put(
      planNutrientTransferSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(planSpeciallyWithId, { payload: response.id });
  } catch (error) {
    yield put(planNutrientTransferError(error));
  }
}

//--------------------------------------------------------------------

const planLackCompletionRequest = ({ id }) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/plan/lackcompletion/${id}`)
    .then((response) => response.data);
};

function* planLackCompletionRecords({ payload }) {
  try {
    const response = yield call(planLackCompletionRequest, payload);
    yield put(planLackCompletionSuccess(response));
  } catch (error) {
    yield put(planLackCompletionError(error));
  }
}

//--------------------------------------------------------------------

const planCommentRequest = ({ id, tools }) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/plan/planComment/${id}/${tools}`)
    .then((response) => response.data);
};

function* planCommentRecords({ payload }) {
  try {
    const response = yield call(planCommentRequest, payload);
    yield put(planCommentSuccess(response));
  } catch (error) {
    yield put(planCommentError(error));
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(100, PLAN_GET_LIST, getPlanListItems);
}
export function* watchSharedGetList() {
  yield debounce(100, SHAREDPLAN_GET_LIST, getSharedPlanListItems);
}
export function* watchSharedPlanImportForMe() {
  yield takeEvery(SHAREDPLAN_IMPORT_FOR_ME, setSharedPlanImportForMe);
}
export function* watchSharedPlanExport() {
  yield takeEvery(SHAREDPLAN_EXPORT, setSharedPlanExport);
}
export function* watchSharedPlanPendingList() {
  yield takeEvery(SHAREDPLAN_PENDING_LIST, getSharedPlanPendingList);
}
export function* watchSharedPlanConfirm() {
  yield takeEvery(SHAREDPLAN_CONFIRM, setSharedPlanConfirm);
}
export function* watchSetSelected() {
  yield takeEvery(PLAN_SET_SELECTED, setPlanSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(PLAN_ADD_RECORD, addPlanRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(PLAN_UPDATE_RECORD, updatePlanRecords);
}
export function* wathcDeleteItem() {
  yield takeEvery(PLAN_DELETE_RECORD, deletePlanRecords);
}
export function* watchClone() {
  yield takeEvery(PLAN_CLONE_SET_SELECTED, planCloneRecord);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(PLAN_ISACTIVECHANGE, isActiveChangePlan);
}
export function* watchSpeciallyWithId() {
  yield debounce(300, PLAN_SPECIALLY_WITH_ID, planSpeciallyWithId);
}
export function* watchPlanAnalysis() {
  yield takeEvery(PLAN_ANALYSIS, planAnalysis);
}
export function* watchPlanImport() {
  yield takeEvery(PLAN_IMPORT, planImport);
}
export function* watchPlanAnalysisReferenceNutrient() {
  yield takeEvery(
    PLAN_ANALYSIS_REFERENCE_NUTRIENT,
    planAnalysisReferenceNutrientRecords
  );
}
export function* watchPlanFoodGroupGrouping() {
  yield takeEvery(PLAN_FOODGROUP_GROUPING, planFoodGroupGroupingRecords);
}
export function* watchPlanNutrientSorting() {
  yield takeEvery(PLAN_NUTRIENTSORTING, planNutrientSortingRecords);
}
export function* watchPlanNutrientTransfer() {
  yield takeEvery(PLAN_NUTRIENTTRANSFER, planNutrientTransferRecords);
}
export function* watchPlanLackCompletion() {
  yield takeEvery(PLAN_LACKCOMPLETION, planLackCompletionRecords);
}
export function* watchPlanComment() {
  yield takeEvery(PLAN_COMMENTT, planCommentRecords);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSharedGetList),
    fork(watchSharedPlanImportForMe),
    fork(watchSharedPlanExport),
    fork(watchSharedPlanPendingList),
    fork(watchSharedPlanConfirm),
    fork(watchSetSelected),
    fork(wathcIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(watchClone),
    fork(wathcDeleteItem),
    fork(watchSpeciallyWithId),
    fork(watchPlanAnalysis),
    fork(watchPlanImport),
    fork(watchPlanAnalysisReferenceNutrient),
    fork(watchPlanFoodGroupGrouping),
    fork(watchPlanNutrientSorting),
    fork(watchPlanNutrientTransfer),
    fork(watchPlanLackCompletion),
    fork(watchPlanComment),
  ]);
}
