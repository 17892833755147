/* eslint-disable prettier/prettier */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  REFERENCERANGE_GET_LIST,
  REFERENCERANGE_GET_LIST_SUCCESS,
  REFERENCERANGE_GET_LIST_ERROR,
  REFERENCERANGE_SET_SELECTED,
  REFERENCERANGE_UPDATE_SELECTED,
  REFERENCERANGE_SET_SELECTED_SUCCESS,
  REFERENCERANGE_SET_SELECTED_ERROR,
  REFERENCERANGE_ADD_RECORD_SUCCESS,
  REFERENCERANGE_ADD_RECORD_ERROR,
  REFERENCERANGE_ADD_RECORD,
  REFERENCERANGE_UPDATE_RECORD_SUCCESS,
  REFERENCERANGE_UPDATE_RECORD_ERROR,
  REFERENCERANGE_UPDATE_RECORD,
  REFERENCERANGE_DELETE_RECORD,
  REFERENCERANGE_DELETE_RECORD_SUCCESS,
  REFERENCERANGE_DELETE_RECORD_ERROR,
  REFERENCERANGE_RESET_STATUSES,
  REFERENCERANGE_ISACTIVECHANGE,
  REFERENCERANGE_ISACTIVECHANGE_SUCCESS,
  REFERENCERANGE_ISACTIVECHANGE_ERROR,
} from '../../actions';

//---
export const getReferenceRangeList = (pageDetail) => ({
  type: REFERENCERANGE_GET_LIST,
  payload: pageDetail,
});

export const getReferenceRangeListSuccess = (items) => ({
  type: REFERENCERANGE_GET_LIST_SUCCESS,
  payload: items,
});

export const getReferenceRangeListError = (error) => ({
  type: REFERENCERANGE_GET_LIST_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const setReferenceRangeSelectedRecord = (recordId) => ({
  type: REFERENCERANGE_SET_SELECTED,
  payload: recordId,
});

export const setReferenceRangeSelectedRecordSuccess = (record) => ({
  type: REFERENCERANGE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setReferenceRangeSelectedRecordError = (error) => ({
  type: REFERENCERANGE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateReferenceRangeSelectedRecord = (record) => ({
  type: REFERENCERANGE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addReferenceRangeRecord = (record) => ({
  type: REFERENCERANGE_ADD_RECORD,
  payload: record,
});

export const addReferenceRangeRecordSuccess = (msg) => ({
  type: REFERENCERANGE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addReferenceRangeRecordError = (error) => ({
  type: REFERENCERANGE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateReferenceRangeRecords = (record) => ({
  type: REFERENCERANGE_UPDATE_RECORD,
  payload: record,
});

export const updateReferenceRangeRecordSuccess = (msg) => ({
  type: REFERENCERANGE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateReferenceRangeRecordError = (error) => ({
  type: REFERENCERANGE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteReferenceRangeRecords = (record) => ({
  type: REFERENCERANGE_DELETE_RECORD,
  payload: record,
});

export const deleteReferenceRangeRecordSuccess = (msg) => ({
  type: REFERENCERANGE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteReferenceRangeRecordError = (error) => ({
  type: REFERENCERANGE_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeReferenceRange = (record) => ({
  type: REFERENCERANGE_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeReferenceRangeSuccess = (msg) => ({
  type: REFERENCERANGE_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeReferenceRangeError = (error) => ({
  type: REFERENCERANGE_ISACTIVECHANGE_ERROR,
  payload: error,
});


export const resetReferenceRangeStatuses = () => ({type: REFERENCERANGE_RESET_STATUSES});
