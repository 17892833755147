/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import {
  PLANREPASTFOODPORTION_GET_LIST,
  PLANREPASTFOODPORTION_GET_LIST_SUCCESS,
  PLANREPASTFOODPORTION_GET_LIST_ERROR,
  PLANREPASTFOODPORTION_SET_SELECTED,
  PLANREPASTFOODPORTION_UPDATE_SELECTED,
  PLANREPASTFOODPORTION_SET_SELECTED_SUCCESS,
  PLANREPASTFOODPORTION_SET_SELECTED_ERROR,
  PLANREPASTFOODPORTION_UPDATE_RECORD,
  PLANREPASTFOODPORTION_UPDATE_RECORD_ERROR,
  PLANREPASTFOODPORTION_UPDATE_RECORD_SUCCESS,
  PLANREPASTFOODPORTION_ADD_RECORD,
  PLANREPASTFOODPORTION_ADD_RECORD_SUCCESS,
  PLANREPASTFOODPORTION_ADD_RECORD_ERROR,
  PLANREPASTFOODPORTION_DELETE_RECORD,
  PLANREPASTFOODPORTION_DELETE_RECORD_SUCCESS,
  PLANREPASTFOODPORTION_DELETE_RECORD_ERROR,
  PLANREPASTFOODPORTION_RESET_STATUSES,
  PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_ERROR,
  PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_SUCCESS,
  PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD,
} from '../../actions';

const INIT_STATE = {
  records: [],
  repast: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PLANREPASTFOODPORTION_GET_LIST:
      return { ...state, loading: true };
    case PLANREPASTFOODPORTION_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case PLANREPASTFOODPORTION_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PLANREPASTFOODPORTION_SET_SELECTED:
      return { ...state, modaLoading: true };
    case PLANREPASTFOODPORTION_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, repast: action.payload };
    case PLANREPASTFOODPORTION_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case PLANREPASTFOODPORTION_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case PLANREPASTFOODPORTION_ADD_RECORD:
      return { ...state, loading: false };
    case PLANREPASTFOODPORTION_ADD_RECORD_SUCCESS:
      action.payload.planRepast.repast.forEach((rep) => {
        rep.planRepastFoods.forEach((prFood) => {
          if (
            prFood.planRepastFoodPortion === undefined ||
            prFood.planRepastFoodPortion === null
          ) {
            if (prFood.id === action.payload.singleData.planRepastFoodId) {
              prFood.planRepastFoodPortion = action.payload.singleData;

              prFood.dynamicFoodMealNutrientsArray.forEach((item) => {
                prFood.food.foodNutrients.forEach(
                  (foodNutri, foodNutriIndex) => {
                    if (item.nutrientId === foodNutri.secondId) {
                      item.value =
                        (foodNutri.value / 100) *
                        action.payload.singleData.defaultValue;
                    }
                  }
                );
              });
            }
          } else if (
            prFood.planRepastFoodPortion.id === action.payload.singleData.id
          ) {
            prFood.planRepastFoodPortion.defaultValue =
              action.payload.singleData.defaultValue;
            prFood.dynamicFoodMealNutrientsArray.forEach((item) => {
              prFood.food.foodNutrients.forEach((foodNutri, foodNutriIndex) => {
                if (item.nutrientId === foodNutri.secondId) {
                  item.value =
                    (foodNutri.value / 100) *
                    (prFood.planRepastFoodPortion != null &&
                    prFood.planRepastFoodPortion.defaultValue !== null
                      ? prFood.planRepastFoodPortion.defaultValue
                      : 0);
                }
              });
            });
          }
        });
      });
      return { ...state, loading: false, success: action.payload };
    case PLANREPASTFOODPORTION_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PLANREPASTFOODPORTION_UPDATE_RECORD:
      return { ...state, loading: false };
    case PLANREPASTFOODPORTION_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPASTFOODPORTION_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD:
      return { ...state, loading: false };
    case PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload.singleData };
    case PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLANREPASTFOODPORTION_DELETE_RECORD:
      return { ...state, loading: false };
    case PLANREPASTFOODPORTION_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPASTFOODPORTION_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLANREPASTFOODPORTION_RESET_STATUSES:
      return { ...state, success: null, error: null };
    default:
      return { ...state };
  }
};
