// eslint-disable-next-line import/no-cycle
import {
  SAMPLEDESCRIPTION_GET_LIST,
  SAMPLEDESCRIPTION_GET_LIST_SUCCESS,
  SAMPLEDESCRIPTION_GET_LIST_ERROR,
  SAMPLEDESCRIPTION_GET_GROUPED_LIST,
  SAMPLEDESCRIPTION_GET_GROUPED_LIST_SUCCESS,
  SAMPLEDESCRIPTION_GET_GROUPED_LIST_ERROR,
  SAMPLEDESCRIPTION_ADD_RECORD,
  SAMPLEDESCRIPTION_ADD_RECORD_ERROR,
  SAMPLEDESCRIPTION_ADD_RECORD_SUCCESS,
  SAMPLEDESCRIPTION_DELETE_RECORD,
  SAMPLEDESCRIPTION_DELETE_RECORD_ERROR,
  SAMPLEDESCRIPTION_DELETE_RECORD_SUCCESS,
  SAMPLEDESCRIPTION_RESET_STATUSES,
  SAMPLEDESCRIPTION_SET_SELECTED,
  SAMPLEDESCRIPTION_SET_SELECTED_ERROR,
  SAMPLEDESCRIPTION_SET_SELECTED_SUCCESS,
  SAMPLEDESCRIPTION_UPDATE_RECORD,
  SAMPLEDESCRIPTION_UPDATE_RECORD_ERROR,
  SAMPLEDESCRIPTION_UPDATE_RECORD_SUCCESS,
  SAMPLEDESCRIPTION_UPDATE_SELECTED,
  SAMPLEDESCRIPTION_ISACTIVECHANGE,
  SAMPLEDESCRIPTION_ISACTIVECHANGE_ERROR,
  SAMPLEDESCRIPTION_ISACTIVECHANGE_SUCCESS,
} from '../../actions';

//-------------------------------------------------------------------------
export const getSampleDescriptionList = (record) => ({
  type: SAMPLEDESCRIPTION_GET_LIST,
  payload: record,
});

export const getSampleDescriptionListSuccess = (items) => ({
  type: SAMPLEDESCRIPTION_GET_LIST_SUCCESS,
  payload: items,
});

export const getSampleDescriptionListError = (error) => ({
  type: SAMPLEDESCRIPTION_GET_LIST_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const getSampleDescriptionGroupedList = () => ({
  type: SAMPLEDESCRIPTION_GET_GROUPED_LIST,
});

export const getSampleDescriptionGroupedListSuccess = (items) => ({
  type: SAMPLEDESCRIPTION_GET_GROUPED_LIST_SUCCESS,
  payload: items,
});

export const getSampleDescriptionGroupedListError = (error) => ({
  type: SAMPLEDESCRIPTION_GET_GROUPED_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setSampleDescriptionSelectedRecord = (recordId) => ({
  type: SAMPLEDESCRIPTION_SET_SELECTED,
  payload: recordId,
});

export const setSampleDescriptionSelectedRecordSuccess = (record) => ({
  type: SAMPLEDESCRIPTION_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setSampleDescriptionSelectedRecordError = (error) => ({
  type: SAMPLEDESCRIPTION_SET_SELECTED_ERROR,
  payload: error,
});

export const updateSampleDescriptionSelectedRecord = (record) => ({
  type: SAMPLEDESCRIPTION_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addSampleDescriptionRecord = (record) => ({
  type: SAMPLEDESCRIPTION_ADD_RECORD,
  payload: record,
});

export const addSampleDescriptionRecordSuccess = (msg) => ({
  type: SAMPLEDESCRIPTION_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addSampleDescriptionRecordError = (error) => ({
  type: SAMPLEDESCRIPTION_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateSampleDescriptionRecords = (record) => ({
  type: SAMPLEDESCRIPTION_UPDATE_RECORD,
  payload: record,
});

export const updateSampleDescriptionRecordSuccess = (msg) => ({
  type: SAMPLEDESCRIPTION_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateSampleDescriptionRecordError = (error) => ({
  type: SAMPLEDESCRIPTION_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteSampleDescriptionRecords = (record) => ({
  type: SAMPLEDESCRIPTION_DELETE_RECORD,
  payload: record,
});

export const deleteSampleDescriptionRecordSuccess = (msg) => ({
  type: SAMPLEDESCRIPTION_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteSampleDescriptionRecordError = (error) => ({
  type: SAMPLEDESCRIPTION_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeSampleDescription = (record) => ({
  type: SAMPLEDESCRIPTION_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeSampleDescriptionSuccess = (msg) => ({
  type: SAMPLEDESCRIPTION_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeSampleDescriptionError = (error) => ({
  type: SAMPLEDESCRIPTION_ISACTIVECHANGE_ERROR,
  payload: error,
});
export const resetSampleDescriptionStatuses = () => ({
  type: SAMPLEDESCRIPTION_RESET_STATUSES,
});
//-------------------------------------------------------------------------
