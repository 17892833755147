/* eslint-disable no-nested-ternary */
const NutrientUnitHelper = (nutrientName, unit, energyValueType = true) => {
  return `${
    energyValueType === true
      ? nutrientName === 'Enerji'
        ? 'kcal'
        : unit || ''
      : unit || ''
  }`;
};

export default NutrientUnitHelper;
