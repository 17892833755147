/* eslint-disable no-param-reassign */

import { all, call, fork, put, debounce, select } from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';

import { LOGINHISTORY_GET_LIST } from '../../actions';

import {
  getLoginHistoryListSuccess,
  getLoginHistoryListError,
} from './actions';

//---------------------------------------------------------------------
const getLoginHistoryListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/loginhistory${url || ''}`)
    .then((response) => response.data);
};

function* getLoginHistoryListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      payload.includes = ['User'];

      const response = yield call(
        getLoginHistoryListRequest,
        filterBuilder(payload)
      );
      yield put(getLoginHistoryListSuccess(response));
    } else {
      const response = yield call(getLoginHistoryListRequest);
      yield put(getLoginHistoryListSuccess(response));
    }
  } catch (error) {
    yield put(getLoginHistoryListError(error));
  }
}

export function* watchGetList() {
  yield debounce(800, LOGINHISTORY_GET_LIST, getLoginHistoryListItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
