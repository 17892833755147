// eslint-disable-next-line import/no-cycle
import {
  COUPON_GET_LIST,
  COUPON_GET_LIST_SUCCESS,
  COUPON_GET_LIST_ERROR,
  COUPON_SET_SELECTED,
  COUPON_UPDATE_SELECTED,
  COUPON_SET_SELECTED_SUCCESS,
  COUPON_SET_SELECTED_ERROR,
  COUPON_ADD_RECORD_SUCCESS,
  COUPON_ADD_RECORD_ERROR,
  COUPON_ADD_RECORD,
  COUPON_UPDATE_RECORD_SUCCESS,
  COUPON_UPDATE_RECORD_ERROR,
  COUPON_UPDATE_RECORD,
  COUPON_DELETE_RECORD,
  COUPON_DELETE_RECORD_SUCCESS,
  COUPON_DELETE_RECORD_ERROR,
  COUPON_RESET_STATUSES,
  COUPON_ISACTIVECHANGE_SUCCESS,
  COUPON_ISACTIVECHANGE_ERROR,
  COUPON_ISACTIVECHANGE,
} from '../../actions';

//-------------------------------------------------------------------------
export const getCouponList = (pageDetail) => ({
  type: COUPON_GET_LIST,
  payload: pageDetail,
});

export const getCouponListSuccess = (items) => ({
  type: COUPON_GET_LIST_SUCCESS,
  payload: items,
});

export const getCouponListError = (error) => ({
  type: COUPON_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setCouponSelectedRecord = (recordId) => ({
  type: COUPON_SET_SELECTED,
  payload: recordId,
});

export const setCouponSelectedRecordSuccess = (record) => ({
  type: COUPON_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setCouponSelectedRecordError = (error) => ({
  type: COUPON_SET_SELECTED_ERROR,
  payload: error,
});

export const updateCouponSelectedRecord = (record) => ({
  type: COUPON_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addCouponRecord = (record) => ({
  type: COUPON_ADD_RECORD,
  payload: record,
});

export const addCouponRecordSuccess = (msg) => ({
  type: COUPON_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addCouponRecordError = (error) => ({
  type: COUPON_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateCouponRecords = (record) => ({
  type: COUPON_UPDATE_RECORD,
  payload: record,
});

export const updateCouponRecordSuccess = (msg) => ({
  type: COUPON_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateCouponRecordError = (error) => ({
  type: COUPON_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const deleteCouponRecords = (record) => ({
  type: COUPON_DELETE_RECORD,
  payload: record,
});

export const deleteCouponRecordSuccess = (msg) => ({
  type: COUPON_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteCouponRecordError = (error) => ({
  type: COUPON_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeCoupon = (record) => ({
  type: COUPON_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeCouponSuccess = (msg) => ({
  type: COUPON_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeCouponError = (error) => ({
  type: COUPON_ISACTIVECHANGE_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const resetCouponStatuses = () => ({
  type: COUPON_RESET_STATUSES,
});
