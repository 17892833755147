import {
  USERPLAN_GET_LIST,
  USERPLAN_GET_LIST_SUCCESS,
  USERPLAN_GET_LIST_ERROR,
  USERPLAN_ADD_RECORD,
  USERPLAN_ADD_RECORD_ERROR,
  USERPLAN_ADD_RECORD_SUCCESS,
  USERPLAN_DELETE_RECORD,
  USERPLAN_DELETE_RECORD_ERROR,
  USERPLAN_DELETE_RECORD_SUCCESS,
  USERPLAN_RESET_STATUSES,
  USERPLAN_SET_SELECTED,
  USERPLAN_SET_SELECTED_ERROR,
  USERPLAN_SET_SELECTED_SUCCESS,
  USERPLAN_UPDATE_RECORD,
  USERPLAN_UPDATE_RECORD_ERROR,
  USERPLAN_UPDATE_RECORD_SUCCESS,
  USERPLAN_UPDATE_SELECTED,
  USERPLAN_ISACTIVECHANGE_SUCCESS,
  USERPLAN_ISACTIVECHANGE_ERROR,
  USERPLAN_ISACTIVECHANGE,
  USERPLAN_COUPONAPPLY,
  USERPLAN_COUPONAPPLY_SUCCESS,
  USERPLAN_COUPONAPPLY_ERROR,
  USERPLAN_GROUPED,
  USERPLAN_GROUPED_ERROR,
  USERPLAN_GROUPED_SUCCESS,
  PACKAGE_GET_LIST,
  PACKAGE_GET_LIST_SUCCESS,
  PACKAGE_GET_LIST_ERROR,
  PACKAGE_SET_SELECTED,
  PACKAGE_SET_SELECTED_SUCCESS,
  PACKAGE_SET_SELECTED_ERROR,
  CHANGE_PACKAGE,
  CHANGE_PACKAGE_SUCCESS,
  CHANGE_PACKAGE_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
  userPlanCoupon: null,
  groupedRecords: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USERPLAN_GET_LIST:
      return { ...state, loading: true };
    case USERPLAN_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRecord: null,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case USERPLAN_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case USERPLAN_SET_SELECTED:
      return { ...state, modaLoading: true };
    case USERPLAN_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case USERPLAN_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case USERPLAN_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case USERPLAN_ADD_RECORD:
      return { ...state, loading: false };
    case USERPLAN_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USERPLAN_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case USERPLAN_UPDATE_RECORD:
      return { ...state, loading: false };
    case USERPLAN_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USERPLAN_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USERPLAN_DELETE_RECORD:
      return { ...state, loading: false };
    case USERPLAN_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USERPLAN_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USERPLAN_ISACTIVECHANGE:
      return { ...state, loading: false };
    case USERPLAN_ISACTIVECHANGE_SUCCESS: {
      // eslint-disable-next-line prefer-const
      let objIndex = state.records.findIndex(
        (x) => x.id === action.payload.singleData.id
      );
      // eslint-disable-next-line no-param-reassign
      state.records[objIndex] = action.payload.singleData;
      return { ...state, loading: false, records: state.records };
    }
    case USERPLAN_ISACTIVECHANGE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USERPLAN_COUPONAPPLY:
      return { ...state, loading: false };
    case USERPLAN_COUPONAPPLY_SUCCESS: {
      return {
        ...state,
        loading: false,
        userPlanCoupon: action.payload,
        couponNotify: action.payload,
      };
    }
    case USERPLAN_COUPONAPPLY_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USERPLAN_GROUPED:
      return { ...state, loading: false };
    case USERPLAN_GROUPED_SUCCESS: {
      return {
        ...state,
        loading: false,
        groupedRecords: action.payload,
      };
    }
    case USERPLAN_GROUPED_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PACKAGE_GET_LIST:
      return { ...state, loading: true };
    case PACKAGE_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRecord: null,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case PACKAGE_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PACKAGE_SET_SELECTED:
      return { ...state, modaLoading: true };
    case PACKAGE_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case PACKAGE_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    //------------------------------------------------------------------------------
    case CHANGE_PACKAGE:
      return { ...state, modaLoading: true };
    case CHANGE_PACKAGE_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case CHANGE_PACKAGE_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    //------------------------------------------------------------------------------
    case USERPLAN_RESET_STATUSES:
      return { ...state, success: null, error: null, couponNotify: undefined };
    default:
      return { ...state };
  }
};
