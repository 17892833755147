/* eslint-disable import/no-cycle */
import {
  REPAST_GET_LIST,
  REPAST_GET_LIST_SUCCESS,
  REPAST_GET_LIST_ERROR,
  REPAST_SET_SELECTED,
  REPAST_UPDATE_SELECTED,
  REPAST_SET_SELECTED_SUCCESS,
  REPAST_SET_SELECTED_ERROR,
  REPAST_ADD_RECORD_SUCCESS,
  REPAST_ADD_RECORD_ERROR,
  REPAST_ADD_RECORD,
  REPAST_UPDATE_RECORD_SUCCESS,
  REPAST_UPDATE_RECORD_ERROR,
  REPAST_UPDATE_RECORD,
  REPAST_DELETE_RECORD,
  REPAST_DELETE_RECORD_SUCCESS,
  REPAST_DELETE_RECORD_ERROR,
  REPAST_RESET_STATUSES,
  REPAST_ISACTIVECHANGE_SUCCESS,
  REPAST_ISACTIVECHANGE_ERROR,
  REPAST_ISACTIVECHANGE,
} from '../../actions';

//-------------------------------------------------------------------------
export const getRepastList = (pageDetail) => ({
  type: REPAST_GET_LIST,
  payload: pageDetail,
});

export const getRepastListSuccess = (items) => ({
  type: REPAST_GET_LIST_SUCCESS,
  payload: items,
});

export const getRepastListError = (error) => ({
  type: REPAST_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setRepastSelectedRecord = (recordId) => ({
  type: REPAST_SET_SELECTED,
  payload: recordId,
});

export const setRepastSelectedRecordSuccess = (record) => ({
  type: REPAST_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setRepastSelectedRecordError = (error) => ({
  type: REPAST_SET_SELECTED_ERROR,
  payload: error,
});

export const updateRepastSelectedRecord = (record) => ({
  type: REPAST_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addRepastRecord = (record) => ({
  type: REPAST_ADD_RECORD,
  payload: record,
});

export const addRepastRecordSuccess = (msg) => ({
  type: REPAST_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addRepastRecordError = (error) => ({
  type: REPAST_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateRepastRecords = (record) => ({
  type: REPAST_UPDATE_RECORD,
  payload: record,
});

export const updateRepastRecordSuccess = (msg) => ({
  type: REPAST_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateRepastRecordError = (error) => ({
  type: REPAST_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteRepastRecords = (record) => ({
  type: REPAST_DELETE_RECORD,
  payload: record,
});

export const deleteRepastRecordSuccess = (msg) => ({
  type: REPAST_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteRepastRecordError = (error) => ({
  type: REPAST_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeRepast = (record) => ({
  type: REPAST_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeRepastSuccess = (msg) => ({
  type: REPAST_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeRepastError = (error) => ({
  type: REPAST_ISACTIVECHANGE_ERROR,
  payload: error,
});
export const resetRepastStatuses = () => ({ type: REPAST_RESET_STATUSES });
//-------------------------------------------------------------------------
