/* eslint-disable no-param-reassign */

import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
import {
  SELECTED_USERPLAN_SEND_NOTIFICATION,
  SELECTED_USER_SEND_NOTIFICATION,
  SELECTED_ALL_USER_SEND_NOTIFICATION,
  SELECTED_MULTI_USER_SEND_NOTIFICATION,
  NOTIFICATION_GET_LIST,
  UPDATE_NOTIFICATION,
} from '../../actions';

import {
  getNotificationListSuccess,
  updateNotificationError,
  updateNotificationSuccess,
} from './actions';
//--------------------------------------------------------------------

const userPlanSendNotificationRequest = (obj) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/notification/userPlanSendNotification`,
      obj
    )
    .then((response) => response.data);

function* userPlanSendNotification({ payload }) {
  try {
    yield call(userPlanSendNotificationRequest, payload);
  } catch (error) {
    console.log(error);
  }
}

//--------------------------------------------------------------------

const userSendNotificationRequest = (userNotification) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/notification/userSendNotification`,
      userNotification
    )
    .then((response) => response.data);

function* userSendNotification({ payload }) {
  try {
    yield call(userSendNotificationRequest, payload);
  } catch (error) {
    console.log(error);
  }
}

//--------------------------------------------------------------------

const multiUserSendNotificationRequest = (listUserEMail) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/notification/multiUserSendNotification`,
      listUserEMail
    )
    .then((response) => response.data);

function* multiUserSendNotification({ payload }) {
  try {
    yield call(multiUserSendNotificationRequest, payload);
  } catch (error) {
    console.log(error);
  }
}

//--------------------------------------------------------------------
const allUserSendNotificationRequest = (obj) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/notification/allUserSendNotification`,
      obj
    )
    .then((response) => response.data);

function* allUserSendNotification({ payload }) {
  try {
    yield call(allUserSendNotificationRequest, payload);
  } catch (error) {
    console.log(error);
  }
}

//---------------------------------------------------------------------
const getNotificationListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/notification${url || ''}`)
    .then((response) => response.data);
};

function* getNotificationList({ payload }) {
  try {
    if (payload) {
      if (!payload.pageSize) {
        payload.pageSize = yield select(
          (state) => state.settings.pageRecordCount
        );
      }
      payload.pageNumber = payload.pageNumber || 0;
      const response = yield call(
        getNotificationListRequest,
        filterBuilder(payload)
      );
      yield put(
        getNotificationListSuccess({ response, settings: payload.settings })
      );
    } else {
      const response = yield call(getNotificationListRequest);
      yield put(getNotificationListSuccess(response));
    }
  } catch (error) {
    console.log(error);
  }
}

const updateNotificationRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/notification`, record)
    .then((response) => response.data);

function* updateNotification({ payload }) {
  try {
    yield call(updateNotificationRequest, payload);

    yield put(updateNotificationSuccess());
  } catch (error) {
    yield put(
      updateNotificationError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

export function* watchUserPlanSendNotification() {
  yield takeEvery(
    SELECTED_USERPLAN_SEND_NOTIFICATION,
    userPlanSendNotification
  );
}

export function* watchUserSendNotification() {
  yield takeEvery(SELECTED_USER_SEND_NOTIFICATION, userSendNotification);
}

export function* watchMultiUserSendNotification() {
  yield takeEvery(
    SELECTED_MULTI_USER_SEND_NOTIFICATION,
    multiUserSendNotification
  );
}

export function* watchAllUserSendNotification() {
  yield takeEvery(SELECTED_ALL_USER_SEND_NOTIFICATION, allUserSendNotification);
}

export function* watchNotificationList() {
  yield takeEvery(NOTIFICATION_GET_LIST, getNotificationList);
}

export function* watchNotificationUpdate() {
  yield takeEvery(UPDATE_NOTIFICATION, updateNotification);
}
export default function* rootSaga() {
  yield all([
    fork(watchUserPlanSendNotification),
    fork(watchUserSendNotification),
    fork(watchMultiUserSendNotification),
    fork(watchAllUserSendNotification),
    fork(watchNotificationList),
    fork(watchNotificationUpdate),
  ]);
}
