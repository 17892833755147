import {
  ADDFOODMODAL_CHECKEDFOODLIST_ONCHANGE,
  ADDFOODMODAL_COLUMNS_ONCHANGE,
  ADDFOODMODAL_PAGENUMBER_ONCHANGE,
  ADDFOODMODAL_SEARCHPARAMS_ONCHANGE,
  ADDFOODMODAL_MODALSTATE_ONCHANGE,
} from '../../actions';

import { ModalMode } from '../../../constants/modalMode';

const INIT_STATE = {
  checkFoodList: [],
  modalState: {
    isOpen: false,
    activeComponent: {
      props: ['foodList'],
    },
    title: '',
    disableButtons: false,
    mode: ModalMode.Read,
  },
  dynamicColumns: [],
  pageNumber: 0,
  searchParameters: {
    keyword: '',
    foodGroupId: '00000000-0000-0000-0000-000000000000',
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADDFOODMODAL_CHECKEDFOODLIST_ONCHANGE:
      return { ...state, checkFoodList: action.payload };
    case ADDFOODMODAL_COLUMNS_ONCHANGE:
      return { ...state, dynamicColumns: action.payload };
    case ADDFOODMODAL_PAGENUMBER_ONCHANGE:
      return { ...state, pageNumber: action.payload };
    case ADDFOODMODAL_SEARCHPARAMS_ONCHANGE:
      return { ...state, searchParameters: action.payload };
    case ADDFOODMODAL_MODALSTATE_ONCHANGE:
      return { ...state, modalState: action.payload };
    default:
      return { ...state };
  }
};
