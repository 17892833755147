/* eslint-disable prettier/prettier */
import {
  LOGINHISTORY_GET_LIST,
  LOGINHISTORY_GET_LIST_SUCCESS,
  LOGINHISTORY_GET_LIST_ERROR,
  LOGINHISTORY_RESET_STATUSES,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGINHISTORY_GET_LIST:
      return { ...state, loading: true };
    case LOGINHISTORY_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case LOGINHISTORY_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case LOGINHISTORY_RESET_STATUSES:
      return { ...state, success: null, error: null };
    default:
      return { ...state };
  }
};
