// eslint-disable-next-line import/no-cycle
import {
  DATASOURCE_GET_LIST,
  DATASOURCE_GET_LIST_SUCCESS,
  DATASOURCE_GET_LIST_ERROR,
  DATASOURCE_SET_SELECTED,
  DATASOURCE_UPDATE_SELECTED,
  DATASOURCE_SET_SELECTED_SUCCESS,
  DATASOURCE_SET_SELECTED_ERROR,
  DATASOURCE_ADD_RECORD_SUCCESS,
  DATASOURCE_ADD_RECORD_ERROR,
  DATASOURCE_ADD_RECORD,
  DATASOURCE_UPDATE_RECORD_SUCCESS,
  DATASOURCE_UPDATE_RECORD_ERROR,
  DATASOURCE_UPDATE_RECORD,
  DATASOURCE_DELETE_RECORD,
  DATASOURCE_DELETE_RECORD_SUCCESS,
  DATASOURCE_DELETE_RECORD_ERROR,
  DATASOURCE_RESET_STATUSES,
  DATASOURCE_ISACTIVECHANGE_SUCCESS,
  DATASOURCE_ISACTIVECHANGE_ERROR,
  DATASOURCE_ISACTIVECHANGE,
  DATASOURCE_GET_DROPDOWN_LIST,
  DATASOURCE_GET_DROPDOWN_LIST_SUCCESS,
  DATASOURCE_GET_DROPDOWN_LIST_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getDataSourceList = (pageDetail) => ({
  type: DATASOURCE_GET_LIST,
  payload: pageDetail,
});

export const getDataSourceListSuccess = (items) => ({
  type: DATASOURCE_GET_LIST_SUCCESS,
  payload: items,
});

export const getDataSourceListError = (error) => ({
  type: DATASOURCE_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const getDataSourceDropDownList = () => ({
  type: DATASOURCE_GET_DROPDOWN_LIST,
});

export const getDataSourceDropDownListSuccess = (items) => ({
  type: DATASOURCE_GET_DROPDOWN_LIST_SUCCESS,
  payload: items,
});

export const getDataSourceDropDownListError = (error) => ({
  type: DATASOURCE_GET_DROPDOWN_LIST_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const setDataSourceSelectedRecord = (recordId) => ({
  type: DATASOURCE_SET_SELECTED,
  payload: recordId,
});

export const setDataSourceSelectedRecordSuccess = (record) => ({
  type: DATASOURCE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setDataSourceSelectedRecordError = (error) => ({
  type: DATASOURCE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateDataSourceSelectedRecord = (record) => ({
  type: DATASOURCE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addDataSourceRecord = (record) => ({
  type: DATASOURCE_ADD_RECORD,
  payload: record,
});

export const addDataSourceRecordSuccess = (msg) => ({
  type: DATASOURCE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addDataSourceRecordError = (error) => ({
  type: DATASOURCE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateDataSourceRecords = (record) => ({
  type: DATASOURCE_UPDATE_RECORD,
  payload: record,
});

export const updateDataSourceRecordSuccess = (msg) => ({
  type: DATASOURCE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateDataSourceRecordError = (error) => ({
  type: DATASOURCE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const deleteDataSourceRecords = (record) => ({
  type: DATASOURCE_DELETE_RECORD,
  payload: record,
});

export const deleteDataSourceRecordSuccess = (msg) => ({
  type: DATASOURCE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteDataSourceRecordError = (error) => ({
  type: DATASOURCE_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeDataSource = (record) => ({
  type: DATASOURCE_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeDataSourceSuccess = (msg) => ({
  type: DATASOURCE_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeDataSourceError = (error) => ({
  type: DATASOURCE_ISACTIVECHANGE_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const resetDataSourceStatuses = () => ({
  type: DATASOURCE_RESET_STATUSES,
});
