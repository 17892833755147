/* eslint-disable prettier/prettier */
import {
  NUTRIENTCOMMENT_GET_LIST,
  NUTRIENTCOMMENT_GET_LIST_SUCCESS,
  NUTRIENTCOMMENT_GET_LIST_ERROR,
  NUTRIENTCOMMENT_SET_SELECTED,
  NUTRIENTCOMMENT_UPDATE_SELECTED,
  NUTRIENTCOMMENT_SET_SELECTED_SUCCESS,
  NUTRIENTCOMMENT_SET_SELECTED_ERROR,
  NUTRIENTCOMMENT_UPDATE_RECORD,
  NUTRIENTCOMMENT_UPDATE_RECORD_ERROR,
  NUTRIENTCOMMENT_UPDATE_RECORD_SUCCESS,
  NUTRIENTCOMMENT_ADD_RECORD,
  NUTRIENTCOMMENT_ADD_RECORD_SUCCESS,
  NUTRIENTCOMMENT_ADD_RECORD_ERROR,
  NUTRIENTCOMMENT_DELETE_RECORD,
  NUTRIENTCOMMENT_DELETE_RECORD_SUCCESS,
  NUTRIENTCOMMENT_DELETE_RECORD_ERROR,
  NUTRIENTCOMMENT_RESET_STATUSES,
  NUTRIENTCOMMENT_ISACTIVECHANGE,
  NUTRIENTCOMMENT_ISACTIVECHANGE_SUCCESS,
  NUTRIENTCOMMENT_ISACTIVECHANGE_ERROR,
  NUTRIENTCOMMENT_BYFILTER,
  NUTRIENTCOMMENT_BYFILTER_SUCCESS,
  NUTRIENTCOMMENT_BYFILTER_ERROR,
  NUTRIENTCOMMENT_UPSERT_RECORD,
  NUTRIENTCOMMENT_UPSERT_RECORD_SUCCESS,
  NUTRIENTCOMMENT_UPSERT_RECORD_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NUTRIENTCOMMENT_GET_LIST:
      return { ...state, loading: true };
    case NUTRIENTCOMMENT_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case NUTRIENTCOMMENT_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case NUTRIENTCOMMENT_SET_SELECTED:
      return { ...state, modaLoading: true };
    case NUTRIENTCOMMENT_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case NUTRIENTCOMMENT_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case NUTRIENTCOMMENT_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case NUTRIENTCOMMENT_ADD_RECORD:
      return { ...state, loading: false };
    case NUTRIENTCOMMENT_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case NUTRIENTCOMMENT_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case NUTRIENTCOMMENT_UPDATE_RECORD:
      return { ...state, loading: false };
    case NUTRIENTCOMMENT_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case NUTRIENTCOMMENT_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case NUTRIENTCOMMENT_DELETE_RECORD:
      return { ...state, loading: false };
    case NUTRIENTCOMMENT_DELETE_RECORD_SUCCESS:
      // eslint-disable-next-line prefer-const, no-case-declarations
      let deleteIndex = state.selectedRecord.findIndex(
        (x) => x.id === action.payload.data
      );
      // eslint-disable-next-line no-param-reassign
      state.selectedRecord.splice(deleteIndex, 1);
      return { ...state, loading: false, success: action.payload.message };
    case NUTRIENTCOMMENT_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case NUTRIENTCOMMENT_ISACTIVECHANGE:
      return { ...state, loading: false };
    case NUTRIENTCOMMENT_ISACTIVECHANGE_SUCCESS: {
      // eslint-disable-next-line prefer-const
      let objIndex = state.records.findIndex(
        (x) => x.id === action.payload.singleData.id
      );
      // eslint-disable-next-line no-param-reassign
      state.records[objIndex] = action.payload.singleData;
      return { ...state, loading: false, records: state.records };
    }
    case NUTRIENTCOMMENT_ISACTIVECHANGE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case NUTRIENTCOMMENT_BYFILTER:
      return { ...state, loading: false };
    case NUTRIENTCOMMENT_BYFILTER_SUCCESS: {
      return { ...state, loading: false, selectedRecord: action.payload };
    }
    case NUTRIENTCOMMENT_BYFILTER_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case NUTRIENTCOMMENT_UPSERT_RECORD:
      return { ...state, loading: true };
    case NUTRIENTCOMMENT_UPSERT_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRecord: action.payload.data,
        success: action.payload.message,
      };
    case NUTRIENTCOMMENT_UPSERT_RECORD_ERROR:
      return { ...state, loading: false, error: action.payload };
    //------------------------------------------------------------------------------
    case NUTRIENTCOMMENT_RESET_STATUSES:
      return { ...state, success: null, error: null };
    //------------------------------------------------------------------------------
    default:
      return { ...state };
  }
};
