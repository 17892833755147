import {
  USER_GET_LIST,
  USER_GET_LIST_SUCCESS,
  USER_GET_LIST_ERROR,
  USER_ADD_RECORD,
  USER_ADD_RECORD_ERROR,
  USER_ADD_RECORD_SUCCESS,
  USER_DELETE_RECORD,
  USER_DELETE_RECORD_ERROR,
  USER_DELETE_RECORD_SUCCESS,
  USER_RESET_STATUSES,
  USER_SET_SELECTED,
  USER_SET_SELECTED_ERROR,
  USER_SET_SELECTED_SUCCESS,
  USER_UPDATE_RECORD,
  USER_UPDATE_RECORD_ERROR,
  USER_UPDATE_RECORD_SUCCESS,
  USER_UPDATE_SELECTED,
  USER_PROFILE_PICTURE_UPDATE,
  USER_PROFILE_PICTURE_UPDATE_SUCCESS,
  USER_PROFILE_PICTURE_UPDATE_ERROR,
  USER_CHANGEPASSWORD_RECORD_UPDATE,
  USER_CHANGEPASSWORD_UPDATE,
  USER_CHANGEPASSWORD_UPDATE_SUCCESS,
  USER_CHANGEPASSWORD_UPDATE_ERROR,
  USER_DELETEPICTURE,
  USER_DELETEPICTURE_SUCCESS,
  USER_DELETEPICTURE_ERROR,
  USER_PENDINGAPPROVAL,
  USER_PENDINGAPPROVAL_SUCCESS,
  USER_PENDINGAPPROVAL_ERROR,
  USER_PENDINGAPPROVAL_CHANGE,
  USER_PENDINGAPPROVAL_CHANGE_SUCCESS,
  USER_PENDINGAPPROVAL_CHANGE_ERROR,
  USER_PAYMENT,
  USER_PAYMENT_SUCCESS,
  USER_PAYMENT_ERROR,
  USER_PREVIEWPDF,
  USER_PREVIEWPDF_SUCCESS,
  USER_PREVIEWPDF_ERROR,
  USER_PAYMENTINTERNAL,
  USER_PAYMENTINTERNAL_SUCCESS,
  USER_PAYMENTINTERNAL_ERROR,
  USER_PAYMENT_CLEARMODAL,
  USER_ISPAYMENTCONTROL,
  USER_ISPAYMENTCONTROL_SUCCESS,
  USER_ISPAYMENTCONTROL_ERROR,
  USER_PERIOD,
  USER_PERIOD_SUCCESS,
  USER_PERIOD_ERROR,
  USER_GETPROFILE_PICTURE,
  USER_GETPROFILE_PICTURE_SUCCESS,
  USER_GETPROFILE_PICTURE_ERROR,
  USER_ISACTIVECHANGE,
  USER_ISACTIVECHANGE_SUCCESS,
  USER_ISACTIVECHANGE_ERROR,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_ERROR,
  USERPLAN_PLANANDUSER,
  USERPLAN_PLANANDUSER_SUCCESS,
  USERPLAN_PLANANDUSER_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  changePasswordRecord: null,
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
  file: null,
  html: null,
  paymentNotify: 0,
  userPeriod: null,
  profilePicture: null,
  userPlans: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_GET_LIST:
      return { ...state, loading: true };
    case USER_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRecord: null,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case USER_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case USER_SET_SELECTED:
      return { ...state, modaLoading: true };
    case USER_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case USER_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case USER_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case USER_ADD_RECORD:
      return { ...state, loading: false };
    case USER_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USER_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case USER_UPDATE_RECORD:
      return { ...state, loading: false };
    case USER_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USER_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_DELETE_RECORD:
      return { ...state, loading: false };
    case USER_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USER_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_PROFILE_PICTURE_UPDATE:
      return { ...state, loading: false };
    case USER_PROFILE_PICTURE_UPDATE_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USER_PROFILE_PICTURE_UPDATE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_CHANGEPASSWORD_RECORD_UPDATE:
      return { ...state, changePasswordRecord: action.payload };

    case USER_CHANGEPASSWORD_UPDATE:
      return { ...state, loading: false };
    case USER_CHANGEPASSWORD_UPDATE_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USER_CHANGEPASSWORD_UPDATE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------

    case USER_DELETEPICTURE:
      return { ...state, loading: false };
    case USER_DELETEPICTURE_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USER_DELETEPICTURE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------

    case USER_PENDINGAPPROVAL:
      return { ...state, loading: false };
    case USER_PENDINGAPPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case USER_PENDINGAPPROVAL_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------

    case USER_PENDINGAPPROVAL_CHANGE:
      return { ...state, loading: false };
    case USER_PENDINGAPPROVAL_CHANGE_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USER_PENDINGAPPROVAL_CHANGE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_PAYMENT:
      return { ...state, loading: false };
    case USER_PAYMENT_SUCCESS:
      return { ...state, loading: false, html: action.payload };
    case USER_PAYMENT_CLEARMODAL:
      return { ...state, html: null, paymentNotify: action.payload };
    case USER_PAYMENT_ERROR:
    case USER_PREVIEWPDF:
      return { ...state, loading: false };
    case USER_PREVIEWPDF_SUCCESS:
      return { ...state, loading: false, file: action.payload };
    case USER_PREVIEWPDF_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_PAYMENTINTERNAL:
      return { ...state, loading: false };
    case USER_PAYMENTINTERNAL_SUCCESS:
      return { ...state, loading: false, file: action.payload };
    case USER_PAYMENTINTERNAL_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_ISPAYMENTCONTROL:
      return { ...state, loading: false };
    case USER_ISPAYMENTCONTROL_SUCCESS:
      return { ...state, loading: false };
    case USER_ISPAYMENTCONTROL_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_PERIOD:
      return { ...state, loading: false };
    case USER_PERIOD_SUCCESS:
      return {
        ...state,
        userPeriod: action.payload.singleData,
        loading: false,
      };
    case USER_PERIOD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_GETPROFILE_PICTURE:
      return { ...state, loading: false };
    case USER_GETPROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        profilePicture: action.payload,
        loading: false,
      };
    case USER_GETPROFILE_PICTURE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_ISACTIVECHANGE:
      return { ...state, loading: false };
    case USER_ISACTIVECHANGE_SUCCESS: {
      // eslint-disable-next-line prefer-const
      let objIndex = state.records.findIndex(
        (x) => x.id === action.payload.singleData.id
      );
      // eslint-disable-next-line no-param-reassign
      state.records[objIndex] = action.payload.singleData;
      return { ...state, loading: false, records: state.records };
    }
    case USER_ISACTIVECHANGE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_CHANGE_PASSWORD:
      return { ...state, loading: false };
    case USER_CHANGE_PASSWORD_SUCCESS: {
      return { ...state, loading: false, success: action.payload };
    }
    case USER_CHANGE_PASSWORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USERPLAN_PLANANDUSER:
      return { ...state, loading: false };
    case USERPLAN_PLANANDUSER_SUCCESS: {
      return { ...state, loading: false, userPlans: action.payload };
    }
    case USERPLAN_PLANANDUSER_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USER_RESET_STATUSES:
      return { ...state, success: null, error: null };
    default:
      return { ...state };
  }
};
