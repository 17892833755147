/* eslint-disable func-names */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  RECIPE_ADD_RECORD,
  RECIPE_UPDATE_RECORD,
  RECIPE_GET_LIST,
  RECIPE_SET_SELECTED,
  RECIPE_DELETE_RECORD,
  deleteRecipeRecordSuccess,
  deleteRecipeRecordError,
  RECIPE_ISACTIVECHANGE,
} from '../../actions';

import {
  getRecipeListSuccess,
  getRecipeListError,
  setRecipeSelectedRecordSuccess,
  setRecipeSelectedRecordError,
  addRecipeRecordSuccess,
  addRecipeRecordError,
  updateRecipeRecordSuccess,
  updateRecipeRecordError,
  isActiveChangeRecipeSuccess,
  isActiveChangeRecipeError,
} from './actions';

//---------------------------------------------------------------------
const getRecipeListRequest = async (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/recipe${url || ''}`)
    .then((response) => response.data);
};

function* getRecipeListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(getRecipeListRequest, filterBuilder(payload));
      yield put(getRecipeListSuccess(response));
    } else {
      const response = yield call(getRecipeListRequest);
      yield put(getRecipeListSuccess(response));
    }
  } catch (error) {
    yield put(getRecipeListError(error));
  }
}
//----------------------------------------------------------------------------------
const setRecipeSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/recipe/${id}`)
    .then((response) => response.data.singleData);

function* setRecipeSelectedRecord({ payload }) {
  try {
    const response = yield call(setRecipeSelectedRecordRequest, payload);
    yield put(setRecipeSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setRecipeSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addRecipeRecordRequest = (record) => {
  if (record.images) {
    let formData = new FormData();
    record.images.forEach(function (element, i) {
      formData.append('images[' + i + '].picturePath', element.picturePath);
      formData.append(
        'images[' + i + '].pictureFileContentType',
        element.pictureFileContentType
      );
      formData.append('images[' + i + '].pictureFile', element.pictureFile);
    });
    formData.set('name', record.name);
    formData.set('mealId', record.mealId);
    formData.set('recipeValue', record.recipeValue);

    return axios
      .post(`${process.env.REACT_APP_API_URL}/recipe/images`, formData, {
        headers: { 'content-type': 'multipart/form-data;' },
      })
      .then((response) => response.data);
  } else {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/recipe`, record)
      .then((response) => response.data);
  }
};

function* addRecipeRecords({ payload }) {
  try {
    yield call(addRecipeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addRecipeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getRecipeListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addRecipeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateRecipeRecordRequest = (record) => {
  let formData = new FormData();
  record.images.forEach(function (element, i) {
    if (element.id !== undefined) {
      formData.append('images[' + i + '].id', element.id);
      formData.append('images[' + i + '].recipeId', element.recipeId);
    }
    formData.append('images[' + i + '].picturePath', element.picturePath);
    formData.append(
      'images[' + i + '].pictureFileContentType',
      element.pictureFileContentType
    );
    formData.append('images[' + i + '].pictureFile', element.pictureFile);
  });
  formData.set('id', record.id);
  formData.set('isActive', true);
  formData.set('name', record.name);
  formData.set('mealId', record.mealId);
  formData.set('recipeValue', record.recipeValue);
  return axios
    .put(`${process.env.REACT_APP_API_URL}/recipe`, formData, {
      headers: { 'content-type': 'multipart/form-data;' },
    })
    .then((response) => response.data);
};

function* updateRecipeRecords({ payload }) {
  try {
    yield call(updateRecipeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateRecipeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getRecipeListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateRecipeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const deleteRecipeRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/recipe/${recordId}`)
    .then((response) => response.data);

function* deleteRecipeRecords({ payload }) {
  try {
    yield call(deleteRecipeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteRecipeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getRecipeListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteRecipeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeRecipeRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/recipe/isactivechange/${recordId}`)
    .then((response) => response.data);

function* isActiveChangeRecipe({ payload }) {
  try {
    const response = yield call(isActiveChangeRecipeRequest, payload);
    yield put(isActiveChangeRecipeSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeRecipeError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(800, RECIPE_GET_LIST, getRecipeListItems);
}

export function* watchSetSelected() {
  yield takeEvery(RECIPE_SET_SELECTED, setRecipeSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(RECIPE_ADD_RECORD, addRecipeRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(RECIPE_UPDATE_RECORD, updateRecipeRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(RECIPE_DELETE_RECORD, deleteRecipeRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(RECIPE_ISACTIVECHANGE, isActiveChangeRecipe);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
