import {
  PLANREPASTFOOD_GET_LIST,
  PLANREPASTFOOD_GET_LIST_SUCCESS,
  PLANREPASTFOOD_GET_LIST_ERROR,
  PLANREPASTFOOD_SET_SELECTED,
  PLANREPASTFOOD_UPDATE_SELECTED,
  PLANREPASTFOOD_SET_SELECTED_SUCCESS,
  PLANREPASTFOOD_SET_SELECTED_ERROR,
  PLANREPASTFOOD_UPDATE_RECORD,
  PLANREPASTFOOD_UPDATE_RECORD_ERROR,
  PLANREPASTFOOD_UPDATE_RECORD_SUCCESS,
  PLANREPASTFOOD_ADD_RECORD,
  PLANREPASTFOOD_ADD_RECORD_SUCCESS,
  PLANREPASTFOOD_ADD_RECORD_ERROR,
  PLANREPASTFOOD_DELETE_RECORD,
  PLANREPASTFOOD_DELETE_RECORD_SUCCESS,
  PLANREPASTFOOD_DELETE_RECORD_ERROR,
  PLANREPASTFOOD_RESET_STATUSES,
  PLANREPASTFOOD_UPDATE_BATCHRECORD,
  PLANREPASTFOOD_UPDATE_BATCHRECORD_ERROR,
  PLANREPASTFOOD_UPDATE_BATCHRECORD_SUCCESS,
  PLANREPASTFOOD_MEALFOODTRANFER,
  PLANREPASTFOOD_MEALFOODTRANFER_SUCCESS,
  PLANREPASTFOOD_MEALFOODTRANFER_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  repast: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PLANREPASTFOOD_GET_LIST:
      return { ...state, loading: true };
    case PLANREPASTFOOD_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case PLANREPASTFOOD_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PLANREPASTFOOD_SET_SELECTED:
      return { ...state, modaLoading: true };
    case PLANREPASTFOOD_SET_SELECTED_SUCCESS: {
      return { ...state, modaLoading: false, repast: action.payload };
    }
    case PLANREPASTFOOD_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case PLANREPASTFOOD_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case PLANREPASTFOOD_ADD_RECORD:
      return { ...state, loading: false };
    case PLANREPASTFOOD_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPASTFOOD_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PLANREPASTFOOD_UPDATE_RECORD:
      return { ...state, loading: false };
    case PLANREPASTFOOD_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPASTFOOD_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLANREPASTFOOD_UPDATE_BATCHRECORD:
      return { ...state, loading: false };
    case PLANREPASTFOOD_UPDATE_BATCHRECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPASTFOOD_UPDATE_BATCHRECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------

    case PLANREPASTFOOD_DELETE_RECORD:
      return { ...state, loading: false };
    case PLANREPASTFOOD_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPASTFOOD_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------

    case PLANREPASTFOOD_MEALFOODTRANFER:
      return { ...state, loading: false };
    case PLANREPASTFOOD_MEALFOODTRANFER_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPASTFOOD_MEALFOODTRANFER_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------

    case PLANREPASTFOOD_RESET_STATUSES:
      return { ...state, success: null, error: null };
    default:
      return { ...state };
  }
};
