import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import foodGroupSagas from './administration/food-group/saga';
import subFoodGroupSagas from './administration/subfood-group/saga';
import foodSagas from './administration/food/saga';
import nutrientSagas from './administration/nutrient/saga';
import referenceSourceSagas from './administration/reference-source/saga';
import referenceRangeSagas from './administration/reference-range/saga';
import mealSagas from './administration/meal/saga';
import mealGroupSagas from './administration/meal-group/saga';
import recipeSagas from './administration/recipe/saga';
import portionSagas from './administration/portion/saga';
import unitSagas from './administration/unit/saga';
import nutrientGroupSagas from './administration/nutrient-group/saga';
import repastSagas from './administration/repast/saga';
import coacheeSagas from './applications/coachee/saga';
import planningSagas from './applications/planning/saga';
import portionPictureSagas from './administration/portion-picture/saga';
import physicalActivitySagas from './administration/physical-activity/saga';
import foodPortionSagas from './administration/food-portion/saga';
import planRepastSagas from './applications/plan-repast/saga';
import tagSagas from './applications/tag/saga';
import dataSourceSagas from './administration/data-source/saga';
import mealNutrientTotalsSagas from './applications/meal-nutrient-total/saga';
import planRepastFoodSagas from './applications/planrepast-food/saga';
import planRepastFoodPortionSagas from './applications/planrepastfood-portion/saga';
import activityTypeSagas from './applications/activity-type/saga';
import bodyMassIndexRangeSagas from './applications/body-mass-index-range/saga';
import formulaSagas from './applications/formula/saga';
import antropometrySagas from './applications/antropometry/saga';
import userSagas from './profile/user/saga';
import userPreferenceSagas from './profile/userpreference/saga';
import roleSagas from './rolemanagement/role/saga';
import roleGroupSagas from './rolemanagement/rolegroup/saga';
import userRoleSagas from './rolemanagement/userrole/saga';
import userPlanSagas from './rolemanagement/userplan/saga';
import userPlanRoleSagas from './rolemanagement/userplanrole/saga';
import couponSagas from './administration/coupon/saga';
import loginHistorySagas from './administration/loginHistory/saga';
import menuprepareSagas from './applications/menuprepare/saga';
import emailSagas from './administration/email/saga';
import lackCompletionSagas from './administration/lackcompletion/saga';
import notificationsSagas from './administration/notification/saga';
import nutrientCommentSagas from './administration/nutrientcomment/saga';
import sampleDescriptionSagas from './applications/sampledescription/saga';

// eslint-disable-next-line no-unused-vars
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    foodGroupSagas(),
    foodSagas(),
    nutrientSagas(),
    referenceSourceSagas(),
    referenceRangeSagas(),
    mealSagas(),
    mealGroupSagas(),
    recipeSagas(),
    portionSagas(),
    unitSagas(),
    nutrientGroupSagas(),
    repastSagas(),
    coacheeSagas(),
    planningSagas(),
    portionPictureSagas(),
    physicalActivitySagas(),
    foodPortionSagas(),
    planRepastSagas(),
    mealNutrientTotalsSagas(),
    planRepastFoodSagas(),
    planRepastFoodPortionSagas(),
    activityTypeSagas(),
    bodyMassIndexRangeSagas(),
    formulaSagas(),
    antropometrySagas(),
    dataSourceSagas(),
    subFoodGroupSagas(),
    userSagas(),
    userPreferenceSagas(),
    roleSagas(),
    roleGroupSagas(),
    userRoleSagas(),
    userPlanSagas(),
    userPlanRoleSagas(),
    tagSagas(),
    couponSagas(),
    loginHistorySagas(),
    menuprepareSagas(),
    emailSagas(),
    lackCompletionSagas(),
    notificationsSagas(),
    nutrientCommentSagas(),
    sampleDescriptionSagas(),
  ]);
}
