/* eslint-disable prettier/prettier */
/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_PAGE_RECORD_COUNT = 'CHANGE_PAGE_RECORD_COUNT';
export const CHANGE_CURRENT_USER = 'CHANGE_CURRENT_USER';
export const CHANGE_NOTIFICATION_MESSAGE = 'CHANGE_NOTIFICATION_MESSAGE';
export const CHANGE_ENERGYVALUE = 'CHANGE_ENERGYVALUE';
export const CHANGE_PLANCOMPARISON = 'CHANGE_PLANCOMPARISON';
/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const CLOSE_TAB_USER = 'CLOSE_TAB_USER';
export const CLOSE_TAB_USER_SUCCESS = 'CLOSE_TAB_USER_SUCCESS';
export const CLOSE_TAB_USER_ERROR = 'CLOSE_TAB_USER_ERROR';

export const REFRESH_USER_TOKEN = 'REFRESH_USER_TOKEN';
// export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
// export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

export const APPROVAL_FILE = 'APPROVAL_FILE';
export const APPROVAL_FILE_SUCCESS = 'APPROVAL_FILE_SUCCESS';
export const APPROVAL_FILE_ERROR = 'APPROVAL_FILE_ERROR';

export const AUTH_RESET_STATUSES = 'AUTH_RESET_STATUSES';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

/* ADMINISTRATION */

/* REFERENCE SOURCE */
export const REFERENCESOURCE_GET_LIST = 'REFERENCE_SOURCE_GET_LIST';
export const REFERENCESOURCE_GET_LIST_SUCCESS =
  'REFERENCE_SOURCE_GET_LIST_SUCCESS';
export const REFERENCESOURCE_GET_LIST_ERROR = 'REFERENCE_SOURCE_GET_LIST_ERROR';

export const REFERENCESOURCE_SET_SELECTED = 'REFERENCE_SOURCE_SET_SELECTED';
export const REFERENCESOURCE_SET_SELECTED_SUCCESS =
  'REFERENCE_SOURCE_SET_SELECTED_SUCCESS';
export const REFERENCESOURCE_SET_SELECTED_ERROR =
  'REFERENCE_SOURCE_SET_SELECTED_ERROR';
export const REFERENCESOURCE_UPDATE_SELECTED =
  'REFERENCESOURCE_UPDATE_SELECTED';

export const REFERENCESOURCE_GET_LIST_WITH_FILTER =
  'REFERENCE_SOURCE_GET_LIST_WITH_FILTER';
export const REFERENCESOURCE_GET_LIST_WITH_ORDER =
  'REFERENCE_SOURCE_GET_LIST_WITH_ORDER';
export const REFERENCESOURCE_GET_LIST_SEARCH =
  'REFERENCE_SOURCE_GET_LIST_SEARCH';

export const REFERENCESOURCE_ADD_RECORD = 'REFERENCESOURCE_ADD_RECORD';
export const REFERENCESOURCE_ADD_RECORD_SUCCESS =
  'REFERENCESOURCE_ADD_RECORD_SUCCESS';
export const REFERENCESOURCE_ADD_RECORD_ERROR =
  'REFERENCESOURCE_ADD_RECORD_ERROR';

export const REFERENCESOURCE_UPDATE_RECORD = 'REFERENCESOURCE_UPDATE_RECORD';
export const REFERENCESOURCE_UPDATE_RECORD_SUCCESS =
  'REFERENCESOURCE_UPDATE_RECORD_SUCCESS';
export const REFERENCESOURCE_UPDATE_RECORD_ERROR =
  'REFERENCESOURCE_UPDATE_RECORD_ERROR';

export const REFERENCESOURCE_DELETE_RECORD = 'REFERENCESOURCE_DELETE_RECORD';
export const REFERENCESOURCE_DELETE_RECORD_SUCCESS =
  'REFERENCESOURCE_DELETE_RECORD_SUCCESS';
export const REFERENCESOURCE_DELETE_RECORD_ERROR =
  'REFERENCESOURCE_DELETE_RECORD_ERROR';
export const REFERENCESOURCE_RESET_STATUSES = 'REFERENCESOURCE_RESET_STATUSES';

export const REFERENCESOURCE_ISACTIVECHANGE = 'REFERENCESOURCE_ISACTIVECHANGE';
export const REFERENCESOURCE_ISACTIVECHANGE_SUCCESS =
  'REFERENCESOURCE_ISACTIVECHANGE_SUCCESS';
export const REFERENCESOURCE_ISACTIVECHANGE_ERROR =
  'REFERENCESOURCE_ISACTIVECHANGE_ERROR';

/* #REFERENCE SOURCE */

/* REFERENCE SOURCE */
export const REPAST_GET_LIST = 'REPAST_GET_LIST';
export const REPAST_GET_LIST_SUCCESS = 'REPAST_GET_LIST_SUCCESS';
export const REPAST_GET_LIST_ERROR = 'REPAST_GET_LIST_ERROR';

export const REPAST_SET_SELECTED = 'REPAST_SET_SELECTED';
export const REPAST_SET_SELECTED_SUCCESS = 'REPAST_SET_SELECTED_SUCCESS';
export const REPAST_SET_SELECTED_ERROR = 'REPAST_SET_SELECTED_ERROR';
export const REPAST_UPDATE_SELECTED = 'REPAST_UPDATE_SELECTED';

export const REPAST_GET_LIST_WITH_FILTER =
  'REFERENCE_SOURCE_GET_LIST_WITH_FILTER';
export const REPAST_GET_LIST_WITH_ORDER =
  'REFERENCE_SOURCE_GET_LIST_WITH_ORDER';
export const REPAST_GET_LIST_SEARCH = 'REFERENCE_SOURCE_GET_LIST_SEARCH';

export const REPAST_ADD_RECORD = 'REPAST_ADD_RECORD';
export const REPAST_ADD_RECORD_SUCCESS = 'REPAST_ADD_RECORD_SUCCESS';
export const REPAST_ADD_RECORD_ERROR = 'REPAST_ADD_RECORD_ERROR';

export const REPAST_UPDATE_RECORD = 'REPAST_UPDATE_RECORD';
export const REPAST_UPDATE_RECORD_SUCCESS = 'REPAST_UPDATE_RECORD_SUCCESS';
export const REPAST_UPDATE_RECORD_ERROR = 'REPAST_UPDATE_RECORD_ERROR';

export const REPAST_DELETE_RECORD = 'REPAST_DELETE_RECORD';
export const REPAST_DELETE_RECORD_SUCCESS = 'REPAST_DELETE_RECORD_SUCCESS';
export const REPAST_DELETE_RECORD_ERROR = 'REPAST_DELETE_RECORD_ERROR';
export const REPAST_RESET_STATUSES = 'REPAST_RESET_STATUSES';

export const REPAST_ISACTIVECHANGE = 'REPAST_ISACTIVECHANGE';
export const REPAST_ISACTIVECHANGE_SUCCESS = 'REPAST_ISACTIVECHANGE_SUCCESS';
export const REPAST_ISACTIVECHANGE_ERROR = 'REPAST_ISACTIVECHANGE_ERROR';

/* #REFERENCE SOURCE */

/* UNIT */
export const UNIT_GET_LIST = 'UNIT_GET_LIST';
export const UNIT_GET_LIST_SUCCESS = 'UNIT_GET_LIST_SUCCESS';
export const UNIT_GET_LIST_ERROR = 'UNIT_GET_LIST_ERROR';

export const UNIT_SET_SELECTED = 'UNIT_SET_SELECTED';
export const UNIT_SET_SELECTED_SUCCESS = 'UNIT_SET_SELECTED_SUCCESS';
export const UNIT_SET_SELECTED_ERROR = 'UNIT_SET_SELECTED_ERROR';
export const UNIT_UPDATE_SELECTED = 'UNIT_UPDATE_SELECTED';

export const UNIT_GET_LIST_WITH_FILTER = 'UNIT_GET_LIST_WITH_FILTER';
export const UNIT_GET_LIST_WITH_ORDER = 'UNIT_GET_LIST_WITH_ORDER';
export const UNIT_GET_LIST_SEARCH = 'UNIT_GET_LIST_SEARCH';

export const UNIT_ADD_RECORD = 'UNIT_ADD_RECORD';
export const UNIT_ADD_RECORD_SUCCESS = 'UNIT_ADD_RECORD_SUCCESS';
export const UNIT_ADD_RECORD_ERROR = 'UNIT_ADD_RECORD_ERROR';

export const UNIT_UPDATE_RECORD = 'UNIT_UPDATE_RECORD';
export const UNIT_UPDATE_RECORD_SUCCESS = 'UNIT_UPDATE_RECORD_SUCCESS';
export const UNIT_UPDATE_RECORD_ERROR = 'UNIT_UPDATE_RECORD_ERROR';

export const UNIT_DELETE_RECORD = 'UNIT_DELETE_RECORD';
export const UNIT_DELETE_RECORD_SUCCESS = 'UNIT_DELETE_RECORD_SUCCESS';
export const UNIT_DELETE_RECORD_ERROR = 'UNIT_DELETE_RECORD_ERROR';
export const UNIT_RESET_STATUSES = 'UNIT_RESET_STATUSES';

export const UNIT_ISACTIVECHANGE = 'UNIT_ISACTIVECHANGE';
export const UNIT_ISACTIVECHANGE_SUCCESS = 'UNIT_ISACTIVECHANGE_SUCCESS';
export const UNIT_ISACTIVECHANGE_ERROR = 'UNIT_ISACTIVECHANGE_ERROR';

/* DATA-SOUCE */
export const DATASOURCE_GET_LIST = 'DATASOURCE_GET_LIST';
export const DATASOURCE_GET_LIST_SUCCESS = 'DATASOURCE_GET_LIST_SUCCESS';
export const DATASOURCE_GET_LIST_ERROR = 'DATASOURCE_GET_LIST_ERROR';

export const DATASOURCE_SET_SELECTED = 'DATASOURCE_SET_SELECTED';
export const DATASOURCE_SET_SELECTED_SUCCESS =
  'DATASOURCE_SET_SELECTED_SUCCESS';
export const DATASOURCE_SET_SELECTED_ERROR = 'DATASOURCE_SET_SELECTED_ERROR';
export const DATASOURCE_UPDATE_SELECTED = 'DATASOURCE_UPDATE_SELECTED';

export const DATASOURCE_GET_LIST_WITH_FILTER =
  'DATASOURCE_GET_LIST_WITH_FILTER';
export const DATASOURCE_GET_LIST_WITH_ORDER = 'DATASOURCE_GET_LIST_WITH_ORDER';
export const DATASOURCE_GET_LIST_SEARCH = 'DATASOURCE_GET_LIST_SEARCH';

export const DATASOURCE_GET_DROPDOWN_LIST = 'DATASOURCE_GET_DROPDOWN_LIST';
export const DATASOURCE_GET_DROPDOWN_LIST_SUCCESS =
  'DATASOURCE_GET_DROPDOWN_LIST_SUCCESS';
export const DATASOURCE_GET_DROPDOWN_LIST_ERROR =
  'DATASOURCE_GET_DROPDOWN_LIST_ERROR';

export const DATASOURCE_ADD_RECORD = 'DATASOURCE_ADD_RECORD';
export const DATASOURCE_ADD_RECORD_SUCCESS = 'DATASOURCE_ADD_RECORD_SUCCESS';
export const DATASOURCE_ADD_RECORD_ERROR = 'DATASOURCE_ADD_RECORD_ERROR';

export const DATASOURCE_UPDATE_RECORD = 'DATASOURCE_UPDATE_RECORD';
export const DATASOURCE_UPDATE_RECORD_SUCCESS =
  'DATASOURCE_UPDATE_RECORD_SUCCESS';
export const DATASOURCE_UPDATE_RECORD_ERROR = 'DATASOURCE_UPDATE_RECORD_ERROR';

export const DATASOURCE_DELETE_RECORD = 'DATASOURCE_DELETE_RECORD';
export const DATASOURCE_DELETE_RECORD_SUCCESS =
  'DATASOURCE_DELETE_RECORD_SUCCESS';
export const DATASOURCE_DELETE_RECORD_ERROR = 'DATASOURCE_DELETE_RECORD_ERROR';
export const DATASOURCE_RESET_STATUSES = 'DATASOURCE_RESET_STATUSES';

export const DATASOURCE_ISACTIVECHANGE = 'DATASOURCE_ISACTIVECHANGE';
export const DATASOURCE_ISACTIVECHANGE_SUCCESS =
  'DATASOURCE_ISACTIVECHANGE_SUCCESS';
export const DATASOURCE_ISACTIVECHANGE_ERROR =
  'DATASOURCE_ISACTIVECHANGE_ERROR';

/* #DATA-SOURCE */

/* REFERENCE RANGE */
export const REFERENCERANGE_GET_LIST = 'REFERENCE_RANGE_GET_LIST';
export const REFERENCERANGE_GET_LIST_SUCCESS =
  'REFERENCE_RANGE_GET_LIST_SUCCESS';
export const REFERENCERANGE_GET_LIST_ERROR = 'REFERENCE_RANGE_GET_LIST_ERROR';

export const REFERENCERANGE_SET_SELECTED = 'REFERENCE_RANGE_SET_SELECTED';
export const REFERENCERANGE_SET_SELECTED_SUCCESS =
  'REFERENCE_RANGE_SET_SELECTED_SUCCESS';
export const REFERENCERANGE_SET_SELECTED_ERROR =
  'REFERENCE_RANGE_SET_SELECTED_ERROR';
export const REFERENCERANGE_UPDATE_SELECTED = 'REFERENCE_RANGE_UPDATE_SELECTED';

export const REFERENCERANGE_GET_LIST_WITH_FILTER =
  'REFERENCE_RANGE_GET_LIST_WITH_FILTER';
export const REFERENCERANGE_GET_LIST_WITH_ORDER =
  'REFERENCE_RANGE_GET_LIST_WITH_ORDER';
export const REFERENCERANGE_GET_LIST_SEARCH = 'REFERENCE_RANGE_GET_LIST_SEARCH';

export const REFERENCERANGE_ADD_RECORD = 'REFERENCE_RANGE_ADD_RECORD';
export const REFERENCERANGE_ADD_RECORD_SUCCESS =
  'REFERENCE_RANGE_ADD_RECORD_SUCCESS';
export const REFERENCERANGE_ADD_RECORD_ERROR =
  'REFERENCE_RANGE_ADD_RECORD_ERROR';

export const REFERENCERANGE_UPDATE_RECORD = 'REFERENCE_RANGE_UPDATE_RECORD';
export const REFERENCERANGE_UPDATE_RECORD_SUCCESS =
  'REFERENCE_RANGE_UPDATE_RECORD_SUCCESS';
export const REFERENCERANGE_UPDATE_RECORD_ERROR =
  'REFERENCE_RANGE_UPDATE_RECORD_ERROR';

export const REFERENCERANGE_DELETE_RECORD = 'REFERENCE_RANGE_DELETE_RECORD';
export const REFERENCERANGE_DELETE_RECORD_SUCCESS =
  'REFERENCE_RANGE_DELETE_RECORD_SUCCESS';
export const REFERENCERANGE_DELETE_RECORD_ERROR =
  'REFERENCE_RANGE_DELETE_RECORD_ERROR';
export const REFERENCERANGE_RESET_STATUSES = 'REFERENCERANGE_RESET_STATUSES';

export const REFERENCERANGE_ISACTIVECHANGE = 'REFERENCERANGE_ISACTIVECHANGE';
export const REFERENCERANGE_ISACTIVECHANGE_SUCCESS =
  'REFERENCERANGE_ISACTIVECHANGE_SUCCESS';
export const REFERENCERANGE_ISACTIVECHANGE_ERROR =
  'REFERENCERANGE_ISACTIVECHANGE_ERROR';

/* #REFERENCE RANGE */

/* FOOD GROUP SOURCE */
export const FOODGROUP_GET_LIST = 'FOOD_GROUP_GET_LIST';
export const FOODGROUP_GET_LIST_SUCCESS = 'FOOD_GROUP_GET_LIST_SUCCESS';
export const FOODGROUP_GET_LIST_ERROR = 'FOOD_GROUP_GET_LIST_ERROR';

export const FOODGROUP_GET_DROPDOWN_LIST = 'FOODGROUP_GET_DROPDOWN_LIST';
export const FOODGROUP_GET_DROPDOWN_LIST_SUCCESS =
  'FOODGROUP_GET_DROPDOWN_LIST_SUCCESS';
export const FOODGROUP_GET_DROPDOWN_LIST_ERROR =
  'FOODGROUP_GET_DROPDOWN_LIST_ERROR';

export const FOODGROUP_SET_SELECTED = 'FOOD_GROUP_SET_SELECTED';
export const FOODGROUP_SET_SELECTED_SUCCESS = 'FOOD_GROUP_SET_SELECTED_SUCCESS';
export const FOODGROUP_SET_SELECTED_ERROR = 'FOOD_GROUP_SET_SELECTED_ERROR';
export const FOODGROUP_UPDATE_SELECTED = 'FOOD_GROUP_UPDATE_SELECTED';

export const FOODGROUP_GET_LIST_WITH_FILTER = 'FOOD_GROUP_GET_LIST_WITH_FILTER';
export const FOODGROUP_GET_LIST_WITH_ORDER = 'FOOD_GROUP_GET_LIST_WITH_ORDER';
export const FOODGROUP_GET_LIST_SEARCH = 'FOOD_GROUP_GET_LIST_SEARCH';

export const FOODGROUP_ADD_RECORD = 'FOOD_GROUP_ADD_RECORD';
export const FOODGROUP_ADD_RECORD_SUCCESS = 'FOOD_GROUP_ADD_RECORD_SUCCESS';
export const FOODGROUP_ADD_RECORD_ERROR = 'FOOD_GROUP_ADD_RECORD_ERROR';

export const FOODGROUP_UPDATE_RECORD = 'FOOD_GROUP_UPDATE_RECORD';
export const FOODGROUP_UPDATE_RECORD_SUCCESS =
  'FOOD_GROUP_UPDATE_RECORD_SUCCESS';
export const FOODGROUP_UPDATE_RECORD_ERROR = 'FOOD_GROUP_UPDATE_RECORD_ERROR';

export const FOODGROUP_DELETE_RECORD = 'FOOD_GROUP_DELETE_RECORD';
export const FOODGROUP_DELETE_RECORD_SUCCESS =
  'FOOD_GROUP_DELETE_RECORD_SUCCESS';
export const FOODGROUP_DELETE_RECORD_ERROR = 'FOOD_GROUP_DELETE_RECORD_ERROR';
export const FOODGROUP_RESET_STATUSES = 'FOODGROUP_RESET_STATUSES';

export const FOODGROUP_ISACTIVECHANGE = 'FOODGROUP_ISACTIVECHANGE';
export const FOODGROUP_ISACTIVECHANGE_SUCCESS =
  'FOODGROUP_ISACTIVECHANGE_SUCCESS';
export const FOODGROUP_ISACTIVECHANGE_ERROR = 'FOODGROUP_ISACTIVECHANGE_ERROR';

/* #FOOD GROUP SOURCE */

/* SUBFOOD GROUP SOURCE */
export const SUBFOODGROUP_GET_LIST = 'SUBFOOD_GROUP_GET_LIST';
export const SUBFOODGROUP_GET_LIST_SUCCESS = 'SUBFOOD_GROUP_GET_LIST_SUCCESS';
export const SUBFOODGROUP_GET_LIST_ERROR = 'SUBFOOD_GROUP_GET_LIST_ERROR';

export const SUBFOODGROUP_SET_SELECTED = 'SUBFOOD_GROUP_SET_SELECTED';
export const SUBFOODGROUP_SET_SELECTED_SUCCESS =
  'SUBFOOD_GROUP_SET_SELECTED_SUCCESS';
export const SUBFOODGROUP_SET_SELECTED_ERROR =
  'SUBFOOD_GROUP_SET_SELECTED_ERROR';
export const SUBFOODGROUP_UPDATE_SELECTED = 'SUBFOOD_GROUP_UPDATE_SELECTED';

export const SUBFOODGROUP_GET_LIST_WITH_FILTER =
  'SUBFOOD_GROUP_GET_LIST_WITH_FILTER';
export const SUBFOODGROUP_GET_LIST_WITH_ORDER =
  'SUBFOOD_GROUP_GET_LIST_WITH_ORDER';
export const SUBFOODGROUP_GET_LIST_SEARCH = 'FOOD_GROUP_GET_LIST_SEARCH';

export const SUBFOODGROUP_ADD_RECORD = 'SUBFOOD_GROUP_ADD_RECORD';
export const SUBFOODGROUP_ADD_RECORD_SUCCESS =
  'SUBFOOD_GROUP_ADD_RECORD_SUCCESS';
export const SUBFOODGROUP_ADD_RECORD_ERROR = 'SUBFOOD_GROUP_ADD_RECORD_ERROR';

export const SUBFOODGROUP_UPDATE_RECORD = 'SUBFOOD_GROUP_UPDATE_RECORD';
export const SUBFOODGROUP_UPDATE_RECORD_SUCCESS =
  'SUBFOOD_GROUP_UPDATE_RECORD_SUCCESS';
export const SUBFOODGROUP_UPDATE_RECORD_ERROR =
  'SUBFOOD_GROUP_UPDATE_RECORD_ERROR';

export const SUBFOODGROUP_DELETE_RECORD = 'SUBFOOD_GROUP_DELETE_RECORD';
export const SUBFOODGROUP_DELETE_RECORD_SUCCESS =
  'SUBFOOD_GROUP_DELETE_RECORD_SUCCESS';
export const SUBFOODGROUP_DELETE_RECORD_ERROR =
  'SUBFOOD_GROUP_DELETE_RECORD_ERROR';
export const SUBFOODGROUP_RESET_STATUSES = 'SUBFOODGROUP_RESET_STATUSES';

export const SUBFOODGROUP_ISACTIVECHANGE = 'SUBFOODGROUP_ISACTIVECHANGE';
export const SUBFOODGROUP_ISACTIVECHANGE_SUCCESS =
  'SUBFOODGROUP_ISACTIVECHANGE_SUCCESS';
export const SUBFOODGROUP_ISACTIVECHANGE_ERROR =
  'SUBFOODGROUP_ISACTIVECHANGE_ERROR';

/* #SUBFOOD GROUP SOURCE */

/* FOOD SOURCE */
export const FOOD_GET_LIST = 'FOOD_GET_LIST';
export const FOOD_GET_LIST_SUCCESS = 'FOOD_GET_LIST_SUCCESS';
export const FOOD_GET_LIST_ERROR = 'FOOD_GET_LIST_ERROR';

export const FOOD_GETNAMEWITHID_LIST = 'FOOD_GETNAMEWITHID_LIST';
export const FOOD_GETNAMEWITHID_LIST_SUCCESS =
  'FOOD_GETNAMEWITHID_LIST_SUCCESS';
export const FOOD_GETNAMEWITHID_LIST_ERROR = 'FOOD_GETNAMEWITHID_LIST_ERROR';

export const FOOD_GETNAMEWITHID_ONERECORD = 'FOOD_GETNAMEWITHID_ONERECORD';
export const FOOD_GETNAMEWITHID_ONERECORD_SUCCESS =
  'FOOD_GETNAMEWITHID_ONERECORD_SUCCESS';
export const FOOD_GETNAMEWITHID_ONERECORD_ERROR =
  'FOOD_GETNAMEWITHID_ONERECORD_ERROR';

export const FOOD_SET_SELECTED = 'FOOD_SET_SELECTED';
export const FOOD_SET_SELECTED_SUCCESS = 'FOOD_SET_SELECTED_SUCCESS';
export const FOOD_SET_SELECTED_ERROR = 'FOOD_SET_SELECTED_ERROR';
export const FOOD_UPDATE_SELECTED = 'FOOD_UPDATE_SELECTED';

export const FOOD_GET_LIST_WITH_FILTER = 'FOOD_GET_LIST_WITH_FILTER';
export const FOOD_GET_LIST_WITH_ORDER = 'FOOD_GET_LIST_WITH_ORDER';
export const FOOD_GET_LIST_SEARCH = 'FOOD_GET_LIST_SEARCH';

export const FOOD_ADD_RECORD = 'FOOD_ADD_RECORD';
export const FOOD_ADD_RECORD_SUCCESS = 'FOOD_ADD_RECORD_SUCCESS';
export const FOOD_ADD_RECORD_ERROR = 'FOOD_ADD_RECORD_ERROR';

export const FOOD_UPDATE_RECORD = 'FOOD_UPDATE_RECORD';
export const FOOD_UPDATE_RECORD_SUCCESS = 'FOOD_UPDATE_RECORD_SUCCESS';
export const FOOD_UPDATE_RECORD_ERROR = 'FOOD_UPDATE_RECORD_ERROR';

export const FOOD_DELETE_RECORD = 'FOOD_DELETE_RECORD';
export const FOOD_DELETE_RECORD_SUCCESS = 'FOOD_DELETE_RECORD_SUCCESS';
export const FOOD_DELETE_RECORD_ERROR = 'FOOD_DELETE_RECORD_ERROR';
export const FOOD_RESET_STATUSES = 'FOOD_RESET_STATUSES';

export const FOOD_COPY_RECORD = 'FOOD_COPY_RECORD';
export const FOOD_COPY_RECORD_SUCCESS = 'FOOD_COPY_RECORD_SUCCESS';
export const FOOD_COPY_RECORD_ERROR = 'FOOD_COPY_RECORD_ERROR';

export const FOOD_ISACTIVECHANGE = 'FOOD_ISACTIVECHANGE';
export const FOOD_ISACTIVECHANGE_SUCCESS = 'FOOD_ISACTIVECHANGE_SUCCESS';
export const FOOD_ISACTIVECHANGE_ERROR = 'FOOD_ISACTIVECHANGE_ERROR';

export const FOOD_GET_FOODNUTRIENTS = 'FOOD_GET_FOODNUTRIENTS';
export const FOOD_GET_FOODNUTRIENTS_SUCCESS = 'FOOD_GET_FOODNUTRIENTS_SUCCESS';
export const FOOD_GET_FOODNUTRIENTS_ERROR = 'FOOD_GET_FOODNUTRIENTS_ERROR';

/* #FOOD SOURCE */

/* NUTRIENT SOURCE */
export const NUTRIENT_GET_LIST = 'NUTRIENT_GET_LIST';
export const NUTRIENT_GET_LIST_SUCCESS = 'NUTRIENT_GET_LIST_SUCCESS';
export const NUTRIENT_GET_LIST_ERROR = 'NUTRIENT_GET_LIST_ERROR';

export const NUTRIENT_GETNAMEWITHID_LIST = 'NUTRIENT_GETNAMEWITHID_LIST';
export const NUTRIENT_GETNAMEWITHID_LIST_SUCCESS =
  'NUTRIENT_GETNAMEWITHID_LIST_SUCCESS';
export const NUTRIENT_GETNAMEWITHID_LIST_ERROR =
  'NUTRIENT_GETNAMEWITHID_LIST_ERROR';

export const NUTRIENT_GETNAMEWITHID_ONERECORD =
  'NUTRIENT_GETNAMEWITHID_ONERECORD';
export const NUTRIENT_GETNAMEWITHID_ONERECORD_SUCCESS =
  'NUTRIENT_GETNAMEWITHID_ONERECORD_SUCCESS';
export const NUTRIENT_GETNAMEWITHID_ONERECORD_ERROR =
  'NUTRIENT_GETNAMEWITHID_ONERECORD_ERROR';

export const NUTRIENT_SET_SELECTED = 'NUTRIENT_SET_SELECTED';
export const NUTRIENT_SET_SELECTED_SUCCESS = 'NUTRIENT_SET_SELECTED_SUCCESS';
export const NUTRIENT_SET_SELECTED_ERROR = 'NUTRIENT_SET_SELECTED_ERROR';
export const NUTRIENT_UPDATE_SELECTED = 'NUTRIENT_UPDATE_SELECTED';

export const NUTRIENT_GET_LIST_WITH_FILTER = 'NUTRIENT_GET_LIST_WITH_FILTER';
export const NUTRIENT_GET_LIST_WITH_ORDER = 'NUTRIENT_GET_LIST_WITH_ORDER';
export const NUTRIENT_GET_LIST_SEARCH = 'NUTRIENT_GET_LIST_SEARCH';

export const NUTRIENT_ADD_RECORD = 'NUTRIENT_ADD_RECORD';
export const NUTRIENT_ADD_RECORD_SUCCESS = 'NUTRIENT_ADD_RECORD_SUCCESS';
export const NUTRIENT_ADD_RECORD_ERROR = 'NUTRIENT_ADD_RECORD_ERROR';

export const NUTRIENT_UPDATE_RECORD = 'NUTRIENT_UPDATE_RECORD';
export const NUTRIENT_UPDATE_RECORD_SUCCESS = 'NUTRIENT_UPDATE_RECORD_SUCCESS';
export const NUTRIENT_UPDATE_RECORD_ERROR = 'NUTRIENT_UPDATE_RECORD_ERROR';

export const NUTRIENT_DELETE_RECORD = 'NUTRIENT_DELETE_RECORD';
export const NUTRIENT_DELETE_RECORD_SUCCESS = 'NUTRIENT_DELETE_RECORD_SUCCESS';
export const NUTRIENT_DELETE_RECORD_ERROR = 'NUTRIENT_DELETE_RECORD_ERROR';
export const NUTRIENT_RESET_STATUSES = 'NUTRIENT_RESET_STATUSES';

export const NUTRIENT_ISACTIVECHANGE = 'NUTRIENT_ISACTIVECHANGE';
export const NUTRIENT_ISACTIVECHANGE_SUCCESS =
  'NUTRIENT_ISACTIVECHANGE_SUCCESS';
export const NUTRIENT_ISACTIVECHANGE_ERROR = 'NUTRIENT_ISACTIVECHANGE_ERROR';

export const NUTRIENT_CALCULATE = 'NUTRIENT_CALCULATE';
export const NUTRIENT_CALCULATE_SUCCESS = 'NUTRIENT_CALCULATE_SUCCESS';
export const NUTRIENT_CALCULATE_ERROR = 'NUTRIENT_CALCULATE_ERROR';

export const NUTRIENTTOFOODWORDLIST = 'NUTRIENTTOFOODWORDLIST';
export const NUTRIENTTOFOODWORDLIST_SUCCESS = 'NUTRIENTTOFOODWORDLIST_SUCCESS';
export const NUTRIENTTOFOODWORDLIST_ERROR = 'NUTRIENTTOFOODWORDLIST_ERROR';
export const NUTRIENTTOFOODWORDLIST_CLEAR = 'NUTRIENTTOFOODWORDLIST_CLEAR';

export const NUTRIENT_GROUPED = 'NUTRIENT_GROUPED';
export const NUTRIENT_GROUPED_SUCCESS = 'NUTRIENT_GROUPED_SUCCESS';
export const NUTRIENT_GROUPED_ERROR = 'NUTRIENT_GROUPED_ERROR';

/* #NUTRIENT SOURCE */

/* NUTRIENT GROUP SOURCE */
export const NUTRIENTGROUP_GET_LIST = 'NUTRIENTGROUP_GET_LIST';
export const NUTRIENTGROUP_GET_LIST_SUCCESS = 'NUTRIENTGROUP_GET_LIST_SUCCESS';
export const NUTRIENTGROUP_GET_LIST_ERROR = 'NUTRIENTGROUP_GET_LIST_ERROR';

export const NUTRIENTGROUP_SET_SELECTED = 'NUTRIENTGROUP_SET_SELECTED';
export const NUTRIENTGROUP_SET_SELECTED_SUCCESS =
  'NUTRIENTGROUP_SET_SELECTED_SUCCESS';
export const NUTRIENTGROUP_SET_SELECTED_ERROR =
  'NUTRIENTGROUP_SET_SELECTED_ERROR';
export const NUTRIENTGROUP_UPDATE_SELECTED = 'NUTRIENTGROUP_UPDATE_SELECTED';

export const NUTRIENTGROUP_GET_LIST_WITH_FILTER =
  'NUTRIENTGROUP_GET_LIST_WITH_FILTER';
export const NUTRIENTGROUP_GET_LIST_WITH_ORDER =
  'NUTRIENTGROUP_GET_LIST_WITH_ORDER';
export const NUTRIENTGROUP_GET_LIST_SEARCH = 'NUTRIENTGROUP_GET_LIST_SEARCH';

export const NUTRIENTGROUP_ADD_RECORD = 'NUTRIENTGROUP_ADD_RECORD';
export const NUTRIENTGROUP_ADD_RECORD_SUCCESS =
  'NUTRIENTGROUP_ADD_RECORD_SUCCESS';
export const NUTRIENTGROUP_ADD_RECORD_ERROR = 'NUTRIENTGROUP_ADD_RECORD_ERROR';

export const NUTRIENTGROUP_UPDATE_RECORD = 'NUTRIENTGROUP_UPDATE_RECORD';
export const NUTRIENTGROUP_UPDATE_RECORD_SUCCESS =
  'NUTRIENTGROUP_UPDATE_RECORD_SUCCESS';
export const NUTRIENTGROUP_UPDATE_RECORD_ERROR =
  'NUTRIENTGROUP_UPDATE_RECORD_ERROR';

export const NUTRIENTGROUP_DELETE_RECORD = 'NUTRIENTGROUP_DELETE_RECORD';
export const NUTRIENTGROUP_DELETE_RECORD_SUCCESS =
  'NUTRIENTGROUP_DELETE_RECORD_SUCCESS';
export const NUTRIENTGROUP_DELETE_RECORD_ERROR =
  'NUTRIENTGROUP_DELETE_RECORD_ERROR';
export const NUTRIENTGROUP_RESET_STATUSES = 'NUTRIENTGROUP_RESET_STATUSES';

export const NUTRIENTGROUP_ISACTIVECHANGE = 'NUTRIENTGROUP_ISACTIVECHANGE';
export const NUTRIENTGROUP_ISACTIVECHANGE_SUCCESS =
  'NUTRIENTGROUP_ISACTIVECHANGE_SUCCESS';
export const NUTRIENTGROUP_ISACTIVECHANGE_ERROR =
  'NUTRIENTGROUP_ISACTIVECHANGE_ERROR';

/* #NUTRIENT GROUP SOURCE */

/* MEAL SOURCE */
export const MEAL_GET_LIST = 'MEAL_GET_LIST';
export const MEAL_GET_LIST_SUCCESS = 'MEAL_GET_LIST_SUCCESS';
export const MEAL_GET_LIST_ERROR = 'MEAL_GET_LIST_ERROR';

export const MEAL_GET_PENDING_LIST = 'MEAL_GET_PENDING_LIST';
export const MEAL_GET_PENDING_LIST_SUCCESS = 'MEAL_GET_PENDING_LIST_SUCCESS';
export const MEAL_GET_PENDING_LIST_ERROR = 'MEAL_GET_PENDING_LIST_ERROR';

export const MEAL_GET_SHARED_LIST = 'MEAL_GET_SHARED_LIST';
export const MEAL_GET_SHARED_LIST_SUCCESS = 'MEAL_GET_SHARED_LIST_SUCCESS';
export const MEAL_GET_SHARED_LIST_ERROR = 'MEAL_GET_SHARED_LIST_ERROR';

export const MEAL_SHARE = 'MEAL_SHARE';
export const MEAL_SHARE_SUCCESS = 'MEAL_SHARE_SUCCESS';
export const MEAL_SHARE_ERROR = 'MEAL_SHARE_ERROR';

export const MEAL_CONFIRM = 'MEAL_CONFIRM';
export const MEAL_CONFIRM_SUCCESS = 'MEAL_CONFIRM_SUCCESS';
export const MEAL_CONFIRM_ERROR = 'MEAL_CONFIRM_ERROR';

export const MEAL_IMPORT_FOR_ME = 'MEAL_IMPORT_FOR_ME';
export const MEAL_IMPORT_FOR_ME_SUCCESS = 'MEAL_IMPORT_FOR_ME_SUCCESS';
export const MEAL_IMPORT_FOR_ME_ERROR = 'MEAL_IMPORT_FOR_ME_ERROR';

export const MEAL_SET_SELECTED = 'MEAL_SET_SELECTED';
export const MEAL_SET_SELECTED_SUCCESS = 'MEAL_SET_SELECTED_SUCCESS';
export const MEAL_SET_SELECTED_ERROR = 'MEAL_SET_SELECTED_ERROR';
export const MEAL_UPDATE_SELECTED = 'MEAL_UPDATE_SELECTED';

export const MEAL_GET_LIST_WITH_FILTER = 'MEAL_GET_LIST_WITH_FILTER';
export const MEAL_GET_LIST_WITH_ORDER = 'MEAL_GET_LIST_WITH_ORDER';
export const MEAL_GET_LIST_SEARCH = 'MEAL_GET_LIST_SEARCH';

export const MEAL_ADD_RECORD = 'MEAL_ADD_RECORD';
export const MEAL_ADD_RECORD_SUCCESS = 'MEAL_ADD_RECORD_SUCCESS';
export const MEAL_ADD_RECORD_ERROR = 'MEAL_ADD_RECORD_ERROR';

export const MEAL_UPDATE_RECORD = 'MEAL_UPDATE_RECORD';
export const MEAL_UPDATE_RECORD_SUCCESS = 'MEAL_UPDATE_RECORD_SUCCESS';
export const MEAL_UPDATE_RECORD_ERROR = 'MEAL_UPDATE_RECORD_ERROR';

export const MEAL_DELETE_RECORD = 'MEAL_DELETE_RECORD';
export const MEAL_DELETE_RECORD_SUCCESS = 'MEAL_DELETE_RECORD_SUCCESS';
export const MEAL_DELETE_RECORD_ERROR = 'MEAL_DELETE_RECORD_ERROR';
export const MEAL_RESET_STATUSES = 'MEAL_RESET_STATUSES';

export const MEAL_ISACTIVECHANGE = 'MEAL_ISACTIVECHANGE';
export const MEAL_ISACTIVECHANGE_SUCCESS = 'MEAL_ISACTIVECHANGE_SUCCESS';
export const MEAL_ISACTIVECHANGE_ERROR = 'MEAL_ISACTIVECHANGE_ERROR';

/* #MEAL SOURCE */

/* MEAL GROUP SOURCE */
export const MEALGROUP_GET_LIST = 'MEAL_GROUP_GET_LIST';
export const MEALGROUP_GET_LIST_SUCCESS = 'MEAL_GROUP_GET_LIST_SUCCESS';
export const MEALGROUP_GET_LIST_ERROR = 'MEAL_GROUP_GET_LIST_ERROR';

export const MEALGROUP_SET_SELECTED = 'MEAL_GROUP_SET_SELECTED';
export const MEALGROUP_SET_SELECTED_SUCCESS = 'MEAL_GROUP_SET_SELECTED_SUCCESS';
export const MEALGROUP_SET_SELECTED_ERROR = 'MEAL_GROUP_SET_SELECTED_ERROR';
export const MEALGROUP_UPDATE_SELECTED = 'MEAL_GROUP_UPDATE_SELECTED';

export const MEALGROUP_GET_LIST_WITH_FILTER = 'MEAL_GROUP_GET_LIST_WITH_FILTER';
export const MEALGROUP_GET_LIST_WITH_ORDER = 'MEAL_GROUP_GET_LIST_WITH_ORDER';
export const MEALGROUP_GET_LIST_SEARCH = 'MEAL_GROUP_GET_LIST_SEARCH';

export const MEALGROUP_ADD_RECORD = 'MEAL_GROUP_ADD_RECORD';
export const MEALGROUP_ADD_RECORD_SUCCESS = 'MEAL_GROUP_ADD_RECORD_SUCCESS';
export const MEALGROUP_ADD_RECORD_ERROR = 'MEAL_GROUP_ADD_RECORD_ERROR';

export const MEALGROUP_UPDATE_RECORD = 'MEAL_GROUP_UPDATE_RECORD';
export const MEALGROUP_UPDATE_RECORD_SUCCESS =
  'MEAL_GROUP_UPDATE_RECORD_SUCCESS';
export const MEALGROUP_UPDATE_RECORD_ERROR = 'MEAL_GROUP_UPDATE_RECORD_ERROR';

export const MEALGROUP_DELETE_RECORD = 'MEAL_GROUP_DELETE_RECORD';
export const MEALGROUP_DELETE_RECORD_SUCCESS =
  'MEAL_GROUP_DELETE_RECORD_SUCCESS';
export const MEALGROUP_DELETE_RECORD_ERROR = 'MEAL_GROUP_DELETE_RECORD_ERROR';
export const MEALGROUP_RESET_STATUSES = 'MEALGROUP_RESET_STATUSES';

export const MEALGROUP_ISACTIVECHANGE = 'MEALGROUP_ISACTIVECHANGE';
export const MEALGROUP_ISACTIVECHANGE_SUCCESS =
  'MEALGROUP_ISACTIVECHANGE_SUCCESS';
export const MEALGROUP_ISACTIVECHANGE_ERROR = 'MEALGROUP_ISACTIVECHANGE_ERROR';

/* #MEAL GROUP SOURCE */

/* RECIPE SOURCE */
export const RECIPE_GET_LIST = 'RECIPE_GET_LIST';
export const RECIPE_GET_LIST_SUCCESS = 'RECIPE_GET_LIST_SUCCESS';
export const RECIPE_GET_LIST_ERROR = 'RECIPE_GET_LIST_ERROR';

export const RECIPE_SET_SELECTED = 'RECIPE_SET_SELECTED';
export const RECIPE_SET_SELECTED_SUCCESS = 'RECIPE_SET_SELECTED_SUCCESS';
export const RECIPE_SET_SELECTED_ERROR = 'RECIPE_SET_SELECTED_ERROR';
export const RECIPE_UPDATE_SELECTED = 'RECIPE_UPDATE_SELECTED';

export const RECIPE_GET_LIST_WITH_FILTER = 'RECIPE_GET_LIST_WITH_FILTER';
export const RECIPE_GET_LIST_WITH_ORDER = 'RECIPE_GET_LIST_WITH_ORDER';
export const RECIPE_GET_LIST_SEARCH = 'RECIPE_GET_LIST_SEARCH';

export const RECIPE_ADD_RECORD = 'RECIPE_ADD_RECORD';
export const RECIPE_ADD_RECORD_SUCCESS = 'RECIPE_ADD_RECORD_SUCCESS';
export const RECIPE_ADD_RECORD_ERROR = 'RECIPE_ADD_RECORD_ERROR';

export const RECIPE_UPDATE_RECORD = 'RECIPE_UPDATE_RECORD';
export const RECIPE_UPDATE_RECORD_SUCCESS = 'RECIPE_UPDATE_RECORD_SUCCESS';
export const RECIPE_UPDATE_RECORD_ERROR = 'RECIPE_UPDATE_RECORD_ERROR';

export const RECIPE_DELETE_RECORD = 'RECIPE_DELETE_RECORD';
export const RECIPE_DELETE_RECORD_SUCCESS = 'RECIPE_DELETE_RECORD_SUCCESS';
export const RECIPE_DELETE_RECORD_ERROR = 'RECIPE_DELETE_RECORD_ERROR';
export const RECIPE_RESET_STATUSES = 'RECIPE_RESET_STATUSES';

export const RECIPE_ISACTIVECHANGE = 'RECIPE_ISACTIVECHANGE';
export const RECIPE_ISACTIVECHANGE_SUCCESS = 'RECIPE_ISACTIVECHANGE_SUCCESS';
export const RECIPE_ISACTIVECHANGE_ERROR = 'RECIPE_ISACTIVECHANGE_ERROR';

/* #RECIPE SOURCE */

/* PORTION */
export const PORTION_GET_LIST = 'PORTION_GET_LIST';
export const PORTION_GET_LIST_SUCCESS = 'PORTION_GET_LIST_SUCCESS';
export const PORTION_GET_LIST_ERROR = 'PORTION_GET_LIST_ERROR';

export const PORTION_GETNAMEWITHID_LIST = 'PORTION_GETNAMEWITHID_LIST';
export const PORTION_GETNAMEWITHID_LIST_SUCCESS =
  'PORTION_GETNAMEWITHID_LIST_SUCCESS';
export const PORTION_GETNAMEWITHID_LIST_ERROR =
  'PORTION_GETNAMEWITHID_LIST_ERROR';

export const PORTION_GETNAMEWITHID_ONERECORD =
  'PORTION_GETNAMEWITHID_ONERECORD';
export const PORTION_GETNAMEWITHID_ONERECORD_SUCCESS =
  'PORTION_GETNAMEWITHID_ONERECORD_SUCCESS';
export const PORTION_GETNAMEWITHID_ONERECORD_ERROR =
  'PORTION_GETNAMEWITHID_ONERECORD_ERROR';

export const PORTION_SET_SELECTED = 'PORTION_SET_SELECTED';
export const PORTION_SET_SELECTED_SUCCESS = 'PORTION_SET_SELECTED_SUCCESS';
export const PORTION_SET_SELECTED_ERROR = 'PORTION_SET_SELECTED_ERROR';
export const PORTION_UPDATE_SELECTED = 'PORTION_UPDATE_SELECTED';

export const PORTION_GET_LIST_WITH_FILTER = 'PORTION_GET_LIST_WITH_FILTER';
export const PORTION_GET_LIST_WITH_ORDER = 'PORTION_GET_LIST_WITH_ORDER';
export const PORTION_GET_LIST_SEARCH = 'PORTION_GET_LIST_SEARCH';

export const PORTION_ADD_RECORD = 'PORTION_ADD_RECORD';
export const PORTION_ADD_RECORD_SUCCESS = 'PORTION_ADD_RECORD_SUCCESS';
export const PORTION_ADD_RECORD_ERROR = 'PORTION_ADD_RECORD_ERROR';

export const PORTION_UPDATE_RECORD = 'PORTION_UPDATE_RECORD';
export const PORTION_UPDATE_RECORD_SUCCESS = 'PORTION_UPDATE_RECORD_SUCCESS';
export const PORTION_UPDATE_RECORD_ERROR = 'PORTION_UPDATE_RECORD_ERROR';

export const PORTION_DELETE_RECORD = 'PORTION_DELETE_RECORD';
export const PORTION_DELETE_RECORD_SUCCESS = 'PORTION_DELETE_RECORD_SUCCESS';
export const PORTION_DELETE_RECORD_ERROR = 'PORTION_DELETE_RECORD_ERROR';
export const PORTION_RESET_STATUSES = 'PORTION_RESET_STATUSES';

export const PORTION_ISACTIVECHANGE = 'PORTION_ISACTIVECHANGE';
export const PORTION_ISACTIVECHANGE_SUCCESS = 'PORTION_ISACTIVECHANGE_SUCCESS';
export const PORTION_ISACTIVECHANGE_ERROR = 'PORTION_ISACTIVECHANGE_ERROR';

/* #PORTION */

/* COACHEE */
export const COACHEE_GET_LIST = 'COACHEE_GET_LIST';
export const COACHEE_GET_LIST_SUCCESS = 'COACHEE_GET_LIST_SUCCESS';
export const COACHEE_GET_LIST_ERROR = 'COACHEE_GET_LIST_ERROR';

export const COACHEE_SET_SELECTED = 'COACHEE_SET_SELECTED';
export const COACHEE_SET_SELECTED_SUCCESS = 'COACHEE_SET_SELECTED_SUCCESS';
export const COACHEE_SET_SELECTED_ERROR = 'COACHEE_SET_SELECTED_ERROR';
export const COACHEE_UPDATE_SELECTED = 'COACHEE_UPDATE_SELECTED';

export const COACHEE_GET_LIST_WITH_FILTER = 'COACHEE_GET_LIST_WITH_FILTER';
export const COACHEE_GET_LIST_WITH_ORDER = 'COACHEE_GET_LIST_WITH_ORDER';
export const COACHEE_GET_LIST_SEARCH = 'COACHEE_GET_LIST_SEARCH';

export const COACHEE_ADD_RECORD = 'COACHEE_ADD_RECORD';
export const COACHEE_ADD_RECORD_SUCCESS = 'COACHEE_ADD_RECORD_SUCCESS';
export const COACHEE_ADD_RECORD_ERROR = 'COACHEE_ADD_RECORD_ERROR';

export const COACHEE_UPDATE_RECORD = 'COACHEE_UPDATE_RECORD';
export const COACHEE_UPDATE_RECORD_SUCCESS = 'COACHEE_UPDATE_RECORD_SUCCESS';
export const COACHEE_UPDATE_RECORD_ERROR = 'COACHEE_UPDATE_RECORD_ERROR';

export const COACHEE_DELETE_RECORD = 'COACHEE_DELETE_RECORD';
export const COACHEE_DELETE_RECORD_SUCCESS = 'COACHEE_DELETE_RECORD_SUCCESS';
export const COACHEE_DELETE_RECORD_ERROR = 'COACHEE_DELETE_RECORD_ERROR';
export const COACHEE_RESET_STATUSES = 'COACHEE_RESET_STATUSES';

export const COACHEE_ISACTIVECHANGE = 'COACHEE_ISACTIVECHANGE';
export const COACHEE_ISACTIVECHANGE_SUCCESS = 'COACHEE_ISACTIVECHANGE_SUCCESS';
export const COACHEE_ISACTIVECHANGE_ERROR = 'COACHEE_ISACTIVECHANGE_ERROR';

/* #COACHEE */

/* NOTE */
export const NOTE_GET_LIST = 'NOTE_GET_LIST';
export const NOTE_GET_LIST_SUCCESS = 'NOTE_GET_LIST_SUCCESS';
export const NOTE_GET_LIST_ERROR = 'NOTE_GET_LIST_ERROR';

export const NOTE_SET_SELECTED = 'NOTE_SET_SELECTED';
export const NOTE_SET_SELECTED_SUCCESS = 'NOTE_SET_SELECTED_SUCCESS';
export const NOTE_SET_SELECTED_ERROR = 'NOTE_SET_SELECTED_ERROR';
export const NOTE_UPDATE_SELECTED = 'NOTE_UPDATE_SELECTED';

export const NOTE_GET_LIST_WITH_FILTER = 'NOTE_GET_LIST_WITH_FILTER';
export const NOTE_GET_LIST_WITH_ORDER = 'NOTE_GET_LIST_WITH_ORDER';
export const NOTE_GET_LIST_SEARCH = 'NOTE_GET_LIST_SEARCH';

export const NOTE_ADD_RECORD = 'NOTE_ADD_RECORD';
export const NOTE_ADD_RECORD_SUCCESS = 'NOTE_ADD_RECORD_SUCCESS';
export const NOTE_ADD_RECORD_ERROR = 'NOTE_ADD_RECORD_ERROR';

export const NOTE_UPDATE_RECORD = 'NOTE_UPDATE_RECORD';
export const NOTE_UPDATE_RECORD_SUCCESS = 'NOTE_UPDATE_RECORD_SUCCESS';
export const NOTE_UPDATE_RECORD_ERROR = 'NOTE_UPDATE_RECORD_ERROR';

export const NOTE_DELETE_RECORD = 'NOTE_DELETE_RECORD';
export const NOTE_DELETE_RECORD_SUCCESS = 'NOTE_DELETE_RECORD_SUCCESS';
export const NOTE_DELETE_RECORD_ERROR = 'NOTE_DELETE_RECORD_ERROR';
export const NOTE_RESET_STATUSES = 'NOTE_RESET_STATUSES';

export const NOTE_ISACTIVECHANGE = 'NOTE_ISACTIVECHANGE';
export const NOTE_ISACTIVECHANGE_SUCCESS = 'NOTE_ISACTIVECHANGE_SUCCESS';
export const NOTE_ISACTIVECHANGE_ERROR = 'NOTE_ISACTIVECHANGE_ERROR';
/* #NOTE */

/* PORTION-PICTURE */
export const PORTIONPICTURE_GET_LIST = 'PORTIONPICTURE_GET_LIST';
export const PORTIONPICTURE_GET_LIST_SUCCESS =
  'PORTIONPICTURE_GET_LIST_SUCCESS';
export const PORTIONPICTURE_GET_LIST_ERROR = 'PORTIONPICTURE_GET_LIST_ERROR';

export const PORTIONPICTURE_SET_SELECTED = 'PORTIONPICTURE_SET_SELECTED';
export const PORTIONPICTURE_SET_SELECTED_SUCCESS =
  'PORTIONPICTURE_SET_SELECTED_SUCCESS';
export const PORTIONPICTURE_SET_SELECTED_ERROR =
  'PORTIONPICTURE_SET_SELECTED_ERROR';
export const PORTIONPICTURE_UPDATE_SELECTED = 'PORTIONPICTURE_UPDATE_SELECTED';

export const PORTIONPICTURE_GET_LIST_WITH_FILTER =
  'PORTIONPICTURE_GET_LIST_WITH_FILTER';
export const PORTIONPICTURE_GET_LIST_WITH_ORDER =
  'PORTIONPICTURE_GET_LIST_WITH_ORDER';
export const PORTIONPICTURE_GET_LIST_SEARCH = 'PORTIONPICTURE_GET_LIST_SEARCH';

export const PORTIONPICTURE_ADD_RECORD = 'PORTIONPICTURE_ADD_RECORD';
export const PORTIONPICTURE_ADD_RECORD_SUCCESS =
  'PORTIONPICTURE_ADD_RECORD_SUCCESS';
export const PORTIONPICTURE_ADD_RECORD_ERROR =
  'PORTIONPICTURE_ADD_RECORD_ERROR';

export const PORTIONPICTURE_UPDATE_RECORD = 'PORTIONPICTURE_UPDATE_RECORD';
export const PORTIONPICTURE_UPDATE_RECORD_SUCCESS =
  'PORTIONPICTURE_UPDATE_RECORD_SUCCESS';
export const PORTIONPICTURE_UPDATE_RECORD_ERROR =
  'PORTIONPICTURE_UPDATE_RECORD_ERROR';

export const PORTIONPICTURE_DELETE_RECORD = 'PORTIONPICTURE_DELETE_RECORD';
export const PORTIONPICTURE_DELETE_RECORD_SUCCESS =
  'PORTIONPICTURE_DELETE_RECORD_SUCCESS';
export const PORTIONPICTURE_DELETE_RECORD_ERROR =
  'PORTIONPICTURE_DELETE_RECORD_ERROR';
export const PORTIONPICTURE_RESET_STATUSES = 'PORTIONPICTURE_RESET_STATUSES';

export const PORTIONPICTURE_ISACTIVECHANGE = 'PORTIONPICTURE_ISACTIVECHANGE';
export const PORTIONPICTURE_ISACTIVECHANGE_SUCCESS =
  'PORTIONPICTURE_ISACTIVECHANGE_SUCCESS';
export const PORTIONPICTURE_ISACTIVECHANGE_ERROR =
  'PORTIONPICTURE_ISACTIVECHANGE_ERROR';
/* # PORTION-PICTURE */

/* PHYSICAL-ACTIVITY */
export const PHYSICALACTIVITY_GET_LIST = 'PHYSICALACTIVITY_GET_LIST';
export const PHYSICALACTIVITY_GET_LIST_SUCCESS =
  'PHYSICALACTIVITY_GET_LIST_SUCCESS';
export const PHYSICALACTIVITY_GET_LIST_ERROR =
  'PHYSICALACTIVITY_GET_LIST_ERROR';

export const PHYSICALACTIVITY_SET_SELECTED = 'PHYSICALACTIVITY_SET_SELECTED';
export const PHYSICALACTIVITY_SET_SELECTED_SUCCESS =
  'PHYSICALACTIVITY_SET_SELECTED_SUCCESS';
export const PHYSICALACTIVITY_SET_SELECTED_ERROR =
  'PHYSICALACTIVITY_SET_SELECTED_ERROR';
export const PHYSICALACTIVITY_UPDATE_SELECTED =
  'PHYSICALACTIVITY_UPDATE_SELECTED';

export const PHYSICALACTIVITY_GET_LIST_WITH_FILTER =
  'PHYSICALACTIVITY_GET_LIST_WITH_FILTER';
export const PHYSICALACTIVITY_GET_LIST_WITH_ORDER =
  'PHYSICALACTIVITY_GET_LIST_WITH_ORDER';
export const PHYSICALACTIVITY_GET_LIST_SEARCH =
  'PHYSICALACTIVITY_GET_LIST_SEARCH';

export const PHYSICALACTIVITY_ADD_RECORD = 'PHYSICALACTIVITY_ADD_RECORD';
export const PHYSICALACTIVITY_ADD_RECORD_SUCCESS =
  'PHYSICALACTIVITY_ADD_RECORD_SUCCESS';
export const PHYSICALACTIVITY_ADD_RECORD_ERROR =
  'PHYSICALACTIVITY_ADD_RECORD_ERROR';

export const PHYSICALACTIVITY_UPDATE_RECORD = 'PHYSICALACTIVITY_UPDATE_RECORD';
export const PHYSICALACTIVITY_UPDATE_RECORD_SUCCESS =
  'PHYSICALACTIVITY_UPDATE_RECORD_SUCCESS';
export const PHYSICALACTIVITY_UPDATE_RECORD_ERROR =
  'PHYSICALACTIVITY_UPDATE_RECORD_ERROR';

export const PHYSICALACTIVITY_DELETE_RECORD = 'PHYSICALACTIVITY_DELETE_RECORD';
export const PHYSICALACTIVITY_DELETE_RECORD_SUCCESS =
  'PHYSICALACTIVITY_DELETE_RECORD_SUCCESS';
export const PHYSICALACTIVITY_DELETE_RECORD_ERROR =
  'PHYSICALACTIVITY_DELETE_RECORD_ERROR';
export const PHYSICALACTIVITY_RESET_STATUSES =
  'PHYSICALACTIVITY_RESET_STATUSES';

export const PHYSICALACTIVITY_ISACTIVECHANGE =
  'PHYSICALACTIVITY_ISACTIVECHANGE';
export const PHYSICALACTIVITY_ISACTIVECHANGE_SUCCESS =
  'PHYSICALACTIVITY_ISACTIVECHANGE_SUCCESS';
export const PHYSICALACTIVITY_ISACTIVECHANGE_ERROR =
  'PHYSICALACTIVITY_ISACTIVECHANGE_ERROR';

/* # PHYSICAL-ACTIVITY


/* FOODPORTION */
export const FOODPORTION_GET_LIST = 'FOODPORTION_GET_LIST';
export const FOODPORTION_GET_LIST_SUCCESS = 'FOODPORTION_GET_LIST_SUCCESS';
export const FOODPORTION_GET_LIST_ERROR = 'FOODPORTION_GET_LIST_ERROR';

export const FOODPORTION_SET_SELECTED = 'FOODPORTION_SET_SELECTED';
export const FOODPORTION_SET_SELECTED_SUCCESS =
  'FOODPORTION_SET_SELECTED_SUCCESS';
export const FOODPORTION_SET_SELECTED_ERROR = 'FOODPORTION_SET_SELECTED_ERROR';
export const FOODPORTION_UPDATE_SELECTED = 'FOODPORTION_UPDATE_SELECTED';

export const FOODPORTION_GET_LIST_WITH_FILTER =
  'FOODPORTION_GET_LIST_WITH_FILTER';
export const FOODPORTION_GET_LIST_WITH_ORDER =
  'FOODPORTION_GET_LIST_WITH_ORDER';
export const FOODPORTION_GET_LIST_SEARCH = 'FOODPORTION_GET_LIST_SEARCH';

export const FOODPORTION_ADD_RECORD = 'FOODPORTION_ADD_RECORD';
export const FOODPORTION_ADD_RECORD_SUCCESS = 'FOODPORTION_ADD_RECORD_SUCCESS';
export const FOODPORTION_ADD_RECORD_ERROR = 'FOODPORTION_ADD_RECORD_ERROR';

export const FOODPORTION_UPDATE_RECORD = 'FOODPORTION_UPDATE_RECORD';
export const FOODPORTION_UPDATE_RECORD_SUCCESS =
  'FOODPORTION_UPDATE_RECORD_SUCCESS';
export const FOODPORTION_UPDATE_RECORD_ERROR =
  'FOODPORTION_UPDATE_RECORD_ERROR';

export const FOODPORTION_DELETE_RECORD = 'FOODPORTION_DELETE_RECORD';
export const FOODPORTION_DELETE_RECORD_SUCCESS =
  'FOODPORTION_DELETE_RECORD_SUCCESS';
export const FOODPORTION_DELETE_RECORD_ERROR =
  'FOODPORTION_DELETE_RECORD_ERROR';
export const FOODPORTION_RESET_STATUSES = 'FOODPORTION_RESET_STATUSES';
export const FOODPORTION_FILTER = 'FOODPORTION_FILTER';

export const FOODPORTION_ISACTIVECHANGE = 'FOODPORTION_ISACTIVECHANGE';
export const FOODPORTION_ISACTIVECHANGE_SUCCESS =
  'FOODPORTION_ISACTIVECHANGE_SUCCESS';
export const FOODPORTION_ISACTIVECHANGE_ERROR =
  'FOODPORTION_ISACTIVECHANGE_ERROR';
/* # FOODPORTION

/* PLANNING */
export const PLAN_GET_LIST = 'PLAN_GET_LIST';
export const PLAN_GET_LIST_SUCCESS = 'PLAN_GET_LIST_SUCCESS';
export const PLAN_GET_LIST_ERROR = 'PLAN_GET_LIST_ERROR';

export const SHAREDPLAN_GET_LIST = 'SHAREDPLAN_GET_LIST';
export const SHAREDPLAN_GET_LIST_SUCCESS = 'SHAREDPLAN_GET_LIST_SUCCESS';
export const SHAREDPLAN_GET_LIST_ERROR = 'SHAREDPLAN_GET_LIST_ERROR';

export const SHAREDPLAN_IMPORT_FOR_ME = 'SHAREDPLAN_IMPORT_FOR_ME';
export const SHAREDPLAN_IMPORT_FOR_ME_SUCCESS =
  'SHAREDPLAN_IMPORT_FOR_ME_SUCCESS';
export const SHAREDPLAN_IMPORT_FOR_ME_ERROR = 'SHAREDPLAN_IMPORT_FOR_ME_ERROR';

export const SHAREDPLAN_EXPORT = 'SHAREDPLAN_EXPORT';
export const SHAREDPLAN_EXPORT_SUCCESS = 'SHAREDPLAN_EXPORT_SUCCESS';
export const SHAREDPLAN_EXPORT_ERROR = 'SHAREDPLAN_EXPORT_ERROR';

export const SHAREDPLAN_PENDING_LIST = 'SHAREDPLAN_PENDING_LIST';
export const SHAREDPLAN_PENDING_LIST_SUCCESS =
  'SHAREDPLAN_PENDING_LIST_SUCCESS';
export const SHAREDPLAN_PENDING_LIST_ERROR = 'SHAREDPLAN_PENDING_LIST_ERROR';

export const SHAREDPLAN_CONFIRM = 'SHAREDPLAN_CONFIRM';
export const SHAREDPLAN_CONFIRM_SUCCESS = 'SHAREDPLAN_CONFIRM_SUCCESS';
export const SHAREDPLAN_CONFIRM_ERROR = 'SHAREDPLAN_CONFIRM_ERROR';

export const PLAN_SET_SELECTED = 'PLAN_SET_SELECTED';
export const PLAN_SET_SELECTED_SUCCESS = 'PLAN_SET_SELECTED_SUCCESS';
export const PLAN_SET_SELECTED_ERROR = 'PLAN_SET_SELECTED_ERROR';
export const PLAN_UPDATE_SELECTED = 'PLAN_UPDATE_SELECTED';

export const PLAN_GET_LIST_WITH_FILTER = 'PLAN_GET_LIST_WITH_FILTER';
export const PLAN_GET_LIST_WITH_ORDER = 'PLAN_GET_LIST_WITH_ORDER';
export const PLAN_GET_LIST_SEARCH = 'PLAN_GET_LIST_SEARCH';

export const PLAN_ADD_RECORD = 'PLAN_ADD_RECORD';
export const PLAN_ADD_RECORD_SUCCESS = 'PLAN_ADD_RECORD_SUCCESS';
export const PLAN_ADD_RECORD_ERROR = 'PLAN_ADD_RECORD_ERROR';

export const PLAN_UPDATE_RECORD = 'PLAN_UPDATE_RECORD';
export const PLAN_UPDATE_RECORD_SUCCESS = 'PLAN_UPDATE_RECORD_SUCCESS';
export const PLAN_UPDATE_RECORD_ERROR = 'PLAN_UPDATE_RECORD_ERROR';

export const PLAN_DELETE_RECORD = 'PLAN_DELETE_RECORD';
export const PLAN_DELETE_RECORD_SUCCESS = 'PLAN_DELETE_RECORD_SUCCESS';
export const PLAN_DELETE_RECORD_ERROR = 'PLAN_DELETE_RECORD_ERROR';

export const PLAN_RESET_STATUSES = 'PLAN_RESET_STATUSES';

export const PLAN_CLONE_SET_SELECTED = 'PLAN_CLONE_SET_SELECTED';
export const PLAN_CLONE_SET_SELECTED_SUCCESS =
  'PLAN_CLONE_SET_SELECTED_SUCCESS';
export const PLAN_CLONE_SET_SELECTED_ERROR = 'PLAN_CLONE_SET_SELECTED_ERROR';
export const PLAN_CLONE_UPDATE_SELECTED = 'PLAN_CLONE_UPDATE_SELECTED';

export const PLAN_ISACTIVECHANGE = 'PLAN_ISACTIVECHANGE';
export const PLAN_ISACTIVECHANGE_SUCCESS = 'PLAN_ISACTIVECHANGE_SUCCESS';
export const PLAN_ISACTIVECHANGE_ERROR = 'PLAN_ISACTIVECHANGE_ERROR';

export const PLAN_GENERICSEARCH = 'PLAN_GENERICSEARCH';
export const PLAN_GENERICSEARCH_SUCCESS = 'PLAN_GENERICSEARCH_SUCCESS';
export const PLAN_GENERICSEARCH_ERROR = 'PLAN_GENERICSEARCH_ERROR';

export const PLAN_SPECIALLY_WITH_ID = 'PLAN_SPECIALLY_WITH_ID';
export const PLAN_SPECIALLY_WITH_ID_SUCCESS = 'PLAN_SPECIALLY_WITH_ID_SUCCESS';
export const PLAN_SPECIALLY_WITH_ID_ERROR = 'PLAN_SPECIALLY_WITH_ID_ERROR';

export const PLAN_ANALYSIS = 'PLAN_ANALYSIS';
export const PLAN_ANALYSIS_SUCCESS = 'PLAN_ANALYSIS_SUCCESS';
export const PLAN_ANALYSIS_ERROR = 'PLAN_ANALYSIS_ERROR';

export const PLAN_IMPORT = 'PLAN_IMPORT';
export const PLAN_IMPORT_SUCCESS = 'PLAN_IMPORT_SUCCESS';
export const PLAN_IMPORT_ERROR = 'PLAN_IMPORT_ERROR';

export const PLAN_ANALYSIS_REFERENCE_NUTRIENT =
  'PLAN_ANALYSIS_REFERENCE_NUTRIENT';
export const PLAN_ANALYSIS_REFERENCE_NUTRIENT_SUCCESS =
  'PLAN_ANALYSIS_REFERENCE_NUTRIENT_SUCCESS';
export const PLAN_ANALYSIS_REFERENCE_NUTRIENT_ERROR =
  'PLAN_ANALYSIS_REFERENCE_NUTRIENT_ERROR';

export const PLAN_FOODGROUP_GROUPING = 'PLAN_FOODGROUP_GROUPING';
export const PLAN_FOODGROUP_GROUPING_SUCCESS =
  'PLAN_FOODGROUP_GROUPING_SUCCESS';
export const PLAN_FOODGROUP_GROUPING_ERROR = 'PLAN_FOODGROUP_GROUPING_ERROR';

export const PLAN_NUTRIENTSORTING = 'PLAN_NUTRIENTSORTING';
export const PLAN_NUTRIENTSORTING_SUCCESS = 'PLAN_NUTRIENTSORTING_SUCCESS';
export const PLAN_NUTRIENTSORTING_ERROR = 'PLAN_NUTRINTSORTING_ERROR';

export const PLAN_NUTRIENTTRANSFER = 'PLAN_NUTRIENTTRANSFER';
export const PLAN_NUTRIENTTRANSFER_SUCCESS = 'PLAN_NUTRIENTTRANSFER_SUCCESS';
export const PLAN_NUTRIENTTRANSFER_ERROR = 'PLAN_NUTRIENTTRANSFER_ERROR';

export const PLAN_LACKCOMPLETION = 'PLAN_LACKCOMPLETION';
export const PLAN_LACKCOMPLETION_SUCCESS = 'PLAN_LACKCOMPLETION_SUCCESS';
export const PLAN_LACKCOMPLETION_ERROR = 'PLAN_LACKCOMPLETION_ERROR';

export const PLAN_COMMENTT = 'PLAN_COMMENTT';
export const PLAN_COMMENTT_SUCCESS = 'PLAN_COMMENTT_SUCCESS';
export const PLAN_COMMENTT_ERROR = 'PLAN_COMMENTT_ERROR';

/* PLANREPAST */
export const PLANREPAST_GET_LIST = 'PLANREPAST_GET_LIST';
export const PLANREPAST_GET_LIST_SUCCESS = 'PLANREPAST_GET_LIST_SUCCESS';
export const PLANREPAST_GET_LIST_ERROR = 'PLANREPAST_GET_LIST_ERROR';

export const PLANREPAST_SET_SELECTED = 'PLANREPAST_SET_SELECTED';
export const PLANREPAST_SET_SELECTED_SUCCESS =
  'PLANREPAST_SET_SELECTED_SUCCESS';
export const PLANREPAST_SET_SELECTED_ERROR = 'PLANREPAST_SET_SELECTED_ERROR';
export const PLANREPAST_UPDATE_SELECTED = 'PLANREPAST_UPDATE_SELECTED';

export const PLANREPAST_GET_LIST_WITH_FILTER =
  'PLANREPAST_GET_LIST_WITH_FILTER';
export const PLANREPAST_GET_LIST_WITH_ORDER = 'PLANREPAST_GET_LIST_WITH_ORDER';
export const PLANREPAST_GET_LIST_SEARCH = 'PLANREPAST_GET_LIST_SEARCH';

export const PLANREPAST_ADD_RECORD = 'PLANREPAST_ADD_RECORD';
export const PLANREPAST_ADD_RECORD_SUCCESS = 'PLANREPAST_ADD_RECORD_SUCCESS';
export const PLANREPAST_ADD_RECORD_ERROR = 'PLANREPAST_ADD_RECORD_ERROR';

export const PLANREPAST_UPDATE_RECORD = 'PLANREPAST_UPDATE_RECORD';
export const PLANREPAST_UPDATE_RECORD_SUCCESS =
  'PLANREPAST_UPDATE_RECORD_SUCCESS';
export const PLANREPAST_UPDATE_RECORD_ERROR = 'PLANREPAST_UPDATE_RECORD_ERROR';

export const PLANREPAST_UPDATE_BATCHRECORD = 'PLANREPAST_UPDATE_BATCHRECORD';
export const PLANREPAST_UPDATE_BATCHRECORD_SUCCESS =
  'PLANREPAST_UPDATE_BATCHRECORD_SUCCESS';
export const PLANREPAST_UPDATE_BATCHRECORD_ERROR =
  'PLANREPAST_UPDATE_BATCHRECORD_ERROR';

export const PLANREPAST_DELETE_RECORD = 'PLANREPAST_DELETE_RECORD';
export const PLANREPAST_DELETE_RECORD_SUCCESS =
  'PLANREPAST_DELETE_RECORD_SUCCESS';
export const PLANREPAST_DELETE_RECORD_ERROR = 'PLANREPAST_DELETE_RECORD_ERROR';
export const PLANREPAST_RESET_STATUSES = 'PLANREPAST_RESET_STATUSES';

export const PLANREPAST_ISACTIVECHANGE = 'PLANREPAST_ISACTIVECHANGE';
export const PLANREPAST_ISACTIVECHANGE_SUCCESS =
  'PLANREPAST_ISACTIVECHANGE_SUCCESS';
export const PLANREPAST_ISACTIVECHANGE_ERROR =
  'PLANREPAST_ISACTIVECHANGE_ERROR';

export const PLANREPAST_PLANREPASTADDFOOD = 'PLANREPAST_PLANREPASTADDFOOD';
export const PLANREPAST_PLANREPASTADDFOOD_SUCCESS =
  'PLANREPAST_PLANREPASTADDFOOD_SUCCESS';
export const PLANREPAST_PLANREPASTADDFOOD_ERROR =
  'PLANREPAST_PLANREPASTADDFOOD_ERROR';

export const PLANREPAST_PLANREPASTDELETEFOOD =
  'PLANREPAST_PLANREPASTDELETEFOOD';
export const PLANREPAST_PLANREPASTDELETEFOOD_SUCCESS =
  'PLANREPAST_PLANREPASTDELETEFOOD_SUCCESS';
export const PLANREPAST_PLANREPASTDELETEFOOD_ERROR =
  'PLANREPAST_PLANREPASTDELETEFOOD_ERROR';

/* MEALNUTRIENTTOTALS */
export const MEALNUTRIENTTOTALS_GET_LIST = 'MEALNUTRIENTTOTALS_GET_LIST';
export const MEALNUTRIENTTOTALS_GET_LIST_SUCCESS =
  'MEALNUTRIENTTOTALS_GET_LIST_SUCCESS';
export const MEALNUTRIENTTOTALS_GET_LIST_ERROR =
  'MEALNUTRIENTTOTALS_GET_LIST_ERROR';

export const MEALNUTRIENTTOTALS_SET_SELECTED =
  'MEALNUTRIENTTOTALS_SET_SELECTED';
export const MEALNUTRIENTTOTALS_SET_SELECTED_SUCCESS =
  'MEALNUTRIENTTOTALS_SET_SELECTED_SUCCESS';
export const MEALNUTRIENTTOTALS_SET_SELECTED_ERROR =
  'MEALNUTRIENTTOTALS_SET_SELECTED_ERROR';
export const MEALNUTRIENTTOTALS_UPDATE_SELECTED =
  'MEALNUTRIENTTOTALS_UPDATE_SELECTED';

export const MEALNUTRIENTTOTALS_GET_LIST_WITH_FILTER =
  'MEALNUTRIENTTOTALS_GET_LIST_WITH_FILTER';
export const MEALNUTRIENTTOTALS_GET_LIST_WITH_ORDER =
  'MEALNUTRIENTTOTALS_GET_LIST_WITH_ORDER';
export const MEALNUTRIENTTOTALS_GET_LIST_SEARCH =
  'MEALNUTRIENTTOTALS_GET_LIST_SEARCH';

export const MEALNUTRIENTTOTALS_ADD_RECORD = 'MEALNUTRIENTTOTALS_ADD_RECORD';
export const MEALNUTRIENTTOTALS_ADD_RECORD_SUCCESS =
  'MEALNUTRIENTTOTALS_ADD_RECORD_SUCCESS';
export const MEALNUTRIENTTOTALS_ADD_RECORD_ERROR =
  'MEALNUTRIENTTOTALS_ADD_RECORD_ERROR';

export const MEALNUTRIENTTOTALS_UPDATE_RECORD =
  'MEALNUTRIENTTOTALS_UPDATE_RECORD';
export const MEALNUTRIENTTOTALS_UPDATE_RECORD_SUCCESS =
  'MEALNUTRIENTTOTALS_UPDATE_RECORD_SUCCESS';
export const MEALNUTRIENTTOTALS_UPDATE_RECORD_ERROR =
  'MEALNUTRIENTTOTALS_UPDATE_RECORD_ERROR';

export const MEALNUTRIENTTOTALS_DELETE_RECORD =
  'MEALNUTRIENTTOTALS_DELETE_RECORD';
export const MEALNUTRIENTTOTALS_DELETE_RECORD_SUCCESS =
  'MEALNUTRIENTTOTALS_DELETE_RECORD_SUCCESS';
export const MEALNUTRIENTTOTALS_DELETE_RECORD_ERROR =
  'MEALNUTRIENTTOTALS_DELETE_RECORD_ERROR';
export const MEALNUTRIENTTOTALS_RESET_STATUSES =
  'MEALNUTRIENTTOTALS_RESET_STATUSES';

export const MEALNUTRIENTTOTALS_ISACTIVECHANGE =
  'MEALNUTRIENTTOTALS_ISACTIVECHANGE';
export const MEALNUTRIENTTOTALS_ISACTIVECHANGE_SUCCESS =
  'MEALNUTRIENTTOTALS_ISACTIVECHANGE_SUCCESS';
export const MEALNUTRIENTTOTALS_ISACTIVECHANGE_ERROR =
  'MEALNUTRIENTTOTALS_ISACTIVECHANGE_ERROR';
/* PLANREPASTFOOD */
export const PLANREPASTFOOD_GET_LIST = 'PLANREPASTFOOD_GET_LIST';
export const PLANREPASTFOOD_GET_LIST_SUCCESS =
  'PLANREPASTFOOD_GET_LIST_SUCCESS';
export const PLANREPASTFOOD_GET_LIST_ERROR = 'PLANREPASTFOOD_GET_LIST_ERROR';

export const PLANREPASTFOOD_SET_SELECTED = 'PLANREPASTFOOD_SET_SELECTED';
export const PLANREPASTFOOD_SET_SELECTED_SUCCESS =
  'PLANREPASTFOOD_SET_SELECTED_SUCCESS';
export const PLANREPASTFOOD_SET_SELECTED_ERROR =
  'PLANREPASTFOOD_SET_SELECTED_ERROR';
export const PLANREPASTFOOD_UPDATE_SELECTED = 'PLANREPASTFOOD_UPDATE_SELECTED';

export const PLANREPASTFOOD_GET_LIST_WITH_FILTER =
  'PLANREPASTFOOD_GET_LIST_WITH_FILTER';
export const PLANREPASTFOOD_GET_LIST_WITH_ORDER =
  'PLANREPASTFOOD_GET_LIST_WITH_ORDER';
export const PLANREPASTFOOD_GET_LIST_SEARCH = 'PLANREPASTFOOD_GET_LIST_SEARCH';

export const PLANREPASTFOOD_ADD_RECORD = 'PLANREPASTFOOD_ADD_RECORD';
export const PLANREPASTFOOD_ADD_RECORD_SUCCESS =
  'PLANREPASTFOOD_ADD_RECORD_SUCCESS';
export const PLANREPASTFOOD_ADD_RECORD_ERROR =
  'PLANREPASTFOOD_ADD_RECORD_ERROR';

export const PLANREPASTFOOD_UPDATE_RECORD = 'PLANREPASTFOOD_UPDATE_RECORD';
export const PLANREPASTFOOD_UPDATE_RECORD_SUCCESS =
  'PLANREPASTFOOD_UPDATE_RECORD_SUCCESS';
export const PLANREPASTFOOD_UPDATE_RECORD_ERROR =
  'PLANREPASTFOOD_UPDATE_RECORD_ERROR';

export const PLANREPASTFOOD_UPDATE_BATCHRECORD =
  'PLANREPASTFOOD_UPDATE_BATCHRECORD';
export const PLANREPASTFOOD_UPDATE_BATCHRECORD_SUCCESS =
  'PLANREPASTFOOD_UPDATE_BATCHRECORD_SUCCESS';
export const PLANREPASTFOOD_UPDATE_BATCHRECORD_ERROR =
  'PLANREPASTFOOD_UPDATE_BATCHRECORD_ERROR';

export const PLANREPASTFOOD_DELETE_RECORD = 'PLANREPASTFOOD_DELETE_RECORD';
export const PLANREPASTFOOD_DELETE_RECORD_SUCCESS =
  'PLANREPASTFOOD_DELETE_RECORD_SUCCESS';
export const PLANREPASTFOOD_DELETE_RECORD_ERROR =
  'PLANREPASTFOOD_DELETE_RECORD_ERROR';
export const PLANREPASTFOOD_RESET_STATUSES = 'PLANREPASTFOOD_RESET_STATUSES';

export const PLANREPASTFOOD_ISACTIVECHANGE = 'PLANREPASTFOOD_ISACTIVECHANGE';
export const PLANREPASTFOOD_ISACTIVECHANGE_SUCCESS =
  'PLANREPASTFOOD_ISACTIVECHANGE_SUCCESS';
export const PLANREPASTFOOD_ISACTIVECHANGE_ERROR =
  'PLANREPASTFOOD_ISACTIVECHANGE_ERROR';

export const PLANREPASTFOOD_MEALFOODTRANFER = 'PLANREPASTFOOD_MEALFOODTRANFER';
export const PLANREPASTFOOD_MEALFOODTRANFER_SUCCESS =
  'PLANREPASTFOOD_MEALFOODTRANFER_SUCCESS';
export const PLANREPASTFOOD_MEALFOODTRANFER_ERROR =
  'PLANREPASTFOOD_MEALFOODTRANFER_ERROR';

/* PLANREPASTFOODPORTİON */
export const PLANREPASTFOODPORTION_GET_LIST = 'PLANREPASTFOODPORTION_GET_LIST';
export const PLANREPASTFOODPORTION_GET_LIST_SUCCESS =
  'PLANREPASTFOODPORTION_GET_LIST_SUCCESS';
export const PLANREPASTFOODPORTION_GET_LIST_ERROR =
  'PLANREPASTFOODPORTION_GET_LIST_ERROR';

export const PLANREPASTFOODPORTION_SET_SELECTED =
  'PLANREPASTFOODPORTION_SET_SELECTED';
export const PLANREPASTFOODPORTION_SET_SELECTED_SUCCESS =
  'PLANREPASTFOODPORTION_SET_SELECTED_SUCCESS';
export const PLANREPASTFOODPORTION_SET_SELECTED_ERROR =
  'PLANREPASTFOODPORTION_SET_SELECTED_ERROR';
export const PLANREPASTFOODPORTION_UPDATE_SELECTED =
  'PLANREPASTFOODPORTION_UPDATE_SELECTED';

export const PLANREPASTFOODPORTION_GET_LIST_WITH_FILTER =
  'PLANREPASTFOODPORTION_GET_LIST_WITH_FILTER';
export const PLANREPASTFOODPORTION_GET_LIST_WITH_ORDER =
  'PLANREPASTFOODPORTION_GET_LIST_WITH_ORDER';
export const PLANREPASTFOODPORTION_GET_LIST_SEARCH =
  'PLANREPASTFOODPORTION_GET_LIST_SEARCH';

export const PLANREPASTFOODPORTION_ADD_RECORD =
  'PLANREPASTFOODPORTION_ADD_RECORD';
export const PLANREPASTFOODPORTION_ADD_RECORD_SUCCESS =
  'PLANREPASTFOODPORTION_ADD_RECORD_SUCCESS';
export const PLANREPASTFOODPORTION_ADD_RECORD_ERROR =
  'PLANREPASTFOODPORTION_ADD_RECORD_ERROR';

export const PLANREPASTFOODPORTION_UPDATE_RECORD =
  'PLANREPASTFOODPORTION_UPDATE_RECORD';
export const PLANREPASTFOODPORTION_UPDATE_RECORD_SUCCESS =
  'PLANREPASTFOODPORTION_UPDATE_RECORD_SUCCESS';
export const PLANREPASTFOODPORTION_UPDATE_RECORD_ERROR =
  'PLANREPASTFOODPORTION_UPDATE_RECORD_ERROR';

export const PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD =
  'PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD';
export const PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_SUCCESS =
  'PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_SUCCESS';
export const PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_ERROR =
  'PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_ERROR';

export const PLANREPASTFOODPORTION_DELETE_RECORD =
  'PLANREPASTFOODPORTION_DELETE_RECORD';
export const PLANREPASTFOODPORTION_DELETE_RECORD_SUCCESS =
  'PLANREPASTFOODPORTION_DELETE_RECORD_SUCCESS';
export const PLANREPASTFOODPORTION_DELETE_RECORD_ERROR =
  'PLANREPASTFOODPORTION_DELETE_RECORD_ERROR';
export const PLANREPASTFOODPORTION_RESET_STATUSES =
  'PLANREPASTFOODPORTION_RESET_STATUSES';

export const PLANREPASTFOODPORTION_ISACTIVECHANGE =
  'PLANREPASTFOODPORTION_ISACTIVECHANGE';
export const PLANREPASTFOODPORTION_ISACTIVECHANGE_SUCCESS =
  'PLANREPASTFOODPORTION_ISACTIVECHANGE_SUCCESS';
export const PLANREPASTFOODPORTION_ISACTIVECHANGE_ERROR =
  'PLANREPASTFOODPORTION_ISACTIVECHANGE_ERROR';

/* ACTIVITYTYPE */

export const ACTIVITYTYPE_GET_LIST = 'ACTIVITYTYPE_GET_LIST';
export const ACTIVITYTYPE_GET_LIST_SUCCESS = 'ACTIVITYTYPE_GET_LIST_SUCCESS';
export const ACTIVITYTYPE_GET_LIST_ERROR = 'ACTIVITYTYPE_GET_LIST_ERROR';

export const ACTIVITYTYPE_SET_SELECTED = 'ACTIVITYTYPE_SET_SELECTED';
export const ACTIVITYTYPE_SET_SELECTED_SUCCESS =
  'ACTIVITYTYPE_SET_SELECTED_SUCCESS';
export const ACTIVITYTYPE_SET_SELECTED_ERROR =
  'ACTIVITYTYPE_SET_SELECTED_ERROR';
export const ACTIVITYTYPE_UPDATE_SELECTED = 'ACTIVITYTYPE_UPDATE_SELECTED';

export const ACTIVITYTYPE_GET_LIST_WITH_FILTER =
  'ACTIVITYTYPE_GET_LIST_WITH_FILTER';
export const ACTIVITYTYPE_GET_LIST_WITH_ORDER =
  'ACTIVITYTYPE_GET_LIST_WITH_ORDER';
export const ACTIVITYTYPE_GET_LIST_SEARCH = 'ACTIVITYTYPE_GET_LIST_SEARCH';

export const ACTIVITYTYPE_ADD_RECORD = 'ACTIVITYTYPE_ADD_RECORD';
export const ACTIVITYTYPE_ADD_RECORD_SUCCESS =
  'ACTIVITYTYPE_ADD_RECORD_SUCCESS';
export const ACTIVITYTYPE_ADD_RECORD_ERROR = 'ACTIVITYTYPE_ADD_RECORD_ERROR';

export const ACTIVITYTYPE_UPDATE_RECORD = 'ACTIVITYTYPE_UPDATE_RECORD';
export const ACTIVITYTYPE_UPDATE_RECORD_SUCCESS =
  'ACTIVITYTYPE_UPDATE_RECORD_SUCCESS';
export const ACTIVITYTYPE_UPDATE_RECORD_ERROR =
  'ACTIVITYTYPE_UPDATE_RECORD_ERROR';

export const ACTIVITYTYPE_DELETE_RECORD = 'ACTIVITYTYPE_DELETE_RECORD';
export const ACTIVITYTYPE_DELETE_RECORD_SUCCESS =
  'ACTIVITYTYPE_DELETE_RECORD_SUCCESS';
export const ACTIVITYTYPE_DELETE_RECORD_ERROR =
  'ACTIVITYTYPE_DELETE_RECORD_ERROR';
export const ACTIVITYTYPE_RESET_STATUSES = 'ACTIVITYTYPE_RESET_STATUSES';

export const ACTIVITYTYPE_GET_GROUPED_LIST = 'ACTIVITYTYPE_GET_GROUPED_LIST';
export const ACTIVITYTYPE_GET_GROUPED_LIST_SUCCESS =
  'ACTIVITYTYPE_GET_GROUPED_LIST_SUCCESS';
export const ACTIVITYTYPE_GET_GROUPED_LIST_ERROR =
  'ACTIVITYTYPE_GET_GROUPED_LIST_ERROR';

export const ACTIVITYTYPE_ISACTIVECHANGE = 'ACTIVITYTYPE_ISACTIVECHANGE';
export const ACTIVITYTYPE_ISACTIVECHANGE_SUCCESS =
  'ACTIVITYTYPE_ISACTIVECHANGE_SUCCESS';
export const ACTIVITYTYPE_ISACTIVECHANGE_ERROR =
  'ACTIVITYTYPE_ISACTIVECHANGE_ERROR';

/* BODYMASSINDEXRANGE */

export const BODYMASSINDEXRANGE_GET_LIST = 'BODYMASSINDEXRANGE_GET_LIST';
export const BODYMASSINDEXRANGE_GET_LIST_SUCCESS =
  'BODYMASSINDEXRANGE_GET_LIST_SUCCESS';
export const BODYMASSINDEXRANGE_GET_LIST_ERROR =
  'BODYMASSINDEXRANGE_GET_LIST_ERROR';

export const BODYMASSINDEXRANGE_SET_SELECTED =
  'BODYMASSINDEXRANGE_SET_SELECTED';
export const BODYMASSINDEXRANGE_SET_SELECTED_SUCCESS =
  'BODYMASSINDEXRANGE_SET_SELECTED_SUCCESS';
export const BODYMASSINDEXRANGE_SET_SELECTED_ERROR =
  'BODYMASSINDEXRANGE_SET_SELECTED_ERROR';
export const BODYMASSINDEXRANGE_UPDATE_SELECTED =
  'BODYMASSINDEXRANGE_UPDATE_SELECTED';

export const BODYMASSINDEXRANGE_GET_LIST_WITH_FILTER =
  'BODYMASSINDEXRANGE_GET_LIST_WITH_FILTER';
export const BODYMASSINDEXRANGE_GET_LIST_WITH_ORDER =
  'BODYMASSINDEXRANGE_GET_LIST_WITH_ORDER';
export const BODYMASSINDEXRANGE_GET_LIST_SEARCH =
  'BODYMASSINDEXRANGE_GET_LIST_SEARCH';

export const BODYMASSINDEXRANGE_ADD_RECORD = 'BODYMASSINDEXRANGE_ADD_RECORD';
export const BODYMASSINDEXRANGE_ADD_RECORD_SUCCESS =
  'BODYMASSINDEXRANGE_ADD_RECORD_SUCCESS';
export const BODYMASSINDEXRANGE_ADD_RECORD_ERROR =
  'BODYMASSINDEXRANGE_ADD_RECORD_ERROR';

export const BODYMASSINDEXRANGE_UPDATE_RECORD =
  'BODYMASSINDEXRANGE_UPDATE_RECORD';
export const BODYMASSINDEXRANGE_UPDATE_RECORD_SUCCESS =
  'BODYMASSINDEXRANGE_UPDATE_RECORD_SUCCESS';
export const BODYMASSINDEXRANGE_UPDATE_RECORD_ERROR =
  'BODYMASSINDEXRANGE_UPDATE_RECORD_ERROR';

export const BODYMASSINDEXRANGE_DELETE_RECORD =
  'BODYMASSINDEXRANGE_DELETE_RECORD';
export const BODYMASSINDEXRANGE_DELETE_RECORD_SUCCESS =
  'BODYMASSINDEXRANGE_DELETE_RECORD_SUCCESS';
export const BODYMASSINDEXRANGE_DELETE_RECORD_ERROR =
  'BODYMASSINDEXRANGE_DELETE_RECORD_ERROR';
export const BODYMASSINDEXRANGE_RESET_STATUSES =
  'BODYMASSINDEXRANGE_RESET_STATUSES';

export const BODYMASSINDEXRANGE_ISACTIVECHANGE =
  'BODYMASSINDEXRANGE_ISACTIVECHANGE';
export const BODYMASSINDEXRANGE_ISACTIVECHANGE_SUCCESS =
  'BODYMASSINDEXRANGE_ISACTIVECHANGE_SUCCESS';
export const BODYMASSINDEXRANGE_ISACTIVECHANGE_ERROR =
  'BODYMASSINDEXRANGE_ISACTIVECHANGE_ERROR';

/* FORMULA */

export const FORMULA_GET_LIST = 'FORMULA_GET_LIST';
export const FORMULA_GET_LIST_SUCCESS = 'FORMULA_GET_LIST_SUCCESS';
export const FORMULA_GET_LIST_ERROR = 'FORMULA_GET_LIST_ERROR';

export const FORMULA_SET_SELECTED = 'FORMULA_SET_SELECTED';
export const FORMULA_SET_SELECTED_SUCCESS = 'FORMULA_SET_SELECTED_SUCCESS';
export const FORMULA_SET_SELECTED_ERROR = 'FORMULA_SET_SELECTED_ERROR';
export const FORMULA_UPDATE_SELECTED = 'FORMULA_UPDATE_SELECTED';

export const FORMULA_GET_LIST_WITH_FILTER = 'FORMULA_GET_LIST_WITH_FILTER';
export const FORMULA_GET_LIST_WITH_ORDER = 'FORMULA_GET_LIST_WITH_ORDER';
export const FORMULA_GET_LIST_SEARCH = 'FORMULA_GET_LIST_SEARCH';

export const FORMULA_ADD_RECORD = 'FORMULA_ADD_RECORD';
export const FORMULA_ADD_RECORD_SUCCESS = 'FORMULA_ADD_RECORD_SUCCESS';
export const FORMULA_ADD_RECORD_ERROR = 'FORMULA_ADD_RECORD_ERROR';

export const FORMULA_UPDATE_RECORD = 'FORMULA_UPDATE_RECORD';
export const FORMULA_UPDATE_RECORD_SUCCESS = 'FORMULA_UPDATE_RECORD_SUCCESS';
export const FORMULA_UPDATE_RECORD_ERROR = 'FORMULA_UPDATE_RECORD_ERROR';

export const FORMULA_DELETE_RECORD = 'FORMULA_DELETE_RECORD';
export const FORMULA_DELETE_RECORD_SUCCESS = 'FORMULA_DELETE_RECORD_SUCCESS';
export const FORMULA_DELETE_RECORD_ERROR = 'FORMULA_DELETE_RECORD_ERROR';
export const FORMULA_RESET_STATUSES = 'FORMULA_RESET_STATUSES';

export const FORMULA_ISACTIVECHANGE = 'FORMULA_ISACTIVECHANGE';
export const FORMULA_ISACTIVECHANGE_SUCCESS = 'FORMULA_ISACTIVECHANGE_SUCCESS';
export const FORMULA_ISACTIVECHANGE_ERROR = 'FORMULA_ISACTIVECHANGE_ERROR';

/* ANTROPOMETRY */

export const ANTROPOMETRY_GET_LIST = 'ANTROPOMETRY_GET_LIST';
export const ANTROPOMETRY_GET_LIST_SUCCESS = 'ANTROPOMETRY_GET_LIST_SUCCESS';
export const ANTROPOMETRY_GET_LIST_ERROR = 'ANTROPOMETRY_GET_LIST_ERROR';

export const ANTROPOMETRY_SET_SELECTED = 'ANTROPOMETRY_SET_SELECTED';
export const ANTROPOMETRY_SET_SELECTED_SUCCESS =
  'ANTROPOMETRY_SET_SELECTED_SUCCESS';
export const ANTROPOMETRY_SET_SELECTED_ERROR =
  'ANTROPOMETRY_SET_SELECTED_ERROR';
export const ANTROPOMETRY_UPDATE_SELECTED = 'ANTROPOMETRY_UPDATE_SELECTED';

export const ANTROPOMETRY_GET_LIST_WITH_FILTER =
  'ANTROPOMETRY_GET_LIST_WITH_FILTER';
export const ANTROPOMETRY_GET_LIST_WITH_ORDER =
  'ANTROPOMETRY_GET_LIST_WITH_ORDER';
export const ANTROPOMETRY_GET_LIST_SEARCH = 'ANTROPOMETRY_GET_LIST_SEARCH';

export const ANTROPOMETRY_ADD_RECORD = 'ANTROPOMETRY_ADD_RECORD';
export const ANTROPOMETRY_ADD_RECORD_SUCCESS =
  'ANTROPOMETRY_ADD_RECORD_SUCCESS';
export const ANTROPOMETRY_ADD_RECORD_ERROR = 'ANTROPOMETRY_ADD_RECORD_ERROR';

export const ANTROPOMETRY_UPDATE_RECORD = 'ANTROPOMETRY_UPDATE_RECORD';
export const ANTROPOMETRY_UPDATE_RECORD_SUCCESS =
  'ANTROPOMETRY_UPDATE_RECORD_SUCCESS';
export const ANTROPOMETRY_UPDATE_RECORD_ERROR =
  'ANTROPOMETRY_UPDATE_RECORD_ERROR';

export const ANTROPOMETRY_DELETE_RECORD = 'ANTROPOMETRY_DELETE_RECORD';
export const ANTROPOMETRY_DELETE_RECORD_SUCCESS =
  'ANTROPOMETRY_DELETE_RECORD_SUCCESS';
export const ANTROPOMETRY_DELETE_RECORD_ERROR =
  'ANTROPOMETRY_DELETE_RECORD_ERROR';
export const ANTROPOMETRY_RESET_STATUSES = 'ANTROPOMETRY_RESET_STATUSES';
export const ANTROPOMETRY_SET_PALVALUE = 'ANTROPOMETRY_SET_PALVALUE';
export const ANTROPOMETRY_SET_EXERCISEACTIVE =
  'ANTROPOMETRY_SET_EXERCISEACTIVE';
export const ANTROPOMETRY_SET_BASALMETABOLICRATE =
  'ANTROPOMETRY_SET_BASALMETABOLICRATE';
export const ANTROPOMETRY_SET_TOTALENERGYEXPENDITURE =
  'ANTROPOMETRY_SET_TOTALENERGYEXPENDITURE';
export const ANTROPOMETRY_SET_EXERCİSESTOTALKCAL =
  'ANTROPOMETRY_SET_EXERCİSESTOTALKCAL';
export const ANTROPOMETRY_SET_CURRENTFORMULA =
  'ANTROPOMETRY_SET_CURRENTFORMULA';

export const ANTROPOMETRY_CLONE_SET_SELECTED =
  'ANTROPOMETRY_CLONE_SET_SELECTED';
export const ANTROPOMETRY_CLONE_SET_SELECTED_SUCCESS =
  'ANTROPOMETRY_CLONE_SET_SELECTED_SUCCESS';
export const ANTROPOMETRY_CLONE_SET_SELECTED_ERROR =
  'ANTROPOMETRY_CLONE_SET_SELECTED_ERROR';
export const ANTROPOMETRY_CLONE_UPDATE_SELECTED =
  'ANTROPOMETRY_CLONE_UPDATE_SELECTED';

export const ANTROPOMETRY_ISACTIVECHANGE = 'ANTROPOMETRY_ISACTIVECHANGE';
export const ANTROPOMETRY_ISACTIVECHANGE_SUCCESS =
  'ANTROPOMETRY_ISACTIVECHANGE_SUCCESS';
export const ANTROPOMETRY_ISACTIVECHANGE_ERROR =
  'ANTROPOMETRY_ISACTIVECHANGE_ERROR';

export const ADDFOODMODAL_CHECKEDFOODLIST_ONCHANGE =
  'ADDFOODMODAL_CHECKEDFOODLIST_ONCHANGE';
export const ADDFOODMODAL_COLUMNS_ONCHANGE = 'ADDFOODMODAL_COLUMNS_ONCHANGE';
export const ADDFOODMODAL_PAGENUMBER_ONCHANGE =
  'ADDFOODMODAL_PAGENUMBER_ONCHANGE';
export const ADDFOODMODAL_SEARCHPARAMS_ONCHANGE =
  'ADDFOODMODAL_SEARCHPARAMS_ONCHANGE';
export const ADDFOODMODAL_MODALSTATE_ONCHANGE =
  'ADDFOODMODAL_MODALSTATE_ONCHANGE';

export const ANTROPOMETRY_GENERICSEARCH = 'ANTROPOMETRY_GENERICSEARCH';
export const ANTROPOMETRY_GENERICSEARCH_SUCCESS =
  'ANTROPOMETRY_GENERICSEARCH_SUCCESS';
export const ANTROPOMETRY_GENERICSEARCH_ERROR =
  'ANTROPOMETRY_GENERICSEARCH_ERROR';

export const ANTROPOMETRY_GETBYCOACHEEID = 'ANTROPOMETRY_GETBYCOACHEEID';
export const ANTROPOMETRY_GETBYCOACHEEID_SUCCESS =
  'ANTROPOMETRY_GETBYCOACHEEID_SUCCESS';
export const ANTROPOMETRY_GETBYCOACHEEID_ERROR =
  'ANTROPOMETRY_GETBYCOACHEEID_ERROR';

/* #ADMINISTRATION */

/* PROFİLE */

/* USER */
export const USER_GET_LIST = 'USER_GET_LIST';
export const USER_GET_LIST_SUCCESS = 'USER_GET_LIST_SUCCESS';
export const USER_GET_LIST_ERROR = 'USER_GET_LIST_ERROR';

export const USER_SET_SELECTED = 'USER_SET_SELECTED';
export const USER_SET_SELECTED_SUCCESS = 'USER_SET_SELECTED_SUCCESS';
export const USER_SET_SELECTED_ERROR = 'USER_SET_SELECTED_ERROR';
export const USER_UPDATE_SELECTED = 'USER_UPDATE_SELECTED';

export const USER_GET_LIST_WITH_FILTER = 'USER_GET_LIST_WITH_FILTER';
export const USER_GET_LIST_WITH_ORDER = 'USER_GET_LIST_WITH_ORDER';
export const USER_GET_LIST_SEARCH = 'USER_GET_LIST_SEARCH';

export const USER_ADD_RECORD = 'USER_ADD_RECORD';
export const USER_ADD_RECORD_SUCCESS = 'USER_ADD_RECORD_SUCCESS';
export const USER_ADD_RECORD_ERROR = 'USER_ADD_RECORD_ERROR';

export const USER_UPDATE_RECORD = 'USER_UPDATE_RECORD';
export const USER_UPDATE_RECORD_SUCCESS = 'USER_UPDATE_RECORD_SUCCESS';
export const USER_UPDATE_RECORD_ERROR = 'USER_UPDATE_RECORD_ERROR';

export const USER_DELETE_RECORD = 'USER_DELETE_RECORD';
export const USER_DELETE_RECORD_SUCCESS = 'USER_DELETE_RECORD_SUCCESS';
export const USER_DELETE_RECORD_ERROR = 'USER_DELETE_RECORD_ERROR';
export const USER_RESET_STATUSES = 'USER_RESET_STATUSES';

export const USER_ISACTIVECHANGE = 'USER_ISACTIVECHANGE';
export const USER_ISACTIVECHANGE_SUCCESS = 'USER_ISACTIVECHANGE_SUCCESS';
export const USER_ISACTIVECHANGE_ERROR = 'USER_ISACTIVECHANGE_ERROR';

export const USER_PROFILE_PICTURE_UPDATE = 'USER_PROFILE_PICTURE_UPDATE';
export const USER_PROFILE_PICTURE_UPDATE_SUCCESS =
  'USER_PROFILE_PICTURE_UPDATE_SUCCESS';
export const USER_PROFILE_PICTURE_UPDATE_ERROR =
  'USER_PROFILE_PICTURE_UPDATE_ERROR';

export const USER_CHANGEPASSWORD_RECORD_UPDATE =
  'USER_CHANGEPASSWORD_RECORD_UPDATE';

export const USER_CHANGEPASSWORD_UPDATE = 'USER_CHANGEPASSWORD_UPDATE';
export const USER_CHANGEPASSWORD_UPDATE_SUCCESS =
  'USER_CHANGEPASSWORD_UPDATE_SUCCESS';
export const USER_CHANGEPASSWORD_UPDATE_ERROR =
  'USER_CHANGEPASSWORD_UPDATE_ERROR';

export const USER_DELETEPICTURE = 'USER_DELETEPICTURE';
export const USER_DELETEPICTURE_SUCCESS = 'USER_DELETEPICTURE_SUCCESS';
export const USER_DELETEPICTURE_ERROR = 'USER_DELETEPICTURE_ERROR';

export const USER_PENDINGAPPROVAL = 'USER_PENDINGAPPROVAL';
export const USER_PENDINGAPPROVAL_SUCCESS = 'USER_PENDINGAPPROVAL_SUCCESS';
export const USER_PENDINGAPPROVAL_ERROR = 'USER_PENDINGAPPROVAL_ERROR';

export const USER_PENDINGAPPROVAL_CHANGE = 'USER_PENDINGAPPROVAL_CHANGE';
export const USER_PENDINGAPPROVAL_CHANGE_SUCCESS =
  'USER_PENDINGAPPROVAL_CHANGE_SUCCESS';
export const USER_PENDINGAPPROVAL_CHANGE_ERROR =
  'USER_PENDINGAPPROVAL_CHANGE_ERROR';

export const USER_PAYMENT = 'USER_PAYMENT';
export const USER_PAYMENT_SUCCESS = 'USER_PAYMENT_SUCCESS';
export const USER_PAYMENT_ERROR = 'USER_PAYMENT_ERROR';
export const USER_PAYMENT_CLEARMODAL = 'USER_PAYMENT_CLEARMODAL';

export const USER_PREVIEWPDF = 'USER_PREVIEWPDF';
export const USER_PREVIEWPDF_SUCCESS = 'USER_PREVIEWPDF_SUCCESS';
export const USER_PREVIEWPDF_ERROR = 'USER_PREVIEWPDF_ERROR';

export const USER_PAYMENTINTERNAL = 'USER_PAYMENTINTERNAL';
export const USER_PAYMENTINTERNAL_SUCCESS = 'USER_PAYMENTINTERNAL_SUCCESS';
export const USER_PAYMENTINTERNAL_ERROR = 'USER_PAYMENTINTERNAL_ERROR';

export const USER_ISPAYMENTCONTROL = 'USER_ISPAYMENTCONTROL';
export const USER_ISPAYMENTCONTROL_SUCCESS = 'USER_ISPAYMENTCONTROL_SUCCESS';
export const USER_ISPAYMENTCONTROL_ERROR = 'USER_ISPAYMENTCONTROL_ERROR';

export const USER_PERIOD = 'USER_PERIOD';
export const USER_PERIOD_SUCCESS = 'USER_PERIOD_SUCCESS';
export const USER_PERIOD_ERROR = 'USER_PERIOD_ERROR';

export const USER_GETPROFILE_PICTURE = 'USER_GETPROFILE_PICTURE';
export const USER_GETPROFILE_PICTURE_SUCCESS =
  'USER_GETPROFILE_PICTURE_SUCCESS';
export const USER_GETPROFILE_PICTURE_ERROR = 'USER_GETPROFILE_PICTURE_ERROR';

export const USER_APPROVAL_FILTER = 'USER_APPROVAL_FILTER';
export const USER_APPROVAL_FILTER_SUCCESS = 'USER_APPROVAL_FILTER_SUCCESS';
export const USER_APPROVAL_FILTER_ERROR = 'USER_APPROVAL_FILTER_ERROR';

export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_ERROR = 'USER_CHANGE_PASSWORD_ERROR';

/* USERPREFERENCE */

export const USERPREFERENCE_GET_LIST = 'USERPREFERENCE_GET_LIST';
export const USERPREFERENCE_GET_LIST_SUCCESS =
  'USERPREFERENCE_GET_LIST_SUCCESS';
export const USERPREFERENCE_GET_LIST_ERROR = 'USERPREFERENCE_GET_LIST_ERROR';

export const USERPREFERENCE_SET_SELECTED = 'USERPREFERENCE_SET_SELECTED';
export const USERPREFERENCE_SET_SELECTED_SUCCESS =
  'USERPREFERENCE_SET_SELECTED_SUCCESS';
export const USERPREFERENCE_SET_SELECTED_ERROR =
  'USERPREFERENCE_SET_SELECTED_ERROR';
export const USERPREFERENCE_UPDATE_SELECTED = 'USERPREFERENCE_UPDATE_SELECTED';

export const USERPREFERENCE_GET_LIST_WITH_FILTER =
  'USERPREFERENCE_GET_LIST_WITH_FILTER';
export const USERPREFERENCE_GET_LIST_WITH_ORDER =
  'USERPREFERENCE_GET_LIST_WITH_ORDER';
export const USERPREFERENCE_GET_LIST_SEARCH = 'USERPREFERENCE_GET_LIST_SEARCH';

export const USERPREFERENCE_ADD_RECORD = 'USERPREFERENCE_ADD_RECORD';
export const USERPREFERENCE_ADD_RECORD_SUCCESS =
  'USERPREFERENCE_ADD_RECORD_SUCCESS';
export const USERPREFERENCE_ADD_RECORD_ERROR =
  'USERPREFERENCE_ADD_RECORD_ERROR';

export const USERPREFERENCE_UPDATE_RECORD = 'USERPREFERENCE_UPDATE_RECORD';
export const USERPREFERENCE_UPDATE_RECORD_SUCCESS =
  'USERPREFERENCE_UPDATE_RECORD_SUCCESS';
export const USERPREFERENCE_UPDATE_RECORD_ERROR =
  'USERPREFERENCE_UPDATE_RECORD_ERROR';

export const USERPREFERENCE_DELETE_RECORD = 'USERPREFERENCE_DELETE_RECORD';
export const USERPREFERENCE_DELETE_RECORD_SUCCESS =
  'USERPREFERENCE_DELETE_RECORD_SUCCESS';
export const USERPREFERENCE_DELETE_RECORD_ERROR =
  'USERPREFERENCE_DELETE_RECORD_ERROR';
export const USERPREFERENCE_RESET_STATUSES = 'USERPREFERENCE_RESET_STATUSES';

export const USERPREFERENCE_ISACTIVECHANGE = 'USERPREFERENCE_ISACTIVECHANGE';
export const USERPREFERENCE_ISACTIVECHANGE_SUCCESS =
  'USERPREFERENCE_ISACTIVECHANGE_SUCCESS';
export const USERPREFERENCE_ISACTIVECHANGE_ERROR =
  'USERPREFERENCE_ISACTIVECHANGE_ERROR';

export const USERPREFERENCE_BYUSERID_SET_SELECTED =
  'USERPREFERENCE_BYUSERID_SET_SELECTED';
export const USERPREFERENCE_BYUSERID_SET_SELECTED_SUCCESS =
  'USERPREFERENCE_BYUSERID_SET_SELECTED_SUCCESS';
export const USERPREFERENCE_BYUSERID_SET_SELECTED_ERROR =
  'USERPREFERENCE_BYUSERID_SET_SELECTED_ERROR';
export const USERPREFERENCE_BYUSERID_UPDATE_SELECTED =
  'USERPREFERENCE_BYUSERID_UPDATE_SELECTED';

/* PROFİLE */

/* ROLE MANAGEMENT */

/* ROLE */
export const ROLE_GET_LIST = 'ROLE_GET_LIST';
export const ROLE_GET_LIST_SUCCESS = 'ROLE_GET_LIST_SUCCESS';
export const ROLE_GET_LIST_ERROR = 'ROLE_GET_LIST_ERROR';

export const ROLE_SET_SELECTED = 'ROLE_SET_SELECTED';
export const ROLE_SET_SELECTED_SUCCESS = 'ROLE_SET_SELECTED_SUCCESS';
export const ROLE_SET_SELECTED_ERROR = 'ROLE_SET_SELECTED_ERROR';
export const ROLE_UPDATE_SELECTED = 'ROLE_UPDATE_SELECTED';

export const ROLE_GET_LIST_WITH_FILTER = 'ROLE_GET_LIST_WITH_FILTER';
export const ROLE_GET_LIST_WITH_ORDER = 'ROLE_GET_LIST_WITH_ORDER';
export const ROLE_GET_LIST_SEARCH = 'ROLE_GET_LIST_SEARCH';

export const ROLE_ADD_RECORD = 'ROLE_ADD_RECORD';
export const ROLE_ADD_RECORD_SUCCESS = 'ROLE_ADD_RECORD_SUCCESS';
export const ROLE_ADD_RECORD_ERROR = 'ROLE_ADD_RECORD_ERROR';

export const ROLE_UPDATE_RECORD = 'ROLE_UPDATE_RECORD';
export const ROLE_UPDATE_RECORD_SUCCESS = 'ROLE_UPDATE_RECORD_SUCCESS';
export const ROLE_UPDATE_RECORD_ERROR = 'ROLE_UPDATE_RECORD_ERROR';

export const ROLE_DELETE_RECORD = 'ROLE_DELETE_RECORD';
export const ROLE_DELETE_RECORD_SUCCESS = 'ROLE_DELETE_RECORD_SUCCESS';
export const ROLE_DELETE_RECORD_ERROR = 'ROLE_DELETE_RECORD_ERROR';
export const ROLE_RESET_STATUSES = 'ROLE_RESET_STATUSES';

export const ROLE_ISACTIVECHANGE = 'ROLE_ISACTIVECHANGE';
export const ROLE_ISACTIVECHANGE_SUCCESS = 'ROLE_ISACTIVECHANGE_SUCCESS';
export const ROLE_ISACTIVECHANGE_ERROR = 'ROLE_ISACTIVECHANGE_ERROR';

export const ROLE_FULLEXISTROLE = 'ROLE_FULLEXISTROLE';
export const ROLE_FULLEXISTROLE_SUCCESS = 'ROLE_FULLEXISTROLE_SUCCESS';
export const ROLE_FULLEXISTROLE_ERROR = 'ROLE_FULLEXISTROLE_ERROR';

export const ROLE_PAGEGETROLES = 'ROLE_PAGEGETROLES';
export const ROLE_PAGEGETROLES_SUCCESS = 'ROLE_PAGEGETROLES_SUCCESS';
export const ROLE_PAGEGETROLES_ERROR = 'ROLE_PAGEGETROLES_ERROR';

/* ROLE */

/* ROLEGROUP */
export const ROLEGROUP_GET_LIST = 'ROLEGROUP_GET_LIST';
export const ROLEGROUP_GET_LIST_SUCCESS = 'ROLEGROUP_GET_LIST_SUCCESS';
export const ROLEGROUP_GET_LIST_ERROR = 'ROLEGROUP_GET_LIST_ERROR';

export const ROLEGROUP_SET_SELECTED = 'ROLEGROUP_SET_SELECTED';
export const ROLEGROUP_SET_SELECTED_SUCCESS = 'ROLEGROUP_SET_SELECTED_SUCCESS';
export const ROLEGROUP_SET_SELECTED_ERROR = 'ROLEGROUP_SET_SELECTED_ERROR';
export const ROLEGROUP_UPDATE_SELECTED = 'ROLEGROUP_UPDATE_SELECTED';

export const ROLEGROUP_GET_LIST_WITH_FILTER = 'ROLEGROUP_GET_LIST_WITH_FILTER';
export const ROLEGROUP_GET_LIST_WITH_ORDER = 'ROLEGROUP_GET_LIST_WITH_ORDER';
export const ROLEGROUP_GET_LIST_SEARCH = 'ROLEGROUP_GET_LIST_SEARCH';

export const ROLEGROUP_ADD_RECORD = 'ROLEGROUP_ADD_RECORD';
export const ROLEGROUP_ADD_RECORD_SUCCESS = 'ROLEGROUP_ADD_RECORD_SUCCESS';
export const ROLEGROUP_ADD_RECORD_ERROR = 'ROLEGROUP_ADD_RECORD_ERROR';

export const ROLEGROUP_UPDATE_RECORD = 'ROLEGROUP_UPDATE_RECORD';
export const ROLEGROUP_UPDATE_RECORD_SUCCESS =
  'ROLEGROUP_UPDATE_RECORD_SUCCESS';
export const ROLEGROUP_UPDATE_RECORD_ERROR = 'ROLEGROUP_UPDATE_RECORD_ERROR';

export const ROLEGROUP_DELETE_RECORD = 'ROLEGROUP_DELETE_RECORD';
export const ROLEGROUP_DELETE_RECORD_SUCCESS =
  'ROLEGROUP_DELETE_RECORD_SUCCESS';
export const ROLEGROUP_DELETE_RECORD_ERROR = 'ROLEGROUP_DELETE_RECORD_ERROR';
export const ROLEGROUP_RESET_STATUSES = 'ROLEGROUP_RESET_STATUSES';

export const ROLEGROUP_ISACTIVECHANGE = 'ROLEGROUP_ISACTIVECHANGE';
export const ROLEGROUP_ISACTIVECHANGE_SUCCESS =
  'ROLEGROUP_ISACTIVECHANGE_SUCCESS';
export const ROLEGROUP_ISACTIVECHANGE_ERROR = 'ROLEGROUP_ISACTIVECHANGE_ERROR';

/* ROLEGROUP */

/* USERROLE */
export const USERROLE_GET_LIST = 'USERROLE_GET_LIST';
export const USERROLE_GET_LIST_SUCCESS = 'USERROLE_GET_LIST_SUCCESS';
export const USERROLE_GET_LIST_ERROR = 'USERROLE_GET_LIST_ERROR';

export const USERROLE_SET_SELECTED = 'USERROLE_SET_SELECTED';
export const USERROLE_SET_SELECTED_SUCCESS = 'USERROLE_SET_SELECTED_SUCCESS';
export const USERROLE_SET_SELECTED_ERROR = 'USERROLE_SET_SELECTED_ERROR';
export const USERROLE_UPDATE_SELECTED = 'USERROLE_UPDATE_SELECTED';

export const USERROLE_GET_LIST_WITH_FILTER = 'USERROLE_GET_LIST_WITH_FILTER';
export const USERROLE_GET_LIST_WITH_ORDER = 'USERROLE_GET_LIST_WITH_ORDER';
export const USERROLE_GET_LIST_SEARCH = 'USERROLE_GET_LIST_SEARCH';

export const USERROLE_ADD_RECORD = 'USERROLE_ADD_RECORD';
export const USERROLE_ADD_RECORD_SUCCESS = 'USERROLE_ADD_RECORD_SUCCESS';
export const USERROLE_ADD_RECORD_ERROR = 'USERROLE_ADD_RECORD_ERROR';

export const USERROLE_UPDATE_RECORD = 'USERROLE_UPDATE_RECORD';
export const USERROLE_UPDATE_RECORD_SUCCESS = 'USERROLE_UPDATE_RECORD_SUCCESS';
export const USERROLE_UPDATE_RECORD_ERROR = 'USERROLE_UPDATE_RECORD_ERROR';

export const USERROLE_DELETE_RECORD = 'USERROLE_DELETE_RECORD';
export const USERROLE_DELETE_RECORD_SUCCESS = 'USERROLE_DELETE_RECORD_SUCCESS';
export const USERROLE_DELETE_RECORD_ERROR = 'USERROLE_DELETE_RECORD_ERROR';
export const USERROLE_RESET_STATUSES = 'USERROLE_RESET_STATUSES';

export const USERROLE_ISACTIVECHANGE = 'USERROLE_ISACTIVECHANGE';
export const USERROLE_ISACTIVECHANGE_SUCCESS =
  'USERROLE_ISACTIVECHANGE_SUCCESS';
export const USERROLE_ISACTIVECHANGE_ERROR = 'USERROLE_ISACTIVECHANGE_ERROR';

export const USERROLE_BYUSERID = 'USERROLE_BYUSERID';
export const USERROLE_BYUSERID_SUCCESS = 'USERROLE_BYUSERID_SUCCESS';
export const USERROLE_BYUSERID_ERROR = 'USERROLE_BYUSERID_ERROR';

export const USERROLE_UPDATE_SELECTED_RECORDS =
  'USERROLE_UPDATE_SELECTED_RECORDS';

export const USERROLE_UPSERT_RECORD = 'USERROLE_UPSERT_RECORD';
export const USERROLE_UPSERT_RECORD_SUCCESS = 'USERROLE_UPSERT_RECORD_SUCCESS';
export const USERROLE_UPSERT_RECORD_ERROR = 'USERROLE_UPSERT_RECORD_ERROR';

/* USERROLE */

/* USERPLAN */
export const USERPLAN_GET_LIST = 'USERPLAN_GET_LIST';
export const USERPLAN_GET_LIST_SUCCESS = 'USERPLAN_GET_LIST_SUCCESS';
export const USERPLAN_GET_LIST_ERROR = 'USERPLAN_GET_LIST_ERROR';

export const USERPLAN_SET_SELECTED = 'USERPLAN_SET_SELECTED';
export const USERPLAN_SET_SELECTED_SUCCESS = 'USERPLAN_SET_SELECTED_SUCCESS';
export const USERPLAN_SET_SELECTED_ERROR = 'USERPLAN_SET_SELECTED_ERROR';
export const USERPLAN_UPDATE_SELECTED = 'USERPLAN_UPDATE_SELECTED';

export const USERPLAN_GET_LIST_WITH_FILTER = 'USERPLAN_GET_LIST_WITH_FILTER';
export const USERPLAN_GET_LIST_WITH_ORDER = 'USERPLAN_GET_LIST_WITH_ORDER';
export const USERPLAN_GET_LIST_SEARCH = 'USERPLAN_GET_LIST_SEARCH';

export const USERPLAN_ADD_RECORD = 'USERPLAN_ADD_RECORD';
export const USERPLAN_ADD_RECORD_SUCCESS = 'USERPLAN_ADD_RECORD_SUCCESS';
export const USERPLAN_ADD_RECORD_ERROR = 'USERPLAN_ADD_RECORD_ERROR';

export const USERPLAN_UPDATE_RECORD = 'USERPLAN_UPDATE_RECORD';
export const USERPLAN_UPDATE_RECORD_SUCCESS = 'USERPLAN_UPDATE_RECORD_SUCCESS';
export const USERPLAN_UPDATE_RECORD_ERROR = 'USERPLAN_UPDATE_RECORD_ERROR';

export const USERPLAN_DELETE_RECORD = 'USERPLAN_DELETE_RECORD';
export const USERPLAN_DELETE_RECORD_SUCCESS = 'USERPLAN_DELETE_RECORD_SUCCESS';
export const USERPLAN_DELETE_RECORD_ERROR = 'USERPLAN_DELETE_RECORD_ERROR';
export const USERPLAN_RESET_STATUSES = 'USERPLAN_RESET_STATUSES';

export const USERPLAN_ISACTIVECHANGE = 'USERPLAN_ISACTIVECHANGE';
export const USERPLAN_ISACTIVECHANGE_SUCCESS =
  'USERPLAN_ISACTIVECHANGE_SUCCESS';
export const USERPLAN_ISACTIVECHANGE_ERROR = 'USERPLAN_ISACTIVECHANGE_ERROR';

export const USERPLAN_COUPONAPPLY = 'USERPLAN_COUPONAPPLY';
export const USERPLAN_COUPONAPPLY_SUCCESS = 'USERPLAN_COUPONAPPLY_SUCCESS';
export const USERPLAN_COUPONAPPLY_ERROR = 'USERPLAN_COUPONAPPLY_ERROR';

export const USERPLAN_GROUPED = 'USERPLAN_GROUPED';
export const USERPLAN_GROUPED_SUCCESS = 'USERPLAN_GROUPED_SUCCESS';
export const USERPLAN_GROUPED_ERROR = 'USERPLAN_GROUPED_ERROR';

export const USERPLAN_PLANANDUSER = 'USERPLAN_PLANANDUSER';
export const USERPLAN_PLANANDUSER_SUCCESS = 'USERPLAN_PLANANDUSER_SUCCESS';
export const USERPLAN_PLANANDUSER_ERROR = 'USERPLAN_PLANANDUSER_ERROR';

export const SELECTED_USERPLAN_SEND_EMAIL = 'SELECTED_USERPLAN_SEND_EMAIL';
export const SELECTED_USER_SEND_EMAIL = 'SELECTED_USER_SEND_EMAIL';
export const SELECTED_ALL_USER_SEND_EMAIL = 'SELECTED_ALL_USER_SEND_EMAIL';
export const SELECTED_MULTI_USER_SEND_EMAIL = 'SELECTED_MULTI_USER_SEND_EMAIL';

export const PACKAGE_GET_LIST = 'PACKAGE_GET_LIST';
export const PACKAGE_GET_LIST_SUCCESS = 'PACKAGE_GET_LIST_SUCCESS';
export const PACKAGE_GET_LIST_ERROR = 'PACKAGE_GET_LIST_ERROR';

export const PACKAGE_SET_SELECTED = 'PACKAGE_SET_SELECTED';
export const PACKAGE_SET_SELECTED_SUCCESS = 'PACKAGE_SET_SELECTED_SUCCESS';
export const PACKAGE_SET_SELECTED_ERROR = 'PACKAGE_SET_SELECTED_ERROR';

export const CHANGE_PACKAGE = 'CHANGE_PACKAGE';
export const CHANGE_PACKAGE_SUCCESS = 'CHANGE_PACKAGE_SUCCESS';
export const CHANGE_PACKAGE_ERROR = 'CHANGE_PACKAGE_ERROR';

/* USERPLAN */

/* USERPLANROLE */
export const USERPLANROLE_GET_LIST = 'USERPLANROLE_GET_LIST';
export const USERPLANROLE_GET_LIST_SUCCESS = 'USERPLANROLE_GET_LIST_SUCCESS';
export const USERPLANROLE_GET_LIST_ERROR = 'USERPLANROLE_GET_LIST_ERROR';

export const USERPLANROLE_SET_SELECTED = 'USERPLANROLE_SET_SELECTED';
export const USERPLANROLE_SET_SELECTED_SUCCESS =
  'USERPLANROLE_SET_SELECTED_SUCCESS';
export const USERPLANROLE_SET_SELECTED_ERROR =
  'USERPLANROLE_SET_SELECTED_ERROR';
export const USERPLANROLE_UPDATE_SELECTED = 'USERPLANROLE_UPDATE_SELECTED';

export const USERPLANROLE_GET_LIST_WITH_FILTER =
  'USERPLANROLE_GET_LIST_WITH_FILTER';
export const USERPLANROLE_GET_LIST_WITH_ORDER =
  'USERPLANROLE_GET_LIST_WITH_ORDER';
export const USERPLANROLE_GET_LIST_SEARCH = 'USERPLANROLE_GET_LIST_SEARCH';

export const USERPLANROLE_ADD_RECORD = 'USERPLANROLE_ADD_RECORD';
export const USERPLANROLE_ADD_RECORD_SUCCESS =
  'USERPLANROLE_ADD_RECORD_SUCCESS';
export const USERPLANROLE_ADD_RECORD_ERROR = 'USERPLANROLE_ADD_RECORD_ERROR';

export const USERPLANROLE_UPDATE_RECORD = 'USERPLANROLE_UPDATE_RECORD';
export const USERPLANROLE_UPDATE_RECORD_SUCCESS =
  'USERPLANROLE_UPDATE_RECORD_SUCCESS';
export const USERPLANROLE_UPDATE_RECORD_ERROR =
  'USERPLANROLE_UPDATE_RECORD_ERROR';

export const USERPLANROLE_DELETE_RECORD = 'USERPLANROLE_DELETE_RECORD';
export const USERPLANROLE_DELETE_RECORD_SUCCESS =
  'USERPLANROLE_DELETE_RECORD_SUCCESS';
export const USERPLANROLE_DELETE_RECORD_ERROR =
  'USERPLANROLE_DELETE_RECORD_ERROR';
export const USERPLANROLE_RESET_STATUSES = 'USERPLANROLE_RESET_STATUSES';

export const USERPLANROLE_ISACTIVECHANGE = 'USERPLANROLE_ISACTIVECHANGE';
export const USERPLANROLE_ISACTIVECHANGE_SUCCESS =
  'USERPLANROLE_ISACTIVECHANGE_SUCCESS';
export const USERPLANROLE_ISACTIVECHANGE_ERROR =
  'USERPLANROLE_ISACTIVECHANGE_ERROR';

export const USERPLANROLE_BYUSERID = 'USERPLANROLE_BYUSERID';
export const USERPLANROLE_BYUSERID_SUCCESS = 'USERPLANROLE_BYUSERID_SUCCESS';
export const USERPLANROLE_BYUSERID_ERROR = 'USERPLANROLE_BYUSERID_ERROR';

export const USERPLANROLE_UPDATE_SELECTED_RECORDS =
  'USERPLANROLE_UPDATE_SELECTED_RECORDS';

export const USERPLANROLE_UPSERT_RECORD = 'USERPLANROLE_UPSERT_RECORD';
export const USERPLANROLE_UPSERT_RECORD_SUCCESS =
  'USERPLANROLE_UPSERT_RECORD_SUCCESS';
export const USERPLANROLE_UPSERT_RECORD_ERROR =
  'USERPLANROLE_UPSERT_RECORD_ERROR';

/* USERPLANROLE */
/* TAG */
export const TAG_GET_LIST = 'TAG_GET_LIST';
export const TAG_GET_LIST_SUCCESS = 'TAG_GET_LIST_SUCCESS';
export const TAG_GET_LIST_ERROR = 'TAG_GET_LIST_ERROR';

export const TAG_SET_SELECTED = 'TAG_SET_SELECTED';
export const TAG_SET_SELECTED_SUCCESS = 'TAG_SET_SELECTED_SUCCESS';
export const TAG_SET_SELECTED_ERROR = 'TAG_SET_SELECTED_ERROR';
export const TAG_UPDATE_SELECTED = 'TAG_UPDATE_SELECTED';

export const TAG_GET_LIST_WITH_FILTER = 'TAG_GET_LIST_WITH_FILTER';
export const TAG_GET_LIST_WITH_ORDER = 'TAG_GET_LIST_WITH_ORDER';
export const TAG_GET_LIST_SEARCH = 'TAG_GET_LIST_SEARCH';

export const TAG_ADD_RECORD = 'TAG_ADD_RECORD';
export const TAG_ADD_RECORD_SUCCESS = 'TAG_ADD_RECORD_SUCCESS';
export const TAG_ADD_RECORD_ERROR = 'TAG_ADD_RECORD_ERROR';

export const TAG_UPDATE_RECORD = 'TAG_UPDATE_RECORD';
export const TAG_UPDATE_RECORD_SUCCESS = 'TAG_UPDATE_RECORD_SUCCESS';
export const TAG_UPDATE_RECORD_ERROR = 'TAG_UPDATE_RECORD_ERROR';

export const TAG_DELETE_RECORD = 'TAG_DELETE_RECORD';
export const TAG_DELETE_RECORD_SUCCESS = 'TAG_DELETE_RECORD_SUCCESS';
export const TAG_DELETE_RECORD_ERROR = 'TAG_DELETE_RECORD_ERROR';
export const TAG_RESET_STATUSES = 'TAG_RESET_STATUSES';

export const TAG_ISACTIVECHANGE = 'TAG_ISACTIVECHANGE';
export const TAG_ISACTIVECHANGE_SUCCESS = 'TAG_ISACTIVECHANGE_SUCCESS';
export const TAG_ISACTIVECHANGE_ERROR = 'TAG_ISACTIVECHANGE_ERROR';

/* TAG */
/* COUPON */
export const COUPON_GET_LIST = 'COUPON_GET_LIST';
export const COUPON_GET_LIST_SUCCESS = 'COUPON_GET_LIST_SUCCESS';
export const COUPON_GET_LIST_ERROR = 'COUPON_GET_LIST_ERROR';

export const COUPON_SET_SELECTED = 'COUPON_SET_SELECTED';
export const COUPON_SET_SELECTED_SUCCESS = 'COUPON_SET_SELECTED_SUCCESS';
export const COUPON_SET_SELECTED_ERROR = 'COUPON_SET_SELECTED_ERROR';
export const COUPON_UPDATE_SELECTED = 'COUPON_UPDATE_SELECTED';

export const COUPON_GET_LIST_WITH_FILTER = 'COUPON_GET_LIST_WITH_FILTER';
export const COUPON_GET_LIST_WITH_ORDER = 'COUPON_GET_LIST_WITH_ORDER';
export const COUPON_GET_LIST_SEARCH = 'COUPON_GET_LIST_SEARCH';

export const COUPON_ADD_RECORD = 'COUPON_ADD_RECORD';
export const COUPON_ADD_RECORD_SUCCESS = 'COUPON_ADD_RECORD_SUCCESS';
export const COUPON_ADD_RECORD_ERROR = 'COUPON_ADD_RECORD_ERROR';

export const COUPON_UPDATE_RECORD = 'COUPON_UPDATE_RECORD';
export const COUPON_UPDATE_RECORD_SUCCESS = 'COUPON_UPDATE_RECORD_SUCCESS';
export const COUPON_UPDATE_RECORD_ERROR = 'COUPON_UPDATE_RECORD_ERROR';

export const COUPON_DELETE_RECORD = 'COUPON_DELETE_RECORD';
export const COUPON_DELETE_RECORD_SUCCESS = 'COUPON_DELETE_RECORD_SUCCESS';
export const COUPON_DELETE_RECORD_ERROR = 'COUPON_DELETE_RECORD_ERROR';
export const COUPON_RESET_STATUSES = 'COUPON_RESET_STATUSES';

export const COUPON_ISACTIVECHANGE = 'COUPON_ISACTIVECHANGE';
export const COUPON_ISACTIVECHANGE_SUCCESS = 'COUPON_ISACTIVECHANGE_SUCCESS';
export const COUPON_ISACTIVECHANGE_ERROR = 'COUPON_ISACTIVECHANGE_ERROR';

/* COUPON */

/* MENUPREPARE */
export const MENUPREPARE_GET_LIST = 'MENUPREPARE_GET_LIST';
export const MENUPREPARE_GET_LIST_SUCCESS = 'MENUPREPARE_GET_LIST_SUCCESS';
export const MENUPREPARE_GET_LIST_ERROR = 'MENUPREPARE_GET_LIST_ERROR';

export const MENUPREPARE_SET_SELECTED = 'MENUPREPARE_SET_SELECTED';
export const MENUPREPARE_SET_SELECTED_SUCCESS =
  'MENUPREPARE_SET_SELECTED_SUCCESS';
export const MENUPREPARE_SET_SELECTED_ERROR = 'MENUPREPARE_SET_SELECTED_ERROR';
export const MENUPREPARE_UPDATE_SELECTED = 'MENUPREPARE_UPDATE_SELECTED';

export const MENUPREPARE_GET_LIST_WITH_FILTER =
  'MENUPREPARE_GET_LIST_WITH_FILTER';
export const MENUPREPARE_GET_LIST_WITH_ORDER =
  'MENUPREPARE_GET_LIST_WITH_ORDER';
export const MENUPREPARE_GET_LIST_SEARCH = 'MENUPREPARE_GET_LIST_SEARCH';

export const MENUPREPARE_ADD_RECORD = 'MENUPREPARE_ADD_RECORD';
export const MENUPREPARE_ADD_RECORD_SUCCESS = 'MENUPREPARE_ADD_RECORD_SUCCESS';
export const MENUPREPARE_ADD_RECORD_ERROR = 'MENUPREPARE_ADD_RECORD_ERROR';

export const MENUPREPARE_UPDATE_RECORD = 'MENUPREPARE_UPDATE_RECORD';
export const MENUPREPARE_UPDATE_RECORD_SUCCESS =
  'MENUPREPARE_UPDATE_RECORD_SUCCESS';
export const MENUPREPARE_UPDATE_RECORD_ERROR =
  'MENUPREPARE_UPDATE_RECORD_ERROR';

export const MENUPREPARE_DELETE_RECORD = 'MENUPREPARE_DELETE_RECORD';
export const MENUPREPARE_DELETE_RECORD_SUCCESS =
  'MENUPREPARE_DELETE_RECORD_SUCCESS';
export const MENUPREPARE_DELETE_RECORD_ERROR =
  'MENUPREPARE_DELETE_RECORD_ERROR';
export const MENUPREPARE_RESET_STATUSES = 'MENUPREPARE_RESET_STATUSES';

export const MENUPREPARE_ISACTIVECHANGE = 'MENUPREPARE_ISACTIVECHANGE';
export const MENUPREPARE_ISACTIVECHANGE_SUCCESS =
  'MENUPREPARE_ISACTIVECHANGE_SUCCESS';
export const MENUPREPARE_ISACTIVECHANGE_ERROR =
  'MENUPREPARE_ISACTIVECHANGE_ERROR';

export const MENUPREPARE_EXPORT = 'MENUPREPARE_EXPORT';
export const MENUPREPARE_EXPORT_SUCCESS = 'MENUPREPARE_EXPORT_SUCCESS';
export const MENUPREPARE_EXPORT_ERROR = 'MENUPREPARE_EXPORT_ERROR';

export const MENUPREPARE_DAY_UPDATE = 'MENUPREPARE_DAY_UPDATE';
export const MENUPREPARE_DAY_UPDATE_SUCCESS = 'MENUPREPARE_DAY_UPDATE_SUCCESS';
export const MENUPREPARE_DAY_UPDATE_ERROR = 'MENUPREPARE_DAY_UPDATE_ERROR';

/* MENUPREPARE */

/* EMAIL */
export const EMAIL_GET_LIST = 'EMAIL_GET_LIST';
export const EMAIL_GET_LIST_SUCCESS = 'EMAIL_GET_LIST_SUCCESS';
export const EMAIL_GET_LIST_ERROR = 'EMAIL_GET_LIST_ERROR';

export const EMAIL_SET_SELECTED = 'EMAIL_SET_SELECTED';
export const EMAIL_SET_SELECTED_SUCCESS = 'EMAIL_SET_SELECTED_SUCCESS';
export const EMAIL_SET_SELECTED_ERROR = 'EMAIL_SET_SELECTED_ERROR';
export const EMAIL_UPDATE_SELECTED = 'EMAIL_UPDATE_SELECTED';

export const EMAIL_GET_LIST_WITH_FILTER = 'EMAIL_GET_LIST_WITH_FILTER';
export const EMAIL_GET_LIST_WITH_ORDER = 'EMAIL_GET_LIST_WITH_ORDER';
export const EMAIL_GET_LIST_SEARCH = 'EMAIL_GET_LIST_SEARCH';

export const EMAIL_ADD_RECORD = 'EMAIL_ADD_RECORD';
export const EMAIL_ADD_RECORD_SUCCESS = 'EMAIL_ADD_RECORD_SUCCESS';
export const EMAIL_ADD_RECORD_ERROR = 'EMAIL_ADD_RECORD_ERROR';

export const EMAIL_UPDATE_RECORD = 'EMAIL_UPDATE_RECORD';
export const EMAIL_UPDATE_RECORD_SUCCESS = 'EMAIL_UPDATE_RECORD_SUCCESS';
export const EMAIL_UPDATE_RECORD_ERROR = 'EMAIL_UPDATE_RECORD_ERROR';

export const EMAIL_DELETE_RECORD = 'EMAIL_DELETE_RECORD';
export const EMAIL_DELETE_RECORD_SUCCESS = 'EMAIL_DELETE_RECORD_SUCCESS';
export const EMAIL_DELETE_RECORD_ERROR = 'EMAIL_DELETE_RECORD_ERROR';
export const EMAIL_RESET_STATUSES = 'EMAIL_RESET_STATUSES';

export const EMAIL_ISACTIVECHANGE = 'EMAIL_ISACTIVECHANGE';
export const EMAIL_ISACTIVECHANGE_SUCCESS = 'EMAIL_ISACTIVECHANGE_SUCCESS';
export const EMAIL_ISACTIVECHANGE_ERROR = 'EMAIL_ISACTIVECHANGE_ERROR';

/* EMAIL */

/* LACKCOMPLETION */
export const LACKCOMPLETION_GET_LIST = 'LACKCOMPLETION_GET_LIST';
export const LACKCOMPLETION_GET_LIST_SUCCESS =
  'LACKCOMPLETION_GET_LIST_SUCCESS';
export const LACKCOMPLETION_GET_LIST_ERROR = 'LACKCOMPLETION_GET_LIST_ERROR';

export const LACKCOMPLETION_SET_SELECTED = 'LACKCOMPLETION_SET_SELECTED';
export const LACKCOMPLETION_SET_SELECTED_SUCCESS =
  'LACKCOMPLETION_SET_SELECTED_SUCCESS';
export const LACKCOMPLETION_SET_SELECTED_ERROR =
  'LACKCOMPLETION_SET_SELECTED_ERROR';
export const LACKCOMPLETION_UPDATE_SELECTED = 'LACKCOMPLETION_UPDATE_SELECTED';

export const LACKCOMPLETION_GET_LIST_WITH_FILTER =
  'LACKCOMPLETION_GET_LIST_WITH_FILTER';
export const LACKCOMPLETION_GET_LIST_WITH_ORDER =
  'LACKCOMPLETION_GET_LIST_WITH_ORDER';
export const LACKCOMPLETION_GET_LIST_SEARCH = 'LACKCOMPLETION_GET_LIST_SEARCH';

export const LACKCOMPLETION_ADD_RECORD = 'LACKCOMPLETION_ADD_RECORD';
export const LACKCOMPLETION_ADD_RECORD_SUCCESS =
  'LACKCOMPLETION_ADD_RECORD_SUCCESS';
export const LACKCOMPLETION_ADD_RECORD_ERROR =
  'LACKCOMPLETION_ADD_RECORD_ERROR';

export const LACKCOMPLETION_UPDATE_RECORD = 'LACKCOMPLETION_UPDATE_RECORD';
export const LACKCOMPLETION_UPDATE_RECORD_SUCCESS =
  'LACKCOMPLETION_UPDATE_RECORD_SUCCESS';
export const LACKCOMPLETION_UPDATE_RECORD_ERROR =
  'LACKCOMPLETION_UPDATE_RECORD_ERROR';

export const LACKCOMPLETION_DELETE_RECORD = 'LACKCOMPLETION_DELETE_RECORD';
export const LACKCOMPLETION_DELETE_RECORD_SUCCESS =
  'LACKCOMPLETION_DELETE_RECORD_SUCCESS';
export const LACKCOMPLETION_DELETE_RECORD_ERROR =
  'LACKCOMPLETION_DELETE_RECORD_ERROR';
export const LACKCOMPLETION_RESET_STATUSES = 'LACKCOMPLETION_RESET_STATUSES';

export const LACKCOMPLETION_ISACTIVECHANGE = 'LACKCOMPLETION_ISACTIVECHANGE';
export const LACKCOMPLETION_ISACTIVECHANGE_SUCCESS =
  'LACKCOMPLETION_ISACTIVECHANGE_SUCCESS';
export const LACKCOMPLETION_ISACTIVECHANGE_ERROR =
  'LACKCOMPLETION_ISACTIVECHANGE_ERROR';

export const LACKCOMPLETION_BYNUTRIENTID = 'LACKCOMPLETION_BYNUTRIENTID';
export const LACKCOMPLETION_BYNUTRIENTID_SUCCESS =
  'LACKCOMPLETION_BYNUTRIENTID_SUCCESS';
export const LACKCOMPLETION_BYNUTRIENTID_ERROR =
  'LACKCOMPLETION_BYNUTRIENTID_ERROR';

export const LACKCOMPLETION_UPSERT_RECORD = 'LACKCOMPLETION_UPSERT_RECORD';
export const LACKCOMPLETION_UPSERT_RECORD_SUCCESS =
  'LACKCOMPLETION_UPSERT_RECORD_SUCCESS';
export const LACKCOMPLETION_UPSERT_RECORD_ERROR =
  'LACKCOMPLETION_UPSERT_RECORD_ERROR';

/* LACKCOMPLETION */

/* NUTRIENTCOMMENT */
export const NUTRIENTCOMMENT_GET_LIST = 'NUTRIENTCOMMENT_GET_LIST';
export const NUTRIENTCOMMENT_GET_LIST_SUCCESS =
  'NUTRIENTCOMMENT_GET_LIST_SUCCESS';
export const NUTRIENTCOMMENT_GET_LIST_ERROR = 'NUTRIENTCOMMENT_GET_LIST_ERROR';

export const NUTRIENTCOMMENT_SET_SELECTED = 'NUTRIENTCOMMENT_SET_SELECTED';
export const NUTRIENTCOMMENT_SET_SELECTED_SUCCESS =
  'NUTRIENTCOMMENT_SET_SELECTED_SUCCESS';
export const NUTRIENTCOMMENT_SET_SELECTED_ERROR =
  'NUTRIENTCOMMENT_SET_SELECTED_ERROR';
export const NUTRIENTCOMMENT_UPDATE_SELECTED =
  'NUTRIENTCOMMENT_UPDATE_SELECTED';

export const NUTRIENTCOMMENT_GET_LIST_WITH_FILTER =
  'NUTRIENTCOMMENT_GET_LIST_WITH_FILTER';
export const NUTRIENTCOMMENT_GET_LIST_WITH_ORDER =
  'NUTRIENTCOMMENT_GET_LIST_WITH_ORDER';
export const NUTRIENTCOMMENT_GET_LIST_SEARCH =
  'NUTRIENTCOMMENT_GET_LIST_SEARCH';

export const NUTRIENTCOMMENT_ADD_RECORD = 'NUTRIENTCOMMENT_ADD_RECORD';
export const NUTRIENTCOMMENT_ADD_RECORD_SUCCESS =
  'NUTRIENTCOMMENT_ADD_RECORD_SUCCESS';
export const NUTRIENTCOMMENT_ADD_RECORD_ERROR =
  'NUTRIENTCOMMENT_ADD_RECORD_ERROR';

export const NUTRIENTCOMMENT_UPDATE_RECORD = 'NUTRIENTCOMMENT_UPDATE_RECORD';
export const NUTRIENTCOMMENT_UPDATE_RECORD_SUCCESS =
  'NUTRIENTCOMMENT_UPDATE_RECORD_SUCCESS';
export const NUTRIENTCOMMENT_UPDATE_RECORD_ERROR =
  'NUTRIENTCOMMENT_UPDATE_RECORD_ERROR';

export const NUTRIENTCOMMENT_DELETE_RECORD = 'NUTRIENTCOMMENT_DELETE_RECORD';
export const NUTRIENTCOMMENT_DELETE_RECORD_SUCCESS =
  'NUTRIENTCOMMENT_DELETE_RECORD_SUCCESS';
export const NUTRIENTCOMMENT_DELETE_RECORD_ERROR =
  'NUTRIENTCOMMENT_DELETE_RECORD_ERROR';
export const NUTRIENTCOMMENT_RESET_STATUSES = 'NUTRIENTCOMMENT_RESET_STATUSES';

export const NUTRIENTCOMMENT_ISACTIVECHANGE = 'NUTRIENTCOMMENT_ISACTIVECHANGE';
export const NUTRIENTCOMMENT_ISACTIVECHANGE_SUCCESS =
  'NUTRIENTCOMMENT_ISACTIVECHANGE_SUCCESS';
export const NUTRIENTCOMMENT_ISACTIVECHANGE_ERROR =
  'NUTRIENTCOMMENT_ISACTIVECHANGE_ERROR';

export const NUTRIENTCOMMENT_BYFILTER = 'NUTRIENTCOMMENT_BYFILTER';
export const NUTRIENTCOMMENT_BYFILTER_SUCCESS =
  'NUTRIENTCOMMENT_BYFILTER_SUCCESS';
export const NUTRIENTCOMMENT_BYFILTER_ERROR = 'NUTRIENTCOMMENT_BYFILTER_ERROR';

export const NUTRIENTCOMMENT_UPSERT_RECORD = 'NUTRIENTCOMMENT_UPSERT_RECORD';
export const NUTRIENTCOMMENT_UPSERT_RECORD_SUCCESS =
  'NUTRIENTCOMMENT_UPSERT_RECORD_SUCCESS';
export const NUTRIENTCOMMENT_UPSERT_RECORD_ERROR =
  'NUTRIENTCOMMENT_UPSERT_RECORD_ERROR';

/* NUTRIENTCOMMENT */

/* SAMPLEDESCRIPTION */

export const SAMPLEDESCRIPTION_GET_LIST = 'SAMPLEDESCRIPTION_GET_LIST';
export const SAMPLEDESCRIPTION_GET_LIST_SUCCESS = 'SAMPLEDESCRIPTION_GET_LIST_SUCCESS';
export const SAMPLEDESCRIPTION_GET_LIST_ERROR = 'SAMPLEDESCRIPTION_GET_LIST_ERROR';

export const SAMPLEDESCRIPTION_SET_SELECTED = 'SAMPLEDESCRIPTION_SET_SELECTED';
export const SAMPLEDESCRIPTION_SET_SELECTED_SUCCESS =
  'SAMPLEDESCRIPTION_SET_SELECTED_SUCCESS';
export const SAMPLEDESCRIPTION_SET_SELECTED_ERROR =
  'SAMPLEDESCRIPTION_SET_SELECTED_ERROR';
export const SAMPLEDESCRIPTION_UPDATE_SELECTED = 'SAMPLEDESCRIPTION_UPDATE_SELECTED';

export const SAMPLEDESCRIPTION_GET_LIST_WITH_FILTER =
  'SAMPLEDESCRIPTION_GET_LIST_WITH_FILTER';
export const SAMPLEDESCRIPTION_GET_LIST_WITH_ORDER =
  'SAMPLEDESCRIPTION_GET_LIST_WITH_ORDER';
export const SAMPLEDESCRIPTION_GET_LIST_SEARCH = 'SAMPLEDESCRIPTION_GET_LIST_SEARCH';

export const SAMPLEDESCRIPTION_ADD_RECORD = 'SAMPLEDESCRIPTION_ADD_RECORD';
export const SAMPLEDESCRIPTION_ADD_RECORD_SUCCESS =
  'SAMPLEDESCRIPTION_ADD_RECORD_SUCCESS';
export const SAMPLEDESCRIPTION_ADD_RECORD_ERROR = 'SAMPLEDESCRIPTION_ADD_RECORD_ERROR';

export const SAMPLEDESCRIPTION_UPDATE_RECORD = 'SAMPLEDESCRIPTION_UPDATE_RECORD';
export const SAMPLEDESCRIPTION_UPDATE_RECORD_SUCCESS =
  'SAMPLEDESCRIPTION_UPDATE_RECORD_SUCCESS';
export const SAMPLEDESCRIPTION_UPDATE_RECORD_ERROR =
  'SAMPLEDESCRIPTION_UPDATE_RECORD_ERROR';

export const SAMPLEDESCRIPTION_DELETE_RECORD = 'SAMPLEDESCRIPTION_DELETE_RECORD';
export const SAMPLEDESCRIPTION_DELETE_RECORD_SUCCESS =
  'SAMPLEDESCRIPTION_DELETE_RECORD_SUCCESS';
export const SAMPLEDESCRIPTION_DELETE_RECORD_ERROR =
  'SAMPLEDESCRIPTION_DELETE_RECORD_ERROR';
export const SAMPLEDESCRIPTION_RESET_STATUSES = 'SAMPLEDESCRIPTION_RESET_STATUSES';

export const SAMPLEDESCRIPTION_GET_GROUPED_LIST = 'SAMPLEDESCRIPTION_GET_GROUPED_LIST';
export const SAMPLEDESCRIPTION_GET_GROUPED_LIST_SUCCESS =
  'SAMPLEDESCRIPTION_GET_GROUPED_LIST_SUCCESS';
export const SAMPLEDESCRIPTION_GET_GROUPED_LIST_ERROR =
  'SAMPLEDESCRIPTION_GET_GROUPED_LIST_ERROR';

export const SAMPLEDESCRIPTION_ISACTIVECHANGE = 'SAMPLEDESCRIPTION_ISACTIVECHANGE';
export const SAMPLEDESCRIPTION_ISACTIVECHANGE_SUCCESS =
  'SAMPLEDESCRIPTION_ISACTIVECHANGE_SUCCESS';
export const SAMPLEDESCRIPTION_ISACTIVECHANGE_ERROR =
  'SAMPLEDESCRIPTION_ISACTIVECHANGE_ERROR';

/* SAMPLE DESCRIPTION * /

/* ROLE MANAGEMENT */

export const LOGINHISTORY_GET_LIST = 'LOGINHISTORY_GET_LIST';
export const LOGINHISTORY_GET_LIST_SUCCESS = 'LOGINHISTORY_GET_LIST_SUCCESS';
export const LOGINHISTORY_GET_LIST_ERROR = 'LOGINHISTORY_GET_LIST_ERROR';

export const LOGINHISTORY_GET_LIST_WITH_FILTER =
  'LOGINHISTORY_GET_LIST_WITH_FILTER';
export const LOGINHISTORY_GET_LIST_WITH_ORDER =
  'LOGINHISTORY_GET_LIST_WITH_ORDER';
export const LOGINHISTORY_GET_LIST_SEARCH = 'LOGINHISTORY_GET_LIST_SEARCH';

export const SELECTED_USERPLAN_SEND_NOTIFICATION =
  'SELECTED_USERPLAN_SEND_NOTIFICATION';
export const SELECTED_USER_SEND_NOTIFICATION =
  'SELECTED_USER_SEND_NOTIFICATION';
export const SELECTED_ALL_USER_SEND_NOTIFICATION =
  'SELECTED_ALL_USER_SEND_NOTIFICATION';
export const SELECTED_MULTI_USER_SEND_NOTIFICATION =
  'SELECTED_MULTI_USER_SEND_NOTIFICATION';

export const NOTIFICATION_GET_LIST = 'NOTIFICATION_GET_LIST';
export const NOTIFICATION_GET_LIST_SUCCESS = 'NOTIFICATION_GET_LIST_SUCCESS';
export const NOTIFICATION_GET_LIST_ERROR = 'NOTIFICATION_GET_LIST_ERROR';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_ERROR = 'UPDATE_NOTIFICATION_ERROR';
export const  LOGINHISTORY_RESET_STATUSES = ' LOGINHISTORY_RESET_STATUSES';

export const ALLSTORERESET = 'ALLSTORERESET';
export const resetStore = () => {
  return {
    type: ALLSTORERESET,
  };
};

export * from './menu/actions';
export * from './settings/actions';
export * from './administration/food-group/actions';
export * from './administration/food/actions';
export * from './administration/subfood-group/actions';
export * from './administration/nutrient/actions';
export * from './administration/reference-source/actions';
export * from './administration/reference-range/actions';
export * from './administration/meal/actions';
export * from './administration/meal-group/actions';
export * from './administration/recipe/actions';
export * from './administration/portion/actions';
export * from './administration/unit/actions';
export * from './administration/nutrient-group/actions';
export * from './administration/repast/actions';
export * from './administration/data-source/actions';
export * from './applications/coachee/actions';
export * from './applications/tag/actions';

export * from './administration/food-portion/actions';
export * from './administration/portion-picture/actions';
export * from './administration/physical-activity/actions';
export * from './auth/actions';
export * from './applications/plan-repast/actions';
export * from './applications/meal-nutrient-total/actions';
export * from './administration/coupon/actions';
export * from './administration/loginHistory/actions';
export * from './administration/email/actions';
export * from './administration/notification/actions';
export * from './administration/lackcompletion/actions';
export * from './administration/nutrientcomment/actions';
/* #APPLICATIONS */

export * from './applications/planning/actions';
export * from './applications/planrepast-food/actions';
export * from './applications/planrepastfood-portion/actions';
export * from './applications/activity-type/actions';
export * from './applications/body-mass-index-range/actions';
export * from './applications/formula/actions';
export * from './applications/antropometry/actions';
export * from './applications/menuprepare/actions';
export * from './applications/sampledescription/actions';

/* PROFİLE */

export * from './profile/user/actions';
export * from './profile/userpreference/actions';

/* ROLE MANAGEMENT */
export * from './rolemanagement/role/actions';
export * from './rolemanagement/rolegroup/actions';
export * from './rolemanagement/userplan/actions';
export * from './rolemanagement/userplanrole/actions';
export * from './rolemanagement/userrole/actions';
