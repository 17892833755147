import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  debounce,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  MEALNUTRIENTTOTALS_ADD_RECORD,
  MEALNUTRIENTTOTALS_UPDATE_RECORD,
  MEALNUTRIENTTOTALS_GET_LIST,
  MEALNUTRIENTTOTALS_SET_SELECTED,
  MEALNUTRIENTTOTALS_DELETE_RECORD,
  deleteMealNutrientTotalsRecordSuccess,
  deleteMealNutrientTotalsRecordError,
} from '../../actions';

import {
  getMealNutrientTotalsListSuccess,
  getMealNutrientTotalsListError,
  setMealNutrientTotalsSelectedRecordSuccess,
  setMealNutrientTotalsSelectedRecordError,
  addMealNutrientTotalsRecordSuccess,
  addMealNutrientTotalsRecordError,
  updateMealNutrientTotalsRecordSuccess,
  updateMealNutrientTotalsRecordError,
} from './actions';

//---------------------------------------------------------------------
const getMealNutrientTotalsListRequest = (pageDetail) => {
  // eslint-disable-next-line prefer-const
  let searchQuery = {
    keyword: pageDetail.searchParams.keyword,
    options: {
      foodGroupId: pageDetail.searchParams.foodGroupId,
      dataSourceId: pageDetail.searchParams.dataSourceId,
    },
  };

  return pageDetail
    ? axios
        .post(
          `${process.env.REACT_APP_API_URL}/MealNutrientTotals/search?ps=${pageDetail.pageSize}&pn=${pageDetail.pageNumber}`,
          searchQuery
        )
        .then((response) => response.data)
    : axios
        .get(`${process.env.REACT_APP_API_URL}/MealNutrientTotals/search`)
        .then((response) => response.data);
};

function* getMealNutrientTotalsListItems({ payload }) {
  try {
    // eslint-disable-next-line prefer-const
    let pageSize = yield select((state) => state.settings.pageRecordCount);
    // eslint-disable-next-line no-param-reassign
    payload.pageSize = pageSize;
    const response = yield call(getMealNutrientTotalsListRequest, payload);
    yield put(getMealNutrientTotalsListSuccess(response));
  } catch (error) {
    yield put(getMealNutrientTotalsListError(error));
  }
}
//----------------------------------------------------------------------------------
const setMealNutrientTotalsSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/MealNutrientTotals/${id}`)
    .then((response) => response.data.singleData);

function* setMealNutrientTotalsSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setMealNutrientTotalsSelectedRecordRequest,
      payload
    );
    yield put(setMealNutrientTotalsSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setMealNutrientTotalsSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addMealNutrientTotalsRecordRequest = (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/MealNutrientTotals`, record)
    .then((response) => response.data);

function* addMealNutrientTotalsRecords({ payload }) {
  try {
    yield call(addMealNutrientTotalsRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addMealNutrientTotalsRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getMealNutrientTotalsListItems);
  } catch (error) {
    yield put(
      addMealNutrientTotalsRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateMealNutrientTotalsRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/MealNutrientTotals`, record)
    .then((response) => response.data);

function* updateMealNutrientTotalsRecords({ payload }) {
  try {
    yield call(updateMealNutrientTotalsRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateMealNutrientTotalsRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getMealNutrientTotalsListItems);
  } catch (error) {
    yield put(
      updateMealNutrientTotalsRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteMealNutrientTotalsRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/MealNutrientTotals/${recordId}`)
    .then((response) => response.data);

function* deleteMealNutrientTotalsRecords({ payload }) {
  try {
    yield call(deleteMealNutrientTotalsRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteMealNutrientTotalsRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getMealNutrientTotalsListItems);
  } catch (error) {
    yield put(
      deleteMealNutrientTotalsRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(
    800,
    MEALNUTRIENTTOTALS_GET_LIST,
    getMealNutrientTotalsListItems
  );
}

export function* watchSetSelected() {
  yield takeEvery(
    MEALNUTRIENTTOTALS_SET_SELECTED,
    setMealNutrientTotalsSelectedRecord
  );
}
export function* wathcAddItem() {
  yield takeEvery(MEALNUTRIENTTOTALS_ADD_RECORD, addMealNutrientTotalsRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(
    MEALNUTRIENTTOTALS_UPDATE_RECORD,
    updateMealNutrientTotalsRecords
  );
}

export function* wathcDeleteItem() {
  yield takeEvery(
    MEALNUTRIENTTOTALS_DELETE_RECORD,
    deleteMealNutrientTotalsRecords
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
