import {
  USERROLE_GET_LIST,
  USERROLE_GET_LIST_SUCCESS,
  USERROLE_GET_LIST_ERROR,
  USERROLE_ADD_RECORD,
  USERROLE_ADD_RECORD_ERROR,
  USERROLE_ADD_RECORD_SUCCESS,
  USERROLE_DELETE_RECORD,
  USERROLE_DELETE_RECORD_ERROR,
  USERROLE_DELETE_RECORD_SUCCESS,
  USERROLE_RESET_STATUSES,
  USERROLE_SET_SELECTED,
  USERROLE_SET_SELECTED_ERROR,
  USERROLE_SET_SELECTED_SUCCESS,
  USERROLE_UPDATE_RECORD,
  USERROLE_UPDATE_RECORD_ERROR,
  USERROLE_UPDATE_RECORD_SUCCESS,
  USERROLE_UPDATE_SELECTED,
  USERROLE_BYUSERID,
  USERROLE_BYUSERID_SUCCESS,
  USERROLE_BYUSERID_ERROR,
  USERROLE_UPDATE_SELECTED_RECORDS,
  USERROLE_UPSERT_RECORD,
  USERROLE_UPSERT_RECORD_SUCCESS,
  USERROLE_UPSERT_RECORD_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USERROLE_GET_LIST:
      return { ...state, loading: true };
    case USERROLE_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRecord: null,
        records: action.payload,
      };
    case USERROLE_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case USERROLE_SET_SELECTED:
      return { ...state, modaLoading: true };
    case USERROLE_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case USERROLE_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case USERROLE_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case USERROLE_ADD_RECORD:
      return { ...state, loading: false };
    case USERROLE_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USERROLE_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case USERROLE_UPDATE_RECORD:
      return { ...state, loading: false };
    case USERROLE_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USERROLE_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USERROLE_DELETE_RECORD:
      return { ...state, loading: false };
    case USERROLE_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USERROLE_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USERROLE_BYUSERID:
      return { ...state, loading: true };
    case USERROLE_BYUSERID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRecord: null,
        records: action.payload,
      };
    case USERROLE_BYUSERID_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case USERROLE_UPDATE_SELECTED_RECORDS:
      return { ...state, records: action.payload };
    //------------------------------------------------------------------------------
    case USERROLE_UPSERT_RECORD:
      return { ...state, loading: true };
    case USERROLE_UPSERT_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case USERROLE_UPSERT_RECORD_ERROR:
      return { ...state, loading: false, error: action.payload };
    //------------------------------------------------------------------------------
    case USERROLE_RESET_STATUSES:
      return { ...state, success: null, error: null };
    default:
      return { ...state };
  }
};
