/* eslint-disable no-var */
import moment from 'moment';
import 'moment/locale/tr';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
    moment.locale(language);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
    moment.locale(locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('aerotive_user') != null
        ? JSON.parse(localStorage.getItem('aerotive_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('aerotive_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('aerotive_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getCurrentUserPreference = () => {
  let userPreference = null;
  try {
    userPreference =
      localStorage.getItem('user_preferences') != null
        ? JSON.parse(localStorage.getItem('user_preferences'))
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getCurrentUserPreference -> error',
      error
    );
    userPreference = null;
  }
  return userPreference;
};

export const setCurrentUserPreference = (userPreference) => {
  try {
    if (userPreference) {
      localStorage.setItem('user_preferences', JSON.stringify(userPreference));
    } else {
      localStorage.removeItem('user_preferences');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentUserPreference -> error',
      error
    );
  }
};
export const getToken = () => {
  let userPreference = null;
  try {
    userPreference =
      localStorage.getItem('token') != null
        ? JSON.parse(localStorage.getItem('token'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getToken -> error', error);
    userPreference = null;
  }
  return userPreference;
};

export const setToken = (token) => {
  try {
    if (token) {
      localStorage.setItem('token', JSON.stringify(token));
    } else {
      localStorage.removeItem('token');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setToken -> error', error);
  }
};
export const isGuid = (value) => {
  var regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  var match = regex.exec(value);
  return match != null;
};

export const filterBuilder = (filteringOptions) => {
  const filter = [];
  let isFilter = false;
  if (filteringOptions.filter) {
    filter.push(`filter=${filteringOptions.filter}`);
    isFilter = true;
  }
  if (filteringOptions.pageSize) {
    filter.push(`ps=${filteringOptions.pageSize}`);
  }
  if (filteringOptions.pageNumber) {
    filter.push(`pn=${filteringOptions.pageNumber}`);
  }
  if (filteringOptions.includes) {
    filteringOptions.includes.forEach((element) => {
      filter.push(`includes=${element}`);
    });
    isFilter = true;
  }

  if (filteringOptions.sort) {
    filter.push(`sort=${filteringOptions.sort.value}`);
    isFilter = true;
  }

  if (!isFilter) {
    const result = filter.join('&');
    return `?${result}`;
  }

  return `/filter?${filter.join('&')}`;
};

export const getPlanEnergyValue = () => {
  let energyValue = null;
  try {
    energyValue =
      localStorage.getItem('energyValue') != null
        ? localStorage.getItem('energyValue') === 'true'
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getPlanEnergyValue -> error',
      error
    );
    energyValue = null;
  }
  return energyValue;
};

export const setPlanEnergyValue = (energyValue) => {
  try {
    localStorage.setItem('energyValue', energyValue);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setPlanEnergyValue -> error',
      error
    );
  }
};

export const getPlanComparison = () => {
  let planComparison = null;
  try {
    planComparison =
      localStorage.getItem('planComparison') != null
        ? JSON.parse(localStorage.getItem('planComparison'))
        : {
            comparison: true,
            comparisonNutrients: [
              { name: 'Protein', value: 12 },
              { name: 'Yağ', value: 30 },
              { name: 'CHO', value: 58 },
            ],
          };
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getPlanComparison -> error',
      error
    );
    planComparison = null;
  }
  return planComparison;
};

export const setPlanComparison = (user) => {
  try {
    if (user) {
      localStorage.setItem('planComparison', JSON.stringify(user));
    } else {
      localStorage.removeItem('planComparison');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setPlanComparison -> error',
      error
    );
  }
};
