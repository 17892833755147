/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable no-else-return */
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  PORTIONPICTURE_ADD_RECORD,
  PORTIONPICTURE_UPDATE_RECORD,
  PORTIONPICTURE_GET_LIST,
  PORTIONPICTURE_SET_SELECTED,
  PORTIONPICTURE_DELETE_RECORD,
  deletePortionPictureRecordSuccess,
  deletePortionPictureRecordError,
} from '../../actions';

import {
  getPortionPictureListSuccess,
  getPortionPictureListError,
  setPortionPictureSelectedRecordSuccess,
  setPortionPictureSelectedRecordError,
  addPortionPictureRecordSuccess,
  addPortionPictureRecordError,
  updatePortionPictureRecordSuccess,
  updatePortionPictureRecordError,
} from './actions';

//---------------------------------------------------------------------
const getPortionPictureListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/portionPicture${url}`)
    .then((response) => response.data);
};

function* getPortionPictureListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getPortionPictureListRequest,
        filterBuilder(payload)
      );
      yield put(getPortionPictureListSuccess(response));
    } else {
      const response = yield call(getPortionPictureListRequest);
      yield put(getPortionPictureListSuccess(response));
    }
  } catch (error) {
    yield put(getPortionPictureListError(error));
  }
}
//----------------------------------------------------------------------------------
const setPortionPictureSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/portionPicture/${id}`)
    .then((response) => response.data.singleData);

function* setPortionPictureSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setPortionPictureSelectedRecordRequest,
      payload
    );
    yield put(setPortionPictureSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setPortionPictureSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addPortionPictureRecordRequest = (record) => {
  let formData = new FormData();
  formData.set('foodId', record.foodId);
  formData.set('unitId', record.unitId);
  formData.set('value', record.value);
  formData.set('picturePath', record.picturePath);
  formData.set('pictureFileContentType', record.pictureFileContentType);
  formData.set('pictureFile', record.pictureFile, record.pictureFile.name);
  formData.set('portionId', record.portionId);
  return axios.post(
    `${process.env.REACT_APP_API_URL}/portionPicture`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    }
  );
};

function* addPortionPictureRecords({ payload }) {
  try {
    yield call(addPortionPictureRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addPortionPictureRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getPortionPictureListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addPortionPictureRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updatePortionPictureRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/portionPicture`, record)
    .then((response) => response.data);

function* updatePortionPictureRecords({ payload }) {
  try {
    yield call(updatePortionPictureRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updatePortionPictureRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getPortionPictureListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updatePortionPictureRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const deletePortionPictureRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/portionPicture/${recordId}`)
    .then((response) => response.data);

function* deletePortionPictureRecords({ payload }) {
  try {
    yield call(deletePortionPictureRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deletePortionPictureRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getPortionPictureListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deletePortionPictureRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(PORTIONPICTURE_GET_LIST, getPortionPictureListItems);
}

export function* watchSetSelected() {
  yield takeEvery(PORTIONPICTURE_SET_SELECTED, setPortionPictureSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(PORTIONPICTURE_ADD_RECORD, addPortionPictureRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(PORTIONPICTURE_UPDATE_RECORD, updatePortionPictureRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(PORTIONPICTURE_DELETE_RECORD, deletePortionPictureRecords);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
