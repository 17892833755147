const NutrientValueCalculate = (
  nutrientName,
  nutrientValue,
  energyValueType = true,
  fixedNumber = 0
) => {
  return (
    nutrientValue /
    // eslint-disable-next-line no-nested-ternary
    (nutrientName === 'Enerji' ? (energyValueType === true ? 4.184 : 1) : 1)
  ).toFixed(fixedNumber);
};

export default NutrientValueCalculate;
