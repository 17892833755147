// eslint-disable-next-line import/no-cycle
import {
  PLANREPAST_GET_LIST,
  PLANREPAST_GET_LIST_SUCCESS,
  PLANREPAST_GET_LIST_ERROR,
  PLANREPAST_SET_SELECTED,
  PLANREPAST_UPDATE_SELECTED,
  PLANREPAST_SET_SELECTED_SUCCESS,
  PLANREPAST_SET_SELECTED_ERROR,
  PLANREPAST_ADD_RECORD_SUCCESS,
  PLANREPAST_ADD_RECORD_ERROR,
  PLANREPAST_ADD_RECORD,
  PLANREPAST_UPDATE_RECORD_SUCCESS,
  PLANREPAST_UPDATE_RECORD_ERROR,
  PLANREPAST_UPDATE_RECORD,
  PLANREPAST_DELETE_RECORD,
  PLANREPAST_DELETE_RECORD_SUCCESS,
  PLANREPAST_DELETE_RECORD_ERROR,
  PLANREPAST_RESET_STATUSES,
  PLANREPAST_UPDATE_BATCHRECORD,
  PLANREPAST_UPDATE_BATCHRECORD_SUCCESS,
  PLANREPAST_UPDATE_BATCHRECORD_ERROR,
  PLANREPAST_PLANREPASTADDFOOD,
  PLANREPAST_PLANREPASTADDFOOD_SUCCESS,
  PLANREPAST_PLANREPASTADDFOOD_ERROR,
  PLANREPAST_PLANREPASTDELETEFOOD,
  PLANREPAST_PLANREPASTDELETEFOOD_SUCCESS,
  PLANREPAST_PLANREPASTDELETEFOOD_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getPlanRepastList = (pageDetail) => ({
  type: PLANREPAST_GET_LIST,
  payload: pageDetail,
});

export const getPlanRepastListSuccess = (items) => ({
  type: PLANREPAST_GET_LIST_SUCCESS,
  payload: items,
});

export const getPlanRepastListError = (error) => ({
  type: PLANREPAST_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setPlanRepastSelectedRecord = (recordId) => ({
  type: PLANREPAST_SET_SELECTED,
  payload: recordId,
});

export const setPlanRepastSelectedRecordSuccess = (record) => ({
  type: PLANREPAST_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setPlanRepastSelectedRecordError = (error) => ({
  type: PLANREPAST_SET_SELECTED_ERROR,
  payload: error,
});

export const updatePlanRepastSelectedRecord = (record) => ({
  type: PLANREPAST_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addPlanRepastRecord = (record) => ({
  type: PLANREPAST_ADD_RECORD,
  payload: record,
});

export const addPlanRepastRecordSuccess = (msg) => ({
  type: PLANREPAST_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addPlanRepastRecordError = (error) => ({
  type: PLANREPAST_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updatePlanRepastRecords = (record) => ({
  type: PLANREPAST_UPDATE_RECORD,
  payload: record,
});

export const updatePlanRepastRecordSuccess = (msg) => ({
  type: PLANREPAST_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updatePlanRepastRecordError = (error) => ({
  type: PLANREPAST_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------

export const updatePlanRepastBatchRecords = (records) => ({
  type: PLANREPAST_UPDATE_BATCHRECORD,
  payload: records,
});
export const updatePlanRepastBatchRecordSuccess = (msg) => ({
  type: PLANREPAST_UPDATE_BATCHRECORD_SUCCESS,
  payload: msg,
});

export const updatePlanRepastBatchRecordError = (error) => ({
  type: PLANREPAST_UPDATE_BATCHRECORD_ERROR,
  payload: error,
});

export const deletePlanRepastRecords = (record) => ({
  type: PLANREPAST_DELETE_RECORD,
  payload: record,
});

export const deletePlanRepastRecordSuccess = (msg) => ({
  type: PLANREPAST_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deletePlanRepastRecordError = (error) => ({
  type: PLANREPAST_DELETE_RECORD_ERROR,
  payload: error,
});
//---------------------------------
export const planRepastPlanRepastAddFoodRecord = (record) => ({
  type: PLANREPAST_PLANREPASTADDFOOD,
  payload: record,
});

export const planRepastPlanRepastAddFoodRecordSuccess = (msg) => ({
  type: PLANREPAST_PLANREPASTADDFOOD_SUCCESS,
  payload: msg,
});

export const planRepastPlanRepastAddFoodRecordError = (error) => ({
  type: PLANREPAST_PLANREPASTADDFOOD_ERROR,
  payload: error,
});

export const planRepastPlanRepastDeleteFoodRecord = (record) => ({
  type: PLANREPAST_PLANREPASTDELETEFOOD,
  payload: record,
});

export const planRepastPlanRepastDeleteFoodRecordSuccess = (msg) => ({
  type: PLANREPAST_PLANREPASTDELETEFOOD_SUCCESS,
  payload: msg,
});

export const planRepastPlanRepastDeleteFoodRecordError = (error) => ({
  type: PLANREPAST_PLANREPASTDELETEFOOD_ERROR,
  payload: error,
});

export const resetPlanRepastStatuses = () => ({
  type: PLANREPAST_RESET_STATUSES,
});
