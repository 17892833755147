// eslint-disable-next-line import/no-cycle
import {
  MEALNUTRIENTTOTALS_GET_LIST,
  MEALNUTRIENTTOTALS_GET_LIST_SUCCESS,
  MEALNUTRIENTTOTALS_GET_LIST_ERROR,
  MEALNUTRIENTTOTALS_SET_SELECTED,
  MEALNUTRIENTTOTALS_UPDATE_SELECTED,
  MEALNUTRIENTTOTALS_SET_SELECTED_SUCCESS,
  MEALNUTRIENTTOTALS_SET_SELECTED_ERROR,
  MEALNUTRIENTTOTALS_ADD_RECORD_SUCCESS,
  MEALNUTRIENTTOTALS_ADD_RECORD_ERROR,
  MEALNUTRIENTTOTALS_ADD_RECORD,
  MEALNUTRIENTTOTALS_UPDATE_RECORD_SUCCESS,
  MEALNUTRIENTTOTALS_UPDATE_RECORD_ERROR,
  MEALNUTRIENTTOTALS_UPDATE_RECORD,
  MEALNUTRIENTTOTALS_DELETE_RECORD,
  MEALNUTRIENTTOTALS_DELETE_RECORD_SUCCESS,
  MEALNUTRIENTTOTALS_DELETE_RECORD_ERROR,
  MEALNUTRIENTTOTALS_RESET_STATUSES,
} from '../../actions';

//-------------------------------------------------------------------------
export const getMealNutrientTotalsList = (pageDetail) => ({
  type: MEALNUTRIENTTOTALS_GET_LIST,
  payload: pageDetail,
});

export const getMealNutrientTotalsListSuccess = (items) => ({
  type: MEALNUTRIENTTOTALS_GET_LIST_SUCCESS,
  payload: items,
});

export const getMealNutrientTotalsListError = (error) => ({
  type: MEALNUTRIENTTOTALS_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setMealNutrientTotalsSelectedRecord = (recordId) => ({
  type: MEALNUTRIENTTOTALS_SET_SELECTED,
  payload: recordId,
});

export const setMealNutrientTotalsSelectedRecordSuccess = (record) => ({
  type: MEALNUTRIENTTOTALS_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setMealNutrientTotalsSelectedRecordError = (error) => ({
  type: MEALNUTRIENTTOTALS_SET_SELECTED_ERROR,
  payload: error,
});

export const updateMealNutrientTotalsSelectedRecord = (record) => ({
  type: MEALNUTRIENTTOTALS_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addMealNutrientTotalsRecord = (record) => ({
  type: MEALNUTRIENTTOTALS_ADD_RECORD,
  payload: record,
});

export const addMealNutrientTotalsRecordSuccess = (msg) => ({
  type: MEALNUTRIENTTOTALS_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addMealNutrientTotalsRecordError = (error) => ({
  type: MEALNUTRIENTTOTALS_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateMealNutrientTotalsRecords = (record) => ({
  type: MEALNUTRIENTTOTALS_UPDATE_RECORD,
  payload: record,
});

export const updateMealNutrientTotalsRecordSuccess = (msg) => ({
  type: MEALNUTRIENTTOTALS_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateMealNutrientTotalsRecordError = (error) => ({
  type: MEALNUTRIENTTOTALS_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteMealNutrientTotalsRecords = (record) => ({
  type: MEALNUTRIENTTOTALS_DELETE_RECORD,
  payload: record,
});

export const deleteMealNutrientTotalsRecordSuccess = (msg) => ({
  type: MEALNUTRIENTTOTALS_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteMealNutrientTotalsRecordError = (error) => ({
  type: MEALNUTRIENTTOTALS_DELETE_RECORD_ERROR,
  payload: error,
});

export const resetMealNutrientTotalsStatuses = () => ({
  type: MEALNUTRIENTTOTALS_RESET_STATUSES,
});
//-------------------------------------------------------------------------
