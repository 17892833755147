/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable no-return-await */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  debounce,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  ANTROPOMETRY_ADD_RECORD,
  ANTROPOMETRY_UPDATE_RECORD,
  ANTROPOMETRY_GET_LIST,
  ANTROPOMETRY_SET_SELECTED,
  ANTROPOMETRY_DELETE_RECORD,
  deleteAntropometryRecordSuccess,
  deleteAntropometryRecordError,
  ANTROPOMETRY_CLONE_SET_SELECTED,
  ANTROPOMETRY_ISACTIVECHANGE,
  ANTROPOMETRY_GENERICSEARCH,
  ANTROPOMETRY_GETBYCOACHEEID,
} from '../../actions';

import {
  getAntropometryListSuccess,
  getAntropometryListError,
  setAntropometrySelectedRecordSuccess,
  setAntropometrySelectedRecordError,
  addAntropometryRecordSuccess,
  addAntropometryRecordError,
  updateAntropometryRecordSuccess,
  updateAntropometryRecordError,
  setAntropometryCloneSelectedRecordSuccess,
  setAntropometryCloneSelectedRecordError,
  isActiveChangeAntropometrySuccess,
  isActiveChangeAntropometryError,
  genericSearchAntropometrySuccess,
  genericSearchAntropometryError,
  getAntropometryByCoacheeIdSuccess,
  getAntropometryByCoacheeIdError,
} from './actions';

//---------------------------------------------------------------------
const getAntropometryListRequest = async (url) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/antropometry${url || ''}`)
    .then((response) => {
      return response.data;
    });
};

function* getAntropometryListItems({ payload }) {
  try {
    payload.pageSize = yield select((state) => state.settings.pageRecordCount);
    payload.pageNumber = payload.pageNumber || 0;
    payload.filter = `${payload.keyword}`;
    const response = yield call(
      getAntropometryListRequest,
      filterBuilder(payload)
    );
    yield put(getAntropometryListSuccess(response));
  } catch (error) {
    yield put(getAntropometryListError(error));
  }
}
//----------------------------------------------------------------------------------
const setAntropometrySelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/antropometry/${id}`)
    .then((response) => response.data.singleData);
};

function* setAntropometrySelectedRecord({ payload }) {
  try {
    const response = yield call(setAntropometrySelectedRecordRequest, payload);
    yield put(setAntropometrySelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setAntropometrySelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addAntropometryRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/antropometry`, record)
    .then((response) => response.data);
};

function* addAntropometryRecords({ payload }) {
  try {
    yield call(addAntropometryRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addAntropometryRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getAntropometryListItems, {
      payload: { pageNumber: 0, keyword: '' },
    });
  } catch (error) {
    yield put(
      addAntropometryRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateAntropometryRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/antropometry`, record)
    .then((response) => response.data);
};

function* updateAntropometryRecords({ payload }) {
  try {
    yield call(updateAntropometryRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateAntropometryRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getAntropometryListItems, {
      payload: { pageNumber: 0, keyword: '' },
    });
  } catch (error) {
    yield put(
      updateAntropometryRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteAntropometryRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/antropometry/${recordId}`)
    .then((response) => response.data);
};

function* deleteAntropometryRecords({ payload }) {
  try {
    yield call(deleteAntropometryRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteAntropometryRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getAntropometryListItems, {
      payload: { pageNumber: 0, keyword: '' },
    });
  } catch (error) {
    yield put(
      deleteAntropometryRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const antropometryCloneRecordRequest = (antropometry) => {
  if (antropometry.coacheeId) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/antropometry/clone/${antropometry.id}/${antropometry.coacheeId}/${antropometry.antropometryName}`
      )
      .then((response) => response.data);
  }
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/antropometry/clone/${antropometry.id}/${antropometry.antropometryName}`
    )
    .then((response) => response.data);
};
function* antropometryCloneRecord({ payload }) {
  try {
    yield call(antropometryCloneRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      setAntropometryCloneSelectedRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getAntropometryListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      setAntropometryCloneSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------
const isActiveChangeAntropometryRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/antropometry/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeAntropometry({ payload }) {
  try {
    const response = yield call(isActiveChangeAntropometryRequest, payload);
    yield put(isActiveChangeAntropometrySuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeAntropometryError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------
const genericSearchRequest = (dynamicQuery) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/antropometry/getWithFilter?filter=${dynamicQuery.filter}&ps=${dynamicQuery.pageSize}&pn=${dynamicQuery.pageNumber}`
    )
    .then((response) => response.data);

function* genericSearch({ payload }) {
  try {
    if (payload) {
      let pageSize = yield select((state) => state.settings.pageRecordCount);
      payload.pageSize = pageSize;
      payload.pageNumber = 0;
      payload.filter = 'Name.Contains("' + payload.filter + '")';
    }

    const response = yield call(genericSearchRequest, payload);
    yield put(genericSearchAntropometrySuccess(response));
  } catch (error) {
    yield put(
      genericSearchAntropometryError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------
const getAntropometryByCoacheeIdRequest = (coacheeId) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/antropometry/bycoacheeid/${coacheeId}`
    )
    .then((response) => response.data);

function* getAntropometryByCoacheeIdRecords({ payload }) {
  try {
    const response = yield call(getAntropometryByCoacheeIdRequest, payload);
    yield put(getAntropometryByCoacheeIdSuccess(response));
  } catch (error) {
    yield put(
      getAntropometryByCoacheeIdError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

export function* watchGetList() {
  yield takeEvery(ANTROPOMETRY_GET_LIST, getAntropometryListItems);
}

export function* watchSetSelected() {
  yield takeEvery(ANTROPOMETRY_SET_SELECTED, setAntropometrySelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(ANTROPOMETRY_ADD_RECORD, addAntropometryRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(ANTROPOMETRY_UPDATE_RECORD, updateAntropometryRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(ANTROPOMETRY_DELETE_RECORD, deleteAntropometryRecords);
}
export function* watchClone() {
  yield takeEvery(ANTROPOMETRY_CLONE_SET_SELECTED, antropometryCloneRecord);
}
export function* watchIsActiveChangeItem() {
  yield takeEvery(ANTROPOMETRY_ISACTIVECHANGE, isActiveChangeAntropometry);
}
export function* watchGenericSearch() {
  yield debounce(800, ANTROPOMETRY_GENERICSEARCH, genericSearch);
}
export function* watchGetCoacheeId() {
  yield takeEvery(
    ANTROPOMETRY_GETBYCOACHEEID,
    getAntropometryByCoacheeIdRecords
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGenericSearch),
    fork(watchGetList),
    fork(watchSetSelected),
    fork(watchIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(watchClone),
    fork(watchGetCoacheeId),
  ]);
}
