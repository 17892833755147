import { getToken } from './Utils';

const getTokenRemainingTime = () => {
  const accessToken = getToken();
  if (!accessToken) {
    return 0;
  }
  const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
  const expires = new Date(jwtToken.exp * 1000);
  return expires.getTime() - Date.now();
};

const refreshTimer = () => {
  const remainingTime = getTokenRemainingTime();
  if (remainingTime <= 60000) {
    return true;
  }
  return false;
};

export { getTokenRemainingTime, refreshTimer };
