/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  EMAIL_ADD_RECORD,
  EMAIL_UPDATE_RECORD,
  EMAIL_GET_LIST,
  EMAIL_SET_SELECTED,
  EMAIL_DELETE_RECORD,
  deleteEmailRecordSuccess,
  deleteEmailRecordError,
  EMAIL_ISACTIVECHANGE,
  SELECTED_USERPLAN_SEND_EMAIL,
  SELECTED_USER_SEND_EMAIL,
  SELECTED_ALL_USER_SEND_EMAIL,
  SELECTED_MULTI_USER_SEND_EMAIL,
} from '../../actions';

import {
  getEmailListSuccess,
  getEmailListError,
  setEmailSelectedRecordSuccess,
  setEmailSelectedRecordError,
  addEmailRecordSuccess,
  addEmailRecordError,
  updateEmailRecordSuccess,
  updateEmailRecordError,
  isActiveChangeEmailSuccess,
  isActiveChangeEmailError,
} from './actions';

//---------------------------------------------------------------------
const getEmailListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/coupon${url || ''}`)
    .then((response) => response.data);
};

function* getEmailListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(getEmailListRequest, filterBuilder(payload));
      yield put(getEmailListSuccess(response));
    } else {
      const response = yield call(getEmailListRequest);
      yield put(getEmailListSuccess(response));
    }
  } catch (error) {
    yield put(getEmailListError(error));
  }
}
//----------------------------------------------------------------------------------
const setEmailSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/coupon/${id}`)
    .then((response) => response.data.singleData);

function* setEmailSelectedRecord({ payload }) {
  try {
    const response = yield call(setEmailSelectedRecordRequest, payload);
    yield put(setEmailSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setEmailSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addEmailRecordRequest = async (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/coupon`, record)
    .then((response) => response.data);

function* addEmailRecords({ payload }) {
  try {
    yield call(addEmailRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addEmailRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getEmailListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addEmailRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateEmailRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/coupon`, record)
    .then((response) => response.data);

function* updateEmailRecords({ payload }) {
  try {
    yield call(updateEmailRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateEmailRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getEmailListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateEmailRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const isActiveChangeEmailRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/coupon/isactivechange/${recordId}`)
    .then((response) => response.data);

function* isActiveChangeEmail({ payload }) {
  try {
    const response = yield call(isActiveChangeEmailRequest, payload);
    yield put(isActiveChangeEmailSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeEmailError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

//----------------------------------------------------------------------------------
const deleteEmailRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/coupon/${recordId}`)
    .then((response) => response.data);

function* deleteEmailRecords({ payload }) {
  try {
    yield call(deleteEmailRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteEmailRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getEmailListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteEmailRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const userPlanSendEmailRequest = (obj) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/email/userPlanSendEmail`, obj)
    .then((response) => response.data);

function* userPlanSendEmail({ payload }) {
  try {
    yield call(userPlanSendEmailRequest, payload);
  } catch (error) {
    console.log(error);
  }
}

//--------------------------------------------------------------------

const userSendEmailRequest = (userEmail) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/email/userSendEmail`, userEmail)
    .then((response) => response.data);

function* userSendEmail({ payload }) {
  try {
    yield call(userSendEmailRequest, payload);
  } catch (error) {
    console.log(error);
  }
}

//--------------------------------------------------------------------

const multiUserSendEmailRequest = (listUserEMail) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/email/multiUserSendEmail`,
      listUserEMail
    )
    .then((response) => response.data);

function* multiUserSendEmail({ payload }) {
  try {
    yield call(multiUserSendEmailRequest, payload);
  } catch (error) {
    console.log(error);
  }
}

//--------------------------------------------------------------------
const allUserSendEmailRequest = (obj) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/email/allUserSendEmail`, obj)
    .then((response) => response.data);

function* allUserSendEmail({ payload }) {
  try {
    yield call(allUserSendEmailRequest, payload);
  } catch (error) {
    console.log(error);
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(200, EMAIL_GET_LIST, getEmailListItems);
}

export function* watchSetSelected() {
  yield takeEvery(EMAIL_SET_SELECTED, setEmailSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(EMAIL_ADD_RECORD, addEmailRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(EMAIL_UPDATE_RECORD, updateEmailRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(EMAIL_DELETE_RECORD, deleteEmailRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(EMAIL_ISACTIVECHANGE, isActiveChangeEmail);
}

export function* watchUserPlanSendEmail() {
  yield takeEvery(SELECTED_USERPLAN_SEND_EMAIL, userPlanSendEmail);
}

export function* watchUserSendEmail() {
  yield takeEvery(SELECTED_USER_SEND_EMAIL, userSendEmail);
}

export function* watchMultiUserSendEmail() {
  yield takeEvery(SELECTED_MULTI_USER_SEND_EMAIL, multiUserSendEmail);
}

export function* watchAllUserSendEmail() {
  yield takeEvery(SELECTED_ALL_USER_SEND_EMAIL, allUserSendEmail);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcIsActiveChangeItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(watchUserPlanSendEmail),
    fork(watchUserSendEmail),
    fork(watchMultiUserSendEmail),
    fork(watchAllUserSendEmail),
  ]);
}
