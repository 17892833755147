import {
  FOODPORTION_GET_LIST,
  FOODPORTION_GET_LIST_SUCCESS,
  FOODPORTION_GET_LIST_ERROR,
  FOODPORTION_SET_SELECTED,
  FOODPORTION_UPDATE_SELECTED,
  FOODPORTION_SET_SELECTED_SUCCESS,
  FOODPORTION_SET_SELECTED_ERROR,
  FOODPORTION_UPDATE_RECORD,
  FOODPORTION_UPDATE_RECORD_ERROR,
  FOODPORTION_UPDATE_RECORD_SUCCESS,
  FOODPORTION_ADD_RECORD,
  FOODPORTION_ADD_RECORD_SUCCESS,
  FOODPORTION_ADD_RECORD_ERROR,
  FOODPORTION_DELETE_RECORD,
  FOODPORTION_DELETE_RECORD_SUCCESS,
  FOODPORTION_DELETE_RECORD_ERROR,
  FOODPORTION_RESET_STATUSES,
  FOODPORTION_FILTER,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  dependentRecords: null,
  selectedDepedentRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FOODPORTION_GET_LIST:
      return { ...state, loading: true };
    case FOODPORTION_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case FOODPORTION_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case FOODPORTION_SET_SELECTED:
      return { ...state, modaLoading: true };
    case FOODPORTION_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case FOODPORTION_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case FOODPORTION_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case FOODPORTION_ADD_RECORD:
      return { ...state, loading: false };
    case FOODPORTION_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case FOODPORTION_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case FOODPORTION_UPDATE_RECORD:
      return { ...state, loading: false };
    case FOODPORTION_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case FOODPORTION_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case FOODPORTION_DELETE_RECORD:
      return { ...state, loading: false };
    case FOODPORTION_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case FOODPORTION_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case FOODPORTION_RESET_STATUSES:
      return { ...state, success: null, error: null };
    case FOODPORTION_FILTER:
      return {
        ...state,
        selectedRecord: state.records.filter(
          (e) =>
            e.id === action.payload.id && e.secondId === action.payload.secondId
        ),
      };
    default:
      return { ...state };
  }
};
