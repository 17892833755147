/* eslint-disable prettier/prettier */
/* eslint-disable import/no-cycle */
import {
    SUBFOODGROUP_GET_LIST,
    SUBFOODGROUP_GET_LIST_SUCCESS,
    SUBFOODGROUP_GET_LIST_ERROR,
    SUBFOODGROUP_SET_SELECTED,
    SUBFOODGROUP_UPDATE_SELECTED,
    SUBFOODGROUP_SET_SELECTED_SUCCESS,
    SUBFOODGROUP_SET_SELECTED_ERROR,
    SUBFOODGROUP_ADD_RECORD_SUCCESS,
    SUBFOODGROUP_ADD_RECORD_ERROR,
    SUBFOODGROUP_ADD_RECORD,
    SUBFOODGROUP_UPDATE_RECORD_SUCCESS,
    SUBFOODGROUP_UPDATE_RECORD_ERROR,
    SUBFOODGROUP_UPDATE_RECORD,
    SUBFOODGROUP_DELETE_RECORD,
    SUBFOODGROUP_DELETE_RECORD_SUCCESS,
    SUBFOODGROUP_DELETE_RECORD_ERROR,
    SUBFOODGROUP_RESET_STATUSES,
    SUBFOODGROUP_ISACTIVECHANGE,
    SUBFOODGROUP_ISACTIVECHANGE_SUCCESS,
    SUBFOODGROUP_ISACTIVECHANGE_ERROR,
  } from '../../actions';
  
  //-------------------------------------------------------------------------
  export const getSubFoodGroupList = (pageDetail) => ({
      type: SUBFOODGROUP_GET_LIST,
      payload: pageDetail
  });
  
  export const getSubFoodGroupListSuccess = (items) => ({
    type: SUBFOODGROUP_GET_LIST_SUCCESS,
    payload: items,
  });
  
  export const getSubFoodGroupListError = (error) => ({
    type: SUBFOODGROUP_GET_LIST_ERROR,
    payload: error,
  });
  
  //----------------------------------------------------------------------------------
  export const setSubFoodGroupSelectedRecord = (recordId) => ({
    type: SUBFOODGROUP_SET_SELECTED,
    payload: recordId
  });
  
  export const setSubFoodGroupSelectedRecordSuccess = (record) => ({
    type: SUBFOODGROUP_SET_SELECTED_SUCCESS,
    payload: record
  });
  
  export const setSubFoodGroupSelectedRecordError = (error) => ({
    type: SUBFOODGROUP_SET_SELECTED_ERROR,
    payload: error
  });
  
  export const updateSubFoodGroupSelectedRecord = (record) => ({
    type: SUBFOODGROUP_UPDATE_SELECTED,
    payload: record
  });
  //----------------------------------------------------------------------------------
  export const addSubFoodGroupRecord = (record) => ({
    type: SUBFOODGROUP_ADD_RECORD,
    payload: record,
  });
  
  export const addSubFoodGroupRecordSuccess = (msg) => ({
    type: SUBFOODGROUP_ADD_RECORD_SUCCESS,
    payload: msg,
  });
  
  export const addSubFoodGroupRecordError = (error) => ({
    type: SUBFOODGROUP_ADD_RECORD_ERROR,
    payload: error,
  });
  //----------------------------------------------------------------------------------
  export const updateSubFoodGroupRecords = (record) => ({
    type: SUBFOODGROUP_UPDATE_RECORD,
    payload: record,
  });
  
  export const updateSubFoodGroupRecordSuccess = (msg) => ({
    type: SUBFOODGROUP_UPDATE_RECORD_SUCCESS,
    payload: msg,
  });
  
  export const updateSubFoodGroupRecordError = (error) => ({
    type: SUBFOODGROUP_UPDATE_RECORD_ERROR,
    payload: error,
  });
  //-------------------------------------------------------------------------
  export const deleteSubFoodGroupRecords = (record) => ({
    type: SUBFOODGROUP_DELETE_RECORD,
    payload: record,
  });
  
  export const deleteSubFoodGroupRecordSuccess = (msg) => ({
    type: SUBFOODGROUP_DELETE_RECORD_SUCCESS,
    payload: msg,
  });
  
  export const deleteSubFoodGroupRecordError = (error) => ({
    type: SUBFOODGROUP_DELETE_RECORD_ERROR,
    payload: error,
  });
  //-------------------------------------------------------------------------
  export const isActiveChangeSubFoodGroup = (record) => ({
    type: SUBFOODGROUP_ISACTIVECHANGE,
    payload: record,
  });
  
  export const isActiveChangeSubFoodGroupSuccess = (msg) => ({
    type: SUBFOODGROUP_ISACTIVECHANGE_SUCCESS,
    payload: msg,
  });
  
  export const isActiveChangeSubFoodGroupError = (error) => ({
    type: SUBFOODGROUP_ISACTIVECHANGE_ERROR,
    payload: error,
  });
  
  export const resetSubFoodGroupStatuses = () => ({ type: SUBFOODGROUP_RESET_STATUSES });
