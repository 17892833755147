// eslint-disable-next-line import/no-cycle
import {
  MENUPREPARE_GET_LIST,
  MENUPREPARE_GET_LIST_SUCCESS,
  MENUPREPARE_GET_LIST_ERROR,
  MENUPREPARE_SET_SELECTED,
  MENUPREPARE_UPDATE_SELECTED,
  MENUPREPARE_SET_SELECTED_SUCCESS,
  MENUPREPARE_SET_SELECTED_ERROR,
  MENUPREPARE_ADD_RECORD_SUCCESS,
  MENUPREPARE_ADD_RECORD_ERROR,
  MENUPREPARE_ADD_RECORD,
  MENUPREPARE_UPDATE_RECORD_SUCCESS,
  MENUPREPARE_UPDATE_RECORD_ERROR,
  MENUPREPARE_UPDATE_RECORD,
  MENUPREPARE_DELETE_RECORD,
  MENUPREPARE_DELETE_RECORD_SUCCESS,
  MENUPREPARE_DELETE_RECORD_ERROR,
  MENUPREPARE_RESET_STATUSES,
  MENUPREPARE_ISACTIVECHANGE_SUCCESS,
  MENUPREPARE_ISACTIVECHANGE_ERROR,
  MENUPREPARE_ISACTIVECHANGE,
  MENUPREPARE_EXPORT,
  MENUPREPARE_EXPORT_ERROR,
  MENUPREPARE_EXPORT_SUCCESS,
  MENUPREPARE_DAY_UPDATE,
  MENUPREPARE_DAY_UPDATE_SUCCESS,
  MENUPREPARE_DAY_UPDATE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getMenuPrepareList = (pageDetail) => ({
  type: MENUPREPARE_GET_LIST,
  payload: pageDetail,
});

export const getMenuPrepareListSuccess = (items) => ({
  type: MENUPREPARE_GET_LIST_SUCCESS,
  payload: items,
});

export const getMenuPrepareListError = (error) => ({
  type: MENUPREPARE_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setMenuPrepareSelectedRecord = (recordId) => ({
  type: MENUPREPARE_SET_SELECTED,
  payload: recordId,
});

export const setMenuPrepareSelectedRecordSuccess = (record) => ({
  type: MENUPREPARE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setMenuPrepareSelectedRecordError = (error) => ({
  type: MENUPREPARE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateMenuPrepareSelectedRecord = (record) => ({
  type: MENUPREPARE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addMenuPrepareRecord = (record) => ({
  type: MENUPREPARE_ADD_RECORD,
  payload: record,
});

export const addMenuPrepareRecordSuccess = (msg) => ({
  type: MENUPREPARE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addMenuPrepareRecordError = (error) => ({
  type: MENUPREPARE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateMenuPrepareRecords = (record) => ({
  type: MENUPREPARE_UPDATE_RECORD,
  payload: record,
});

export const updateMenuPrepareRecordSuccess = (msg) => ({
  type: MENUPREPARE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateMenuPrepareRecordError = (error) => ({
  type: MENUPREPARE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const deleteMenuPrepareRecords = (record) => ({
  type: MENUPREPARE_DELETE_RECORD,
  payload: record,
});

export const deleteMenuPrepareRecordSuccess = (msg) => ({
  type: MENUPREPARE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteMenuPrepareRecordError = (error) => ({
  type: MENUPREPARE_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeMenuPrepare = (record) => ({
  type: MENUPREPARE_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeMenuPrepareSuccess = (msg) => ({
  type: MENUPREPARE_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeMenuPrepareError = (error) => ({
  type: MENUPREPARE_ISACTIVECHANGE_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const exportMenuPrepare = (record) => ({
  type: MENUPREPARE_EXPORT,
  payload: record,
});

export const exportMenuPrepareSuccess = (msg) => ({
  type: MENUPREPARE_EXPORT_SUCCESS,
  payload: msg,
});

export const exportMenuPrepareError = (error) => ({
  type: MENUPREPARE_EXPORT_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const menuPrepareDayUpdate = (record) => ({
  type: MENUPREPARE_DAY_UPDATE,
  payload: record,
});

export const menuPrepareDayUpdateSuccess = (msg) => ({
  type: MENUPREPARE_DAY_UPDATE_SUCCESS,
  payload: msg,
});

export const menuPrepareDayUpdateError = (error) => ({
  type: MENUPREPARE_DAY_UPDATE_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const resetMenuPrepareStatuses = () => ({
  type: MENUPREPARE_RESET_STATUSES,
});
