import {
  NotificationType,
  NotificationSubType,
} from 'constants/notificationType';

const NotificationHelpers = (messageType, subtype) => {
  const messageSubType = messageType.messages.find(
    (x) => x.notifyType === subtype
  ); // subtype filtresi ile
  return {
    timestamp: Date.now(),
    name: messageType.title,
    message: messageSubType.message,
  };
};
const NotificationMessageCreate = (messages) => {
  return [
    {
      type: NotificationType.Success,
      title: messages['notification-success-title'],
      messages: [
        {
          notifyType: NotificationSubType.Add,
          message: messages['notification-add-success'],
        },
        {
          notifyType: NotificationSubType.Edit,
          message: messages['notification-update-success'],
        },
        {
          notifyType: NotificationSubType.Delete,
          message: messages['notification-delete-success'],
        },
      ],
    },
  ];
};
export {
  NotificationHelpers,
  NotificationMessageCreate,
  NotificationType,
  NotificationSubType,
};
