/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  ACTIVITYTYPE_ADD_RECORD,
  ACTIVITYTYPE_UPDATE_RECORD,
  ACTIVITYTYPE_GET_LIST,
  ACTIVITYTYPE_GET_GROUPED_LIST,
  ACTIVITYTYPE_SET_SELECTED,
  ACTIVITYTYPE_DELETE_RECORD,
  deleteActivityTypeRecordSuccess,
  deleteActivityTypeRecordError,
  ACTIVITYTYPE_ISACTIVECHANGE,
} from '../../actions';

import {
  getActivityTypeListSuccess,
  getActivityTypeListError,
  getActivityTypeGroupedListSuccess,
  getActivityTypeGroupedListError,
  setActivityTypeSelectedRecordSuccess,
  setActivityTypeSelectedRecordError,
  addActivityTypeRecordSuccess,
  addActivityTypeRecordError,
  updateActivityTypeRecordSuccess,
  updateActivityTypeRecordError,
  isActiveChangeActivityTypeSuccess,
  isActiveChangeActivityTypeError,
} from './actions';

//---------------------------------------------------------------------
const getActivityTypeListRequest = async (url) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/activitytype${url || ''}`)
    .then((response) => response.data);
};

function* getActivityTypeListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;
      const response = yield call(
        getActivityTypeListRequest,
        filterBuilder(payload)
      );
      yield put(getActivityTypeListSuccess(response));
    } else {
      const response = yield call(getActivityTypeListRequest);
      yield put(getActivityTypeListSuccess(response));
    }
  } catch (error) {
    yield put(getActivityTypeListError(error));
  }
}
//----------------------------------------------------------------------------------
const getActivityTypeGroupedListRequest = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/activitytype/grouped`)
    .then((response) => response.data.data);
};

function* getActivityTypeGroupedListItems() {
  try {
    const response = yield call(getActivityTypeGroupedListRequest);
    yield put(getActivityTypeGroupedListSuccess(response));
  } catch (error) {
    yield put(getActivityTypeGroupedListError(error));
  }
}
//----------------------------------------------------------------------------------
const setActivityTypeSelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/activitytype/${id}`)
    .then((response) => response.data.singleData);
};

function* setActivityTypeSelectedRecord({ payload }) {
  try {
    const response = yield call(setActivityTypeSelectedRecordRequest, payload);
    yield put(setActivityTypeSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setActivityTypeSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addActivityTypeRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/activitytype`, record)
    .then((response) => response.data);
};

function* addActivityTypeRecords({ payload }) {
  try {
    yield call(addActivityTypeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addActivityTypeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getActivityTypeListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addActivityTypeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateActivityTypeRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/activitytype`, record)
    .then((response) => response.data);
};

function* updateActivityTypeRecords({ payload }) {
  try {
    yield call(updateActivityTypeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateActivityTypeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getActivityTypeListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateActivityTypeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteActivityTypeRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/activitytype/${recordId}`)
    .then((response) => response.data);
};

function* deleteActivityTypeRecords({ payload }) {
  try {
    yield call(deleteActivityTypeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteActivityTypeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getActivityTypeListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteActivityTypeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const isActiveChangeActivityTypeRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/activitytype/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeActivityType({ payload }) {
  try {
    const response = yield call(isActiveChangeActivityTypeRequest, payload);
    yield put(isActiveChangeActivityTypeSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeActivityTypeError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(ACTIVITYTYPE_GET_LIST, getActivityTypeListItems);
}
export function* watchGetGroupedList() {
  yield takeEvery(
    ACTIVITYTYPE_GET_GROUPED_LIST,
    getActivityTypeGroupedListItems
  );
}

export function* watchSetSelected() {
  yield takeEvery(ACTIVITYTYPE_SET_SELECTED, setActivityTypeSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(ACTIVITYTYPE_ADD_RECORD, addActivityTypeRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(ACTIVITYTYPE_UPDATE_RECORD, updateActivityTypeRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(ACTIVITYTYPE_DELETE_RECORD, deleteActivityTypeRecords);
}

export function* wathcIsActiveChangeItem() {
  yield takeEvery(ACTIVITYTYPE_ISACTIVECHANGE, isActiveChangeActivityType);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetGroupedList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(wathcIsActiveChangeItem),
  ]);
}
