// eslint-disable-next-line import/no-cycle
import {
  ROLEGROUP_GET_LIST,
  ROLEGROUP_GET_LIST_SUCCESS,
  ROLEGROUP_GET_LIST_ERROR,
  ROLEGROUP_ADD_RECORD,
  ROLEGROUP_ADD_RECORD_ERROR,
  ROLEGROUP_ADD_RECORD_SUCCESS,
  ROLEGROUP_DELETE_RECORD,
  ROLEGROUP_DELETE_RECORD_ERROR,
  ROLEGROUP_DELETE_RECORD_SUCCESS,
  ROLEGROUP_RESET_STATUSES,
  ROLEGROUP_SET_SELECTED,
  ROLEGROUP_SET_SELECTED_ERROR,
  ROLEGROUP_SET_SELECTED_SUCCESS,
  ROLEGROUP_UPDATE_RECORD,
  ROLEGROUP_UPDATE_RECORD_ERROR,
  ROLEGROUP_UPDATE_RECORD_SUCCESS,
  ROLEGROUP_UPDATE_SELECTED,
  ROLEGROUP_ISACTIVECHANGE,
  ROLEGROUP_ISACTIVECHANGE_SUCCESS,
  ROLEGROUP_ISACTIVECHANGE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getRoleGroupList = (pageDetail) => ({
  type: ROLEGROUP_GET_LIST,
  payload: pageDetail,
});

export const getRoleGroupListSuccess = (items) => ({
  type: ROLEGROUP_GET_LIST_SUCCESS,
  payload: items,
});

export const getRoleGroupListError = (error) => ({
  type: ROLEGROUP_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setRoleGroupSelectedRecord = (recordId) => ({
  type: ROLEGROUP_SET_SELECTED,
  payload: recordId,
});

export const setRoleGroupSelectedRecordSuccess = (record) => ({
  type: ROLEGROUP_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setRoleGroupSelectedRecordError = (error) => ({
  type: ROLEGROUP_SET_SELECTED_ERROR,
  payload: error,
});

export const updateRoleGroupSelectedRecord = (record) => ({
  type: ROLEGROUP_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addRoleGroupRecord = (record) => ({
  type: ROLEGROUP_ADD_RECORD,
  payload: record,
});

export const addRoleGroupRecordSuccess = (msg) => ({
  type: ROLEGROUP_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addRoleGroupRecordError = (error) => ({
  type: ROLEGROUP_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateRoleGroupRecords = (record) => ({
  type: ROLEGROUP_UPDATE_RECORD,
  payload: record,
});

export const updateRoleGroupRecordSuccess = (msg) => ({
  type: ROLEGROUP_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateRoleGroupRecordError = (error) => ({
  type: ROLEGROUP_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteRoleGroupRecords = (record) => ({
  type: ROLEGROUP_DELETE_RECORD,
  payload: record,
});

export const deleteRoleGroupRecordSuccess = (msg) => ({
  type: ROLEGROUP_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteRoleGroupRecordError = (error) => ({
  type: ROLEGROUP_DELETE_RECORD_ERROR,
  payload: error,
});

export const resetRoleGroupStatuses = () => ({
  type: ROLEGROUP_RESET_STATUSES,
});
export const isActiveChangeRoleGroup = (record) => ({
  type: ROLEGROUP_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeRoleGroupSuccess = (msg) => ({
  type: ROLEGROUP_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeRoleGroupError = (error) => ({
  type: ROLEGROUP_ISACTIVECHANGE_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
