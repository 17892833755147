import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  REFRESH_USER_TOKEN,
  CLOSE_TAB_USER,
  APPROVAL_FILE,
  APPROVAL_FILE_SUCCESS,
  APPROVAL_FILE_ERROR,
  AUTH_RESET_STATUSES,
} from '../actions';
import { getCurrentUser } from '../../helpers/Utils';
import { isAuthGuardActive } from '../../constants/defaultValues';

const INIT_STATE = {
  currentUser: isAuthGuardActive ? getCurrentUser() : null,
  forgotUserMail: '',
  newPassword: '',
  rePassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REFRESH_USER_TOKEN:
      return { ...state, success: null, error: null };
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: '',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: '' };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: '',
        repassword: '',
        error: '',
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: '',
        repassword: '',
        resetPasswordCode: '',
        error: action.payload.message,
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case LOGOUT_USER:
      return { ...state, currentUser: null, error: '' };
    case CLOSE_TAB_USER:
      return { ...state };
    case APPROVAL_FILE:
      return { ...state };
    case APPROVAL_FILE_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload.value,
      };
    case APPROVAL_FILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.value,
      };
    case AUTH_RESET_STATUSES:
      return {
        ...state,
        success:null,
        error: null,
      };
    default:
      return { ...state };
  }
};
