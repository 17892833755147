/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import {
  ROLE_GET_LIST,
  ROLE_GET_LIST_SUCCESS,
  ROLE_GET_LIST_ERROR,
  ROLE_ADD_RECORD,
  ROLE_ADD_RECORD_ERROR,
  ROLE_ADD_RECORD_SUCCESS,
  ROLE_DELETE_RECORD,
  ROLE_DELETE_RECORD_ERROR,
  ROLE_DELETE_RECORD_SUCCESS,
  ROLE_RESET_STATUSES,
  ROLE_SET_SELECTED,
  ROLE_SET_SELECTED_ERROR,
  ROLE_SET_SELECTED_SUCCESS,
  ROLE_UPDATE_RECORD,
  ROLE_UPDATE_RECORD_ERROR,
  ROLE_UPDATE_RECORD_SUCCESS,
  ROLE_UPDATE_SELECTED,
  ROLE_FULLEXISTROLE,
  ROLE_FULLEXISTROLE_SUCCESS,
  ROLE_FULLEXISTROLE_ERROR,
  ROLE_ISACTIVECHANGE,
  ROLE_ISACTIVECHANGE_SUCCESS,
  ROLE_ISACTIVECHANGE_ERROR,
  ROLE_PAGEGETROLES,
  ROLE_PAGEGETROLES_SUCCESS,
  ROLE_PAGEGETROLES_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
  roleSuccess: null,
  roleError: null,
  roles: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROLE_GET_LIST:
      return { ...state, loading: true };
    case ROLE_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRecord: null,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case ROLE_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case ROLE_SET_SELECTED:
      return { ...state, modaLoading: true };
    case ROLE_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case ROLE_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case ROLE_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case ROLE_ADD_RECORD:
      return { ...state, loading: false };
    case ROLE_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case ROLE_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case ROLE_UPDATE_RECORD:
      return { ...state, loading: false };
    case ROLE_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case ROLE_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case ROLE_DELETE_RECORD:
      return { ...state, loading: false };
    case ROLE_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case ROLE_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case ROLE_FULLEXISTROLE:
      return { ...state, loading: false };
    case ROLE_FULLEXISTROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roleSuccess: action.payload.singleData,
      };
    case ROLE_FULLEXISTROLE_ERROR:
      return { ...state, loading: true, roleError: action.payload.singleData };
    //------------------------------------------------------------------------------
    case ROLE_PAGEGETROLES:
      return { ...state, loading: false };
    case ROLE_PAGEGETROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload.data,
      };
    case ROLE_PAGEGETROLES_ERROR:
      return { ...state, loading: true, error: action.payload.singleData };
    //------------------------------------------------------------------------------
    case ROLE_ISACTIVECHANGE:
      return { ...state, loading: false };
    case ROLE_ISACTIVECHANGE_SUCCESS: {
      let objIndex = state.records.findIndex(
        (x) => x.id === action.payload.singleData.id
      );
      state.records[objIndex] = action.payload.singleData;
      return { ...state, loading: false, records: state.records };
    }
    case ROLE_ISACTIVECHANGE_ERROR:
      return { ...state, loading: true, error: action.payload };
    case ROLE_RESET_STATUSES:
      return { ...state, success: null, error: null };
    default:
      return { ...state };
  }
};
