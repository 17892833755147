// eslint-disable-next-line import/no-cycle
import {
  FOODGROUP_GET_LIST,
  FOODGROUP_GET_LIST_SUCCESS,
  FOODGROUP_GET_LIST_ERROR,
  FOODGROUP_GET_DROPDOWN_LIST,
  FOODGROUP_GET_DROPDOWN_LIST_SUCCESS,
  FOODGROUP_GET_DROPDOWN_LIST_ERROR,
  FOODGROUP_SET_SELECTED,
  FOODGROUP_UPDATE_SELECTED,
  FOODGROUP_SET_SELECTED_SUCCESS,
  FOODGROUP_SET_SELECTED_ERROR,
  FOODGROUP_ADD_RECORD_SUCCESS,
  FOODGROUP_ADD_RECORD_ERROR,
  FOODGROUP_ADD_RECORD,
  FOODGROUP_UPDATE_RECORD_SUCCESS,
  FOODGROUP_UPDATE_RECORD_ERROR,
  FOODGROUP_UPDATE_RECORD,
  FOODGROUP_DELETE_RECORD,
  FOODGROUP_DELETE_RECORD_SUCCESS,
  FOODGROUP_DELETE_RECORD_ERROR,
  FOODGROUP_RESET_STATUSES,
  FOODGROUP_ISACTIVECHANGE,
  FOODGROUP_ISACTIVECHANGE_SUCCESS,
  FOODGROUP_ISACTIVECHANGE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getFoodGroupList = (pageDetail) => ({
  type: FOODGROUP_GET_LIST,
  payload: pageDetail,
});

export const getFoodGroupListSuccess = (items) => ({
  type: FOODGROUP_GET_LIST_SUCCESS,
  payload: items,
});

export const getFoodGroupListError = (error) => ({
  type: FOODGROUP_GET_LIST_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const getFoodGroupDropDownList = (pageDetail) => ({
  type: FOODGROUP_GET_DROPDOWN_LIST,
  payload: pageDetail,
});

export const getFoodGroupDropDownListSuccess = (items) => ({
  type: FOODGROUP_GET_DROPDOWN_LIST_SUCCESS,
  payload: items,
});

export const getFoodGroupDropDownListError = (error) => ({
  type: FOODGROUP_GET_DROPDOWN_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setFoodGroupSelectedRecord = (recordId) => ({
  type: FOODGROUP_SET_SELECTED,
  payload: recordId,
});

export const setFoodGroupSelectedRecordSuccess = (record) => ({
  type: FOODGROUP_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setFoodGroupSelectedRecordError = (error) => ({
  type: FOODGROUP_SET_SELECTED_ERROR,
  payload: error,
});

export const updateFoodGroupSelectedRecord = (record) => ({
  type: FOODGROUP_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addFoodGroupRecord = (record) => ({
  type: FOODGROUP_ADD_RECORD,
  payload: record,
});

export const addFoodGroupRecordSuccess = (msg) => ({
  type: FOODGROUP_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addFoodGroupRecordError = (error) => ({
  type: FOODGROUP_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateFoodGroupRecords = (record) => ({
  type: FOODGROUP_UPDATE_RECORD,
  payload: record,
});

export const updateFoodGroupRecordSuccess = (msg) => ({
  type: FOODGROUP_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateFoodGroupRecordError = (error) => ({
  type: FOODGROUP_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteFoodGroupRecords = (record) => ({
  type: FOODGROUP_DELETE_RECORD,
  payload: record,
});

export const deleteFoodGroupRecordSuccess = (msg) => ({
  type: FOODGROUP_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteFoodGroupRecordError = (error) => ({
  type: FOODGROUP_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeFoodGroup = (record) => ({
  type: FOODGROUP_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeFoodGroupSuccess = (msg) => ({
  type: FOODGROUP_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeFoodGroupError = (error) => ({
  type: FOODGROUP_ISACTIVECHANGE_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const resetFoodGroupStatuses = () => ({
  type: FOODGROUP_RESET_STATUSES,
});
