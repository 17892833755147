/* eslint-disable import/no-cycle */
import {
  UNIT_GET_LIST,
  UNIT_GET_LIST_SUCCESS,
  UNIT_GET_LIST_ERROR,
  UNIT_SET_SELECTED,
  UNIT_UPDATE_SELECTED,
  UNIT_SET_SELECTED_SUCCESS,
  UNIT_SET_SELECTED_ERROR,
  UNIT_ADD_RECORD_SUCCESS,
  UNIT_ADD_RECORD_ERROR,
  UNIT_ADD_RECORD,
  UNIT_UPDATE_RECORD_SUCCESS,
  UNIT_UPDATE_RECORD_ERROR,
  UNIT_UPDATE_RECORD,
  UNIT_DELETE_RECORD,
  UNIT_DELETE_RECORD_SUCCESS,
  UNIT_DELETE_RECORD_ERROR,
  UNIT_RESET_STATUSES,
  UNIT_ISACTIVECHANGE,
  UNIT_ISACTIVECHANGE_SUCCESS,
  UNIT_ISACTIVECHANGE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getUnitList = (pageDetail) => ({
  type: UNIT_GET_LIST,
  payload: pageDetail,
});

export const getUnitListSuccess = (items) => ({
  type: UNIT_GET_LIST_SUCCESS,
  payload: items,
});

export const getUnitListError = (error) => ({
  type: UNIT_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setUnitSelectedRecord = (recordId) => ({
  type: UNIT_SET_SELECTED,
  payload: recordId,
});

export const setUnitSelectedRecordSuccess = (record) => ({
  type: UNIT_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setUnitSelectedRecordError = (error) => ({
  type: UNIT_SET_SELECTED_ERROR,
  payload: error,
});

export const updateUnitSelectedRecord = (record) => ({
  type: UNIT_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addUnitRecord = (record) => ({
  type: UNIT_ADD_RECORD,
  payload: record,
});

export const addUnitRecordSuccess = (msg) => ({
  type: UNIT_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addUnitRecordError = (error) => ({
  type: UNIT_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateUnitRecords = (record) => ({
  type: UNIT_UPDATE_RECORD,
  payload: record,
});

export const updateUnitRecordSuccess = (msg) => ({
  type: UNIT_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateUnitRecordError = (error) => ({
  type: UNIT_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteUnitRecords = (record) => ({
  type: UNIT_DELETE_RECORD,
  payload: record,
});

export const deleteUnitRecordSuccess = (msg) => ({
  type: UNIT_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteUnitRecordError = (error) => ({
  type: UNIT_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeUnit = (record) => ({
  type: UNIT_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeUnitSuccess = (msg) => ({
  type: UNIT_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeUnitError = (error) => ({
  type: UNIT_ISACTIVECHANGE_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const resetUnitStatuses = () => ({ type: UNIT_RESET_STATUSES });
