/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */

import * as XLSX from 'xlsx';

const excelHelper = (result) => {
  let ws = XLSX.utils.json_to_sheet(result);
  let objectMaxLength = [];
  let columnCount = 0;
  let allKeys = [];
  for (let i = 0; i < result.length; i++) {
    let value = Object.values(result[i]);
    if (value.length >= columnCount) {
      columnCount = value.length;
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] === 'number') {
          objectMaxLength[j] = 10;
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j].length
              ? objectMaxLength[j]
              : value[j].length + 3;
        }
      }
      let key = Object.keys(result[i]);
      allKeys = key;
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] =
          objectMaxLength[j] >= key[j].length
            ? objectMaxLength[j]
            : key[j].length + 3;
      }
    } else {
      let value = Object.values(result[i]);
      let key = Object.keys(result[i]);
      for (let j = 0; j < key.length; j++) {
        for (let k = 0; k < allKeys.length; k++) {
          if (key[j] === allKeys[k]) {
            objectMaxLength[k] =
              objectMaxLength[k] >= value[j].length
                ? objectMaxLength[k]
                : value[j].length + 3;

            objectMaxLength[k] =
              objectMaxLength[k] >= key[j].length
                ? objectMaxLength[k]
                : key[j].length + 3;
          }
        }
      }
    }
  }

  let wscols = objectMaxLength.map((value) => {
    return { width: value, wch: value };
  });
  ws['!cols'] = wscols;
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet!');
  XLSX.writeFile(wb, `${new Date()}.xlsx`);
};

export default excelHelper;
