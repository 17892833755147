/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  ROLEGROUP_ADD_RECORD,
  ROLEGROUP_UPDATE_RECORD,
  ROLEGROUP_GET_LIST,
  ROLEGROUP_SET_SELECTED,
  ROLEGROUP_DELETE_RECORD,
  ROLEGROUP_ISACTIVECHANGE,
} from '../../actions';

import {
  getRoleGroupListSuccess,
  getRoleGroupListError,
  setRoleGroupSelectedRecordSuccess,
  setRoleGroupSelectedRecordError,
  addRoleGroupRecordSuccess,
  addRoleGroupRecordError,
  updateRoleGroupRecordSuccess,
  deleteRoleGroupRecordSuccess,
  deleteRoleGroupRecordError,
  updateRoleGroupRecordError,
  isActiveChangeRoleGroupError,
  isActiveChangeRoleGroupSuccess,
} from './actions';

//---------------------------------------------------------------------
const getRoleGroupListRequest = async (pageDetail) => {
  if (pageDetail) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/rolegroup?ps=${pageDetail.pageSize}&pn=${pageDetail.pageNumber}`
      )
      .then((response) => response.data);
  }
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/rolegroup`)
    .then((response) => response.data);
};

function* getRoleGroupListItems({ payload }) {
  try {
    if (payload) {
      let pageSize = yield select((state) => state.settings.pageRecordCount);
      payload.pageSize = pageSize;
    }
    const response = yield call(getRoleGroupListRequest, payload);
    yield put(getRoleGroupListSuccess(response));
  } catch (error) {
    yield put(getRoleGroupListError(error));
  }
}
//----------------------------------------------------------------------------------
const setRoleGroupSelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/rolegroup/${id}`)
    .then((response) => response.data.singleData);
};

function* setRoleGroupSelectedRecord({ payload }) {
  try {
    const response = yield call(setRoleGroupSelectedRecordRequest, payload);
    yield put(setRoleGroupSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setRoleGroupSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addRoleGroupRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/rolegroup`, record)
    .then((response) => response.data);
};

function* addRoleGroupRecords({ payload }) {
  try {
    yield call(addRoleGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addRoleGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getRoleGroupListItems);
  } catch (error) {
    yield put(
      addRoleGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateRoleGroupRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/rolegroup`, record)
    .then((response) => response.data);
};

function* updateRoleGroupRecords({ payload }) {
  try {
    yield call(updateRoleGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateRoleGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getRoleGroupListItems);
  } catch (error) {
    yield put(
      updateRoleGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeRoleGroupRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/rolegroup/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeRoleGroup({ payload }) {
  try {
    const response = yield call(isActiveChangeRoleGroupRequest, payload);
    yield put(isActiveChangeRoleGroupSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeRoleGroupError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const deleteRoleGroupRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/rolegroup/${recordId}`)
    .then((response) => response.data);
};

function* deleteRoleGroupRecords({ payload }) {
  try {
    yield call(deleteRoleGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteRoleGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getRoleGroupListItems);
  } catch (error) {
    yield put(
      deleteRoleGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(ROLEGROUP_GET_LIST, getRoleGroupListItems);
}

export function* watchSetSelected() {
  yield takeEvery(ROLEGROUP_SET_SELECTED, setRoleGroupSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(ROLEGROUP_ADD_RECORD, addRoleGroupRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(ROLEGROUP_UPDATE_RECORD, updateRoleGroupRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(ROLEGROUP_DELETE_RECORD, deleteRoleGroupRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(ROLEGROUP_ISACTIVECHANGE, isActiveChangeRoleGroup);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(wathcIsActiveChangeItem),
  ]);
}
