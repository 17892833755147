/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  LACKCOMPLETION_ADD_RECORD,
  LACKCOMPLETION_UPDATE_RECORD,
  LACKCOMPLETION_GET_LIST,
  LACKCOMPLETION_SET_SELECTED,
  LACKCOMPLETION_DELETE_RECORD,
  LACKCOMPLETION_ISACTIVECHANGE,
  LACKCOMPLETION_BYNUTRIENTID,
  LACKCOMPLETION_UPSERT_RECORD,
} from '../../actions';

import {
  getLackCompletionListSuccess,
  getLackCompletionListError,
  setLackCompletionSelectedRecordSuccess,
  setLackCompletionSelectedRecordError,
  addLackCompletionRecordSuccess,
  addLackCompletionRecordError,
  updateLackCompletionRecordSuccess,
  updateLackCompletionRecordError,
  isActiveChangeLackCompletionSuccess,
  isActiveChangeLackCompletionError,
  deleteLackCompletionRecordSuccess,
  deleteLackCompletionRecordError,
  LackCompletionByNutrientIdSuccess,
  LackCompletionByNutrientIdError,
  upsertLackCompletionRecordsSuccess,
  upsertLackCompletionRecordsError,
} from './actions';

//---------------------------------------------------------------------
const getLackCompletionListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/lackcompletion${url || ''}`)
    .then((response) => response.data);
};

function* getLackCompletionListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getLackCompletionListRequest,
        filterBuilder(payload)
      );

      yield put(getLackCompletionListSuccess(response));
    } else {
      const response = yield call(getLackCompletionListRequest);
      yield put(getLackCompletionListSuccess(response));
    }
  } catch (error) {
    yield put(getLackCompletionListError(error));
  }
}
const setLackCompletionSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/lackcompletion/${id}`)
    .then((response) => response.data.singleData);

function* setLackCompletionSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setLackCompletionSelectedRecordRequest,
      payload
    );
    yield put(setLackCompletionSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setLackCompletionSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addLackCompletionRecordRequest = async (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/lackcompletion`, record)
    .then((response) => response.data);

function* addLackCompletionRecords({ payload }) {
  try {
    yield call(addLackCompletionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addLackCompletionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getLackCompletionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addLackCompletionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateLackCompletionRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/lackcompletion`, record)
    .then((response) => response.data);

function* updateLackCompletionRecords({ payload }) {
  try {
    yield call(updateLackCompletionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateLackCompletionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getLackCompletionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateLackCompletionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const isActiveChangeLackCompletionRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/lackcompletion/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeLackCompletion({ payload }) {
  try {
    const response = yield call(isActiveChangeLackCompletionRequest, payload);
    yield put(isActiveChangeLackCompletionSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeLackCompletionError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

//----------------------------------------------------------------------------------
const deleteLackCompletionRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/lackcompletion/${recordId}`)
    .then((response) => response.data);

function* deleteLackCompletionRecords({ payload }) {
  try {
    yield call(deleteLackCompletionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteLackCompletionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getLackCompletionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteLackCompletionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const lackCompletionByNutrientIdRequest = (recordId) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/lackcompletion/byNutrientId/${recordId}`
    )
    .then((response) => response.data.singleData);

function* lackCompletionByNutrientIdRecords({ payload }) {
  try {
    const response = yield call(lackCompletionByNutrientIdRequest, payload);
    yield put(LackCompletionByNutrientIdSuccess(response));
  } catch (error) {
    yield put(
      LackCompletionByNutrientIdError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const upsertLackCompletionRecordRequest = async (records) => {
  // eslint-disable-next-line no-return-await
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/lackcompletion/upsert`, records)
    .then((response) => response.data);
};

function* upsertLackCompletionRecords({ payload }) {
  try {
    const response = yield call(upsertLackCompletionRecordRequest, payload);
    if (response) {
      const messageType = yield select((state) =>
        state.settings.notificationMessages.find(
          (x) => x.type === NotificationType.Success
        )
      );
      yield put(
        upsertLackCompletionRecordsSuccess(
          NotificationHelpers(messageType, NotificationSubType.Add)
        )
      );
    }
  } catch (error) {
    yield put(upsertLackCompletionRecordsError(error));
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(800, LACKCOMPLETION_GET_LIST, getLackCompletionListItems);
}

export function* watchSetSelected() {
  yield takeEvery(LACKCOMPLETION_SET_SELECTED, setLackCompletionSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(LACKCOMPLETION_ADD_RECORD, addLackCompletionRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(LACKCOMPLETION_UPDATE_RECORD, updateLackCompletionRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(LACKCOMPLETION_DELETE_RECORD, deleteLackCompletionRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(LACKCOMPLETION_ISACTIVECHANGE, isActiveChangeLackCompletion);
}
export function* wathcByNutientId() {
  yield takeEvery(
    LACKCOMPLETION_BYNUTRIENTID,
    lackCompletionByNutrientIdRecords
  );
}
export function* watchUpsert() {
  yield takeEvery(LACKCOMPLETION_UPSERT_RECORD, upsertLackCompletionRecords);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcIsActiveChangeItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(wathcByNutientId),
    fork(watchUpsert),
  ]);
}
