// eslint-disable-next-line import/no-cycle
import {
  USERPREFERENCE_GET_LIST,
  USERPREFERENCE_GET_LIST_SUCCESS,
  USERPREFERENCE_GET_LIST_ERROR,
  USERPREFERENCE_ADD_RECORD,
  USERPREFERENCE_ADD_RECORD_ERROR,
  USERPREFERENCE_ADD_RECORD_SUCCESS,
  USERPREFERENCE_DELETE_RECORD,
  USERPREFERENCE_DELETE_RECORD_ERROR,
  USERPREFERENCE_DELETE_RECORD_SUCCESS,
  USERPREFERENCE_RESET_STATUSES,
  USERPREFERENCE_SET_SELECTED,
  USERPREFERENCE_SET_SELECTED_ERROR,
  USERPREFERENCE_SET_SELECTED_SUCCESS,
  USERPREFERENCE_UPDATE_RECORD,
  USERPREFERENCE_UPDATE_RECORD_ERROR,
  USERPREFERENCE_UPDATE_RECORD_SUCCESS,
  USERPREFERENCE_UPDATE_SELECTED,
  USERPREFERENCE_BYUSERID_SET_SELECTED,
  USERPREFERENCE_BYUSERID_SET_SELECTED_SUCCESS,
  USERPREFERENCE_BYUSERID_SET_SELECTED_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getUserPreferenceList = () => ({
  type: USERPREFERENCE_GET_LIST,
});

export const getUserPreferenceListSuccess = (items) => ({
  type: USERPREFERENCE_GET_LIST_SUCCESS,
  payload: items,
});

export const getUserPreferenceListError = (error) => ({
  type: USERPREFERENCE_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setUserPreferenceSelectedRecord = (recordId) => ({
  type: USERPREFERENCE_SET_SELECTED,
  payload: recordId,
});

export const setUserPreferenceSelectedRecordSuccess = (record) => ({
  type: USERPREFERENCE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setUserPreferenceSelectedRecordError = (error) => ({
  type: USERPREFERENCE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateUserPreferenceSelectedRecord = (record) => ({
  type: USERPREFERENCE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addUserPreferenceRecord = (record) => ({
  type: USERPREFERENCE_ADD_RECORD,
  payload: record,
});

export const addUserPreferenceRecordSuccess = (msg) => ({
  type: USERPREFERENCE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addUserPreferenceRecordError = (error) => ({
  type: USERPREFERENCE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateUserPreferenceRecords = (record) => ({
  type: USERPREFERENCE_UPDATE_RECORD,
  payload: record,
});

export const updateUserPreferenceRecordSuccess = (msg) => ({
  type: USERPREFERENCE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateUserPreferenceRecordError = (error) => ({
  type: USERPREFERENCE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteUserPreferenceRecords = (record) => ({
  type: USERPREFERENCE_DELETE_RECORD,
  payload: record,
});

export const deleteUserPreferenceRecordSuccess = (msg) => ({
  type: USERPREFERENCE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteUserPreferenceRecordError = (error) => ({
  type: USERPREFERENCE_DELETE_RECORD_ERROR,
  payload: error,
});

export const resetUserPreferenceStatuses = () => ({
  type: USERPREFERENCE_RESET_STATUSES,
});
//-------------------------------------------------------------------------
export const setUserPreferenceByUserId = (recordId) => ({
  type: USERPREFERENCE_BYUSERID_SET_SELECTED,
  payload: recordId,
});
export const setUserPreferenceByUserIdSuccess = (record) => ({
  type: USERPREFERENCE_BYUSERID_SET_SELECTED_SUCCESS,
  payload: record,
});
export const setUserPreferenceByUserIdError = (record) => ({
  type: USERPREFERENCE_BYUSERID_SET_SELECTED_ERROR,
  payload: record,
});
