/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  DATASOURCE_ADD_RECORD,
  DATASOURCE_UPDATE_RECORD,
  DATASOURCE_GET_LIST,
  DATASOURCE_SET_SELECTED,
  DATASOURCE_DELETE_RECORD,
  deleteDataSourceRecordSuccess,
  deleteDataSourceRecordError,
  DATASOURCE_ISACTIVECHANGE,
  DATASOURCE_GET_DROPDOWN_LIST,
} from '../../actions';

import {
  getDataSourceListSuccess,
  getDataSourceListError,
  setDataSourceSelectedRecordSuccess,
  setDataSourceSelectedRecordError,
  addDataSourceRecordSuccess,
  addDataSourceRecordError,
  updateDataSourceRecordSuccess,
  updateDataSourceRecordError,
  isActiveChangeDataSourceSuccess,
  isActiveChangeDataSourceError,
  getDataSourceDropDownListSuccess,
  getDataSourceDropDownListError,
} from './actions';

//---------------------------------------------------------------------
const getDataSourceListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/datasource${url || ''}`)
    .then((response) => response.data);
};

function* getDataSourceListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getDataSourceListRequest,
        filterBuilder(payload)
      );

      yield put(getDataSourceListSuccess(response));
    } else {
      const response = yield call(getDataSourceListRequest);
      yield put(getDataSourceListSuccess(response));
    }
  } catch (error) {
    yield put(getDataSourceListError(error));
  }
}
//----------------------------------------------------------------------------------
const getDataSouceDropDownListRequest = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/datasource/fordropdown`)
    .then((response) => response.data);
};

function* getDataSourceDropDownListItems() {
  try {
    const response = yield call(getDataSouceDropDownListRequest);
    yield put(getDataSourceDropDownListSuccess(response));
  } catch (error) {
    yield put(getDataSourceDropDownListError(error));
  }
}
//----------------------------------------------------------------------------------
const setDataSourceSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/datasource/${id}`)
    .then((response) => response.data.singleData);

function* setDataSourceSelectedRecord({ payload }) {
  try {
    const response = yield call(setDataSourceSelectedRecordRequest, payload);
    yield put(setDataSourceSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setDataSourceSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addDataSourceRecordRequest = async (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/datasource`, record)
    .then((response) => response.data);

function* addDataSourceRecords({ payload }) {
  try {
    yield call(addDataSourceRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addDataSourceRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getDataSourceListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addDataSourceRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateDataSourceRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/datasource`, record)
    .then((response) => response.data);

function* updateDataSourceRecords({ payload }) {
  try {
    yield call(updateDataSourceRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateDataSourceRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getDataSourceListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateDataSourceRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const isActiveChangeDataSourceRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/datasource/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeDataSource({ payload }) {
  try {
    const response = yield call(isActiveChangeDataSourceRequest, payload);
    yield put(isActiveChangeDataSourceSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeDataSourceError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

//----------------------------------------------------------------------------------
const deleteDataSourceRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/datasource/${recordId}`)
    .then((response) => response.data);

function* deleteDataSourceRecords({ payload }) {
  try {
    yield call(deleteDataSourceRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteDataSourceRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getDataSourceListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteDataSourceRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

export function* watchGetList() {
  yield debounce(800, DATASOURCE_GET_LIST, getDataSourceListItems);
}

export function* watchSetSelected() {
  yield takeEvery(DATASOURCE_SET_SELECTED, setDataSourceSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(DATASOURCE_ADD_RECORD, addDataSourceRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(DATASOURCE_UPDATE_RECORD, updateDataSourceRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(DATASOURCE_DELETE_RECORD, deleteDataSourceRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(DATASOURCE_ISACTIVECHANGE, isActiveChangeDataSource);
}
export function* watchGetDropDownList() {
  yield takeEvery(DATASOURCE_GET_DROPDOWN_LIST, getDataSourceDropDownListItems);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(watchGetDropDownList),
    fork(wathcAddItem),
    fork(wathcIsActiveChangeItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
