const NotificationType = Object.freeze({
  Success: Symbol('Add'),
  Error: Symbol('Error'),
  Warning: Symbol('Warning'),
  Info: Symbol('Info'),
});

const NotificationSubType = Object.freeze({
  Add: Symbol('Add'),
  Edit: Symbol('Update'),
  Delete: Symbol('Delete'),
});

export { NotificationType, NotificationSubType };
