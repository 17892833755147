// eslint-disable-next-line import/no-cycle
import {
  PLANREPASTFOODPORTION_GET_LIST,
  PLANREPASTFOODPORTION_GET_LIST_SUCCESS,
  PLANREPASTFOODPORTION_GET_LIST_ERROR,
  PLANREPASTFOODPORTION_SET_SELECTED,
  PLANREPASTFOODPORTION_UPDATE_SELECTED,
  PLANREPASTFOODPORTION_SET_SELECTED_SUCCESS,
  PLANREPASTFOODPORTION_SET_SELECTED_ERROR,
  PLANREPASTFOODPORTION_ADD_RECORD_SUCCESS,
  PLANREPASTFOODPORTION_ADD_RECORD_ERROR,
  PLANREPASTFOODPORTION_ADD_RECORD,
  PLANREPASTFOODPORTION_UPDATE_RECORD_SUCCESS,
  PLANREPASTFOODPORTION_UPDATE_RECORD_ERROR,
  PLANREPASTFOODPORTION_UPDATE_RECORD,
  PLANREPASTFOODPORTION_DELETE_RECORD,
  PLANREPASTFOODPORTION_DELETE_RECORD_SUCCESS,
  PLANREPASTFOODPORTION_DELETE_RECORD_ERROR,
  PLANREPASTFOODPORTION_RESET_STATUSES,
  PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD,
  PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_ERROR,
  PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_SUCCESS,
} from '../../actions';

//-------------------------------------------------------------------------
export const getPlanRepastFoodPortionList = (pageDetail) => ({
  type: PLANREPASTFOODPORTION_GET_LIST,
  payload: pageDetail,
});

export const getPlanRepastFoodPortionListSuccess = (items) => ({
  type: PLANREPASTFOODPORTION_GET_LIST_SUCCESS,
  payload: items,
});

export const getPlanRepastFoodPortionListError = (error) => ({
  type: PLANREPASTFOODPORTION_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setPlanRepastFoodPortionSelectedRecord = (recordId) => ({
  type: PLANREPASTFOODPORTION_SET_SELECTED,
  payload: recordId,
});

export const setPlanRepastFoodPortionSelectedRecordSuccess = (record) => ({
  type: PLANREPASTFOODPORTION_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setPlanRepastFoodPortionSelectedRecordError = (error) => ({
  type: PLANREPASTFOODPORTION_SET_SELECTED_ERROR,
  payload: error,
});

export const updatePlanRepastFoodPortionSelectedRecord = (record) => ({
  type: PLANREPASTFOODPORTION_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addPlanRepastFoodPortionRecord = (record) => ({
  type: PLANREPASTFOODPORTION_ADD_RECORD,
  payload: record,
});

export const addPlanRepastFoodPortionRecordSuccess = (msg) => ({
  type: PLANREPASTFOODPORTION_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addPlanRepastFoodPortionRecordError = (error) => ({
  type: PLANREPASTFOODPORTION_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updatePlanRepastFoodPortionRecords = (record) => ({
  type: PLANREPASTFOODPORTION_UPDATE_RECORD,
  payload: record,
});

export const updatePlanRepastFoodPortionRecordSuccess = (msg) => ({
  type: PLANREPASTFOODPORTION_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updatePlanRepastFoodPortionRecordError = (error) => ({
  type: PLANREPASTFOODPORTION_UPDATE_RECORD_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------

export const updatePlanRepastFoodPortionDefaultValue = (record) => ({
  type: PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD,
  payload: record,
});

export const updatePlanRepastFoodPortionDefaultValueSuccess = (msg) => ({
  type: PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_SUCCESS,
  payload: msg,
});

export const updatePlanRepastFoodPortionDefaultValuedError = (error) => ({
  type: PLANREPASTFOODPORTION_UPDATE_DEFAULTVALUE_RECORD_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------

export const deletePlanRepastFoodPortionRecords = (record) => ({
  type: PLANREPASTFOODPORTION_DELETE_RECORD,
  payload: record,
});

export const deletePlanRepastFoodPortionRecordSuccess = (msg) => ({
  type: PLANREPASTFOODPORTION_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deletePlanRepastFoodPortionRecordError = (error) => ({
  type: PLANREPASTFOODPORTION_DELETE_RECORD_ERROR,
  payload: error,
});

export const resetPlanRepastFoodPortionStatuses = () => ({
  type: PLANREPASTFOODPORTION_RESET_STATUSES,
});
//-------------------------------------------------------------------------
