/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  UNIT_ADD_RECORD,
  UNIT_UPDATE_RECORD,
  UNIT_GET_LIST,
  UNIT_SET_SELECTED,
  UNIT_DELETE_RECORD,
  deleteUnitRecordSuccess,
  deleteUnitRecordError,
  UNIT_ISACTIVECHANGE,
} from '../../actions';

import {
  getUnitListSuccess,
  getUnitListError,
  setUnitSelectedRecordSuccess,
  setUnitSelectedRecordError,
  addUnitRecordSuccess,
  addUnitRecordError,
  updateUnitRecordSuccess,
  updateUnitRecordError,
  isActiveChangeUnitSuccess,
  isActiveChangeUnitError,
} from './actions';

//---------------------------------------------------------------------
const getUnitListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/unit${url || ''}`)
    .then((response) => response.data);
};

function* getUnitListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );

      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(getUnitListRequest, filterBuilder(payload));
      yield put(getUnitListSuccess(response));
    } else {
      const response = yield call(getUnitListRequest);
      yield put(getUnitListSuccess(response));
    }
  } catch (error) {
    yield put(getUnitListError(error));
  }
}
//----------------------------------------------------------------------------------
const setUnitSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/unit/${id}`)
    .then((response) => response.data.singleData);

function* setUnitSelectedRecord({ payload }) {
  try {
    const response = yield call(setUnitSelectedRecordRequest, payload);
    yield put(setUnitSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setUnitSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addUnitRecordRequest = async (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/unit`, record)
    .then((response) => response.data);

function* addUnitRecords({ payload }) {
  try {
    yield call(addUnitRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addUnitRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getUnitListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addUnitRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateUnitRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/unit`, record)
    .then((response) => response.data);

function* updateUnitRecords({ payload }) {
  try {
    yield call(updateUnitRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateUnitRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getUnitListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateUnitRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const deleteUnitRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/unit/${recordId}`)
    .then((response) => response.data);

function* deleteUnitRecords({ payload }) {
  try {
    yield call(deleteUnitRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteUnitRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getUnitListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteUnitRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeUnitRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/unit/isactivechange/${recordId}`)
    .then((response) => response.data);

function* isActiveChangeUnit({ payload }) {
  try {
    const response = yield call(isActiveChangeUnitRequest, payload);
    yield put(isActiveChangeUnitSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeUnitError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(800, UNIT_GET_LIST, getUnitListItems);
}

export function* watchSetSelected() {
  yield takeEvery(UNIT_SET_SELECTED, setUnitSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(UNIT_ADD_RECORD, addUnitRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(UNIT_UPDATE_RECORD, updateUnitRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(UNIT_DELETE_RECORD, deleteUnitRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(UNIT_ISACTIVECHANGE, isActiveChangeUnit);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
