/* eslint-disable no-param-reassign */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  PORTION_ADD_RECORD,
  PORTION_UPDATE_RECORD,
  PORTION_GET_LIST,
  PORTION_SET_SELECTED,
  PORTION_DELETE_RECORD,
  PORTION_GETNAMEWITHID_ONERECORD,
  PORTION_GETNAMEWITHID_LIST,
  deletePortionRecordSuccess,
  deletePortionRecordError,
  PORTION_ISACTIVECHANGE,
} from '../../actions';

import {
  getPortionListSuccess,
  getPortionListError,
  setPortionSelectedRecordSuccess,
  setPortionSelectedRecordError,
  addPortionRecordSuccess,
  addPortionRecordError,
  updatePortionRecordSuccess,
  updatePortionRecordError,
  getPortionNameWithIdSuccess,
  getPortionNameWithIdError,
  getPortionNameWithIdOneRecordSuccess,
  getPortionNameWithIdOneRecordError,
  isActiveChangePortionError,
  isActiveChangePortionSuccess,
} from './actions';

//---------------------------------------------------------------------
const getPortionListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/portion${url || ''}`)
    .then((response) => response.data);
};

function* getPortionListItems({ payload }) {
  try {
    if (payload) {
      if (!payload.pageSize) {
        payload.pageSize = yield select(
          (state) => state.settings.pageRecordCount
        );
      }
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getPortionListRequest,
        filterBuilder(payload)
      );
      yield put(getPortionListSuccess(response));
    } else {
      const response = yield call(getPortionListRequest);
      yield put(getPortionListSuccess(response));
    }
  } catch (error) {
    yield put(getPortionListError(error));
  }
}

//----------------------------------------------------------------------------------

const getPortionNameWithIdOneRecordRequest = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/portion/portionnamewithid/${id}`)
    .then((response) => response.data);
};

function* getPortionNameWithIdOneRecordItems({ payload }) {
  try {
    if (payload) {
      const pageSize = yield select((state) => state.settings.pageRecordCount);
      payload.pageSize = pageSize;
    }
    const response = yield call(getPortionNameWithIdOneRecordRequest, payload);
    yield put(getPortionNameWithIdOneRecordSuccess(response));
  } catch (error) {
    yield put(getPortionNameWithIdOneRecordError(error));
  }
}
//----------------------------------------------------------------------------------

const getPortionNameWithIdListRequest = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/portion/portionnamewithid`)
    .then((response) => response.data);
};

function* getPortionNameWithIdListItems({ payload }) {
  try {
    if (payload) {
      const pageSize = yield select((state) => state.settings.pageRecordCount);
      payload.pageSize = pageSize;
    }
    const response = yield call(getPortionNameWithIdListRequest, payload);
    yield put(getPortionNameWithIdSuccess(response));
  } catch (error) {
    yield put(getPortionNameWithIdError(error));
  }
}
//----------------------------------------------------------------------------------

const setPortionSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/portion/portion/${id}`)
    .then((response) => response.data.singleData);

function* setPortionSelectedRecord({ payload }) {
  try {
    const response = yield call(setPortionSelectedRecordRequest, payload);
    yield put(setPortionSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setPortionSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addPortionRecordRequest = (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/portion`, record)
    .then((response) => response.data);

function* addPortionRecords({ payload }) {
  try {
    yield call(addPortionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addPortionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getPortionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addPortionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updatePortionRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/portion`, record)
    .then((response) => response.data);

function* updatePortionRecords({ payload }) {
  try {
    yield call(updatePortionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updatePortionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getPortionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updatePortionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangePortionRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/portion/isactivechange/${recordId}`)
    .then((response) => response.data);

function* isActiveChangePortion({ payload }) {
  try {
    const response = yield call(isActiveChangePortionRequest, payload);
    yield put(isActiveChangePortionSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangePortionError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const deletePortionRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/portion/${recordId}`)
    .then((response) => response.data);

function* deletePortionRecords({ payload }) {
  try {
    yield call(deletePortionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deletePortionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getPortionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deletePortionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(PORTION_GET_LIST, getPortionListItems);
}
export function* watchGetNameWithIdOneRecord() {
  yield takeEvery(
    PORTION_GETNAMEWITHID_ONERECORD,
    getPortionNameWithIdOneRecordItems
  );
}
export function* watchGetNameWithIdList() {
  yield debounce(
    800,
    PORTION_GETNAMEWITHID_LIST,
    getPortionNameWithIdListItems
  );
}
export function* watchSetSelected() {
  yield takeEvery(PORTION_SET_SELECTED, setPortionSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(PORTION_ADD_RECORD, addPortionRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(PORTION_UPDATE_RECORD, updatePortionRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(PORTION_DELETE_RECORD, deletePortionRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(PORTION_ISACTIVECHANGE, isActiveChangePortion);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetNameWithIdOneRecord),
    fork(wathcIsActiveChangeItem),
    fork(watchGetNameWithIdList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
