/* eslint-disable spaced-comment */
/* eslint-disable prefer-template */
/* eslint-disable prettier/prettier */
/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-else-return */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  debounce,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  PLANREPAST_ADD_RECORD,
  PLANREPAST_UPDATE_RECORD,
  PLANREPAST_GET_LIST,
  PLANREPAST_SET_SELECTED,
  PLANREPAST_DELETE_RECORD,
  deletePlanRepastRecordSuccess,
  deletePlanRepastRecordError,
  PLANREPAST_UPDATE_BATCHRECORD,
  PLANREPAST_PLANREPASTADDFOOD,
  PLANREPAST_PLANREPASTDELETEFOOD,
  getNutrientList,
  getNutrientListSuccess,
} from '../../actions';

import {
  getPlanRepastListSuccess,
  getPlanRepastListError,
  setPlanRepastSelectedRecordSuccess,
  setPlanRepastSelectedRecordError,
  addPlanRepastRecordSuccess,
  addPlanRepastRecordError,
  updatePlanRepastRecordSuccess,
  updatePlanRepastRecordError,
  updatePlanRepastBatchRecordSuccess,
  updatePlanRepastBatchRecordError,
  planRepastPlanRepastAddFoodRecordSuccess,
} from './actions';

import {
  planAnalysisReferenceNutrient,
  speciallyWithId,
} from '../planning/actions';

//---------------------------------------------------------------------
const getPlanRepastListRequest = (pageDetail) => {
  if (pageDetail) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/planrepast?ps=${pageDetail.pageSize}&pn=${pageDetail.pageNumber}`
      )
      .then((response) => response.data);
  } else {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/planrepast`)
      .then((response) => response.data);
  }
};

function* getPlanRepastListItems({ payload }) {
  try {
    if (payload) {
      let pageSize = yield select((state) => state.settings.pageRecordCount);
      // eslint-disable-next-line no-param-reassign
      payload.pageSize = pageSize;
    }
    const response = yield call(getPlanRepastListRequest, payload);
    yield put(getPlanRepastListSuccess(response));
  } catch (error) {
    yield put(getPlanRepastListError(error));
  }
}
//----------------------------------------------------------------------------------

const setPlanRepastSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/planrepast/${id}`)
    .then((response) => response.data.data);

function* setPlanRepastSelectedRecord({ payload }) {
  try {
    const response = yield call(setPlanRepastSelectedRecordRequest, payload);
    let nutrient = yield select((state) => state.nutrient);
  } catch (error) {
    yield put(
      setPlanRepastSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------

// function* planRepastFoodAdd(response) {
//   let nutrient = yield select((state) => state.nutrient);
//   let planRepast = yield select((state) => state.planRepast);

//   let newRepast = planRepast.repast.find(
//     (x) => x.id === response.payload.response.planRepastId
//   );
//   let dynamicFoodMealNutrientsArray = [];
//   let nutrientIdArray = []; //ilerde bu arrayın içine kullanıcının belirlediği sıradaki nutrientlar dolduracaksın. aşağıdaki kod gene calısacaktır
//   nutrient.records
//     .sort((a, b) => a.displayOrder - b.displayOrder)
//     .forEach((element, key) => {
//       if (key < 8) {
//         nutrientIdArray.push(element.id);
//       }
//     });

//   let dynamicTotal = [];
//   response.payload.response.food.foodNutrients.forEach(
//     (foodNutri, foodNutriIndex) => {
//       let isFood = nutrientIdArray.find((x) => x === foodNutri.secondId);
//       if (isFood) {
//         let dynamicFoodMealNutrient = {
//           header: nutrient.records.find((x) => x.id === foodNutri.secondId)
//             ?.name,
//           value:
//             (foodNutri.value / 100) *
//             (response.payload.response.planRepastFoodPortion != null &&
//             response.payload.planRepastFoodPortion.defaultValue != null
//               ? response.payload.planRepastFoodPortion.defaultValue
//               : 0),
//           nutrientId: foodNutri.secondId,
//           displayOrder: nutrient.records.find(
//             (x) => x.id === foodNutri.secondId
//           )?.displayOrder,
//         };
//         let dynamicValue = dynamicTotal.find(
//           (x) => x.nutrientId === dynamicFoodMealNutrient.nutrientId
//         );
//         if (dynamicValue) {
//           dynamicValue.value += dynamicFoodMealNutrient.value;
//         } else {
//           dynamicTotal.push({
//             header: nutrient.records.find((x) => x.id === foodNutri.secondId)
//               ?.name,
//             value:
//               (foodNutri.value / 100) *
//               (response.payload.response.planRepastFoodPortion != null &&
//               response.payload.response.planRepastFoodPortion.defaultValue !=
//                 null
//                 ? response.payload.planRepastFoodPortion.defaultValue
//                 : 0),
//             nutrientId: foodNutri.secondId,
//             displayOrder: nutrient.records.find(
//               (x) => x.id === foodNutri.secondId
//             )?.displayOrder,
//           });
//         }

//         dynamicFoodMealNutrientsArray.push(dynamicFoodMealNutrient);
//       }
//     }
//   );

//   response.payload.response['dynamicFoodMealNutrientsArray'] =
//     dynamicFoodMealNutrientsArray;
//   response.payload.response['dynamicTotal'] = dynamicTotal;
//   newRepast.planRepastFoods.push(response.payload.response);
//   let oldRepast = planRepast.repast.filter((x) => x.id !== newRepast.id);
//   oldRepast.push(newRepast);

//   yield put(planRepastPlanRepastAddFoodRecordSuccess(oldRepast));
// }

//***************************************************************************************** */

function* planRepastFoodDelete(response) {
  let planRepast = yield select((state) => state.planRepast);
  planRepast.repast.forEach((repa) => {
    repa.planRepastFoods = repa.planRepastFoods.filter(
      (repaFood) => repaFood.id !== response.payload.response
    );
  });
}

//----------------------------------------------------------------------------------
const addPlanRepastRecordRequest = (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/planrepast`, record)
    .then((response) => response.data);

function* addPlanRepastRecords({ payload }) {
  try {
    yield call(addPlanRepastRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addPlanRepastRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(setPlanRepastSelectedRecord, { payload: payload.planId });
    yield put(
      speciallyWithId(yield select((state) => state.plan?.selectedRecord.id))
    );
  } catch (error) {
    yield put(
      addPlanRepastRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updatePlanRepastRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/planrepast`, record)
    .then((response) => response.data);

function* updatePlanRepastRecords({ payload }) {
  try {
    yield call(updatePlanRepastRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updatePlanRepastRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(setPlanRepastSelectedRecord, { payload: payload.planId });
  } catch (error) {
    yield put(
      updatePlanRepastRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const updatePlanRepastBatchRecordRequest = (records) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}/planrepast/batchupdate`, records)
    .then((response) => response.data);
};

function* updatePlanRepastBatchRecords({ payload }) {
  try {
    yield call(updatePlanRepastBatchRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updatePlanRepastBatchRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    // yield put(planAnalysisReferenceNutrient(payload[0].planId));
    yield put(speciallyWithId(yield select((state) => state.plan?.selectedRecord.id)));
  } catch (error) {
    yield put(
      updatePlanRepastBatchRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

const deletePlanRepastRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/planrepast/${recordId}`)
    .then((response) => response.data);

function* deletePlanRepastRecords({ payload }) {
  try {
    yield call(deletePlanRepastRecordRequest, payload.repastId);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deletePlanRepastRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );

    yield put(
      speciallyWithId(yield select((state) => state.plan?.selectedRecord.id))
    );
  } catch (error) {
    yield put(
      deletePlanRepastRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(PLANREPAST_GET_LIST, getPlanRepastListItems);
}

export function* watchSetSelected() {
  yield takeEvery(PLANREPAST_SET_SELECTED, setPlanRepastSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(PLANREPAST_ADD_RECORD, addPlanRepastRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(PLANREPAST_UPDATE_RECORD, updatePlanRepastRecords);
}
export function* wathcUpdateItems() {
  yield takeEvery(PLANREPAST_UPDATE_BATCHRECORD, updatePlanRepastBatchRecords);
}
export function* wathcDeleteItem() {
  yield takeEvery(PLANREPAST_DELETE_RECORD, deletePlanRepastRecords);
}

// export function* wathcPlanRepastFoodRecordAdd() {
//   yield takeEvery(PLANREPAST_PLANREPASTADDFOOD, planRepastFoodAdd);
// }
export function* wathcPlanRepastFoodRecordDelete() {
  yield takeEvery(PLANREPAST_PLANREPASTDELETEFOOD, planRepastFoodDelete);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    // fork(wathcPlanRepastFoodRecordAdd),
    fork(wathcPlanRepastFoodRecordDelete),

    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcUpdateItems),
    fork(wathcDeleteItem),
  ]);
}
