// eslint-disable-next-line import/no-cycle
import {
  USERPLAN_GET_LIST,
  USERPLAN_GET_LIST_SUCCESS,
  USERPLAN_GET_LIST_ERROR,
  USERPLAN_ADD_RECORD,
  USERPLAN_ADD_RECORD_ERROR,
  USERPLAN_ADD_RECORD_SUCCESS,
  USERPLAN_DELETE_RECORD,
  USERPLAN_DELETE_RECORD_ERROR,
  USERPLAN_DELETE_RECORD_SUCCESS,
  USERPLAN_RESET_STATUSES,
  USERPLAN_SET_SELECTED,
  USERPLAN_SET_SELECTED_ERROR,
  USERPLAN_SET_SELECTED_SUCCESS,
  USERPLAN_UPDATE_RECORD,
  USERPLAN_UPDATE_RECORD_ERROR,
  USERPLAN_UPDATE_RECORD_SUCCESS,
  USERPLAN_UPDATE_SELECTED,
  USERPLAN_ISACTIVECHANGE_SUCCESS,
  USERPLAN_ISACTIVECHANGE_ERROR,
  USERPLAN_ISACTIVECHANGE,
  USERPLAN_COUPONAPPLY,
  USERPLAN_COUPONAPPLY_SUCCESS,
  USERPLAN_COUPONAPPLY_ERROR,
  USERPLAN_GROUPED,
  USERPLAN_GROUPED_SUCCESS,
  USERPLAN_GROUPED_ERROR,
  PACKAGE_GET_LIST,
  PACKAGE_GET_LIST_SUCCESS,
  PACKAGE_GET_LIST_ERROR,
  PACKAGE_SET_SELECTED,
  PACKAGE_SET_SELECTED_SUCCESS,
  PACKAGE_SET_SELECTED_ERROR,
  CHANGE_PACKAGE,
  CHANGE_PACKAGE_SUCCESS,
  CHANGE_PACKAGE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getUserPlanList = (pageDetail) => ({
  type: USERPLAN_GET_LIST,
  payload: pageDetail,
});

export const getUserPlanListSuccess = (items) => ({
  type: USERPLAN_GET_LIST_SUCCESS,
  payload: items,
});

export const getUserPlanListError = (error) => ({
  type: USERPLAN_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setUserPlanSelectedRecord = (recordId) => ({
  type: USERPLAN_SET_SELECTED,
  payload: recordId,
});

export const setUserPlanSelectedRecordSuccess = (record) => ({
  type: USERPLAN_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setUserPlanSelectedRecordError = (error) => ({
  type: USERPLAN_SET_SELECTED_ERROR,
  payload: error,
});

export const updateUserPlanSelectedRecord = (record) => ({
  type: USERPLAN_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addUserPlanRecord = (record) => ({
  type: USERPLAN_ADD_RECORD,
  payload: record,
});

export const addUserPlanRecordSuccess = (msg) => ({
  type: USERPLAN_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addUserPlanRecordError = (error) => ({
  type: USERPLAN_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateUserPlanRecords = (record) => ({
  type: USERPLAN_UPDATE_RECORD,
  payload: record,
});

export const updateUserPlanRecordSuccess = (msg) => ({
  type: USERPLAN_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateUserPlanRecordError = (error) => ({
  type: USERPLAN_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteUserPlanRecords = (record) => ({
  type: USERPLAN_DELETE_RECORD,
  payload: record,
});

export const deleteUserPlanRecordSuccess = (msg) => ({
  type: USERPLAN_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteUserPlanRecordError = (error) => ({
  type: USERPLAN_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeUserPlan = (record) => ({
  type: USERPLAN_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeUserPlanSuccess = (msg) => ({
  type: USERPLAN_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeUserPlanError = (error) => ({
  type: USERPLAN_ISACTIVECHANGE_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const userPlanCouponApply = (record) => ({
  type: USERPLAN_COUPONAPPLY,
  payload: record,
});

export const userPlanCouponApplySuccess = (msg) => ({
  type: USERPLAN_COUPONAPPLY_SUCCESS,
  payload: msg,
});

export const userPlanCouponApplyError = (error) => ({
  type: USERPLAN_COUPONAPPLY_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const userPlanGrouped = (record) => ({
  type: USERPLAN_GROUPED,
  payload: record,
});

export const userPlanGroupedSuccess = (msg) => ({
  type: USERPLAN_GROUPED_SUCCESS,
  payload: msg,
});

export const userPlanGroupedError = (error) => ({
  type: USERPLAN_GROUPED_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const getPackageList = () => ({
  type: PACKAGE_GET_LIST,
});

export const getPackageListSuccess = (items) => ({
  type: PACKAGE_GET_LIST_SUCCESS,
  payload: items,
});

export const getPackageListError = (error) => ({
  type: PACKAGE_GET_LIST_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const setPackageSelectedRecord = (recordId) => ({
  type: PACKAGE_SET_SELECTED,
  payload: recordId,
});

export const setPackageSelectedRecordSuccess = (record) => ({
  type: PACKAGE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setPackageSelectedRecordError = (error) => ({
  type: PACKAGE_SET_SELECTED_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const changePackage = (recordId) => ({
  type: CHANGE_PACKAGE,
  payload: recordId,
});

export const changePackageSuccess = (record) => ({
  type: CHANGE_PACKAGE_SUCCESS,
  payload: record,
});

export const changePackageError = (error) => ({
  type: CHANGE_PACKAGE_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const resetUserPlanStatuses = () => ({ type: USERPLAN_RESET_STATUSES });
//-------------------------------------------------------------------------
