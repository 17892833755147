/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  FOOD_ADD_RECORD,
  FOOD_UPDATE_RECORD,
  FOOD_GET_LIST,
  FOOD_SET_SELECTED,
  FOOD_DELETE_RECORD,
  FOOD_GETNAMEWITHID_LIST,
  FOOD_GETNAMEWITHID_ONERECORD,
  deleteFoodRecordSuccess,
  deleteFoodRecordError,
  FOOD_COPY_RECORD,
  FOOD_ISACTIVECHANGE,
  FOOD_GET_FOODNUTRIENTS,
} from '../../actions';

import {
  getFoodListSuccess,
  getFoodListError,
  setFoodSelectedRecordSuccess,
  setFoodSelectedRecordError,
  addFoodRecordSuccess,
  addFoodRecordError,
  updateFoodRecordSuccess,
  updateFoodRecordError,
  getFoodNameWithIdSuccess,
  getFoodNameWithIdError,
  getFoodNameWithIdOneRecordSuccess,
  getFoodNameWithIdOneRecordError,
  copyFoodRecordSuccess,
  copyFoodRecordError,
  isActiveChangeFoodSuccess,
  isActiveChangeFoodError,
  foodNutrientsFoodSuccess,
  foodNutrientsFoodError,
} from './actions';

//---------------------------------------------------------------------
const getFoodListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/food${url || ''}`)
    .then((response) => response.data);
};

function* getFoodListItems({ payload }) {
  try {
    if (payload) {
      if (!payload.pageSize) {
        payload.pageSize = yield select(
          (state) => state.settings.pageRecordCount
        );
      }
      payload.pageNumber = payload.pageNumber || 0;

      if ((payload.filter && payload.filter !== '') || payload.sort) {
        payload.includes = ['SubFoodGroup'];
      }

      const response = yield call(getFoodListRequest, filterBuilder(payload));
      yield put(getFoodListSuccess(response));
    } else {
      const response = yield call(getFoodListRequest);
      yield put(getFoodListSuccess(response));
    }
  } catch (error) {
    yield put(getFoodListError(error));
  }
}
//----------------------------------------------------------------------------------

const getFoodNameWithIdOneRecordRequest = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/food/foodnamewithid/${id}`)
    .then((response) => response.data);
};

function* getFoodNameWithIdOneRecordItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
    }
    const response = yield call(getFoodNameWithIdOneRecordRequest, payload);
    yield put(getFoodNameWithIdOneRecordSuccess(response));
  } catch (error) {
    yield put(getFoodNameWithIdOneRecordError(error));
  }
}
//----------------------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
const getFoodNameWithIdListRequest = ({ isFood, isDropdown }) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/food/foodnamewithid/filter/${isFood}/${isDropdown}`
    )
    .then((response) => response.data);
};

function* getFoodNameWithIdListItems({ payload }) {
  try {
    if (payload) {
      const pageSize = yield select((state) => state.settings.pageRecordCount);
      payload.pageSize = pageSize;
    }
    if (!payload) {
      payload = { ...payload, isFood: true, isDropdown: true };
    }
    const response = yield call(getFoodNameWithIdListRequest, payload);
    yield put(getFoodNameWithIdSuccess(response));
  } catch (error) {
    yield put(getFoodNameWithIdError(error));
  }
}
//----------------------------------------------------------------------------------

const setFoodSelectedRecordRequest = async (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/food/${id}`)
    .then((response) => response.data.singleData);

function* setFoodSelectedRecord({ payload }) {
  try {
    const response = yield call(setFoodSelectedRecordRequest, payload);
    yield put(setFoodSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setFoodSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addFoodRecordRequest = (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/food`, record)
    .then((response) => response.data);

function* addFoodRecords({ payload }) {
  try {
    yield call(addFoodRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addFoodRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getFoodListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addFoodRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------

const copyFoodRecordRequest = (record) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/food/copy?foodId=${record.foodId}&foodName=${record.foodName}&foodCode=${record.foodCode}`
    )
    .then((response) => response.data);

function* copyFoodRecords({ payload }) {
  try {
    yield call(copyFoodRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      copyFoodRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getFoodListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      copyFoodRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------

const updateFoodRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/food`, record)
    .then((response) => response.data);

function* updateFoodRecords({ payload }) {
  try {
    yield call(updateFoodRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateFoodRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getFoodListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateFoodRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeFoodRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/food/isactivechange/${recordId}`)
    .then((response) => response.data);

function* isActiveChangeFood({ payload }) {
  try {
    const response = yield call(isActiveChangeFoodRequest, payload);
    yield put(isActiveChangeFoodSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeFoodError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

const deleteFoodRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/food/${recordId}`)
    .then((response) => response.data);

function* deleteFoodRecords({ payload }) {
  try {
    yield call(deleteFoodRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteFoodRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getFoodListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteFoodRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const foodNutrientsFoodRecordRequest = ({ id, gram }) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/food/foodNutrient/${id}/${gram || 1}`
    )
    .then((response) => response.data.data);

function* foodNutrientsFoodRecords({ payload }) {
  try {
    const response = yield call(foodNutrientsFoodRecordRequest, payload);
    yield put(foodNutrientsFoodSuccess(response));
  } catch (error) {
    yield put(
      foodNutrientsFoodError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(800, FOOD_GET_LIST, getFoodListItems);
}
export function* watchGetNameWithIdOneRecord() {
  yield takeEvery(
    FOOD_GETNAMEWITHID_ONERECORD,
    getFoodNameWithIdOneRecordItems
  );
}
export function* watchGetNameWithIdList() {
  yield takeEvery(FOOD_GETNAMEWITHID_LIST, getFoodNameWithIdListItems);
}
export function* watchSetSelected() {
  yield takeEvery(FOOD_SET_SELECTED, setFoodSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(FOOD_ADD_RECORD, addFoodRecords);
}
export function* wathcCopyItem() {
  yield takeEvery(FOOD_COPY_RECORD, copyFoodRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(FOOD_UPDATE_RECORD, updateFoodRecords);
}
export function* wathcDeleteItem() {
  yield takeEvery(FOOD_DELETE_RECORD, deleteFoodRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(FOOD_ISACTIVECHANGE, isActiveChangeFood);
}
export function* watchFoodNutrients() {
  yield takeEvery(FOOD_GET_FOODNUTRIENTS, foodNutrientsFoodRecords);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetNameWithIdOneRecord),
    fork(watchGetNameWithIdList),
    fork(watchSetSelected),
    fork(wathcIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcCopyItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(watchFoodNutrients),
  ]);
}
