/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  MENUPREPARE_ADD_RECORD,
  MENUPREPARE_UPDATE_RECORD,
  MENUPREPARE_GET_LIST,
  MENUPREPARE_SET_SELECTED,
  MENUPREPARE_DELETE_RECORD,
  deleteMenuPrepareRecordSuccess,
  deleteMenuPrepareRecordError,
  MENUPREPARE_ISACTIVECHANGE,
  MENUPREPARE_EXPORT,
  MENUPREPARE_DAY_UPDATE,
} from '../../actions';

import {
  getMenuPrepareListSuccess,
  getMenuPrepareListError,
  setMenuPrepareSelectedRecordSuccess,
  setMenuPrepareSelectedRecordError,
  addMenuPrepareRecordSuccess,
  addMenuPrepareRecordError,
  updateMenuPrepareRecordSuccess,
  updateMenuPrepareRecordError,
  isActiveChangeMenuPrepareSuccess,
  isActiveChangeMenuPrepareError,
  exportMenuPrepareSuccess,
  exportMenuPrepareError,
  menuPrepareDayUpdateSuccess,
  menuPrepareDayUpdateError,
} from './actions';

//---------------------------------------------------------------------
const getMenuPrepareListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/menuprepare${url || ''}`)
    .then((response) => response.data);
};

function* getMenuPrepareListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getMenuPrepareListRequest,
        filterBuilder(payload)
      );

      yield put(getMenuPrepareListSuccess(response));
    } else {
      const response = yield call(getMenuPrepareListRequest);
      yield put(getMenuPrepareListSuccess(response));
    }
  } catch (error) {
    yield put(getMenuPrepareListError(error));
  }
}
//----------------------------------------------------------------------------------
const setMenuPrepareSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/menuprepare/${id}`)
    .then((response) => response.data.singleData);

function* setMenuPrepareSelectedRecord({ payload }) {
  try {
    const response = yield call(setMenuPrepareSelectedRecordRequest, payload);
    yield put(setMenuPrepareSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setMenuPrepareSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addMenuPrepareRecordRequest = async (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/menuprepare`, record)
    .then((response) => response.data);

function* addMenuPrepareRecords({ payload }) {
  try {
    yield call(addMenuPrepareRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addMenuPrepareRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getMenuPrepareListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addMenuPrepareRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateMenuPrepareRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/menuprepare`, record)
    .then((response) => response.data);

function* updateMenuPrepareRecords({ payload }) {
  try {
    yield call(updateMenuPrepareRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateMenuPrepareRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getMenuPrepareListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateMenuPrepareRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const isActiveChangeMenuPrepareRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/menuprepare/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeMenuPrepare({ payload }) {
  try {
    const response = yield call(isActiveChangeMenuPrepareRequest, payload);
    yield put(isActiveChangeMenuPrepareSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeMenuPrepareError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

//----------------------------------------------------------------------------------
const deleteMenuPrepareRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/menuprepare/${recordId}`)
    .then((response) => response.data);

function* deleteMenuPrepareRecords({ payload }) {
  try {
    yield call(deleteMenuPrepareRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteMenuPrepareRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getMenuPrepareListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteMenuPrepareRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const exportMenuPrepareRequest = (recordId) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/menuprepare/exportMenu/${recordId}`)
    .then((response) => response.data);

function* exportMenuPrepareRecords({ payload }) {
  try {
    const response = yield call(exportMenuPrepareRequest, payload);
    yield put(exportMenuPrepareSuccess(response.singleData));
  } catch (error) {
    yield put(
      exportMenuPrepareError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const updateMenuPrepareDayRequest = (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/menuprepare/menuDayUpdate`, record)
    .then((response) => response.data);

function* updateMenuPrepareDayRecords({ payload }) {
  try {
    const response = yield call(updateMenuPrepareDayRequest, payload);
    yield put(menuPrepareDayUpdateSuccess(response.singleData));
  } catch (error) {
    yield put(
      menuPrepareDayUpdateError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

export function* watchGetList() {
  yield debounce(800, MENUPREPARE_GET_LIST, getMenuPrepareListItems);
}

export function* watchSetSelected() {
  yield takeEvery(MENUPREPARE_SET_SELECTED, setMenuPrepareSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(MENUPREPARE_ADD_RECORD, addMenuPrepareRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(MENUPREPARE_UPDATE_RECORD, updateMenuPrepareRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(MENUPREPARE_DELETE_RECORD, deleteMenuPrepareRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(MENUPREPARE_ISACTIVECHANGE, isActiveChangeMenuPrepare);
}
export function* watchExport() {
  yield takeEvery(MENUPREPARE_EXPORT, exportMenuPrepareRecords);
}
export function* watchDayUpdate() {
  yield takeEvery(MENUPREPARE_DAY_UPDATE, updateMenuPrepareDayRecords);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcIsActiveChangeItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(watchExport),
    fork(watchDayUpdate),
  ]);
}
