/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  USERROLE_ADD_RECORD,
  USERROLE_UPDATE_RECORD,
  USERROLE_GET_LIST,
  USERROLE_SET_SELECTED,
  USERROLE_DELETE_RECORD,
  USERROLE_BYUSERID,
  USERROLE_UPSERT_RECORD,
  getUserList,
} from '../../actions';

import {
  getUserRoleListSuccess,
  getUserRoleListError,
  setUserRoleSelectedRecordSuccess,
  setUserRoleSelectedRecordError,
  addUserRoleRecordSuccess,
  addUserRoleRecordError,
  updateUserRoleRecordSuccess,
  deleteUserRoleRecordSuccess,
  deleteUserRoleRecordError,
  updateUserRoleRecordError,
  upsertUserRoleRecordsError,
  upsertUserRoleRecordsSuccess,
  getUserRoleByUserListSuccess,
  getUserRoleByUserListError,
} from './actions';

//---------------------------------------------------------------------
const getUserRoleListRequest = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userrole`)
    .then((response) => response.data.data);
};

function* getUserRoleListItems() {
  try {
    const response = yield call(getUserRoleListRequest);
    yield put(getUserRoleListSuccess(response));
  } catch (error) {
    yield put(getUserRoleListError(error));
  }
}
//----------------------------------------------------------------------------------
const setUserRoleSelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userrole/${id}`)
    .then((response) => response.data.singleData);
};

function* setUserRoleSelectedRecord({ payload }) {
  try {
    const response = yield call(setUserRoleSelectedRecordRequest, payload);
    yield put(setUserRoleSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setUserRoleSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addUserRoleRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/userrole`, record)
    .then((response) => response.data);
};

function* addUserRoleRecords({ payload }) {
  try {
    yield call(addUserRoleRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addUserRoleRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getUserRoleListItems);
  } catch (error) {
    yield put(
      addUserRoleRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateUserRoleRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/userrole`, record)
    .then((response) => response.data);
};

function* updateUserRoleRecords({ payload }) {
  try {
    yield call(updateUserRoleRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateUserRoleRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getUserRoleListItems);
  } catch (error) {
    yield put(
      updateUserRoleRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteUserRoleRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/userrole/${recordId}`)
    .then((response) => response.data);
};

function* deleteUserRoleRecords({ payload }) {
  try {
    yield call(deleteUserRoleRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteUserRoleRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getUserRoleListItems);
  } catch (error) {
    yield put(
      deleteUserRoleRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const getUserRoleByUserIdListRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userrole/byUser/${id}`)
    .then((response) => response.data.data);
};

function* getUserRoleByUserIdListItems({ payload }) {
  try {
    const response = yield call(getUserRoleByUserIdListRequest, payload);
    yield put(getUserRoleByUserListSuccess(response));
  } catch (error) {
    yield put(getUserRoleByUserListError(error));
  }
}
//--------------------------------------------------------------------
const upsertUserRoleRecordRequest = async (records) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/userrole/upsert`, records)
    .then((response) => response.data);
};

function* upsertUserRoleRecord({ payload }) {
  try {
    const response = yield call(upsertUserRoleRecordRequest, payload);
    yield put(getUserList({ pageNumber: 0 }));
    if (response) {
      const messageType = yield select((state) =>
        state.settings.notificationMessages.find(
          (x) => x.type === NotificationType.Success
        )
      );
      yield put(
        upsertUserRoleRecordsSuccess(
          NotificationHelpers(messageType, NotificationSubType.Add)
        )
      );
    }
  } catch (error) {
    yield put(upsertUserRoleRecordsError(error));
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(USERROLE_GET_LIST, getUserRoleListItems);
}

export function* watchSetSelected() {
  yield takeEvery(USERROLE_SET_SELECTED, setUserRoleSelectedRecord);
}
export function* watchAddItem() {
  yield takeEvery(USERROLE_ADD_RECORD, addUserRoleRecords);
}
export function* watchUpdateItem() {
  yield takeEvery(USERROLE_UPDATE_RECORD, updateUserRoleRecords);
}

export function* watchDeleteItem() {
  yield takeEvery(USERROLE_DELETE_RECORD, deleteUserRoleRecords);
}
export function* watchByUserIdList() {
  yield takeEvery(USERROLE_BYUSERID, getUserRoleByUserIdListItems);
}

export function* watchUpsert() {
  yield takeEvery(USERROLE_UPSERT_RECORD, upsertUserRoleRecord);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(watchAddItem),
    fork(watchUpdateItem),
    fork(watchDeleteItem),
    fork(watchByUserIdList),
    fork(watchUpsert),
  ]);
}
