/* eslint-disable import/no-cycle */

import {
  PORTION_GET_LIST,
  PORTION_GET_LIST_SUCCESS,
  PORTION_GET_LIST_ERROR,
  PORTION_SET_SELECTED,
  PORTION_UPDATE_SELECTED,
  PORTION_SET_SELECTED_SUCCESS,
  PORTION_SET_SELECTED_ERROR,
  PORTION_ADD_RECORD_SUCCESS,
  PORTION_ADD_RECORD_ERROR,
  PORTION_ADD_RECORD,
  PORTION_UPDATE_RECORD_SUCCESS,
  PORTION_UPDATE_RECORD_ERROR,
  PORTION_UPDATE_RECORD,
  PORTION_DELETE_RECORD,
  PORTION_DELETE_RECORD_SUCCESS,
  PORTION_DELETE_RECORD_ERROR,
  PORTION_RESET_STATUSES,
  PORTION_GETNAMEWITHID_LIST,
  PORTION_GETNAMEWITHID_LIST_SUCCESS,
  PORTION_GETNAMEWITHID_LIST_ERROR,
  PORTION_GETNAMEWITHID_ONERECORD,
  PORTION_GETNAMEWITHID_ONERECORD_SUCCESS,
  PORTION_GETNAMEWITHID_ONERECORD_ERROR,
  PORTION_ISACTIVECHANGE,
  PORTION_ISACTIVECHANGE_SUCCESS,
  PORTION_ISACTIVECHANGE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getPortionList = (pageDetail) => ({
  type: PORTION_GET_LIST,
  payload: pageDetail,
});

export const getPortionListSuccess = (items) => ({
  type: PORTION_GET_LIST_SUCCESS,
  payload: items,
});

export const getPortionListError = (error) => ({
  type: PORTION_GET_LIST_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const getPortionNameWithIdList = (pageDetail) => ({
  type: PORTION_GETNAMEWITHID_LIST,
  payload: pageDetail,
});

export const getPortionNameWithIdSuccess = (items) => ({
  type: PORTION_GETNAMEWITHID_LIST_SUCCESS,
  payload: items,
});

export const getPortionNameWithIdError = (error) => ({
  type: PORTION_GETNAMEWITHID_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------

export const getPortionNameWithIdOneRecord = (pageDetail) => ({
  type: PORTION_GETNAMEWITHID_ONERECORD,
  payload: pageDetail,
});

export const getPortionNameWithIdOneRecordSuccess = (items) => ({
  type: PORTION_GETNAMEWITHID_ONERECORD_SUCCESS,
  payload: items,
});

export const getPortionNameWithIdOneRecordError = (error) => ({
  type: PORTION_GETNAMEWITHID_ONERECORD_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------

//----------------------------------------------------------------------------------
export const setPortionSelectedRecord = (recordId) => ({
  type: PORTION_SET_SELECTED,
  payload: recordId,
});

export const setPortionSelectedRecordSuccess = (record) => ({
  type: PORTION_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setPortionSelectedRecordError = (error) => ({
  type: PORTION_SET_SELECTED_ERROR,
  payload: error,
});

export const updatePortionSelectedRecord = (record) => ({
  type: PORTION_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addPortionRecord = (record) => ({
  type: PORTION_ADD_RECORD,
  payload: record,
});

export const addPortionRecordSuccess = (msg) => ({
  type: PORTION_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addPortionRecordError = (error) => ({
  type: PORTION_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updatePortionRecords = (record) => ({
  type: PORTION_UPDATE_RECORD,
  payload: record,
});

export const updatePortionRecordSuccess = (msg) => ({
  type: PORTION_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updatePortionRecordError = (error) => ({
  type: PORTION_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangePortion = (record) => ({
  type: PORTION_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangePortionSuccess = (msg) => ({
  type: PORTION_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangePortionError = (error) => ({
  type: PORTION_ISACTIVECHANGE_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const deletePortionRecords = (record) => ({
  type: PORTION_DELETE_RECORD,
  payload: record,
});

export const deletePortionRecordSuccess = (msg) => ({
  type: PORTION_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deletePortionRecordError = (error) => ({
  type: PORTION_DELETE_RECORD_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const resetPortionStatuses = () => ({ type: PORTION_RESET_STATUSES });
