/* eslint-disable prettier/prettier */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  PLANREPASTFOOD_ADD_RECORD,
  PLANREPASTFOOD_UPDATE_RECORD,
  PLANREPASTFOOD_GET_LIST,
  PLANREPASTFOOD_UPDATE_BATCHRECORD,
  PLANREPASTFOOD_SET_SELECTED,
  PLANREPASTFOOD_DELETE_RECORD,
  // deletePlanRepastFoodRecordSuccess,
  deletePlanRepastFoodRecordError,
  PLANREPASTFOOD_MEALFOODTRANFER,
} from '../../actions';

import {
  getPlanRepastFoodListSuccess,
  getPlanRepastFoodListError,
  setPlanRepastFoodSelectedRecordSuccess,
  setPlanRepastFoodSelectedRecordError,
  // addPlanRepastFoodRecordSuccess,
  addPlanRepastFoodRecordError,
  updatePlanRepastFoodRecordSuccess,
  updatePlanRepastFoodRecordError,
  updatePlanRepastFoodBatchRecordSuccess,
  updatePlanRepastFoodBatchRecordError,
  planRepastFoodMealFoodTranferSuccess,
  planRepastFoodMealFoodTranferError,
} from './actions';
// import { planRepastPlanRepastDeleteFoodRecord } from '../plan-repast/actions';

import { speciallyWithId } from '../planning/actions';

//---------------------------------------------------------------------
const getPlanRepastFoodListRequest = (pageDetail) => {
  if (pageDetail) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/planrepastfood?ps=${pageDetail.pageSize}&pn=${pageDetail.pageNumber}`
      )
      .then((response) => response.data);
    // eslint-disable-next-line no-else-return
  } else {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/planrepastfood`)
      .then((response) => response.data);
  }
};

function* getPlanRepastFoodListItems({ payload }) {
  try {
    let pageSize = yield select((state) => state.settings.pageRecordCount);
    // eslint-disable-next-line no-param-reassign
    payload.pageSize = pageSize;
    const response = yield call(getPlanRepastFoodListRequest, payload);
    yield put(getPlanRepastFoodListSuccess(response));
  } catch (error) {
    yield put(getPlanRepastFoodListError(error));
  }
}
//----------------------------------------------------------------------------------
const setPlanRepastFoodSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/planrepastfood/${id}`)
    .then((response) => response.data.data);

function* setPlanRepastFoodSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setPlanRepastFoodSelectedRecordRequest,
      payload
    );
    yield put(setPlanRepastFoodSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setPlanRepastFoodSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addPlanRepastFoodRecordRequest = (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/planrepastfood`, record)
    .then((response) => response.data);

function* addPlanRepastFoodRecords({ payload }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(addPlanRepastFoodRecordRequest, payload);
    if (response) {
      yield put(
        speciallyWithId(yield select((state) => state.plan?.selectedRecord.id))
      );
      // const messageType = yield select((state) =>
      //   state.settings.notificationMessages.find(
      //     (x) => x.type === NotificationType.Success
      //   )
      // );
      // yield put(
      //   addPlanRepastFoodRecordSuccess(
      //     NotificationHelpers(messageType, NotificationSubType.Add)
      //   )
      // );
    }
  } catch (error) {
    console.log(error);
    yield put(
      addPlanRepastFoodRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const updatePlanRepastFoodRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/planrepastfood`, record)
    .then((response) => response.data);

function* updatePlanRepastFoodRecords({ payload }) {
  try {
    yield call(updatePlanRepastFoodRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updatePlanRepastFoodRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getPlanRepastFoodListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updatePlanRepastFoodRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

const updatePlanRepastFoodBatchRecordRequest = (records) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/planrepastfood/batchupdate`, records)
    .then((response) => response.data);

function* updatePlanRepastFoodBatchRecords({ payload }) {
  try {
    const response = yield call(
      updatePlanRepastFoodBatchRecordRequest,
      payload
    );
    if (response) {
      const messageType = yield select((state) =>
        state.settings.notificationMessages.find(
          (x) => x.type === NotificationType.Success
        )
      );
      yield put(
        updatePlanRepastFoodBatchRecordSuccess(
          NotificationHelpers(messageType, NotificationSubType.Edit)
        )
      );
      yield put(
        speciallyWithId(yield select((state) => state.plan?.selectedRecord.id))
      );
    }
  } catch (error) {
    yield put(
      updatePlanRepastFoodBatchRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deletePlanRepastFoodRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/planrepastfood/${recordId}`)
    .then((response) => response.data);

function* deletePlanRepastFoodRecords({ payload }) {
  try {
    yield call(deletePlanRepastFoodRecordRequest, payload);

    yield put(
      speciallyWithId(yield select((state) => state.plan?.selectedRecord.id))
    );
    // const messageType = yield select((state) =>
    //   state.settings.notificationMessages.find(
    //     (x) => x.type === NotificationType.Success
    //   )
    // );
    yield call(getPlanRepastFoodListItems, { payload: { pageNumber: 0 } });
    // yield put(
    //   deletePlanRepastFoodRecordSuccess(
    //     NotificationHelpers(messageType, NotificationSubType.Delete)
    //   )
    // );
  } catch (error) {
    yield put(
      deletePlanRepastFoodRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const mealFoodTransferPlanRepastRequest = ({ id }) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/planrepastfood/mealtransfer/${id}`)
    .then((response) => response.data);

function* mealFoodTransferPlanRepastRecords({ payload }) {
  try {
    yield call(mealFoodTransferPlanRepastRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      planRepastFoodMealFoodTranferSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );

    yield put(
      speciallyWithId(yield select((state) => state.plan?.selectedRecord.id))
    );
  } catch (error) {
    yield put(
      planRepastFoodMealFoodTranferError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
export function* watchGetList() {
  yield takeEvery(PLANREPASTFOOD_GET_LIST, getPlanRepastFoodListItems);
}

export function* watchSetSelected() {
  yield takeEvery(PLANREPASTFOOD_SET_SELECTED, setPlanRepastFoodSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(PLANREPASTFOOD_ADD_RECORD, addPlanRepastFoodRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(PLANREPASTFOOD_UPDATE_RECORD, updatePlanRepastFoodRecords);
}
export function* wathcUpdateItems() {
  yield takeEvery(
    PLANREPASTFOOD_UPDATE_BATCHRECORD,
    updatePlanRepastFoodBatchRecords
  );
}
export function* wathcDeleteItem() {
  yield takeEvery(PLANREPASTFOOD_DELETE_RECORD, deletePlanRepastFoodRecords);
}

export function* watchMealFoodTransfer() {
  yield takeEvery(
    PLANREPASTFOOD_MEALFOODTRANFER,
    mealFoodTransferPlanRepastRecords
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcUpdateItems),
    fork(wathcDeleteItem),
    fork(watchMealFoodTransfer),
  ]);
}
