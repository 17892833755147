/* eslint-disable no-param-reassign */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  TAG_ADD_RECORD,
  TAG_UPDATE_RECORD,
  TAG_GET_LIST,
  TAG_SET_SELECTED,
  TAG_DELETE_RECORD,
  TAG_ISACTIVECHANGE,
} from '../../actions';
import {
  getTagListSuccess,
  getTagListError,
  setTagSelectedRecordSuccess,
  setTagSelectedRecordError,
  addTagRecordSuccess,
  addTagRecordError,
  updateTagRecordSuccess,
  updateTagRecordError,
  isActiveChangeTagSuccess,
  isActiveChangeTagError,
  deleteTagRecordSuccess,
  deleteTagRecordError,
} from './actions';

//---------------------------------------------------------------------
const getTagListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/tag${url || ''}`)
    .then((response) => response.data);
};

function* getTagListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(getTagListRequest, filterBuilder(payload));
      yield put(getTagListSuccess(response));
    } else {
      const response = yield call(getTagListRequest);
      yield put(getTagListSuccess(response));
    }
  } catch (error) {
    yield put(getTagListError(error));
  }
}
//----------------------------------------------------------------------------------
const setTagSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/tag/${id}`)
    .then((response) => response.data.singleData);

function* setTagSelectedRecord({ payload }) {
  try {
    const response = yield call(setTagSelectedRecordRequest, payload);
    yield put(setTagSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setTagSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addTagRecordRequest = async (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/tag`, record)
    .then((response) => response.data);

function* addTagRecords({ payload }) {
  try {
    yield call(addTagRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addTagRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getTagListItems, {});
  } catch (error) {
    yield put(
      addTagRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateTagRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/tag`, record)
    .then((response) => response.data);

function* updateTagRecords({ payload }) {
  try {
    yield call(updateTagRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateTagRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getTagListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateTagRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const deleteTagRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/tag/${recordId}`)
    .then((response) => response.data);

function* deleteTagRecords({ payload }) {
  try {
    yield call(deleteTagRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteTagRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getTagListItems, {});
  } catch (error) {
    yield put(
      deleteTagRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeTagRequest = (recordId) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/tag/isactivechange/${recordId}`)
    .then((response) => response.data);

function* isActiveChangeTag({ payload }) {
  try {
    const response = yield call(isActiveChangeTagRequest, payload);
    yield put(isActiveChangeTagSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeTagError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(800, TAG_GET_LIST, getTagListItems);
}

export function* watchSetSelected() {
  yield takeEvery(TAG_SET_SELECTED, setTagSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(TAG_ADD_RECORD, addTagRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(TAG_UPDATE_RECORD, updateTagRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(TAG_DELETE_RECORD, deleteTagRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(TAG_ISACTIVECHANGE, isActiveChangeTag);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
