/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { applicationRoot } from 'constants/defaultValues';
import { setCurrentUser, setToken } from 'helpers/Utils';
import {
  USERPLAN_ADD_RECORD,
  USERPLAN_UPDATE_RECORD,
  USERPLAN_GET_LIST,
  USERPLAN_SET_SELECTED,
  USERPLAN_DELETE_RECORD,
  USERPLAN_ISACTIVECHANGE,
  USERPLAN_COUPONAPPLY,
  USERPLAN_GROUPED,
  PACKAGE_GET_LIST,
  PACKAGE_SET_SELECTED,
  CHANGE_PACKAGE,
  loginUserSuccess,
} from '../../actions';

import {
  getUserPlanListSuccess,
  getUserPlanListError,
  setUserPlanSelectedRecordSuccess,
  setUserPlanSelectedRecordError,
  addUserPlanRecordSuccess,
  addUserPlanRecordError,
  updateUserPlanRecordSuccess,
  deleteUserPlanRecordSuccess,
  deleteUserPlanRecordError,
  updateUserPlanRecordError,
  isActiveChangeUserPlanSuccess,
  isActiveChangeUserPlanError,
  userPlanCouponApplySuccess,
  userPlanCouponApplyError,
  userPlanGroupedSuccess,
  userPlanGroupedError,
  getPackageListSuccess,
  getPackageListError,
  setPackageSelectedRecordSuccess,
  setPackageSelectedRecordError,
  changePackageSuccess,
  changePackageError,
} from './actions';

//---------------------------------------------------------------------
const getUserPlanListRequest = async (pageDetail) => {
  if (pageDetail) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/userplan?ps=${pageDetail.pageSize}&pn=${pageDetail.pageNumber}`
      )
      .then((response) => response.data);
  }
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/userplan`)
    .then((response) => response.data);
};

function* getUserPlanListItems({ payload }) {
  try {
    if (payload) {
      let pageSize = yield select((state) => state.settings.pageRecordCount);
      payload.pageSize = pageSize;
    }
    const response = yield call(getUserPlanListRequest, payload);
    yield put(getUserPlanListSuccess(response));
  } catch (error) {
    yield put(getUserPlanListError(error));
  }
}
//----------------------------------------------------------------------------------
const setUserPlanSelectedRecordRequest = async (record) => {
  const { id, packageDetail } = record;
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/userplan/${
        packageDetail === true ? 'package/' : ''
      }${id}`
    )
    .then((response) => response.data.singleData);
};

function* setUserPlanSelectedRecord({ payload }) {
  try {
    const response = yield call(setUserPlanSelectedRecordRequest, payload);
    yield put(setUserPlanSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setUserPlanSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addUserPlanRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/userplan`, record)
    .then((response) => response.data);
};

function* addUserPlanRecords({ payload }) {
  try {
    if (!payload.userPlanCoupons) {
      payload.userPlanCoupons = [];
    }
    if (!payload.userPlanPeriods) {
      payload.userPlanPeriods = [];
    }
    yield call(addUserPlanRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addUserPlanRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getUserPlanListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addUserPlanRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateUserPlanRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/userplan`, record)
    .then((response) => response.data);
};

function* updateUserPlanRecords({ payload }) {
  try {
    yield call(updateUserPlanRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateUserPlanRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getUserPlanListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateUserPlanRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteUserPlanRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/userplan/${recordId}`)
    .then((response) => response.data);
};

function* deleteUserPlanRecords({ payload }) {
  try {
    yield call(deleteUserPlanRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteUserPlanRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getUserPlanListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteUserPlanRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const isActiveChangeUserPlanRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/userplan/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeUserPlan({ payload }) {
  try {
    const response = yield call(isActiveChangeUserPlanRequest, payload);
    yield put(isActiveChangeUserPlanSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeUserPlanError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//--------------------------------------------------------------------
const userPlanCouponApplyRequest = (record) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/userplan/couponapply/${record.userPlanId}/${record.coupon}`
    )
    .then((response) => response.data.singleData);

function* userPlanCouponApplyRecords({ payload }) {
  try {
    const response = yield call(userPlanCouponApplyRequest, payload);
    yield put(userPlanCouponApplySuccess(response));
  } catch (error) {
    yield put(
      userPlanCouponApplyError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------
const userPlanGroupedRequest = () =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/userplan/grouped`)
    .then((response) => response.data.data);

function* userPlanGroupedRecords() {
  try {
    const response = yield call(userPlanGroupedRequest);
    yield put(userPlanGroupedSuccess(response));
  } catch (error) {
    yield put(
      userPlanGroupedError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------
const getPackageListRequest = async () =>
  await axios
    .get(`${process.env.REACT_APP_API_URL}/userplan/packages`)
    .then((response) => response.data);

function* getPackageListItems() {
  try {
    const response = yield call(getPackageListRequest);
    yield put(getPackageListSuccess(response));
  } catch (error) {
    yield put(getPackageListError(error));
  }
}
//----------------------------------------------------------------------------------
const setUserPackageSelectedRecordRequest = async (id) =>
  await axios
    .get(`${process.env.REACT_APP_API_URL}/userplan/package/${id}`)
    .then((response) => response.data.singleData);

function* setUserPackageSelectedRecord({ payload }) {
  try {
    const response = yield call(setUserPackageSelectedRecordRequest, payload);
    yield put(setPackageSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setPackageSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------
const ChangePackageRequest = async (record) =>
  await axios
    .post(
      `${process.env.REACT_APP_API_URL}/user/changeUserPackage/${record.userId}/${record.packageId}/${record.periodId}`
    )
    .then((response) => response.data.singleData);

function* changePackageRecord({ payload }) {
  try {
    const { history } = payload;
    const response = yield call(ChangePackageRequest, payload);
    yield put(changePackageSuccess(response));
    setCurrentUser(null);
    setToken(null);
    yield put(loginUserSuccess(null));
    history.push(`${applicationRoot}/user/login`);
  } catch (error) {
    yield put(
      changePackageError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(USERPLAN_GET_LIST, getUserPlanListItems);
}

export function* watchSetSelected() {
  yield takeEvery(USERPLAN_SET_SELECTED, setUserPlanSelectedRecord);
}
export function* watchAddItem() {
  yield takeEvery(USERPLAN_ADD_RECORD, addUserPlanRecords);
}
export function* watchUpdateItem() {
  yield takeEvery(USERPLAN_UPDATE_RECORD, updateUserPlanRecords);
}

export function* watchDeleteItem() {
  yield takeEvery(USERPLAN_DELETE_RECORD, deleteUserPlanRecords);
}

export function* watchIsActiveChangeItem() {
  yield takeEvery(USERPLAN_ISACTIVECHANGE, isActiveChangeUserPlan);
}
export function* watchCouponApply() {
  yield takeEvery(USERPLAN_COUPONAPPLY, userPlanCouponApplyRecords);
}
export function* watchGrouped() {
  yield takeEvery(USERPLAN_GROUPED, userPlanGroupedRecords);
}

export function* watchPackageGetList() {
  yield takeEvery(PACKAGE_GET_LIST, getPackageListItems);
}

export function* watchSetPacakgeSelected() {
  yield takeEvery(PACKAGE_SET_SELECTED, setUserPackageSelectedRecord);
}
export function* watchChangePackage() {
  yield takeEvery(CHANGE_PACKAGE, changePackageRecord);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(watchAddItem),
    fork(watchUpdateItem),
    fork(watchDeleteItem),
    fork(watchIsActiveChangeItem),
    fork(watchCouponApply),
    fork(watchGrouped),
    fork(watchPackageGetList),
    fork(watchSetPacakgeSelected),
    fork(watchChangePackage),
  ]);
}
