/* eslint-disable prettier/prettier */
import {
  FOOD_GET_LIST,
  FOOD_GET_LIST_SUCCESS,
  FOOD_GET_LIST_ERROR,
  FOOD_SET_SELECTED,
  FOOD_UPDATE_SELECTED,
  FOOD_SET_SELECTED_SUCCESS,
  FOOD_SET_SELECTED_ERROR,
  FOOD_UPDATE_RECORD,
  FOOD_UPDATE_RECORD_ERROR,
  FOOD_UPDATE_RECORD_SUCCESS,
  FOOD_ADD_RECORD,
  FOOD_ADD_RECORD_SUCCESS,
  FOOD_ADD_RECORD_ERROR,
  FOOD_DELETE_RECORD,
  FOOD_DELETE_RECORD_SUCCESS,
  FOOD_DELETE_RECORD_ERROR,
  FOOD_RESET_STATUSES,
  FOOD_GETNAMEWITHID_LIST,
  FOOD_GETNAMEWITHID_LIST_SUCCESS,
  FOOD_GETNAMEWITHID_LIST_ERROR,
  FOOD_GETNAMEWITHID_ONERECORD,
  FOOD_GETNAMEWITHID_ONERECORD_SUCCESS,
  FOOD_GETNAMEWITHID_ONERECORD_ERROR,
  FOOD_COPY_RECORD,
  FOOD_COPY_RECORD_SUCCESS,
  FOOD_COPY_RECORD_ERROR,
  FOOD_ISACTIVECHANGE,
  FOOD_ISACTIVECHANGE_SUCCESS,
  FOOD_ISACTIVECHANGE_ERROR,
  FOOD_GET_FOODNUTRIENTS,
  FOOD_GET_FOODNUTRIENTS_SUCCESS,
  FOOD_GET_FOODNUTRIENTS_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
  nutrientData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FOOD_GET_LIST:
      return { ...state, loading: true };
    case FOOD_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case FOOD_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case FOOD_GETNAMEWITHID_LIST:
      return { ...state, loading: true };
    case FOOD_GETNAMEWITHID_LIST_SUCCESS:
      return { ...state, loading: false, records: action.payload };
    case FOOD_GETNAMEWITHID_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case FOOD_GETNAMEWITHID_ONERECORD:
      return { ...state, loading: true };
    case FOOD_GETNAMEWITHID_ONERECORD_SUCCESS:
      return { ...state, loading: false, records: action.payload };
    case FOOD_GETNAMEWITHID_ONERECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case FOOD_SET_SELECTED:
      return { ...state, modaLoading: true };
    case FOOD_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case FOOD_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case FOOD_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case FOOD_ADD_RECORD:
      return { ...state, loading: false };
    case FOOD_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case FOOD_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case FOOD_COPY_RECORD:
      return { ...state, loading: false };
    case FOOD_COPY_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case FOOD_COPY_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case FOOD_UPDATE_RECORD:
      return { ...state, loading: false };
    case FOOD_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case FOOD_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case FOOD_DELETE_RECORD:
      return { ...state, loading: false };
    case FOOD_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case FOOD_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case FOOD_ISACTIVECHANGE:
      return { ...state, loading: false };
    case FOOD_ISACTIVECHANGE_SUCCESS: {
      // eslint-disable-next-line prefer-const
      let objIndex = state.records.findIndex(
        (x) => x.id === action.payload.singleData.id
      );
      // eslint-disable-next-line no-param-reassign
      state.records[objIndex] = action.payload.singleData;
      return { ...state, loading: false, records: state.records };
    }
    case FOOD_ISACTIVECHANGE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case FOOD_GET_FOODNUTRIENTS:
      return { ...state, loading: false };
    case FOOD_GET_FOODNUTRIENTS_SUCCESS: {
      return { ...state, loading: false, nutrientData: action.payload };
    }
    case FOOD_GET_FOODNUTRIENTS_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case FOOD_RESET_STATUSES:
      return { ...state, success: null, error: null };
    default:
      return { ...state };
  }
};
