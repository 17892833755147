/* eslint-disable no-case-declarations */
import {
  ANTROPOMETRY_GET_LIST,
  ANTROPOMETRY_GET_LIST_SUCCESS,
  ANTROPOMETRY_GET_LIST_ERROR,
  ANTROPOMETRY_ADD_RECORD,
  ANTROPOMETRY_ADD_RECORD_ERROR,
  ANTROPOMETRY_ADD_RECORD_SUCCESS,
  ANTROPOMETRY_DELETE_RECORD,
  ANTROPOMETRY_DELETE_RECORD_ERROR,
  ANTROPOMETRY_DELETE_RECORD_SUCCESS,
  ANTROPOMETRY_RESET_STATUSES,
  ANTROPOMETRY_SET_SELECTED,
  ANTROPOMETRY_SET_SELECTED_ERROR,
  ANTROPOMETRY_SET_SELECTED_SUCCESS,
  ANTROPOMETRY_UPDATE_RECORD,
  ANTROPOMETRY_UPDATE_RECORD_ERROR,
  ANTROPOMETRY_UPDATE_RECORD_SUCCESS,
  ANTROPOMETRY_UPDATE_SELECTED,
  ANTROPOMETRY_SET_PALVALUE,
  ANTROPOMETRY_SET_EXERCISEACTIVE,
  ANTROPOMETRY_SET_BASALMETABOLICRATE,
  ANTROPOMETRY_SET_TOTALENERGYEXPENDITURE,
  ANTROPOMETRY_SET_EXERCİSESTOTALKCAL,
  ANTROPOMETRY_SET_CURRENTFORMULA,
  ANTROPOMETRY_CLONE_SET_SELECTED,
  ANTROPOMETRY_CLONE_SET_SELECTED_ERROR,
  ANTROPOMETRY_CLONE_SET_SELECTED_SUCCESS,
  ANTROPOMETRY_ISACTIVECHANGE,
  ANTROPOMETRY_ISACTIVECHANGE_SUCCESS,
  ANTROPOMETRY_ISACTIVECHANGE_ERROR,
  ANTROPOMETRY_GENERICSEARCH,
  ANTROPOMETRY_GENERICSEARCH_SUCCESS,
  ANTROPOMETRY_GENERICSEARCH_ERROR,
  ANTROPOMETRY_GETBYCOACHEEID,
  ANTROPOMETRY_GETBYCOACHEEID_SUCCESS,
  ANTROPOMETRY_GETBYCOACHEEID_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
  palValue: 0,
  exerciseActive: true,
  basalMetabolicRate: 0,
  totalEnergyExpenditure: 0,
  exercisesTotalKCal: 0,
  currentFormula: null,
};

export default (state = INIT_STATE, action) => {
  const { selectedRecord } = state;
  switch (action.type) {
    case ANTROPOMETRY_GET_LIST:
      return { ...state, loading: true };
    case ANTROPOMETRY_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        currentFormula: null,
        exerciseActive: true,
        basalMetabolicRate: 0,
        totalMetabolicRate: 0,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case ANTROPOMETRY_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case ANTROPOMETRY_SET_SELECTED:
      return { ...state, modaLoading: true };
    case ANTROPOMETRY_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case ANTROPOMETRY_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case ANTROPOMETRY_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case ANTROPOMETRY_ADD_RECORD:
      return { ...state, loading: false };
    case ANTROPOMETRY_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case ANTROPOMETRY_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case ANTROPOMETRY_UPDATE_RECORD:
      return { ...state, loading: false };
    case ANTROPOMETRY_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case ANTROPOMETRY_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case ANTROPOMETRY_DELETE_RECORD:
      return { ...state, loading: false };
    case ANTROPOMETRY_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case ANTROPOMETRY_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case ANTROPOMETRY_RESET_STATUSES:
      return { ...state, success: null, error: null };
    case ANTROPOMETRY_SET_PALVALUE:
      return { ...state, palValue: action.payload };
    case ANTROPOMETRY_SET_EXERCISEACTIVE:
      return { ...state, exerciseActive: action.payload };
    case ANTROPOMETRY_SET_BASALMETABOLICRATE:
      // selectedRecord.basalMetabolicRate = action.payload;
      return {
        ...state,
        basalMetabolicRate: action.payload,
        selectedRecord,
      };
    case ANTROPOMETRY_SET_TOTALENERGYEXPENDITURE:
      // selectedRecord.totalMetabolicRate = action.payload;
      return {
        ...state,
        totalEnergyExpenditure: action.payload,
        selectedRecord,
      };
    case ANTROPOMETRY_SET_EXERCİSESTOTALKCAL:
      return { ...state, exercisesTotalKCal: action.payload };
    case ANTROPOMETRY_SET_CURRENTFORMULA:
      return { ...state, currentFormula: action.payload };
    case ANTROPOMETRY_CLONE_SET_SELECTED:
      return { ...state };
    case ANTROPOMETRY_CLONE_SET_SELECTED_SUCCESS:
      return { ...state };
    case ANTROPOMETRY_CLONE_SET_SELECTED_ERROR:
      return { ...state };
    case ANTROPOMETRY_ISACTIVECHANGE:
      return { ...state, loading: false };
    case ANTROPOMETRY_ISACTIVECHANGE_SUCCESS:
      // eslint-disable-next-line prefer-const
      let objIndex = state.records.findIndex(
        (x) => x.id === action.payload.singleData.id
      );
      // eslint-disable-next-line no-param-reassign
      state.records[objIndex] = action.payload.singleData;
      return { ...state, loading: false, records: state.records };
    case ANTROPOMETRY_ISACTIVECHANGE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case ANTROPOMETRY_GENERICSEARCH:
      return { ...state, loading: false };
    case ANTROPOMETRY_GENERICSEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case ANTROPOMETRY_GENERICSEARCH_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case ANTROPOMETRY_GETBYCOACHEEID:
      return { ...state, loading: false };
    case ANTROPOMETRY_GETBYCOACHEEID_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case ANTROPOMETRY_GETBYCOACHEEID_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    default:
      return { ...state };
  }
};
