/* eslint-disable react/react-in-jsx-scope */
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import excelHelper from './excelHelper';
import NutrientValueCalculate from './nutrientValueCalculate';
import exportPdfHelpers from './exportPdfHelpers';
import NutrientUnitHelper from './nutrientUnitHelper';

const ValuePercentage = (total, referenceNutrient) => {
  if (total.value === 0 || referenceNutrient.value === 0) return 0;
  return (
    (total.value /
      (total.header === 'Enerji' ? 4.184 : 1) /
      referenceNutrient.value) *
    100
  ).toFixed(2);
};

const excelExport = (response, tools, energyValue) => {
  const result = [];
  response.item1
    ?.filter((x) => x.name !== 'Ortalama Plan')
    ?.forEach((element) => {
      const rowData = {
        'Plan Adı': element.name || '',
        '': '',
        Gün: element.day || 1,
        ' ': 'Porsiyon (g)',
      };
      element.planTotal.forEach((total) => {
        rowData[
          `${total.header} (${NutrientUnitHelper(
            total.header,
            total.unit,
            energyValue
          )})`
        ] = NutrientValueCalculate(
          total.header,
          total.value,
          energyValue,
          total.commaNumber
        );
      });
      rowData.Ad = `${element.coachee?.name || ''} ${
        element.coachee?.surname || ''
      }`;
      rowData['Yaş '] = element?.antropometry?.age || '';
      rowData['Boy(cm)'] = element?.antropometry?.stature || '';
      rowData['Ağırlık(kg)'] = element?.antropometry?.weight || '';
      if (element?.antropometry?.rangeType)
        rowData.Cinsiyet =
          (element?.antropometry?.rangeType === 2 ? 'Erkek' : 'Kadın') || '';
      else if (element.coachee?.rRangeType)
        rowData.Cinsiyet =
          element.coachee?.rRangeType === 2 ? 'Erkek' : 'Kadın';
      else rowData.Cinsiyet = '';
      rowData['Bazal metab. (kcal)'] =
        element?.antropometry?.basalMetabolicRate || '';
      rowData['Aktivite metab. (kcal)'] =
        element?.antropometry?.totalMetabolicRate -
          element?.antropometry?.basalMetabolicRate || '';
      rowData['Toplam (kcal)'] =
        element?.antropometry?.totalMetabolicRate || '';
      if (tools.suggestionComparison || tools.selfSuggestionComparison) {
        rowData['   '] = '';
        rowData['    '] = '';
        if (tools.suggestionComparison || tools.selfSuggestionComparison) {
          element.planTotal?.forEach((total) => {
            rowData[
              `${total.header}  (${NutrientUnitHelper(
                total.header,
                total.unit,
                energyValue
              )}) `
            ] = `${ValuePercentage(
              total,
              element.planReferenceRangeNutrientValue.find(
                (x) => x.nutrientId === total.nutrientId
              )
            )} %`;
          });
        }
      }
      result.push(rowData);
    });
  result.push({});
  if (tools.repastAnalysis) {
    const repastHeader = {
      'Plan Adı': 'Plan Adı',
      '': '',
      Gün: 'Öğünler',
      ' ': '',
    };
    response.item1[0]?.planTotal?.forEach((total) => {
      repastHeader[
        `${total.header} (${NutrientUnitHelper(
          total.header,
          total.unit,
          energyValue
        )})`
      ] = `${total.header} (${NutrientUnitHelper(
        total.header,
        total.unit,
        energyValue
      )})`;
    });
    result.push(repastHeader);
    result.push({});
    response.item1
      ?.filter((x) => x.name !== 'Ortalama Plan')
      ?.forEach((repastPlan) => {
        repastPlan?.planRepastModel
          ?.sort((a, b) => a.displayOrder - b.displayOrder)
          ?.forEach((planRepast) => {
            const rowData = {
              'Plan Adı': repastPlan?.name || '',
              '': '',
              Gün: planRepast?.repast?.name || '',
              ' ': '',
            };
            planRepast.dynamicTotal?.forEach((total) => {
              rowData[
                `${total.header} (${NutrientUnitHelper(
                  total.header,
                  total.unit,
                  energyValue
                )})`
              ] = NutrientValueCalculate(
                total.header,
                total.value,
                energyValue,
                total.commaNumber
              );
            });
            result.push(rowData);
          });
        result.push({});
      });
  }
  if (tools.foodList || tools.foodListRecipe) {
    const foodListHeader = {
      'Plan Adı': tools.summary ? '' : 'Plan Adı',
      '': 'Bebis Kodu',
      Gün: 'Besin Maddesi',
      ' ': 'Porsiyon (g)',
    };
    response.item1[0]?.planTotal?.forEach((total) => {
      foodListHeader[
        `${total.header} (${NutrientUnitHelper(
          total.header,
          total.unit,
          energyValue
        )})`
      ] = `${total.header} (${NutrientUnitHelper(
        total.header,
        total.unit,
        energyValue
      )})`;
    });
    result.push(foodListHeader);
    result.push({});
    if (tools.summary) {
      const avaragePlan = response.item1?.find(
        (x) => x.name === 'Ortalama Plan'
      );
      avaragePlan?.planRepastModel
        ?.sort((a, b) => a.displayOrder - b.displayOrder)
        .forEach((planRepast) => {
          planRepast?.planRepastFoodModel
            ?.sort((a, b) => a.displayOrder - b.displayOrder)
            ?.forEach((planRepastFood) => {
              const rowData = {
                'Plan Adı': '',
              };
              rowData[''] = planRepastFood?.food?.code || '';
              rowData.Gün = planRepastFood?.food?.name || '';
              rowData[' '] = (
                planRepastFood?.planRepastFoodPortion?.defaultValue || 0
              ).toString();
              planRepastFood?.foodDynamicTotal.forEach((total) => {
                rowData[
                  `${total.header} (${NutrientUnitHelper(
                    total.header,
                    total.unit,
                    energyValue
                  )})`
                ] = NutrientValueCalculate(
                  total.header,
                  total.value,
                  energyValue,
                  total.commaNumber
                );
              });
              result.push(rowData);
            });
        });
      result.push({});
    } else
      response.item1
        ?.filter((x) => x.name !== 'Ortalama Plan')
        ?.forEach((foodPlan) => {
          foodPlan?.planRepastModel
            ?.sort((a, b) => a.displayOrder - b.displayOrder)
            ?.forEach((planRepast) => {
              planRepast?.planRepastFoodModel
                ?.sort((a, b) => a.displayOrder - b.displayOrder)
                ?.forEach((planRepastFood) => {
                  const rowData = {
                    'Plan Adı': foodPlan.name || '',
                  };
                  rowData[''] = planRepastFood?.food?.code || '';
                  rowData.Gün = planRepastFood?.food?.name || '';
                  rowData[' '] = (
                    planRepastFood?.planRepastFoodPortion?.defaultValue || 0
                  ).toString();
                  planRepastFood?.foodDynamicTotal.forEach((total) => {
                    rowData[
                      `${total.header} (${NutrientUnitHelper(
                        total.header,
                        total.unit,
                        energyValue
                      )})`
                    ] = NutrientValueCalculate(
                      total.header,
                      total.value,
                      energyValue,
                      total.commaNumber
                    );
                  });
                  result.push(rowData);
                });
            });
          result.push({});
        });
  }
  if (tools.foodGrouping) {
    result.push({});
    const foodGroupData = {
      'Plan Adı': 'Besin Grubu',
      '': 'Miktar',
      Gün: '',
      ' ': '',
    };
    response.item1[0]?.planTotal?.forEach((total) => {
      foodGroupData[
        `${total.header} (${NutrientUnitHelper(
          total.header,
          total.unit,
          energyValue
        )})`
      ] = `${total.header} (${NutrientUnitHelper(
        total.header,
        total.unit,
        energyValue
      )})`;
    });
    result.push(foodGroupData);
    result.push({});
    response.item2?.foodGroups.forEach((foodGroup) => {
      const foodRowData = {
        'Plan Adı': foodGroup?.groupName || '',
        '': '',
        Gün: '',
        ' ': '',
      };
      response.item1[0].planTotal.forEach((header) => {
        foodRowData[
          `${header.header} (${NutrientUnitHelper(
            header.header,
            header.unit,
            energyValue
          )})`
        ] = '';
      });
      result.push(foodRowData);
      result.push({});
      foodGroup?.subFoodGroups?.forEach((subFoodGroup) => {
        const subFoodRowData = {
          'Plan Adı': ` ${subFoodGroup?.subGroupName || ''}`,
          '': `${subFoodGroup.subFoodGroupTotalGram || ''} gr`,
          Gün: '',
          ' ': '',
        };
        response.item1[0].planTotal.forEach((header) => {
          subFoodRowData[
            `${header.header} (${NutrientUnitHelper(
              header.header,
              header.unit,
              energyValue
            )})`
          ] = `${
            parseFloat(
              subFoodGroup.subGroupNutrientValues.find(
                (x) => x.nutrientId === header.nutrientId
              )?.oran || 0,
              10
            )?.toFixed(2) || 0
          } %`;
        });
        result.push(subFoodRowData);
      });
      result.push({});
      const foodRowResultData = {
        'Plan Adı': 'Grup Toplamı',
        '': `${foodGroup?.foodGroupTotalGram || ''} gr`,
        Gün: '',
        ' ': '',
      };
      response.item1[0].planTotal.forEach((header) => {
        foodRowResultData[
          `${header.header} (${NutrientUnitHelper(
            header.header,
            header.unit,
            energyValue
          )})`
        ] = `${
          parseFloat(
            foodGroup.groupValues.find(
              (x) => x.nutrientId === header.nutrientId
            )?.oran || 0,
            10
          ).toFixed(2) || 0
        } %`;
      });
      result.push(foodRowResultData);
      result.push({});
    });
  }
  excelHelper(result);
};
const pdfExport = (response, tools, energyValue) => {
  console.log('response', response);
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Roboto',
      fontSize: '8px',
      width: '100%',
      height: '100vh',
      paddingTop: 60,
      paddingLeft: 60,
      paddingRight: 60,
      paddingBottom: 50,
    },
    head: {
      width: '100%',
      fontWeight: 'bold',
      textAlign: 'center',
      borderBottom: '1px solid black',
      borderTop: '1px solid black',
      padding: '10 0px',
      fontSize: '16px',
    },
    tableFoodHead: {
      width: `${100 / 6}%`,
    },
    tavleFoodHeadMeal: {
      width: '50%',
      fontWeight: 'bold',
      fontSize: '12px',
    },
    resultFoodHead: {
      width: `${100 / 4}%`,
    },
  });
  const avaragePlan = response.item1.find((x) => x.name === 'Ortalama Plan');
  const views = (
    <>
      <View style={styles.head}>
        <Text>Birden Fazla Plan Analizi</Text>
      </View>
      {!tools.pdfFoodlist && (
        <View
          style={{
            width: '100%',
            alignItems: 'start',
            justifyContent: 'flex-start',
            display: 'flex',
            marginTop: 5,
            paddingBottom: 10,
          }}
        >
          <View>
            <Text>
              Plan Sayısı:{' '}
              {response.item1?.filter((x) => x.name !== 'Ortalama Plan')
                ?.length || 0}
            </Text>
          </View>
          <View>
            <Text>
              Planlar :{' '}
              {response.item1
                ?.filter((x) => x.name !== 'Ortalama Plan')
                ?.map((x) => x.name || '')
                .join(' - ')}
            </Text>
          </View>
        </View>
      )}
      {tools.pdfFoodList && (
        <>
          <View style={{ margin: '3%' }} />
          <View
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 5,
              borderBottom: '1px solid black',
              paddingBottom: 10,
            }}
          >
            <View style={{ ...styles.tableFoodHead, width: '50%' }}>
              <Text>Besin</Text>
            </View>
            <View style={styles.tableFoodHead}>
              <Text>Miktar</Text>
            </View>
            {response.item1[0]?.planRepastModel[0]?.dynamicDocumentTotal?.map(
              (documentTotal) => (
                <View
                  key={documentTotal.nutrientId}
                  style={styles.tableFoodHead}
                >
                  <Text>
                    {documentTotal.header} (
                    {NutrientUnitHelper(
                      documentTotal?.header,
                      documentTotal?.unit,
                      energyValue
                    )}
                    )
                  </Text>
                </View>
              )
            )}
          </View>
          {response.item1
            .filter((x) => x.name !== 'Ortalama Plan')
            .map((plan, index) => {
              return (
                <View key={plan.id} break={index !== 0}>
                  <View
                    style={{
                      ...styles.tavleFoodHeadMeal,
                      borderBottom: '1px solid black',
                      paddingBottom: 10,
                      width: '100%',
                    }}
                  >
                    <Text>{plan?.name || ''}</Text>
                  </View>
                  <View>
                    {plan.planRepastModel.map((planRepast) => (
                      <View key={planRepast.id}>
                        <View
                          style={{
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 10,
                          }}
                        >
                          <View
                            style={{
                              ...styles.tableFoodHead,
                              width: '50%',
                            }}
                          >
                            <Text style={styles.tavleFoodHeadMeal}>
                              {planRepast?.repast?.name}
                            </Text>
                          </View>
                          {/* {planRepast?.dynamicDocumentTotal?.map(
                            (documentTotal) => (
                              <View
                                key={documentTotal.nutrientId}
                                style={styles.tableFoodHead}
                              >
                                <Text>
                                  {documentTotal.header} (
                                  {NutrientUnitHelper(
                                    documentTotal?.header,
                                    documentTotal?.unit,
                                    energyValue
                                  )}
                                  )
                                </Text>
                              </View>
                            )
                          )} */}
                        </View>
                        {planRepast.planRepastFoodModel?.map((itemFood) => {
                          return (
                            <View
                              key={itemFood.id}
                              style={{
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 10,
                              }}
                            >
                              <View
                                style={{
                                  ...styles.tableFoodHead,
                                  width: '50%',
                                }}
                              >
                                <Text>{itemFood?.food?.name}</Text>
                              </View>
                              <View style={styles.tableFoodHead}>
                                <Text>
                                  {itemFood?.planRepastFoodPortion
                                    ?.defaultValue || 0}
                                  g
                                </Text>
                              </View>
                              {itemFood?.foodDynamicDocumentTotal?.map(
                                (documentTotal) => (
                                  <View
                                    key={documentTotal.nutrientId}
                                    style={styles.tableFoodHead}
                                  >
                                    <Text>
                                      {documentTotal?.value
                                        ? NutrientValueCalculate(
                                            documentTotal?.header,
                                            documentTotal?.value,
                                            energyValue,
                                            documentTotal?.commaNumber
                                          )
                                        : '0.00'}
                                    </Text>
                                  </View>
                                )
                              )}
                            </View>
                          );
                        })}
                      </View>
                    ))}
                  </View>
                </View>
              );
            })}
        </>
      )}
      <View style={{ ...styles.head, borderTop: 'none' }} break>
        <Text>Sonuç</Text>
      </View>
      <View
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          marginTop: 10,
          borderBottom: '1px solid black',
          paddingBottom: 10,
        }}
      >
        <View style={styles.resultFoodHead}>
          <Text>Besinler</Text>
        </View>
        <View style={styles.resultFoodHead}>
          <Text>Analiz Edilmiş Miktarlar/Gün</Text>
        </View>
        <View style={styles.resultFoodHead}>
          <Text>Önerilen Miktarlar/Gün</Text>
        </View>
        <View style={styles.resultFoodHead}>
          <Text>Ögenin karşılanma yüzdesi (%)</Text>
        </View>
      </View>
      {avaragePlan?.planTotal?.map((item) => {
        const planReferenceValue =
          avaragePlan?.planReferenceRangeNutrientValue.find(
            (x) => x.nutrientId === item.nutrientId
          );
        return (
          <View
            key={item.nutrientId}
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
            }}
          >
            <View style={styles.resultFoodHead}>
              <Text>
                {item?.header} (
                {NutrientUnitHelper(item.header, item.unit, energyValue)})
              </Text>
            </View>
            <View style={styles.resultFoodHead}>
              <Text>
                {item.value
                  ? NutrientValueCalculate(
                      item.header,
                      item.value,
                      energyValue,
                      item.commaNumber
                    )
                  : 0}
              </Text>
            </View>
            <View style={styles.resultFoodHead}>
              <Text>
                {(energyValue
                  ? planReferenceValue.value
                  : planReferenceValue.value *
                      (item.header === 'Enerji' ? 4.184 : 1) || 0
                ).toFixed(2)}
              </Text>
            </View>
            <View style={styles.resultFoodHead}>
              <Text>
                {item?.value !== 0 && planReferenceValue.value !== 0
                  ? (
                      (item.value /
                        (item.header === 'Enerji' ? 4.184 : 1) /
                        planReferenceValue.value) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </Text>
            </View>
          </View>
        );
      })}
      {tools.assessment && (
        <>
          <View
            style={{ ...styles.head, borderTop: 'none', marginBottom: '10px' }}
            break
          >
            <Text>Besin Öğeleri Yorumları</Text>
          </View>
          {avaragePlan.planCommentTotals.map((comment) => (
            <View key={comment.nutrientId}>
              <View>
                <Text style={{ fontSize: '12px' }}>
                  {comment.header || ''} - {comment.comment || ''}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: '10px',
                    marginTop: '5px',
                    marginLeft: '5px',
                  }}
                >
                  {comment.description || ''}
                </Text>
              </View>
              <View
                style={{
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
              >
                <Text style={{ fontSize: '12px' }}>
                  Eksik Miktar:{'   '}
                  {NutrientValueCalculate(
                    comment.lackTotal?.header,
                    comment.lackTotal?.lack,
                    energyValue,
                    comment.lackTotal?.commaNumber
                  )}
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '75%',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ width: '50%', fontSize: '10px' }}>
                    <Text>Besin Adı</Text>
                  </View>
                  <View style={{ width: '25%', fontSize: '10px' }}>
                    <Text>Miktar</Text>
                  </View>
                  <View style={{ width: '25%', fontSize: '10px' }}>
                    <Text>Enerji</Text>
                  </View>
                </View>
                {comment.lackTotal?.planLackFoods.map((lackFood) => (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '75%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                    key={comment.nutrientId + lackFood.foodId}
                  >
                    <View style={{ width: '50%' }}>
                      <Text>{lackFood.foodName}</Text>
                    </View>
                    <View style={{ width: '25%' }}>
                      <Text>{lackFood.portion.toFixed(2) || 0}</Text>
                    </View>
                    <View style={{ width: '25%' }}>
                      <Text>
                        {NutrientValueCalculate(
                          'Enerji',
                          lackFood.dynamicTotals.find(
                            (x) => x.header === 'Enerji'
                          )?.value,
                          energyValue,
                          lackFood.dynamicTotals.find(
                            (x) => x.header === 'Enerji'
                          )?.commaNumber
                        )}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </>
      )}
    </>
  );

  exportPdfHelpers(views);
  return { response, tools, energyValue };
};

const PlanToolExportHelper = (response, tools, energyValue) => {
  switch (tools.type) {
    case 0:
      excelExport(response, tools, energyValue);
      break;
    case 1:
      pdfExport(response, tools, energyValue);
      break;
    default:
      break;
  }
};

export default PlanToolExportHelper;
