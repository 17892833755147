/* eslint-disable prettier/prettier */
/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import {
  PLANREPAST_GET_LIST,
  PLANREPAST_GET_LIST_SUCCESS,
  PLANREPAST_GET_LIST_ERROR,
  PLANREPAST_SET_SELECTED,
  PLANREPAST_UPDATE_SELECTED,
  PLANREPAST_SET_SELECTED_SUCCESS,
  PLANREPAST_SET_SELECTED_ERROR,
  PLANREPAST_UPDATE_RECORD,
  PLANREPAST_UPDATE_RECORD_ERROR,
  PLANREPAST_UPDATE_RECORD_SUCCESS,
  PLANREPAST_ADD_RECORD,
  PLANREPAST_ADD_RECORD_SUCCESS,
  PLANREPAST_ADD_RECORD_ERROR,
  PLANREPAST_DELETE_RECORD,
  PLANREPAST_DELETE_RECORD_SUCCESS,
  PLANREPAST_DELETE_RECORD_ERROR,
  PLANREPAST_RESET_STATUSES,
  PLANREPAST_UPDATE_BATCHRECORD,
  PLANREPAST_UPDATE_BATCHRECORD_SUCCESS,
  PLANREPAST_UPDATE_BATCHRECORD_ERROR,
  PLANREPAST_PLANREPASTADDFOOD,
  PLANREPAST_PLANREPASTADDFOOD_SUCCESS,
  PLANREPAST_PLANREPASTADDFOOD_ERROR,
  PLANREPAST_PLANREPASTDELETEFOOD,
  PLANREPAST_PLANREPASTDELETEFOOD_SUCCESS,
  PLANREPAST_PLANREPASTDELETEFOOD_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  repast: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
  dynamicTotal: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PLANREPAST_GET_LIST:
      return { ...state, loading: true };
    case PLANREPAST_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        repast: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case PLANREPAST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PLANREPAST_SET_SELECTED:
      return { ...state, modalLoading: true };
    case PLANREPAST_SET_SELECTED_SUCCESS: {
      return { ...state, modalLoading: false, repast: action.payload };
    }
    case PLANREPAST_SET_SELECTED_ERROR:
      return { ...state, modalLoading: false, error: action.payload };
    case PLANREPAST_UPDATE_SELECTED:
      return { ...state, modalLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case PLANREPAST_ADD_RECORD:
      return { ...state, loading: false };
    case PLANREPAST_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPAST_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PLANREPAST_UPDATE_RECORD:
      return { ...state, loading: false };
    case PLANREPAST_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPAST_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLANREPAST_UPDATE_BATCHRECORD:
      return { ...state, loading: false };
    case PLANREPAST_UPDATE_BATCHRECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPAST_UPDATE_BATCHRECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLANREPAST_DELETE_RECORD:
      return { ...state, loading: false };
    case PLANREPAST_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPAST_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------

    case PLANREPAST_PLANREPASTADDFOOD: {
      return { ...state, loading: false };
    }

    case PLANREPAST_PLANREPASTADDFOOD_SUCCESS:
      return { ...state, loading: false };
    case PLANREPAST_PLANREPASTADDFOOD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------

    case PLANREPAST_PLANREPASTDELETEFOOD: {
      return { ...state, loading: false };
    }

    case PLANREPAST_PLANREPASTDELETEFOOD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLANREPAST_PLANREPASTDELETEFOOD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------

    case PLANREPAST_RESET_STATUSES:
      return { ...state, success: null, error: null };
    default:
      return { ...state };
  }
};
