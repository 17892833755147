/* eslint-disable prettier/prettier */
import {
  PLAN_GET_LIST,
  PLAN_GET_LIST_SUCCESS,
  PLAN_GET_LIST_ERROR,
  PLAN_SET_SELECTED,
  PLAN_UPDATE_SELECTED,
  PLAN_SET_SELECTED_SUCCESS,
  PLAN_SET_SELECTED_ERROR,
  PLAN_UPDATE_RECORD,
  PLAN_UPDATE_RECORD_ERROR,
  PLAN_UPDATE_RECORD_SUCCESS,
  PLAN_ADD_RECORD,
  PLAN_ADD_RECORD_SUCCESS,
  PLAN_ADD_RECORD_ERROR,
  PLAN_DELETE_RECORD,
  PLAN_DELETE_RECORD_SUCCESS,
  PLAN_DELETE_RECORD_ERROR,
  PLAN_RESET_STATUSES,
  PLAN_CLONE_SET_SELECTED,
  PLAN_CLONE_SET_SELECTED_SUCCESS,
  PLAN_CLONE_SET_SELECTED_ERROR,
  PLAN_ISACTIVECHANGE_ERROR,
  PLAN_ISACTIVECHANGE_SUCCESS,
  PLAN_ISACTIVECHANGE,
  PLAN_GENERICSEARCH,
  PLAN_GENERICSEARCH_SUCCESS,
  PLAN_GENERICSEARCH_ERROR,
  PLAN_SPECIALLY_WITH_ID,
  PLAN_SPECIALLY_WITH_ID_SUCCESS,
  PLAN_SPECIALLY_WITH_ID_ERROR,
  PLAN_ANALYSIS,
  PLAN_ANALYSIS_SUCCESS,
  PLAN_ANALYSIS_ERROR,
  PLAN_IMPORT,
  PLAN_IMPORT_SUCCESS,
  PLAN_IMPORT_ERROR,
  PLAN_ANALYSIS_REFERENCE_NUTRIENT,
  PLAN_ANALYSIS_REFERENCE_NUTRIENT_SUCCESS,
  PLAN_ANALYSIS_REFERENCE_NUTRIENT_ERROR,
  PLAN_FOODGROUP_GROUPING,
  PLAN_FOODGROUP_GROUPING_SUCCESS,
  PLAN_FOODGROUP_GROUPING_ERROR,
  PLAN_NUTRIENTSORTING,
  PLAN_NUTRIENTSORTING_SUCCESS,
  PLAN_NUTRIENTSORTING_ERROR,
  PLAN_NUTRIENTTRANSFER,
  PLAN_NUTRIENTTRANSFER_SUCCESS,
  PLAN_NUTRIENTTRANSFER_ERROR,
  SHAREDPLAN_GET_LIST,
  SHAREDPLAN_GET_LIST_SUCCESS,
  SHAREDPLAN_GET_LIST_ERROR,
  SHAREDPLAN_IMPORT_FOR_ME,
  SHAREDPLAN_IMPORT_FOR_ME_SUCCESS,
  SHAREDPLAN_IMPORT_FOR_ME_ERROR,
  SHAREDPLAN_EXPORT,
  SHAREDPLAN_EXPORT_SUCCESS,
  SHAREDPLAN_EXPORT_ERROR,
  SHAREDPLAN_PENDING_LIST,
  SHAREDPLAN_PENDING_LIST_SUCCESS,
  SHAREDPLAN_PENDING_LIST_ERROR,
  SHAREDPLAN_CONFIRM,
  SHAREDPLAN_CONFIRM_SUCCESS,
  SHAREDPLAN_CONFIRM_ERROR,
  PLAN_LACKCOMPLETION,
  PLAN_LACKCOMPLETION_SUCCESS,
  PLAN_LACKCOMPLETION_ERROR,
  PLAN_COMMENTT,
  PLAN_COMMENTT_SUCCESS,
  PLAN_COMMENTT_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
  planImportSuccess: null,
  planReferenceRangeNutrientValue: null,
  graphicData: null,
  pdfData: null,
  lackCompletionData: null,
  commentData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PLAN_GET_LIST:
      return { ...state, loading: true };
    case PLAN_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case PLAN_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case SHAREDPLAN_GET_LIST:
      return { ...state, loading: true };
    case SHAREDPLAN_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case SHAREDPLAN_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------

    case SHAREDPLAN_IMPORT_FOR_ME:
      return { ...state, loading: true };
    case SHAREDPLAN_IMPORT_FOR_ME_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRecord: action.payload.singleData,
      };
    case SHAREDPLAN_IMPORT_FOR_ME_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case SHAREDPLAN_EXPORT:
      return { ...state, loading: true };
    case SHAREDPLAN_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SHAREDPLAN_EXPORT_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case SHAREDPLAN_PENDING_LIST:
      return {
        ...state,
        loading: true,
      };
    case SHAREDPLAN_PENDING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case SHAREDPLAN_PENDING_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case SHAREDPLAN_CONFIRM:
      return {
        ...state,
        loading: true,
      };
    case SHAREDPLAN_CONFIRM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SHAREDPLAN_CONFIRM_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PLAN_SET_SELECTED:
      return { ...state, modaLoading: true };
    case PLAN_SET_SELECTED_SUCCESS: {
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    }
    case PLAN_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case PLAN_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case PLAN_ADD_RECORD:
      return { ...state, loading: false };
    case PLAN_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLAN_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PLAN_UPDATE_RECORD:
      return { ...state, loading: false };
    case PLAN_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLAN_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLAN_DELETE_RECORD:
      return { ...state, loading: false };
    case PLAN_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLAN_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLAN_RESET_STATUSES:
      return { ...state, success: null, error: null };
    case PLAN_CLONE_SET_SELECTED:
      return { ...state };
    case PLAN_CLONE_SET_SELECTED_SUCCESS:
      return { ...state };
    case PLAN_CLONE_SET_SELECTED_ERROR:
      return { ...state };
    //----------------------------------------------------------------------------------
    case PLAN_ISACTIVECHANGE:
      return { ...state, loading: false };
    case PLAN_ISACTIVECHANGE_SUCCESS: {
      // eslint-disable-next-line prefer-const
      let objIndex = state.records.findIndex(
        (x) => x.id === action.payload.singleData.id
      );
      // eslint-disable-next-line no-param-reassign
      state.records[objIndex] = action.payload.singleData;
      return { ...state, loading: false, records: state.records };
    }
    case PLAN_ISACTIVECHANGE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLAN_GENERICSEARCH:
      return { ...state, loading: false };
    case PLAN_GENERICSEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case PLAN_GENERICSEARCH_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLAN_SPECIALLY_WITH_ID:
      return { ...state, loading: false };
    case PLAN_SPECIALLY_WITH_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRecord: action.payload,
      };
    case PLAN_SPECIALLY_WITH_ID_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PLAN_ANALYSIS:
      return { ...state, loading: false };
    case PLAN_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        excelData: action.payload,
      };
    case PLAN_ANALYSIS_ERROR:
      return { ...state, loading: true, error: action.payload };
    case PLAN_IMPORT:
      return { ...state, loading: false };
    case PLAN_IMPORT_SUCCESS:
      return {
        ...state,
        planImportSuccess: action.payload,
      };
    case PLAN_IMPORT_ERROR:
      return { ...state, loading: true, error: action.payload };
    case PLAN_ANALYSIS_REFERENCE_NUTRIENT:
      return { ...state, loading: false };
    case PLAN_ANALYSIS_REFERENCE_NUTRIENT_SUCCESS:
      return {
        ...state,
        planReferenceRangeNutrientValue:
          action.payload.planReferenceRangeNutrientValue?.length > 0
            ? action.payload
            : state.planReferenceRangeNutrientValue,
        pdfData:
          action.payload.planReferenceSources?.length > 0
            ? action.payload
            : null,
      };
    case PLAN_ANALYSIS_REFERENCE_NUTRIENT_ERROR:
      return { ...state, loading: true, error: action.payload };
    case PLAN_FOODGROUP_GROUPING:
      return { ...state, loading: false };
    case PLAN_FOODGROUP_GROUPING_SUCCESS:
      return { ...state, loading: false, excelData: action.payload };
    case PLAN_FOODGROUP_GROUPING_ERROR:
      return { ...state, loading: true, error: action.payload };
    case PLAN_NUTRIENTSORTING:
      return { ...state, loading: false };
    case PLAN_NUTRIENTSORTING_SUCCESS:
      return { ...state, loading: false, graphicData: action.payload };
    case PLAN_NUTRIENTSORTING_ERROR:
      return { ...state, loading: true, error: action.payload };
    case PLAN_NUTRIENTTRANSFER:
      return { ...state, loading: false };
    case PLAN_NUTRIENTTRANSFER_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PLAN_NUTRIENTTRANSFER_ERROR:
      return { ...state, loading: true, error: action.payload };
    case PLAN_LACKCOMPLETION:
      return { ...state, loading: false };
    case PLAN_LACKCOMPLETION_SUCCESS:
      return { ...state, loading: false, lackCompletionData: action.payload };
    case PLAN_LACKCOMPLETION_ERROR:
      return { ...state, loading: true, error: action.payload };
    case PLAN_COMMENTT:
      return { ...state, loading: false };
    case PLAN_COMMENTT_SUCCESS:
      return { ...state, loading: false, commentData: action.payload };
    case PLAN_COMMENTT_ERROR:
      return { ...state, loading: true, error: action.payload };
    default:
      return { ...state };
  }
};
