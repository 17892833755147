/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  debounce,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  REFERENCESOURCE_ADD_RECORD,
  REFERENCESOURCE_UPDATE_RECORD,
  REFERENCESOURCE_GET_LIST,
  REFERENCESOURCE_SET_SELECTED,
  REFERENCESOURCE_DELETE_RECORD,
  deleteReferenceSourceRecordSuccess,
  deleteReferenceSourceRecordError,
  REFERENCESOURCE_ISACTIVECHANGE,
} from '../../actions';

import {
  getReferenceSourceListSuccess,
  getReferenceSourceListError,
  setReferenceSourceSelectedRecordSuccess,
  setReferenceSourceSelectedRecordError,
  addReferenceSourceRecordSuccess,
  addReferenceSourceRecordError,
  updateReferenceSourceRecordSuccess,
  updateReferenceSourceRecordError,
  isActiveChangeReferenceSourceSuccess,
  isActiveChangeReferenceSourceError,
} from './actions';

//---------------------------------------------------------------------
const getReferenceSourceListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/referencesource${url || ''}`)
    .then((response) => response.data);
};

function* getReferenceSourceListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getReferenceSourceListRequest,
        filterBuilder(payload)
      );
      yield put(getReferenceSourceListSuccess(response));
    } else {
      const response = yield call(getReferenceSourceListRequest);
      yield put(getReferenceSourceListSuccess(response));
    }
  } catch (error) {
    yield put(getReferenceSourceListError(error));
  }
}
//----------------------------------------------------------------------------------
const setReferenceSourceSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/referencesource/${id}`)
    .then((response) => response.data.singleData);

function* setReferenceSourceSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setReferenceSourceSelectedRecordRequest,
      payload
    );
    yield put(setReferenceSourceSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setReferenceSourceSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addReferenceSourceRecordRequest = (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/referencesource`, record)
    .then((response) => response.data);

function* addReferenceSourceRecords({ payload }) {
  try {
    yield call(addReferenceSourceRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addReferenceSourceRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getReferenceSourceListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addReferenceSourceRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateReferenceSourceRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/referencesource`, record)
    .then((response) => response.data);

function* updateReferenceSourceRecords({ payload }) {
  try {
    yield call(updateReferenceSourceRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateReferenceSourceRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getReferenceSourceListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateReferenceSourceRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeReferenceSourceRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/referencesource/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeReferenceSource({ payload }) {
  try {
    const response = yield call(isActiveChangeReferenceSourceRequest, payload);
    yield put(isActiveChangeReferenceSourceSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeReferenceSourceError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const deleteReferenceSourceRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/referencesource/${recordId}`)
    .then((response) => response.data);

function* deleteReferenceSourceRecords({ payload }) {
  try {
    yield call(deleteReferenceSourceRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteReferenceSourceRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getReferenceSourceListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteReferenceSourceRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(800, REFERENCESOURCE_GET_LIST, getReferenceSourceListItems);
}

export function* watchSetSelected() {
  yield takeEvery(
    REFERENCESOURCE_SET_SELECTED,
    setReferenceSourceSelectedRecord
  );
}
export function* wathcAddItem() {
  yield takeEvery(REFERENCESOURCE_ADD_RECORD, addReferenceSourceRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(REFERENCESOURCE_UPDATE_RECORD, updateReferenceSourceRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(REFERENCESOURCE_DELETE_RECORD, deleteReferenceSourceRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(
    REFERENCESOURCE_ISACTIVECHANGE,
    isActiveChangeReferenceSource
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
