/* eslint-disable import/no-cycle */
import {
  REFERENCESOURCE_GET_LIST,
  REFERENCESOURCE_GET_LIST_SUCCESS,
  REFERENCESOURCE_GET_LIST_ERROR,
  REFERENCESOURCE_SET_SELECTED,
  REFERENCESOURCE_UPDATE_SELECTED,
  REFERENCESOURCE_SET_SELECTED_SUCCESS,
  REFERENCESOURCE_SET_SELECTED_ERROR,
  REFERENCESOURCE_ADD_RECORD_SUCCESS,
  REFERENCESOURCE_ADD_RECORD_ERROR,
  REFERENCESOURCE_ADD_RECORD,
  REFERENCESOURCE_UPDATE_RECORD_SUCCESS,
  REFERENCESOURCE_UPDATE_RECORD_ERROR,
  REFERENCESOURCE_UPDATE_RECORD,
  REFERENCESOURCE_DELETE_RECORD,
  REFERENCESOURCE_DELETE_RECORD_SUCCESS,
  REFERENCESOURCE_DELETE_RECORD_ERROR,
  REFERENCESOURCE_RESET_STATUSES,
  REFERENCESOURCE_ISACTIVECHANGE_SUCCESS,
  REFERENCESOURCE_ISACTIVECHANGE_ERROR,
  REFERENCESOURCE_ISACTIVECHANGE,
} from '../../actions';

//-------------------------------------------------------------------------
export const getReferenceSourceList = (pageDetail) => ({
  type: REFERENCESOURCE_GET_LIST,
  payload: pageDetail,
});

export const getReferenceSourceListSuccess = (items) => ({
  type: REFERENCESOURCE_GET_LIST_SUCCESS,
  payload: items,
});

export const getReferenceSourceListError = (error) => ({
  type: REFERENCESOURCE_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setReferenceSourceSelectedRecord = (recordId) => ({
  type: REFERENCESOURCE_SET_SELECTED,
  payload: recordId,
});

export const setReferenceSourceSelectedRecordSuccess = (record) => ({
  type: REFERENCESOURCE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setReferenceSourceSelectedRecordError = (error) => ({
  type: REFERENCESOURCE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateReferenceSourceSelectedRecord = (record) => ({
  type: REFERENCESOURCE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addReferenceSourceRecord = (record) => ({
  type: REFERENCESOURCE_ADD_RECORD,
  payload: record,
});

export const addReferenceSourceRecordSuccess = (msg) => ({
  type: REFERENCESOURCE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addReferenceSourceRecordError = (error) => ({
  type: REFERENCESOURCE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateReferenceSourceRecords = (record) => ({
  type: REFERENCESOURCE_UPDATE_RECORD,
  payload: record,
});

export const updateReferenceSourceRecordSuccess = (msg) => ({
  type: REFERENCESOURCE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateReferenceSourceRecordError = (error) => ({
  type: REFERENCESOURCE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteReferenceSourceRecords = (record) => ({
  type: REFERENCESOURCE_DELETE_RECORD,
  payload: record,
});

export const deleteReferenceSourceRecordSuccess = (msg) => ({
  type: REFERENCESOURCE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteReferenceSourceRecordError = (error) => ({
  type: REFERENCESOURCE_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeReferenceSource = (record) => ({
  type: REFERENCESOURCE_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeReferenceSourceSuccess = (msg) => ({
  type: REFERENCESOURCE_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeReferenceSourceError = (error) => ({
  type: REFERENCESOURCE_ISACTIVECHANGE_ERROR,
  payload: error,
});
export const resetReferenceSourceStatuses = () => ({
  type: REFERENCESOURCE_RESET_STATUSES,
});
//-------------------------------------------------------------------------
