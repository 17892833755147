/* eslint-disable prettier/prettier */
import {
  MENUPREPARE_GET_LIST,
  MENUPREPARE_GET_LIST_SUCCESS,
  MENUPREPARE_GET_LIST_ERROR,
  MENUPREPARE_SET_SELECTED,
  MENUPREPARE_UPDATE_SELECTED,
  MENUPREPARE_SET_SELECTED_SUCCESS,
  MENUPREPARE_SET_SELECTED_ERROR,
  MENUPREPARE_UPDATE_RECORD,
  MENUPREPARE_UPDATE_RECORD_ERROR,
  MENUPREPARE_UPDATE_RECORD_SUCCESS,
  MENUPREPARE_ADD_RECORD,
  MENUPREPARE_ADD_RECORD_SUCCESS,
  MENUPREPARE_ADD_RECORD_ERROR,
  MENUPREPARE_DELETE_RECORD,
  MENUPREPARE_DELETE_RECORD_SUCCESS,
  MENUPREPARE_DELETE_RECORD_ERROR,
  MENUPREPARE_RESET_STATUSES,
  MENUPREPARE_ISACTIVECHANGE,
  MENUPREPARE_ISACTIVECHANGE_SUCCESS,
  MENUPREPARE_ISACTIVECHANGE_ERROR,
  MENUPREPARE_EXPORT,
  MENUPREPARE_EXPORT_SUCCESS,
  MENUPREPARE_EXPORT_ERROR,
  MENUPREPARE_DAY_UPDATE,
  MENUPREPARE_DAY_UPDATE_SUCCESS,
  MENUPREPARE_DAY_UPDATE_ERROR,
} from '../../actions';

const INIT_STATE = {
  records: [],
  dropdownData: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
  exportData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MENUPREPARE_GET_LIST:
      return { ...state, loading: true };
    case MENUPREPARE_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case MENUPREPARE_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case MENUPREPARE_SET_SELECTED:
      return { ...state, modaLoading: true };
    case MENUPREPARE_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case MENUPREPARE_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case MENUPREPARE_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case MENUPREPARE_ADD_RECORD:
      return { ...state, loading: false };
    case MENUPREPARE_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case MENUPREPARE_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case MENUPREPARE_UPDATE_RECORD:
      return { ...state, loading: false };
    case MENUPREPARE_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case MENUPREPARE_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case MENUPREPARE_DELETE_RECORD:
      return { ...state, loading: false };
    case MENUPREPARE_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case MENUPREPARE_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case MENUPREPARE_ISACTIVECHANGE:
      return { ...state, loading: false };
    case MENUPREPARE_ISACTIVECHANGE_SUCCESS: {
      // eslint-disable-next-line prefer-const
      let objIndex = state.records.findIndex(
        (x) => x.id === action.payload.singleData.id
      );
      // eslint-disable-next-line no-param-reassign
      state.records[objIndex] = action.payload.singleData;
      return { ...state, loading: false, records: state.records };
    }
    case MENUPREPARE_ISACTIVECHANGE_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case MENUPREPARE_EXPORT:
      return { ...state, loading: false };
    case MENUPREPARE_EXPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        exportData: action.payload,
      };
    }
    case MENUPREPARE_EXPORT_ERROR:
      return { ...state, loading: true };
    //------------------------------------------------------------------------------
    case MENUPREPARE_DAY_UPDATE:
      return { ...state, loading: false };
    case MENUPREPARE_DAY_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case MENUPREPARE_DAY_UPDATE_ERROR:
      return { ...state, loading: true };
    //------------------------------------------------------------------------------
    case MENUPREPARE_RESET_STATUSES:
      return { ...state, success: null, error: null };
    //------------------------------------------------------------------------------
    default:
      return { ...state };
  }
};
