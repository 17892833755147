// eslint-disable-next-line import/no-cycle
import {
  EMAIL_GET_LIST,
  EMAIL_GET_LIST_SUCCESS,
  EMAIL_GET_LIST_ERROR,
  EMAIL_SET_SELECTED,
  EMAIL_UPDATE_SELECTED,
  EMAIL_SET_SELECTED_SUCCESS,
  EMAIL_SET_SELECTED_ERROR,
  EMAIL_ADD_RECORD_SUCCESS,
  EMAIL_ADD_RECORD_ERROR,
  EMAIL_ADD_RECORD,
  EMAIL_UPDATE_RECORD_SUCCESS,
  EMAIL_UPDATE_RECORD_ERROR,
  EMAIL_UPDATE_RECORD,
  EMAIL_DELETE_RECORD,
  EMAIL_DELETE_RECORD_SUCCESS,
  EMAIL_DELETE_RECORD_ERROR,
  EMAIL_RESET_STATUSES,
  EMAIL_ISACTIVECHANGE_SUCCESS,
  EMAIL_ISACTIVECHANGE_ERROR,
  EMAIL_ISACTIVECHANGE,
  SELECTED_USER_SEND_EMAIL,
  SELECTED_ALL_USER_SEND_EMAIL,
  SELECTED_MULTI_USER_SEND_EMAIL,
} from '../../actions';

//-------------------------------------------------------------------------
export const getEmailList = (pageDetail) => ({
  type: EMAIL_GET_LIST,
  payload: pageDetail,
});

export const getEmailListSuccess = (items) => ({
  type: EMAIL_GET_LIST_SUCCESS,
  payload: items,
});

export const getEmailListError = (error) => ({
  type: EMAIL_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setEmailSelectedRecord = (recordId) => ({
  type: EMAIL_SET_SELECTED,
  payload: recordId,
});

export const setEmailSelectedRecordSuccess = (record) => ({
  type: EMAIL_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setEmailSelectedRecordError = (error) => ({
  type: EMAIL_SET_SELECTED_ERROR,
  payload: error,
});

export const updateEmailSelectedRecord = (record) => ({
  type: EMAIL_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addEmailRecord = (record) => ({
  type: EMAIL_ADD_RECORD,
  payload: record,
});

export const addEmailRecordSuccess = (msg) => ({
  type: EMAIL_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addEmailRecordError = (error) => ({
  type: EMAIL_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateEmailRecords = (record) => ({
  type: EMAIL_UPDATE_RECORD,
  payload: record,
});

export const updateEmailRecordSuccess = (msg) => ({
  type: EMAIL_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateEmailRecordError = (error) => ({
  type: EMAIL_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const deleteEmailRecords = (record) => ({
  type: EMAIL_DELETE_RECORD,
  payload: record,
});

export const deleteEmailRecordSuccess = (msg) => ({
  type: EMAIL_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteEmailRecordError = (error) => ({
  type: EMAIL_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeEmail = (record) => ({
  type: EMAIL_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeEmailSuccess = (msg) => ({
  type: EMAIL_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeEmailError = (error) => ({
  type: EMAIL_ISACTIVECHANGE_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const resetEmailStatuses = () => ({
  type: EMAIL_RESET_STATUSES,
});

export const selectedUserSendEmail = (error) => ({
  type: SELECTED_USER_SEND_EMAIL,
  payload: error,
});

export const selectedMultiUserSendEmail = (error) => ({
  type: SELECTED_MULTI_USER_SEND_EMAIL,
  payload: error,
});

export const selectedAllUserSendEmail = (error) => ({
  type: SELECTED_ALL_USER_SEND_EMAIL,
  payload: error,
});
