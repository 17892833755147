/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  MEAL_GET_LIST,
  MEAL_GET_LIST_SUCCESS,
  MEAL_GET_LIST_ERROR,
  MEAL_SET_SELECTED,
  MEAL_UPDATE_SELECTED,
  MEAL_SET_SELECTED_SUCCESS,
  MEAL_SET_SELECTED_ERROR,
  MEAL_ADD_RECORD_SUCCESS,
  MEAL_ADD_RECORD_ERROR,
  MEAL_ADD_RECORD,
  MEAL_UPDATE_RECORD_SUCCESS,
  MEAL_UPDATE_RECORD_ERROR,
  MEAL_UPDATE_RECORD,
  MEAL_DELETE_RECORD,
  MEAL_DELETE_RECORD_SUCCESS,
  MEAL_DELETE_RECORD_ERROR,
  MEAL_RESET_STATUSES,
  MEAL_ISACTIVECHANGE,
  MEAL_ISACTIVECHANGE_SUCCESS,
  MEAL_ISACTIVECHANGE_ERROR,
  MEAL_GET_PENDING_LIST,
  MEAL_GET_PENDING_LIST_SUCCESS,
  MEAL_GET_PENDING_LIST_ERROR,
  MEAL_GET_SHARED_LIST,
  MEAL_GET_SHARED_LIST_SUCCESS,
  MEAL_GET_SHARED_LIST_ERROR,
  MEAL_SHARE,
  MEAL_SHARE_SUCCESS,
  MEAL_SHARE_ERROR,
  MEAL_CONFIRM,
  MEAL_CONFIRM_SUCCESS,
  MEAL_CONFIRM_ERROR,
  MEAL_IMPORT_FOR_ME,
  MEAL_IMPORT_FOR_ME_SUCCESS,
  MEAL_IMPORT_FOR_ME_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getMealList = (pageDetail) => ({
  type: MEAL_GET_LIST,
  payload: pageDetail,
});

export const getMealListSuccess = (items) => ({
  type: MEAL_GET_LIST_SUCCESS,
  payload: items,
});

export const getMealListError = (error) => ({
  type: MEAL_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------

//-------------------------------------------------------------------------
export const getMealPendingList = (pageDetail) => ({
  type: MEAL_GET_PENDING_LIST,
  payload: pageDetail,
});

export const getMealPendingListSuccess = (items) => ({
  type: MEAL_GET_PENDING_LIST_SUCCESS,
  payload: items,
});

export const getMealPendingListError = (error) => ({
  type: MEAL_GET_PENDING_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------

//-------------------------------------------------------------------------
export const getMealSharedList = (pageDetail) => ({
  type: MEAL_GET_SHARED_LIST,
  payload: pageDetail,
});

export const getMealSharedListSuccess = (items) => ({
  type: MEAL_GET_SHARED_LIST_SUCCESS,
  payload: items,
});

export const getMealSharedListError = (error) => ({
  type: MEAL_GET_SHARED_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const mealShare = (record) => ({
  type: MEAL_SHARE,
  payload: record,
});

export const mealShareSuccess = (msg) => ({
  type: MEAL_SHARE_SUCCESS,
  payload: msg,
});

export const mealShareError = (error) => ({
  type: MEAL_SHARE_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------

export const mealConfirm = (record) => ({
  type: MEAL_CONFIRM,
  payload: record,
});

export const mealConfirmSuccess = (msg) => ({
  type: MEAL_CONFIRM_SUCCESS,
  payload: msg,
});

export const mealConfirmError = (error) => ({
  type: MEAL_CONFIRM_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const importForMeMeal = (record) => ({
  type: MEAL_IMPORT_FOR_ME,
  payload: record,
});

export const importForMeMealSuccess = (msg) => ({
  type: MEAL_IMPORT_FOR_ME_SUCCESS,
  payload: msg,
});

export const importForMeMealError = (error) => ({
  type: MEAL_IMPORT_FOR_ME_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const setMealSelectedRecord = (recordId) => ({
  type: MEAL_SET_SELECTED,
  payload: recordId,
});

export const setMealSelectedRecordSuccess = (record) => ({
  type: MEAL_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setMealSelectedRecordError = (error) => ({
  type: MEAL_SET_SELECTED_ERROR,
  payload: error,
});

export const updateMealSelectedRecord = (record) => ({
  type: MEAL_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addMealRecord = (record) => ({
  type: MEAL_ADD_RECORD,
  payload: record,
});

export const addMealRecordSuccess = (msg) => ({
  type: MEAL_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addMealRecordError = (error) => ({
  type: MEAL_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateMealRecords = (record) => ({
  type: MEAL_UPDATE_RECORD,
  payload: record,
});

export const updateMealRecordSuccess = (msg) => ({
  type: MEAL_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateMealRecordError = (error) => ({
  type: MEAL_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteMealRecords = (record) => ({
  type: MEAL_DELETE_RECORD,
  payload: record,
});

export const deleteMealRecordSuccess = (msg) => ({
  type: MEAL_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteMealRecordError = (error) => ({
  type: MEAL_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeMeal = (record) => ({
  type: MEAL_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeMealSuccess = (msg) => ({
  type: MEAL_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeMealError = (error) => ({
  type: MEAL_ISACTIVECHANGE_ERROR,
  payload: error,
});

export const resetMealStatuses = () => ({ type: MEAL_RESET_STATUSES });
