/* eslint-disable no-param-reassign */

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import { filterBuilder } from 'helpers/Utils';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  NUTRIENTCOMMENT_ADD_RECORD,
  NUTRIENTCOMMENT_UPDATE_RECORD,
  NUTRIENTCOMMENT_GET_LIST,
  NUTRIENTCOMMENT_SET_SELECTED,
  NUTRIENTCOMMENT_DELETE_RECORD,
  NUTRIENTCOMMENT_ISACTIVECHANGE,
  NUTRIENTCOMMENT_BYFILTER,
  NUTRIENTCOMMENT_UPSERT_RECORD,
} from '../../actions';

import {
  getNutrientCommentListSuccess,
  getNutrientCommentListError,
  setNutrientCommentSelectedRecordSuccess,
  setNutrientCommentSelectedRecordError,
  addNutrientCommentRecordSuccess,
  addNutrientCommentRecordError,
  updateNutrientCommentRecordSuccess,
  updateNutrientCommentRecordError,
  isActiveChangeNutrientCommentSuccess,
  isActiveChangeNutrientCommentError,
  deleteNutrientCommentRecordSuccess,
  deleteNutrientCommentRecordError,
  NutrientCommentByFilterSuccess,
  NutrientCommentByFilterError,
  upsertNutrientCommentRecordsSuccess,
  upsertNutrientCommentRecordsError,
} from './actions';

//---------------------------------------------------------------------
const getNutrientCommentListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/nutrientcomment${url || ''}`)
    .then((response) => response.data);
};

function* getNutrientCommentListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getNutrientCommentListRequest,
        filterBuilder(payload)
      );

      yield put(getNutrientCommentListSuccess(response));
    } else {
      const response = yield call(getNutrientCommentListRequest);
      yield put(getNutrientCommentListSuccess(response));
    }
  } catch (error) {
    yield put(getNutrientCommentListError(error));
  }
}
const setNutrientCommentSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/nutrientcomment/${id}`)
    .then((response) => response.data.singleData);

function* setNutrientCommentSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setNutrientCommentSelectedRecordRequest,
      payload
    );
    yield put(setNutrientCommentSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setNutrientCommentSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addNutrientCommentRecordRequest = async (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/nutrientcomment`, record)
    .then((response) => response.data);

function* addNutrientCommentRecords({ payload }) {
  try {
    yield call(addNutrientCommentRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addNutrientCommentRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getNutrientCommentListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addNutrientCommentRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateNutrientCommentRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/nutrientcomment`, record)
    .then((response) => response.data);

function* updateNutrientCommentRecords({ payload }) {
  try {
    yield call(updateNutrientCommentRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateNutrientCommentRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getNutrientCommentListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateNutrientCommentRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const isActiveChangeNutrientCommentRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/nutrientcomment/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeNutrientComment({ payload }) {
  try {
    const response = yield call(isActiveChangeNutrientCommentRequest, payload);
    yield put(isActiveChangeNutrientCommentSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeNutrientCommentError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

//----------------------------------------------------------------------------------
const deleteNutrientCommentRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/nutrientcomment/${recordId}`)
    .then((response) => response.data);

function* deleteNutrientCommentRecords({ payload }) {
  try {
    yield call(deleteNutrientCommentRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteNutrientCommentRecordSuccess({
        data: payload,
        message: NotificationHelpers(messageType, NotificationSubType.Delete),
      })
    );
  } catch (error) {
    yield put(
      deleteNutrientCommentRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const nutrientByFilterRequest = (record) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/nutrientcomment/byfilter/${record.nutrientId}/${record.type}/${record.age}`
    )
    .then((response) => response.data.data);

function* nutrientByFilterRecords({ payload }) {
  try {
    const response = yield call(nutrientByFilterRequest, payload);
    yield put(NutrientCommentByFilterSuccess(response));
  } catch (error) {
    yield put(
      NutrientCommentByFilterError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const upsertNutrientCommentRecordRequest = async (records) => {
  // eslint-disable-next-line no-return-await
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/nutrientcomment/upsert`, records)
    .then((response) => response.data);
};

function* upsertNutrientCommentRecords({ payload }) {
  try {
    const response = yield call(upsertNutrientCommentRecordRequest, payload);
    if (response) {
      const messageType = yield select((state) =>
        state.settings.notificationMessages.find(
          (x) => x.type === NotificationType.Success
        )
      );
      yield put(
        upsertNutrientCommentRecordsSuccess({
          data: response,
          message: NotificationHelpers(messageType, NotificationSubType.Add),
        })
      );
    }
  } catch (error) {
    yield put(upsertNutrientCommentRecordsError(error));
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(800, NUTRIENTCOMMENT_GET_LIST, getNutrientCommentListItems);
}

export function* watchSetSelected() {
  yield takeEvery(
    NUTRIENTCOMMENT_SET_SELECTED,
    setNutrientCommentSelectedRecord
  );
}
export function* wathcAddItem() {
  yield takeEvery(NUTRIENTCOMMENT_ADD_RECORD, addNutrientCommentRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(NUTRIENTCOMMENT_UPDATE_RECORD, updateNutrientCommentRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(NUTRIENTCOMMENT_DELETE_RECORD, deleteNutrientCommentRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(
    NUTRIENTCOMMENT_ISACTIVECHANGE,
    isActiveChangeNutrientComment
  );
}
export function* wathcByNutientId() {
  yield takeEvery(NUTRIENTCOMMENT_BYFILTER, nutrientByFilterRecords);
}
export function* watchUpsert() {
  yield takeEvery(NUTRIENTCOMMENT_UPSERT_RECORD, upsertNutrientCommentRecords);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcIsActiveChangeItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(wathcByNutientId),
    fork(watchUpsert),
  ]);
}
