/* eslint-disable no-param-reassign */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  NUTRIENT_ADD_RECORD,
  NUTRIENT_UPDATE_RECORD,
  NUTRIENT_GET_LIST,
  NUTRIENT_SET_SELECTED,
  NUTRIENT_DELETE_RECORD,
  NUTRIENT_GETNAMEWITHID_ONERECORD,
  NUTRIENT_GETNAMEWITHID_LIST,
  deleteNutrientRecordSuccess,
  deleteNutrientRecordError,
  NUTRIENT_ISACTIVECHANGE,
  NUTRIENT_CALCULATE,
  NUTRIENT_GROUPED,
  NUTRIENTTOFOODWORDLIST,
} from '../../actions';

import {
  getNutrientListSuccess,
  getNutrientListError,
  setNutrientSelectedRecordSuccess,
  setNutrientSelectedRecordError,
  addNutrientRecordSuccess,
  addNutrientRecordError,
  updateNutrientRecordSuccess,
  updateNutrientRecordError,
  getNutrientNameWithIdOneRecordError,
  getNutrientNameWithIdSuccess,
  getNutrientNameWithIdError,
  getNutrientNameWithIdOneRecordSuccess,
  isActiveChangeNutrientError,
  isActiveChangeNutrientSuccess,
  nutrientCalculateError,
  nutrientCalculateSuccess,
  getNutrientGroupedSuccess,
  getNutrientGroupedError,
  NutrientToFoodWordListSuccess,
  NutrientToFoodWordListError,
} from './actions';

//---------------------------------------------------------------------
const getNutrientListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/nutrient${url || ''}`)
    .then((response) => response.data);
};

function* getNutrientListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getNutrientListRequest,
        filterBuilder(payload)
      );
      yield put(getNutrientListSuccess(response));
    } else {
      const response = yield call(getNutrientListRequest);
      yield put(getNutrientListSuccess(response));
    }
  } catch (error) {
    yield put(getNutrientListError(error));
  }
}
//----------------------------------------------------------------------------------
const getNutrientNameWithIdOneRecordRequest = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/nutrient/nutrientnamewithid/${id}`)
    .then((response) => response.data);
};

function* getNutrientNameWithIdOneRecordItems({ payload }) {
  try {
    if (payload) {
      const pageSize = yield select((state) => state.settings.pageRecordCount);
      payload.pageSize = pageSize;
    }
    const response = yield call(getNutrientNameWithIdOneRecordRequest, payload);
    yield put(getNutrientNameWithIdOneRecordSuccess(response));
  } catch (error) {
    yield put(getNutrientNameWithIdOneRecordError(error));
  }
}
//----------------------------------------------------------------------------------

const getNutrientNameWithIdListRequest = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/nutrient/nutrientnamewithid`)
    .then((response) => response.data);
};

function* getNutrientNameWithIdListItems({ payload }) {
  try {
    if (payload) {
      const pageSize = yield select((state) => state.settings.pageRecordCount);
      payload.pageSize = pageSize;
    }
    const response = yield call(getNutrientNameWithIdListRequest, payload);
    yield put(getNutrientNameWithIdSuccess(response));
  } catch (error) {
    yield put(getNutrientNameWithIdError(error));
  }
}
//----------------------------------------------------------------------------------
const setNutrientSelectedRecordRequest = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/nutrient/${id}`)
    .then((response) => response.data.singleData);
};

function* setNutrientSelectedRecord({ payload }) {
  try {
    const response = yield call(setNutrientSelectedRecordRequest, payload);
    yield put(setNutrientSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setNutrientSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addNutrientRecordRequest = (record) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/nutrient`, record)
    .then((response) => response.data);
};

function* addNutrientRecords({ payload }) {
  try {
    yield call(addNutrientRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addNutrientRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getNutrientListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addNutrientRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateNutrientRecordRequest = (record) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/nutrient`, record)
    .then((response) => response.data);

function* updateNutrientRecords({ payload }) {
  try {
    yield call(updateNutrientRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateNutrientRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getNutrientListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateNutrientRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//--------------------------------------------------------------------
const isActiveChangeNutrientRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/nutrient/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeNutrient({ payload }) {
  try {
    const response = yield call(isActiveChangeNutrientRequest, payload);
    yield put(isActiveChangeNutrientSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeNutrientError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const deleteNutrientRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/nutrient/${recordId}`)
    .then((response) => response.data);

function* deleteNutrientRecords({ payload }) {
  try {
    yield call(deleteNutrientRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteNutrientRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getNutrientListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteNutrientRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const calculateNutrientRequest = (object) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/nutrient/nutrientCalculate`, object)
    .then((response) => response.data);

function* calculateNutrient({ payload }) {
  try {
    const response = yield call(calculateNutrientRequest, payload);
    yield put(nutrientCalculateSuccess(response));
  } catch (error) {
    yield put(
      nutrientCalculateError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const nutrientToFoodWordListRequest = (object) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/nutrient/nutrientToFoodWordList`,
      object
    )
    .then((response) => response.data);

function* nutrientToFoodWordList({ payload }) {
  try {
    const response = yield call(nutrientToFoodWordListRequest, payload);
    yield put(NutrientToFoodWordListSuccess(response));
  } catch (error) {
    yield put(
      NutrientToFoodWordListError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const getNutrientGroupedRequest = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/nutrient/nutrientgrouped`)
    .then((response) => response.data.data);
};

function* getNutrientGroupedItems() {
  try {
    const response = yield call(getNutrientGroupedRequest);
    yield put(getNutrientGroupedSuccess(response));
  } catch (error) {
    yield put(getNutrientGroupedError(error));
  }
}
//----------------------------------------------------------------------------------
export function* watchGetList() {
  yield debounce(800, NUTRIENT_GET_LIST, getNutrientListItems);
}
export function* watchGetNameWithIdOneRecord() {
  yield takeEvery(
    NUTRIENT_GETNAMEWITHID_ONERECORD,
    getNutrientNameWithIdOneRecordItems
  );
}
export function* watchGetNameWithIdList() {
  yield takeEvery(NUTRIENT_GETNAMEWITHID_LIST, getNutrientNameWithIdListItems);
}
export function* watchSetSelected() {
  yield takeEvery(NUTRIENT_SET_SELECTED, setNutrientSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(NUTRIENT_ADD_RECORD, addNutrientRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(NUTRIENT_UPDATE_RECORD, updateNutrientRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(NUTRIENT_DELETE_RECORD, deleteNutrientRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(NUTRIENT_ISACTIVECHANGE, isActiveChangeNutrient);
}
export function* watchNutrientGrouped() {
  yield takeEvery(NUTRIENT_GROUPED, getNutrientGroupedItems);
}

export function* watchNutrientCalculate() {
  yield debounce(500, NUTRIENT_CALCULATE, calculateNutrient);
}
export function* watchNutrientToFoodWordList() {
  yield takeEvery(NUTRIENTTOFOODWORDLIST, nutrientToFoodWordList);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchNutrientCalculate),
    fork(watchNutrientToFoodWordList),
    fork(watchGetNameWithIdOneRecord),
    fork(watchGetNameWithIdList),
    fork(wathcIsActiveChangeItem),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(watchNutrientGrouped),
  ]);
}
