/* eslint-disable import/no-cycle */
import {
  LOGINHISTORY_GET_LIST,
  LOGINHISTORY_GET_LIST_SUCCESS,
  LOGINHISTORY_GET_LIST_ERROR,
  LOGINHISTORY_RESET_STATUSES,
} from '../../actions';

//-------------------------------------------------------------------------
export const getLoginHistoryList = (pageDetail) => ({
  type: LOGINHISTORY_GET_LIST,
  payload: pageDetail,
});

export const getLoginHistoryListSuccess = (items) => ({
  type: LOGINHISTORY_GET_LIST_SUCCESS,
  payload: items,
});

export const getLoginHistoryListError = (error) => ({
  type: LOGINHISTORY_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const resetLoginHistoryStatuses = () => ({
  type: LOGINHISTORY_RESET_STATUSES,
});
