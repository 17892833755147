/* eslint-disable prettier/prettier */
import {
  NOTIFICATION_GET_LIST,
  NOTIFICATION_GET_LIST_SUCCESS,
  NOTIFICATION_GET_LIST_ERROR,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_ERROR,
  UPDATE_NOTIFICATION_SUCCESS,
} from '../../actions';

const INIT_STATE = {
  records: [],
  userRecords: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_GET_LIST:
      return { ...state, loading: true };
    case NOTIFICATION_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: !action.payload.settings
          ? action.payload.response.data
          : state.records,
        userRecords: action.payload.settings
          ? action.payload.response.data
          : state.userRecords,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case NOTIFICATION_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case UPDATE_NOTIFICATION:
      return { ...state, loading: true };
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NOTIFICATION_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    default:
      return { ...state };
  }
};
