// eslint-disable-next-line import/no-cycle
import {
  FOOD_GET_LIST,
  FOOD_GET_LIST_SUCCESS,
  FOOD_GET_LIST_ERROR,
  FOOD_SET_SELECTED,
  FOOD_UPDATE_SELECTED,
  FOOD_SET_SELECTED_SUCCESS,
  FOOD_SET_SELECTED_ERROR,
  FOOD_ADD_RECORD_SUCCESS,
  FOOD_ADD_RECORD_ERROR,
  FOOD_ADD_RECORD,
  FOOD_UPDATE_RECORD_SUCCESS,
  FOOD_UPDATE_RECORD_ERROR,
  FOOD_UPDATE_RECORD,
  FOOD_DELETE_RECORD,
  FOOD_DELETE_RECORD_SUCCESS,
  FOOD_DELETE_RECORD_ERROR,
  FOOD_RESET_STATUSES,
  FOOD_GETNAMEWITHID_LIST,
  FOOD_GETNAMEWITHID_LIST_SUCCESS,
  FOOD_GETNAMEWITHID_LIST_ERROR,
  FOOD_GETNAMEWITHID_ONERECORD,
  FOOD_GETNAMEWITHID_ONERECORD_SUCCESS,
  FOOD_GETNAMEWITHID_ONERECORD_ERROR,
  FOOD_COPY_RECORD,
  FOOD_COPY_RECORD_SUCCESS,
  FOOD_COPY_RECORD_ERROR,
  FOOD_ISACTIVECHANGE,
  FOOD_ISACTIVECHANGE_SUCCESS,
  FOOD_ISACTIVECHANGE_ERROR,
  FOOD_GET_FOODNUTRIENTS,
  FOOD_GET_FOODNUTRIENTS_SUCCESS,
  FOOD_GET_FOODNUTRIENTS_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getFoodList = (pageDetail) => ({
  type: FOOD_GET_LIST,
  payload: pageDetail,
});

export const getFoodListSuccess = (items) => ({
  type: FOOD_GET_LIST_SUCCESS,
  payload: items,
});

export const getFoodListError = (error) => ({
  type: FOOD_GET_LIST_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const getFoodNameWithIdList = (pageDetail) => ({
  type: FOOD_GETNAMEWITHID_LIST,
  payload: pageDetail,
});

export const getFoodNameWithIdSuccess = (items) => ({
  type: FOOD_GETNAMEWITHID_LIST_SUCCESS,
  payload: items,
});

export const getFoodNameWithIdError = (error) => ({
  type: FOOD_GETNAMEWITHID_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------

export const getFoodNameWithIdOneRecord = (pageDetail) => ({
  type: FOOD_GETNAMEWITHID_ONERECORD,
  payload: pageDetail,
});

export const getFoodNameWithIdOneRecordSuccess = (items) => ({
  type: FOOD_GETNAMEWITHID_ONERECORD_SUCCESS,
  payload: items,
});

export const getFoodNameWithIdOneRecordError = (error) => ({
  type: FOOD_GETNAMEWITHID_ONERECORD_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------

export const setFoodSelectedRecord = (recordId) => ({
  type: FOOD_SET_SELECTED,
  payload: recordId,
});

export const setFoodSelectedRecordSuccess = (record) => ({
  type: FOOD_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setFoodSelectedRecordError = (error) => ({
  type: FOOD_SET_SELECTED_ERROR,
  payload: error,
});

export const updateFoodSelectedRecord = (record) => ({
  type: FOOD_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addFoodRecord = (record) => ({
  type: FOOD_ADD_RECORD,
  payload: record,
});

export const addFoodRecordSuccess = (msg) => ({
  type: FOOD_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addFoodRecordError = (error) => ({
  type: FOOD_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------

export const copyFoodRecord = (record) => ({
  type: FOOD_COPY_RECORD,
  payload: record,
});

export const copyFoodRecordSuccess = (msg) => ({
  type: FOOD_COPY_RECORD_SUCCESS,
  payload: msg,
});

export const copyFoodRecordError = (error) => ({
  type: FOOD_COPY_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------

export const updateFoodRecords = (record) => ({
  type: FOOD_UPDATE_RECORD,
  payload: record,
});

export const updateFoodRecordSuccess = (msg) => ({
  type: FOOD_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateFoodRecordError = (error) => ({
  type: FOOD_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteFoodRecords = (record) => ({
  type: FOOD_DELETE_RECORD,
  payload: record,
});

export const deleteFoodRecordSuccess = (msg) => ({
  type: FOOD_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteFoodRecordError = (error) => ({
  type: FOOD_DELETE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeFood = (record) => ({
  type: FOOD_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeFoodSuccess = (msg) => ({
  type: FOOD_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeFoodError = (error) => ({
  type: FOOD_ISACTIVECHANGE_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const foodNutrientsFood = (record) => ({
  type: FOOD_GET_FOODNUTRIENTS,
  payload: record,
});

export const foodNutrientsFoodSuccess = (msg) => ({
  type: FOOD_GET_FOODNUTRIENTS_SUCCESS,
  payload: msg,
});

export const foodNutrientsFoodError = (error) => ({
  type: FOOD_GET_FOODNUTRIENTS_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------

export const resetFoodStatuses = () => ({ type: FOOD_RESET_STATUSES });
