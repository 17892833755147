/* eslint-disable no-param-reassign */
import {
  all,
  call,
  fork,
  put,
  debounce,
  takeEvery,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  MEALGROUP_ADD_RECORD,
  MEALGROUP_UPDATE_RECORD,
  MEALGROUP_GET_LIST,
  MEALGROUP_SET_SELECTED,
  MEALGROUP_DELETE_RECORD,
  deleteMealGroupRecordSuccess,
  deleteMealGroupRecordError,
  MEALGROUP_ISACTIVECHANGE,
} from '../../actions';

import {
  getMealGroupListSuccess,
  getMealGroupListError,
  setMealGroupSelectedRecordSuccess,
  setMealGroupSelectedRecordError,
  addMealGroupRecordSuccess,
  addMealGroupRecordError,
  updateMealGroupRecordSuccess,
  updateMealGroupRecordError,
  isActiveChangeMealGroupSuccess,
  isActiveChangeMealGroupError,
} from './actions';

//---------------------------------------------------------------------
const getMealGroupListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/mealgroup${url || ''}`)
    .then((response) => response.data);
};

function* getMealGroupListItems({ payload }) {
  try {
    if (payload) {
      if (!payload.pageSize) {
        payload.pageSize = yield select(
          (state) => state.settings.pageRecordCount
        );
      }
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getMealGroupListRequest,
        filterBuilder(payload)
      );

      yield put(getMealGroupListSuccess(response));
    } else {
      const response = yield call(getMealGroupListRequest);
      yield put(getMealGroupListSuccess(response));
    }
  } catch (error) {
    yield put(getMealGroupListError(error));
  }
}
//----------------------------------------------------------------------------------
const setMealGroupSelectedRecordRequest = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/mealgroup/${id}`)
    .then((response) => response.data.singleData);
};

function* setMealGroupSelectedRecord({ payload }) {
  try {
    const response = yield call(setMealGroupSelectedRecordRequest, payload);
    yield put(setMealGroupSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setMealGroupSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addMealGroupRecordRequest = (record) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/mealgroup`, record)
    .then((response) => response.data);

function* addMealGroupRecords({ payload }) {
  try {
    yield call(addMealGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addMealGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getMealGroupListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addMealGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateMealGroupRecordRequest = (record) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}/mealgroup`, record)
    .then((response) => response.data);
};

function* updateMealGroupRecords({ payload }) {
  try {
    yield call(updateMealGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateMealGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getMealGroupListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateMealGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeMealGroupRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/mealgroup/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeMealGroup({ payload }) {
  try {
    const response = yield call(isActiveChangeMealGroupRequest, payload);
    yield put(isActiveChangeMealGroupSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeMealGroupError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const deleteMealGroupRecordRequest = (recordId) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/mealgroup/${recordId}`)
    .then((response) => response.data);

function* deleteMealGroupRecords({ payload }) {
  try {
    yield call(deleteMealGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteMealGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getMealGroupListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteMealGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(800, MEALGROUP_GET_LIST, getMealGroupListItems);
}

export function* watchSetSelected() {
  yield takeEvery(MEALGROUP_SET_SELECTED, setMealGroupSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(MEALGROUP_ADD_RECORD, addMealGroupRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(MEALGROUP_UPDATE_RECORD, updateMealGroupRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(MEALGROUP_DELETE_RECORD, deleteMealGroupRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(MEALGROUP_ISACTIVECHANGE, isActiveChangeMealGroup);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
