/* eslint-disable import/no-cycle */
import {
  TAG_GET_LIST,
  TAG_GET_LIST_SUCCESS,
  TAG_GET_LIST_ERROR,
  TAG_SET_SELECTED,
  TAG_UPDATE_SELECTED,
  TAG_SET_SELECTED_SUCCESS,
  TAG_SET_SELECTED_ERROR,
  TAG_ADD_RECORD_SUCCESS,
  TAG_ADD_RECORD_ERROR,
  TAG_ADD_RECORD,
  TAG_UPDATE_RECORD_SUCCESS,
  TAG_UPDATE_RECORD_ERROR,
  TAG_UPDATE_RECORD,
  TAG_DELETE_RECORD,
  TAG_DELETE_RECORD_SUCCESS,
  TAG_DELETE_RECORD_ERROR,
  TAG_RESET_STATUSES,
  TAG_ISACTIVECHANGE,
  TAG_ISACTIVECHANGE_SUCCESS,
  TAG_ISACTIVECHANGE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getTagList = (pageDetail) => ({
  type: TAG_GET_LIST,
  payload: pageDetail,
});

export const getTagListSuccess = (items) => ({
  type: TAG_GET_LIST_SUCCESS,
  payload: items,
});

export const getTagListError = (error) => ({
  type: TAG_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setTagSelectedRecord = (recordId) => ({
  type: TAG_SET_SELECTED,
  payload: recordId,
});

export const setTagSelectedRecordSuccess = (record) => ({
  type: TAG_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setTagSelectedRecordError = (error) => ({
  type: TAG_SET_SELECTED_ERROR,
  payload: error,
});

export const updateTagSelectedRecord = (record) => ({
  type: TAG_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addTagRecord = (record) => ({
  type: TAG_ADD_RECORD,
  payload: record,
});

export const addTagRecordSuccess = (msg) => ({
  type: TAG_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addTagRecordError = (error) => ({
  type: TAG_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateTagRecords = (record) => ({
  type: TAG_UPDATE_RECORD,
  payload: record,
});

export const updateTagRecordSuccess = (msg) => ({
  type: TAG_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateTagRecordError = (error) => ({
  type: TAG_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteTagRecords = (record) => ({
  type: TAG_DELETE_RECORD,
  payload: record,
});

export const deleteTagRecordSuccess = (msg) => ({
  type: TAG_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteTagRecordError = (error) => ({
  type: TAG_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeTag = (record) => ({
  type: TAG_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeTagSuccess = (msg) => ({
  type: TAG_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeTagError = (error) => ({
  type: TAG_ISACTIVECHANGE_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const resetTagStatuses = () => ({ type: TAG_RESET_STATUSES });
