/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  SAMPLEDESCRIPTION_ADD_RECORD,
  SAMPLEDESCRIPTION_UPDATE_RECORD,
  SAMPLEDESCRIPTION_GET_LIST,
  SAMPLEDESCRIPTION_GET_GROUPED_LIST,
  SAMPLEDESCRIPTION_SET_SELECTED,
  SAMPLEDESCRIPTION_DELETE_RECORD,
  deleteSampleDescriptionRecordSuccess,
  deleteSampleDescriptionRecordError,
  SAMPLEDESCRIPTION_ISACTIVECHANGE,
} from '../../actions';

import {
  getSampleDescriptionListSuccess,
  getSampleDescriptionListError,
  getSampleDescriptionGroupedListSuccess,
  getSampleDescriptionGroupedListError,
  setSampleDescriptionSelectedRecordSuccess,
  setSampleDescriptionSelectedRecordError,
  addSampleDescriptionRecordSuccess,
  addSampleDescriptionRecordError,
  updateSampleDescriptionRecordSuccess,
  updateSampleDescriptionRecordError,
  isActiveChangeSampleDescriptionSuccess,
  isActiveChangeSampleDescriptionError,
} from './actions';

//---------------------------------------------------------------------
const getSampleDescriptionListRequest = async (url) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/sampledescription${url || ''}`)
    .then((response) => response.data);
};

function* getSampleDescriptionListItems({ payload }) {
  try {
    if (payload) {
      if (!payload.pageSize)
        payload.pageSize = yield select(
          (state) => state.settings.pageRecordCount
        );
      payload.pageNumber = payload.pageNumber || 0;
      const response = yield call(
        getSampleDescriptionListRequest,
        filterBuilder(payload)
      );
      yield put(getSampleDescriptionListSuccess(response));
    } else {
      const response = yield call(getSampleDescriptionListRequest);
      yield put(getSampleDescriptionListSuccess(response));
    }
  } catch (error) {
    yield put(getSampleDescriptionListError(error));
  }
}
//----------------------------------------------------------------------------------
const getSampleDescriptionGroupedListRequest = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/sampledescription/grouped`)
    .then((response) => response.data.data);
};

function* getSampleDescriptionGroupedListItems() {
  try {
    const response = yield call(getSampleDescriptionGroupedListRequest);
    yield put(getSampleDescriptionGroupedListSuccess(response));
  } catch (error) {
    yield put(getSampleDescriptionGroupedListError(error));
  }
}
//----------------------------------------------------------------------------------
const setSampleDescriptionSelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/sampledescription/${id}`)
    .then((response) => response.data.singleData);
};

function* setSampleDescriptionSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setSampleDescriptionSelectedRecordRequest,
      payload
    );
    yield put(setSampleDescriptionSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setSampleDescriptionSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addSampleDescriptionRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/sampledescription`, record)
    .then((response) => response.data);
};

function* addSampleDescriptionRecords({ payload }) {
  try {
    yield call(addSampleDescriptionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addSampleDescriptionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getSampleDescriptionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addSampleDescriptionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateSampleDescriptionRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/sampledescription`, record)
    .then((response) => response.data);
};

function* updateSampleDescriptionRecords({ payload }) {
  try {
    yield call(updateSampleDescriptionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateSampleDescriptionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getSampleDescriptionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateSampleDescriptionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteSampleDescriptionRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/sampledescription/${recordId}`)
    .then((response) => response.data);
};

function* deleteSampleDescriptionRecords({ payload }) {
  try {
    yield call(deleteSampleDescriptionRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteSampleDescriptionRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getSampleDescriptionListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteSampleDescriptionRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const isActiveChangeSampleDescriptionRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/sampledescription/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeSampleDescription({ payload }) {
  try {
    const response = yield call(
      isActiveChangeSampleDescriptionRequest,
      payload
    );
    yield put(isActiveChangeSampleDescriptionSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeSampleDescriptionError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(SAMPLEDESCRIPTION_GET_LIST, getSampleDescriptionListItems);
}
export function* watchGetGroupedList() {
  yield takeEvery(
    SAMPLEDESCRIPTION_GET_GROUPED_LIST,
    getSampleDescriptionGroupedListItems
  );
}

export function* watchSetSelected() {
  yield takeEvery(
    SAMPLEDESCRIPTION_SET_SELECTED,
    setSampleDescriptionSelectedRecord
  );
}
export function* wathcAddItem() {
  yield takeEvery(SAMPLEDESCRIPTION_ADD_RECORD, addSampleDescriptionRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(
    SAMPLEDESCRIPTION_UPDATE_RECORD,
    updateSampleDescriptionRecords
  );
}

export function* wathcDeleteItem() {
  yield takeEvery(
    SAMPLEDESCRIPTION_DELETE_RECORD,
    deleteSampleDescriptionRecords
  );
}

export function* wathcIsActiveChangeItem() {
  yield takeEvery(
    SAMPLEDESCRIPTION_ISACTIVECHANGE,
    isActiveChangeSampleDescription
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetGroupedList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
    fork(wathcIsActiveChangeItem),
  ]);
}
