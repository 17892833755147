import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import foodGroup from './administration/food-group/reducer';
import subFoodGroup from './administration/subfood-group/reducer';
import food from './administration/food/reducer';
import nutrient from './administration/nutrient/reducer';
import referenceSource from './administration/reference-source/reducer';
import referenceRange from './administration/reference-range/reducer';
import meal from './administration/meal/reducer';
import mealGroup from './administration/meal-group/reducer';
import recipe from './administration/recipe/reducer';
import portion from './administration/portion/reducer';
import unit from './administration/unit/reducer';
import repast from './administration/repast/reducer';
import nutrientGroup from './administration/nutrient-group/reducer';
import tag from './applications/tag/reducer';
import coachee from './applications/coachee/reducer';
import plan from './applications/planning/reducer';
import portionPicture from './administration/portion-picture/reducer';
import physicalActivity from './administration/physical-activity/reducer';
import foodPortion from './administration/food-portion/reducer';
import planRepast from './applications/plan-repast/reducer';
import mealNutrientTotals from './applications/meal-nutrient-total/reducer';
import planRepastFood from './applications/planrepast-food/reducer';
import planRepastFoodPortion from './applications/planrepastfood-portion/reducer';
import activityType from './applications/activity-type/reducer';
import bodyMassIndexRange from './applications/body-mass-index-range/reducer';
import formula from './applications/formula/reducer';
import antropometry from './applications/antropometry/reducer';
import dataSource from './administration/data-source/reducer';
import addFoodModal from './applications/addFoodModal/reducer';
import user from './profile/user/reducer';
import userpreference from './profile/userpreference/reducer';
import role from './rolemanagement/role/reducer';
import rolegroup from './rolemanagement/rolegroup/reducer';
import userrole from './rolemanagement/userrole/reducer';
import userplan from './rolemanagement/userplan/reducer';
import userplanrole from './rolemanagement/userplanrole/reducer';
import coupon from './administration/coupon/reducer';
import loginHistory from './administration/loginHistory/reducer';
import menuprepare from './applications/menuprepare/reducer';
import email from './administration/email/reducer';
import lackCompletion from './administration/lackcompletion/reducer';
import notification from './administration/notification/reducer';
import nutrientComment from './administration/nutrientcomment/reducer';
import sampleDescription from './applications/sampledescription/reducer';

import { ALLSTORERESET } from './actions';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  foodGroup,
  food,
  nutrient,
  referenceSource,
  referenceRange,
  meal,
  mealGroup,
  recipe,
  portion,
  unit,
  nutrientGroup,
  repast,
  coachee,
  plan,
  portionPicture,
  physicalActivity,
  foodPortion,
  planRepast,
  mealNutrientTotals,
  planRepastFood,
  planRepastFoodPortion,
  activityType,
  bodyMassIndexRange,
  formula,
  antropometry,
  dataSource,
  subFoodGroup,
  addFoodModal,
  user,
  userpreference,
  role,
  rolegroup,
  userrole,
  userplan,
  userplanrole,
  tag,
  coupon,
  loginHistory,
  menuprepare,
  email,
  lackCompletion,
  notification,
  nutrientComment,
  sampleDescription,
});

const rootReducer = (state, action) => {
  if (action.type === ALLSTORERESET) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return reducers(state, action);
};
export default rootReducer;
