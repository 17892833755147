// eslint-disable-next-line import/no-cycle
import {
  COACHEE_GET_LIST,
  COACHEE_GET_LIST_SUCCESS,
  COACHEE_GET_LIST_ERROR,
  COACHEE_SET_SELECTED,
  COACHEE_UPDATE_SELECTED,
  COACHEE_SET_SELECTED_SUCCESS,
  COACHEE_SET_SELECTED_ERROR,
  COACHEE_ADD_RECORD_SUCCESS,
  COACHEE_ADD_RECORD_ERROR,
  COACHEE_ADD_RECORD,
  COACHEE_UPDATE_RECORD_SUCCESS,
  COACHEE_UPDATE_RECORD_ERROR,
  COACHEE_UPDATE_RECORD,
  COACHEE_DELETE_RECORD,
  COACHEE_DELETE_RECORD_SUCCESS,
  COACHEE_DELETE_RECORD_ERROR,
  COACHEE_RESET_STATUSES,
  COACHEE_ISACTIVECHANGE,
  COACHEE_ISACTIVECHANGE_SUCCESS,
  COACHEE_ISACTIVECHANGE_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getCoacheeList = (pageDetail) => ({
  type: COACHEE_GET_LIST,
  payload: pageDetail,
});

export const getCoacheeListSuccess = (items) => ({
  type: COACHEE_GET_LIST_SUCCESS,
  payload: items,
});

export const getCoacheeListError = (error) => ({
  type: COACHEE_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setCoacheeSelectedRecord = (recordId) => ({
  type: COACHEE_SET_SELECTED,
  payload: recordId,
});

export const setCoacheeSelectedRecordSuccess = (record) => ({
  type: COACHEE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setCoacheeSelectedRecordError = (error) => ({
  type: COACHEE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateCoacheeSelectedRecord = (record) => ({
  type: COACHEE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addCoacheeRecord = (record) => ({
  type: COACHEE_ADD_RECORD,
  payload: record,
});

export const addCoacheeRecordSuccess = (msg) => ({
  type: COACHEE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addCoacheeRecordError = (error) => ({
  type: COACHEE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateCoacheeRecords = (record) => ({
  type: COACHEE_UPDATE_RECORD,
  payload: record,
});

export const updateCoacheeRecordSuccess = (msg) => ({
  type: COACHEE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateCoacheeRecordError = (error) => ({
  type: COACHEE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteCoacheeRecords = (record) => ({
  type: COACHEE_DELETE_RECORD,
  payload: record,
});

export const deleteCoacheeRecordSuccess = (msg) => ({
  type: COACHEE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteCoacheeRecordError = (error) => ({
  type: COACHEE_DELETE_RECORD_ERROR,
  payload: error,
});
export const isActiveChangeCoachee = (record) => ({
  type: COACHEE_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeCoacheeSuccess = (msg) => ({
  type: COACHEE_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeCoacheeError = (error) => ({
  type: COACHEE_ISACTIVECHANGE_ERROR,
  payload: error,
});
export const resetCoacheeStatuses = () => ({ type: COACHEE_RESET_STATUSES });
//-------------------------------------------------------------------------
