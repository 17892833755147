/* eslint-disable prefer-template */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable no-else-return */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  SUBFOODGROUP_ADD_RECORD,
  SUBFOODGROUP_UPDATE_RECORD,
  SUBFOODGROUP_GET_LIST,
  SUBFOODGROUP_SET_SELECTED,
  SUBFOODGROUP_DELETE_RECORD,
  deleteSubFoodGroupRecordSuccess,
  deleteSubFoodGroupRecordError,
  SUBFOODGROUP_ISACTIVECHANGE,
} from '../../actions';

import {
  getSubFoodGroupListSuccess,
  getSubFoodGroupListError,
  setSubFoodGroupSelectedRecordSuccess,
  setSubFoodGroupSelectedRecordError,
  addSubFoodGroupRecordSuccess,
  addSubFoodGroupRecordError,
  updateSubFoodGroupRecordSuccess,
  updateSubFoodGroupRecordError,
  isActiveChangeSubFoodGroupSuccess,
  isActiveChangeSubFoodGroupError,
} from './actions';

//---------------------------------------------------------------------
const getSubFoodGroupListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/subfoodgroup${url || ''}`)
    .then((response) => response.data);
};

function* getSubFoodGroupListItems({ payload }) {
  try {
    if (payload) {
      payload.pageSize = yield select(
        (state) => state.settings.pageRecordCount
      );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getSubFoodGroupListRequest,
        filterBuilder(payload)
      );
      yield put(getSubFoodGroupListSuccess(response));
    } else {
      const response = yield call(getSubFoodGroupListRequest);
      yield put(getSubFoodGroupListSuccess(response));
    }
  } catch (error) {
    yield put(getSubFoodGroupListError(error));
  }
}
//----------------------------------------------------------------------------------
const setSubFoodGroupSelectedRecordRequest = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/subfoodgroup/${id}`)
    .then((response) => response.data.singleData);
};

function* setSubFoodGroupSelectedRecord({ payload }) {
  try {
    const response = yield call(setSubFoodGroupSelectedRecordRequest, payload);
    yield put(setSubFoodGroupSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setSubFoodGroupSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addSubFoodGroupRecordRequest = (record) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/subfoodgroup`, record)
    .then((response) => response.data);
};

function* addSubFoodGroupRecords({ payload }) {
  try {
    yield call(addSubFoodGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addSubFoodGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getSubFoodGroupListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addSubFoodGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateSubFoodGroupRecordRequest = (record) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}/subfoodgroup`, record)
    .then((response) => response.data);
};

function* updateSubFoodGroupRecords({ payload }) {
  try {
    yield call(updateSubFoodGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateSubFoodGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getSubFoodGroupListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateSubFoodGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeSubFoodGroupRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/subfoodgroup/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeSubFoodGroup({ payload }) {
  try {
    const response = yield call(isActiveChangeSubFoodGroupRequest, payload);
    yield put(isActiveChangeSubFoodGroupSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeSubFoodGroupError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const deleteSubFoodGroupRecordRequest = (recordId) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/subfoodgroup/${recordId}`)
    .then((response) => response.data);
};

function* deleteSubFoodGroupRecords({ payload }) {
  try {
    yield call(deleteSubFoodGroupRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteSubFoodGroupRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getSubFoodGroupListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteSubFoodGroupRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield debounce(800, SUBFOODGROUP_GET_LIST, getSubFoodGroupListItems);
}

export function* watchSetSelected() {
  yield takeEvery(SUBFOODGROUP_SET_SELECTED, setSubFoodGroupSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(SUBFOODGROUP_ADD_RECORD, addSubFoodGroupRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(SUBFOODGROUP_UPDATE_RECORD, updateSubFoodGroupRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(SUBFOODGROUP_DELETE_RECORD, deleteSubFoodGroupRecords);
}
export function* wathcIsActiveChangeItem() {
  yield takeEvery(SUBFOODGROUP_ISACTIVECHANGE, isActiveChangeSubFoodGroup);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcIsActiveChangeItem),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
