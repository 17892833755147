// eslint-disable-next-line import/no-cycle
import {
  USERPLANROLE_GET_LIST,
  USERPLANROLE_GET_LIST_SUCCESS,
  USERPLANROLE_GET_LIST_ERROR,
  USERPLANROLE_ADD_RECORD,
  USERPLANROLE_ADD_RECORD_ERROR,
  USERPLANROLE_ADD_RECORD_SUCCESS,
  USERPLANROLE_DELETE_RECORD,
  USERPLANROLE_DELETE_RECORD_ERROR,
  USERPLANROLE_DELETE_RECORD_SUCCESS,
  USERPLANROLE_RESET_STATUSES,
  USERPLANROLE_SET_SELECTED,
  USERPLANROLE_SET_SELECTED_ERROR,
  USERPLANROLE_SET_SELECTED_SUCCESS,
  USERPLANROLE_UPDATE_RECORD,
  USERPLANROLE_UPDATE_RECORD_ERROR,
  USERPLANROLE_UPDATE_RECORD_SUCCESS,
  USERPLANROLE_UPDATE_SELECTED,
  USERPLANROLE_BYUSERID,
  USERPLANROLE_BYUSERID_SUCCESS,
  USERPLANROLE_BYUSERID_ERROR,
  USERPLANROLE_UPDATE_SELECTED_RECORDS,
  USERPLANROLE_UPSERT_RECORD,
  USERPLANROLE_UPSERT_RECORD_SUCCESS,
  USERPLANROLE_UPSERT_RECORD_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getUserPlanRoleList = () => ({
  type: USERPLANROLE_GET_LIST,
});

export const getUserPlanRoleListSuccess = (items) => ({
  type: USERPLANROLE_GET_LIST_SUCCESS,
  payload: items,
});

export const getUserPlanRoleListError = (error) => ({
  type: USERPLANROLE_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setUserPlanRoleSelectedRecord = (recordId) => ({
  type: USERPLANROLE_SET_SELECTED,
  payload: recordId,
});

export const setUserPlanRoleSelectedRecordSuccess = (record) => ({
  type: USERPLANROLE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setUserPlanRoleSelectedRecordError = (error) => ({
  type: USERPLANROLE_SET_SELECTED_ERROR,
  payload: error,
});

export const updateUserPlanRoleSelectedRecord = (record) => ({
  type: USERPLANROLE_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addUserPlanRoleRecord = (record) => ({
  type: USERPLANROLE_ADD_RECORD,
  payload: record,
});

export const addUserPlanRoleRecordSuccess = (msg) => ({
  type: USERPLANROLE_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addUserPlanRoleRecordError = (error) => ({
  type: USERPLANROLE_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateUserPlanRoleRecords = (record) => ({
  type: USERPLANROLE_UPDATE_RECORD,
  payload: record,
});

export const updateUserPlanRoleRecordSuccess = (msg) => ({
  type: USERPLANROLE_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateUserPlanRoleRecordError = (error) => ({
  type: USERPLANROLE_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteUserPlanRoleRecords = (record) => ({
  type: USERPLANROLE_DELETE_RECORD,
  payload: record,
});

export const deleteUserPlanRoleRecordSuccess = (msg) => ({
  type: USERPLANROLE_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteUserPlanRoleRecordError = (error) => ({
  type: USERPLANROLE_DELETE_RECORD_ERROR,
  payload: error,
});

export const getUserPlanRoleByUserPlanList = (recordId) => ({
  type: USERPLANROLE_BYUSERID,
  payload: recordId,
});

export const getUserRoleByUserPlanListSuccess = (items) => ({
  type: USERPLANROLE_BYUSERID_SUCCESS,
  payload: items,
});

export const getUserRoleByUserPlanListError = (error) => ({
  type: USERPLANROLE_BYUSERID_ERROR,
  payload: error,
});

export const updateUserPlanRoleSelectedRecords = (records) => ({
  type: USERPLANROLE_UPDATE_SELECTED_RECORDS,
  payload: records,
});

export const upsertUserPlanRoleRecords = (records) => ({
  type: USERPLANROLE_UPSERT_RECORD,
  payload: records,
});

export const upsertUserPlanRoleRecordsSuccess = (items) => ({
  type: USERPLANROLE_UPSERT_RECORD_SUCCESS,
  payload: items,
});
export const upsertUserPlanRoleRecordsError = (error) => ({
  type: USERPLANROLE_UPSERT_RECORD_ERROR,
  payload: error,
});

export const resetUserPlanRoleStatuses = () => ({
  type: USERPLANROLE_RESET_STATUSES,
});
//-------------------------------------------------------------------------
