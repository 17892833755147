/* eslint-disable import/no-cycle */
import {
  PHYSICALACTIVITY_GET_LIST,
  PHYSICALACTIVITY_GET_LIST_SUCCESS,
  PHYSICALACTIVITY_GET_LIST_ERROR,
  PHYSICALACTIVITY_SET_SELECTED,
  PHYSICALACTIVITY_UPDATE_SELECTED,
  PHYSICALACTIVITY_SET_SELECTED_SUCCESS,
  PHYSICALACTIVITY_SET_SELECTED_ERROR,
  PHYSICALACTIVITY_ADD_RECORD_SUCCESS,
  PHYSICALACTIVITY_ADD_RECORD_ERROR,
  PHYSICALACTIVITY_ADD_RECORD,
  PHYSICALACTIVITY_UPDATE_RECORD_SUCCESS,
  PHYSICALACTIVITY_UPDATE_RECORD_ERROR,
  PHYSICALACTIVITY_UPDATE_RECORD,
  PHYSICALACTIVITY_DELETE_RECORD,
  PHYSICALACTIVITY_DELETE_RECORD_SUCCESS,
  PHYSICALACTIVITY_DELETE_RECORD_ERROR,
  PHYSICALACTIVITY_RESET_STATUSES,
  PHYSICALACTIVITY_ISACTIVECHANGE_ERROR,
  PHYSICALACTIVITY_ISACTIVECHANGE_SUCCESS,
  PHYSICALACTIVITY_ISACTIVECHANGE,
} from '../../actions';

//-------------------------------------------------------------------------
export const getPhysicalActivityList = (pageDetail) => ({
  type: PHYSICALACTIVITY_GET_LIST,
  payload: pageDetail,
});

export const getPhysicalActivityListSuccess = (items) => ({
  type: PHYSICALACTIVITY_GET_LIST_SUCCESS,
  payload: items,
});

export const getPhysicalActivityListError = (error) => ({
  type: PHYSICALACTIVITY_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setPhysicalActivitySelectedRecord = (recordId) => ({
  type: PHYSICALACTIVITY_SET_SELECTED,
  payload: recordId,
});

export const setPhysicalActivitySelectedRecordSuccess = (record) => ({
  type: PHYSICALACTIVITY_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setPhysicalActivitySelectedRecordError = (error) => ({
  type: PHYSICALACTIVITY_SET_SELECTED_ERROR,
  payload: error,
});

export const updatePhysicalActivitySelectedRecord = (record) => ({
  type: PHYSICALACTIVITY_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addPhysicalActivityRecord = (record) => ({
  type: PHYSICALACTIVITY_ADD_RECORD,
  payload: record,
});

export const addPhysicalActivityRecordSuccess = (msg) => ({
  type: PHYSICALACTIVITY_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addPhysicalActivityRecordError = (error) => ({
  type: PHYSICALACTIVITY_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updatePhysicalActivityRecords = (record) => ({
  type: PHYSICALACTIVITY_UPDATE_RECORD,
  payload: record,
});

export const updatePhysicalActivityRecordSuccess = (msg) => ({
  type: PHYSICALACTIVITY_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updatePhysicalActivityRecordError = (error) => ({
  type: PHYSICALACTIVITY_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deletePhysicalActivityRecords = (record) => ({
  type: PHYSICALACTIVITY_DELETE_RECORD,
  payload: record,
});

export const deletePhysicalActivityRecordSuccess = (msg) => ({
  type: PHYSICALACTIVITY_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deletePhysicalActivityRecordError = (error) => ({
  type: PHYSICALACTIVITY_DELETE_RECORD_ERROR,
  payload: error,
});

export const isActiveChangePhysicalActivity = (record) => ({
  type: PHYSICALACTIVITY_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangePhysicalActivitySuccess = (msg) => ({
  type: PHYSICALACTIVITY_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangePhysicalActivityError = (error) => ({
  type: PHYSICALACTIVITY_ISACTIVECHANGE_ERROR,
  payload: error,
});

//-------------------------------------------------------------------------
export const resetPhysicalActivityStatuses = () => ({
  type: PHYSICALACTIVITY_RESET_STATUSES,
});
