// eslint-disable-next-line import/no-cycle
import {
  ANTROPOMETRY_GET_LIST,
  ANTROPOMETRY_GET_LIST_SUCCESS,
  ANTROPOMETRY_GET_LIST_ERROR,
  ANTROPOMETRY_ADD_RECORD,
  ANTROPOMETRY_ADD_RECORD_ERROR,
  ANTROPOMETRY_ADD_RECORD_SUCCESS,
  ANTROPOMETRY_DELETE_RECORD,
  ANTROPOMETRY_DELETE_RECORD_ERROR,
  ANTROPOMETRY_DELETE_RECORD_SUCCESS,
  ANTROPOMETRY_RESET_STATUSES,
  ANTROPOMETRY_SET_SELECTED,
  ANTROPOMETRY_SET_SELECTED_ERROR,
  ANTROPOMETRY_SET_SELECTED_SUCCESS,
  ANTROPOMETRY_UPDATE_RECORD,
  ANTROPOMETRY_UPDATE_RECORD_ERROR,
  ANTROPOMETRY_UPDATE_RECORD_SUCCESS,
  ANTROPOMETRY_UPDATE_SELECTED,
  ANTROPOMETRY_SET_PALVALUE,
  ANTROPOMETRY_SET_EXERCISEACTIVE,
  ANTROPOMETRY_SET_BASALMETABOLICRATE,
  ANTROPOMETRY_SET_TOTALENERGYEXPENDITURE,
  ANTROPOMETRY_SET_EXERCİSESTOTALKCAL,
  ANTROPOMETRY_SET_CURRENTFORMULA,
  ANTROPOMETRY_CLONE_SET_SELECTED,
  ANTROPOMETRY_CLONE_SET_SELECTED_ERROR,
  ANTROPOMETRY_CLONE_SET_SELECTED_SUCCESS,
  ANTROPOMETRY_ISACTIVECHANGE,
  ANTROPOMETRY_ISACTIVECHANGE_SUCCESS,
  ANTROPOMETRY_ISACTIVECHANGE_ERROR,
  ANTROPOMETRY_GENERICSEARCH,
  ANTROPOMETRY_GENERICSEARCH_SUCCESS,
  ANTROPOMETRY_GENERICSEARCH_ERROR,
  ANTROPOMETRY_GETBYCOACHEEID,
  ANTROPOMETRY_GETBYCOACHEEID_SUCCESS,
  ANTROPOMETRY_GETBYCOACHEEID_ERROR,
} from '../../actions';

//-------------------------------------------------------------------------
export const getAntropometryList = (pageDetail) => ({
  type: ANTROPOMETRY_GET_LIST,
  payload: pageDetail,
});

export const getAntropometryListSuccess = (items) => ({
  type: ANTROPOMETRY_GET_LIST_SUCCESS,
  payload: items,
});

export const getAntropometryListError = (error) => ({
  type: ANTROPOMETRY_GET_LIST_ERROR,
  payload: error,
});

//----------------------------------------------------------------------------------
export const setAntropometrySelectedRecord = (recordId) => ({
  type: ANTROPOMETRY_SET_SELECTED,
  payload: recordId,
});

export const setAntropometrySelectedRecordSuccess = (record) => ({
  type: ANTROPOMETRY_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setAntropometrySelectedRecordError = (error) => ({
  type: ANTROPOMETRY_SET_SELECTED_ERROR,
  payload: error,
});

export const updateAntropometrySelectedRecord = (record) => ({
  type: ANTROPOMETRY_UPDATE_SELECTED,
  payload: record,
});
//----------------------------------------------------------------------------------
export const addAntropometryRecord = (record) => ({
  type: ANTROPOMETRY_ADD_RECORD,
  payload: record,
});

export const addAntropometryRecordSuccess = (msg) => ({
  type: ANTROPOMETRY_ADD_RECORD_SUCCESS,
  payload: msg,
});

export const addAntropometryRecordError = (error) => ({
  type: ANTROPOMETRY_ADD_RECORD_ERROR,
  payload: error,
});
//----------------------------------------------------------------------------------
export const updateAntropometryRecords = (record) => ({
  type: ANTROPOMETRY_UPDATE_RECORD,
  payload: record,
});

export const updateAntropometryRecordSuccess = (msg) => ({
  type: ANTROPOMETRY_UPDATE_RECORD_SUCCESS,
  payload: msg,
});

export const updateAntropometryRecordError = (error) => ({
  type: ANTROPOMETRY_UPDATE_RECORD_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export const deleteAntropometryRecords = (record) => ({
  type: ANTROPOMETRY_DELETE_RECORD,
  payload: record,
});

export const deleteAntropometryRecordSuccess = (msg) => ({
  type: ANTROPOMETRY_DELETE_RECORD_SUCCESS,
  payload: msg,
});

export const deleteAntropometryRecordError = (error) => ({
  type: ANTROPOMETRY_DELETE_RECORD_ERROR,
  payload: error,
});

export const resetAntropometryStatuses = () => ({
  type: ANTROPOMETRY_RESET_STATUSES,
});
//-------------------------------------------------------------------------
export const setAntropometryCloneSelectedRecord = (recordId) => ({
  type: ANTROPOMETRY_CLONE_SET_SELECTED,
  payload: recordId,
});

export const setAntropometryCloneSelectedRecordSuccess = (record) => ({
  type: ANTROPOMETRY_CLONE_SET_SELECTED_SUCCESS,
  payload: record,
});

export const setAntropometryCloneSelectedRecordError = (error) => ({
  type: ANTROPOMETRY_CLONE_SET_SELECTED_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const isActiveChangeAntropometry = (record) => ({
  type: ANTROPOMETRY_ISACTIVECHANGE,
  payload: record,
});

export const isActiveChangeAntropometrySuccess = (msg) => ({
  type: ANTROPOMETRY_ISACTIVECHANGE_SUCCESS,
  payload: msg,
});

export const isActiveChangeAntropometryError = (error) => ({
  type: ANTROPOMETRY_ISACTIVECHANGE_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------
export const setAntropometryPalValue = (palValue) => ({
  type: ANTROPOMETRY_SET_PALVALUE,
  payload: palValue,
});
export const setAntropometryExerciseActive = (exerciseActive) => ({
  type: ANTROPOMETRY_SET_EXERCISEACTIVE,
  payload: exerciseActive,
});
export const setAntropometryBasalMetabolicRate = (basalMetabolicRate) => ({
  type: ANTROPOMETRY_SET_BASALMETABOLICRATE,
  payload: basalMetabolicRate,
});
export const setAntropometryTotalEnergyExpenditure = (
  totalEnergyExpenditure
) => ({
  type: ANTROPOMETRY_SET_TOTALENERGYEXPENDITURE,
  payload: totalEnergyExpenditure,
});
export const setAntropometryExercisesTotalKcal = (exercisesTotalKcal) => ({
  type: ANTROPOMETRY_SET_EXERCİSESTOTALKCAL,
  payload: exercisesTotalKcal,
});
export const setAntropometryCurrentFormula = (currentFormula) => ({
  type: ANTROPOMETRY_SET_CURRENTFORMULA,
  payload: currentFormula,
});
//-------------------------------------------------------------------------

export const genericSearchAntropometry = (record) => ({
  type: ANTROPOMETRY_GENERICSEARCH,
  payload: record,
});

export const genericSearchAntropometrySuccess = (msg) => ({
  type: ANTROPOMETRY_GENERICSEARCH_SUCCESS,
  payload: msg,
});

export const genericSearchAntropometryError = (error) => ({
  type: ANTROPOMETRY_GENERICSEARCH_ERROR,
  payload: error,
});
//-------------------------------------------------------------------------

export const getAntropometryByCoacheeId = (record) => ({
  type: ANTROPOMETRY_GETBYCOACHEEID,
  payload: record,
});

export const getAntropometryByCoacheeIdSuccess = (msg) => ({
  type: ANTROPOMETRY_GETBYCOACHEEID_SUCCESS,
  payload: msg,
});

export const getAntropometryByCoacheeIdError = (error) => ({
  type: ANTROPOMETRY_GETBYCOACHEEID_ERROR,
  payload: error,
});
