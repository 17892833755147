/* eslint-disable no-return-await */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import {
  FORMULA_ADD_RECORD,
  FORMULA_UPDATE_RECORD,
  FORMULA_GET_LIST,
  FORMULA_SET_SELECTED,
  FORMULA_DELETE_RECORD,
  deleteFormulaRecordSuccess,
  deleteFormulaRecordError,
} from '../../actions';

import {
  getFormulaListSuccess,
  getFormulaListError,
  setFormulaSelectedRecordSuccess,
  setFormulaSelectedRecordError,
  addFormulaRecordSuccess,
  addFormulaRecordError,
  updateFormulaRecordSuccess,
  updateFormulaRecordError,
} from './actions';

//---------------------------------------------------------------------
const getFormulaListRequest = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/formula`)
    .then((response) => response.data.data);
};

function* getFormulaListItems() {
  try {
    const response = yield call(getFormulaListRequest);
    yield put(getFormulaListSuccess(response));
  } catch (error) {
    yield put(getFormulaListError(error));
  }
}
//----------------------------------------------------------------------------------
const setFormulaSelectedRecordRequest = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/formula/${id}`)
    .then((response) => response.data.singleData);
};

function* setFormulaSelectedRecord({ payload }) {
  try {
    const response = yield call(setFormulaSelectedRecordRequest, payload);
    yield put(setFormulaSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setFormulaSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addFormulaRecordRequest = async (record) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/formula`, record)
    .then((response) => response.data);
};

function* addFormulaRecords({ payload }) {
  try {
    yield call(addFormulaRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addFormulaRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getFormulaListItems);
  } catch (error) {
    yield put(
      addFormulaRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateFormulaRecordRequest = async (record) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/formula`, record)
    .then((response) => response.data);
};

function* updateFormulaRecords({ payload }) {
  try {
    yield call(updateFormulaRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateFormulaRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getFormulaListItems);
  } catch (error) {
    yield put(
      updateFormulaRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
//----------------------------------------------------------------------------------
const deleteFormulaRecordRequest = async (recordId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/formula/${recordId}`)
    .then((response) => response.data);
};

function* deleteFormulaRecords({ payload }) {
  try {
    yield call(deleteFormulaRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteFormulaRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getFormulaListItems);
  } catch (error) {
    yield put(
      deleteFormulaRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------

export function* watchGetList() {
  yield takeEvery(FORMULA_GET_LIST, getFormulaListItems);
}

export function* watchSetSelected() {
  yield takeEvery(FORMULA_SET_SELECTED, setFormulaSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(FORMULA_ADD_RECORD, addFormulaRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(FORMULA_UPDATE_RECORD, updateFormulaRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(FORMULA_DELETE_RECORD, deleteFormulaRecords);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
