/* eslint-disable no-param-reassign */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  debounce,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  NotificationHelpers,
  NotificationType,
  NotificationSubType,
} from 'helpers/notificationHelpers';
import { filterBuilder } from 'helpers/Utils';
import {
  REFERENCERANGE_ADD_RECORD,
  REFERENCERANGE_UPDATE_RECORD,
  REFERENCERANGE_GET_LIST,
  REFERENCERANGE_SET_SELECTED,
  REFERENCERANGE_DELETE_RECORD,
  deleteReferenceRangeRecordSuccess,
  deleteReferenceRangeRecordError,
  REFERENCERANGE_ISACTIVECHANGE,
} from '../../actions';

import {
  getReferenceRangeListSuccess,
  getReferenceRangeListError,
  setReferenceRangeSelectedRecordSuccess,
  setReferenceRangeSelectedRecordError,
  addReferenceRangeRecordSuccess,
  addReferenceRangeRecordError,
  updateReferenceRangeRecordSuccess,
  updateReferenceRangeRecordError,
  isActiveChangeReferenceRangeSuccess,
  isActiveChangeReferenceRangeError,
} from './actions';

//---------------------------------------------------------------------
const getReferenceRangeListRequest = (url) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/referencerange${url || ''}`)
    .then((response) => response.data);
};

function* getReferenceRangeListItems({ payload }) {
  try {
    if (payload) {
      if (!payload.pageSize)
        payload.pageSize = yield select(
          (state) => state.settings.pageRecordCount
        );
      payload.pageNumber = payload.pageNumber || 0;

      const response = yield call(
        getReferenceRangeListRequest,
        filterBuilder(payload)
      );
      yield put(getReferenceRangeListSuccess(response));
    } else {
      const response = yield call(getReferenceRangeListRequest);
      yield put(getReferenceRangeListSuccess(response));
    }
  } catch (error) {
    yield put(getReferenceRangeListError(error));
  }
}
//----------------------------------------------------------------------------------
const setReferenceRangeSelectedRecordRequest = (id) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/referencerange/${id}`)
    .then((response) => response.data.singleData);

function* setReferenceRangeSelectedRecord({ payload }) {
  try {
    const response = yield call(
      setReferenceRangeSelectedRecordRequest,
      payload
    );
    yield put(setReferenceRangeSelectedRecordSuccess(response));
  } catch (error) {
    yield put(
      setReferenceRangeSelectedRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const addReferenceRangeRecordRequest = (record) =>
  axios.post(`${process.env.REACT_APP_API_URL}/referencerange`, record);

function* addReferenceRangeRecords({ payload }) {
  try {
    yield call(addReferenceRangeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      addReferenceRangeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Add)
      )
    );
    yield call(getReferenceRangeListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      addReferenceRangeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}
//----------------------------------------------------------------------------------
const updateReferenceRangeRecordRequest = (record) =>
  axios.put(`${process.env.REACT_APP_API_URL}/referencerange`, record);

function* updateReferenceRangeRecords({ payload }) {
  try {
    yield call(updateReferenceRangeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      updateReferenceRangeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Edit)
      )
    );
    yield call(getReferenceRangeListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      updateReferenceRangeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//--------------------------------------------------------------------
const isActiveChangeReferenceRangeRequest = (recordId) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/referencerange/isactivechange/${recordId}`
    )
    .then((response) => response.data);

function* isActiveChangeReferenceRange({ payload }) {
  try {
    const response = yield call(isActiveChangeReferenceRangeRequest, payload);
    yield put(isActiveChangeReferenceRangeSuccess(response));
  } catch (error) {
    yield put(
      isActiveChangeReferenceRangeError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

//----------------------------------------------------------------------------------
const deleteReferenceRangeRecordRequest = (recordId) =>
  axios.delete(`${process.env.REACT_APP_API_URL}/referencerange/${recordId}`);

function* deleteReferenceRangeRecords({ payload }) {
  try {
    yield call(deleteReferenceRangeRecordRequest, payload);
    const messageType = yield select((state) =>
      state.settings.notificationMessages.find(
        (x) => x.type === NotificationType.Success
      )
    );
    yield put(
      deleteReferenceRangeRecordSuccess(
        NotificationHelpers(messageType, NotificationSubType.Delete)
      )
    );
    yield call(getReferenceRangeListItems, { payload: { pageNumber: 0 } });
  } catch (error) {
    yield put(
      deleteReferenceRangeRecordError({
        timestamp: Date.now(),
        message: error.message,
        name: error.name,
      })
    );
  }
}

export function* watchGetList() {
  yield debounce(800, REFERENCERANGE_GET_LIST, getReferenceRangeListItems);
}

export function* watchSetSelected() {
  yield takeEvery(REFERENCERANGE_SET_SELECTED, setReferenceRangeSelectedRecord);
}
export function* wathcAddItem() {
  yield takeEvery(REFERENCERANGE_ADD_RECORD, addReferenceRangeRecords);
}
export function* wathcUpdateItem() {
  yield takeEvery(REFERENCERANGE_UPDATE_RECORD, updateReferenceRangeRecords);
}

export function* wathcDeleteItem() {
  yield takeEvery(REFERENCERANGE_DELETE_RECORD, deleteReferenceRangeRecords);
}

export function* wathcIsActiveChangeItem() {
  yield takeEvery(REFERENCERANGE_ISACTIVECHANGE, isActiveChangeReferenceRange);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchSetSelected),
    fork(wathcAddItem),
    fork(wathcIsActiveChangeItem),
    fork(wathcUpdateItem),
    fork(wathcDeleteItem),
  ]);
}
