import {
  BODYMASSINDEXRANGE_GET_LIST,
  BODYMASSINDEXRANGE_GET_LIST_SUCCESS,
  BODYMASSINDEXRANGE_GET_LIST_ERROR,
  BODYMASSINDEXRANGE_ADD_RECORD,
  BODYMASSINDEXRANGE_ADD_RECORD_ERROR,
  BODYMASSINDEXRANGE_ADD_RECORD_SUCCESS,
  BODYMASSINDEXRANGE_DELETE_RECORD,
  BODYMASSINDEXRANGE_DELETE_RECORD_ERROR,
  BODYMASSINDEXRANGE_DELETE_RECORD_SUCCESS,
  BODYMASSINDEXRANGE_RESET_STATUSES,
  BODYMASSINDEXRANGE_SET_SELECTED,
  BODYMASSINDEXRANGE_SET_SELECTED_ERROR,
  BODYMASSINDEXRANGE_SET_SELECTED_SUCCESS,
  BODYMASSINDEXRANGE_UPDATE_RECORD,
  BODYMASSINDEXRANGE_UPDATE_RECORD_ERROR,
  BODYMASSINDEXRANGE_UPDATE_RECORD_SUCCESS,
  BODYMASSINDEXRANGE_UPDATE_SELECTED,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BODYMASSINDEXRANGE_GET_LIST:
      return { ...state, loading: true };
    case BODYMASSINDEXRANGE_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRecord: null,
        records: action.payload,
      };
    case BODYMASSINDEXRANGE_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case BODYMASSINDEXRANGE_SET_SELECTED:
      return { ...state, modaLoading: true };
    case BODYMASSINDEXRANGE_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case BODYMASSINDEXRANGE_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case BODYMASSINDEXRANGE_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case BODYMASSINDEXRANGE_ADD_RECORD:
      return { ...state, loading: false };
    case BODYMASSINDEXRANGE_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case BODYMASSINDEXRANGE_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case BODYMASSINDEXRANGE_UPDATE_RECORD:
      return { ...state, loading: false };
    case BODYMASSINDEXRANGE_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case BODYMASSINDEXRANGE_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case BODYMASSINDEXRANGE_DELETE_RECORD:
      return { ...state, loading: false };
    case BODYMASSINDEXRANGE_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case BODYMASSINDEXRANGE_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case BODYMASSINDEXRANGE_RESET_STATUSES:
      return { ...state, success: null, error: null };
    default:
      return { ...state };
  }
};
