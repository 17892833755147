/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import {
  PORTION_GET_LIST,
  PORTION_GET_LIST_SUCCESS,
  PORTION_GET_LIST_ERROR,
  PORTION_SET_SELECTED,
  PORTION_UPDATE_SELECTED,
  PORTION_SET_SELECTED_SUCCESS,
  PORTION_SET_SELECTED_ERROR,
  PORTION_UPDATE_RECORD,
  PORTION_UPDATE_RECORD_ERROR,
  PORTION_UPDATE_RECORD_SUCCESS,
  PORTION_ADD_RECORD,
  PORTION_ADD_RECORD_SUCCESS,
  PORTION_ADD_RECORD_ERROR,
  PORTION_DELETE_RECORD,
  PORTION_DELETE_RECORD_SUCCESS,
  PORTION_DELETE_RECORD_ERROR,
  PORTION_RESET_STATUSES,
  PORTION_GETNAMEWITHID_LIST,
  PORTION_GETNAMEWITHID_LIST_SUCCESS,
  PORTION_GETNAMEWITHID_LIST_ERROR,
  PORTION_GETNAMEWITHID_ONERECORD,
  PORTION_GETNAMEWITHID_ONERECORD_SUCCESS,
  PORTION_GETNAMEWITHID_ONERECORD_ERROR,
  PORTION_ISACTIVECHANGE_ERROR,
  PORTION_ISACTIVECHANGE_SUCCESS,
  PORTION_ISACTIVECHANGE,
} from '../../actions';

const INIT_STATE = {
  records: [],
  selectedRecord: null,
  loading: false,
  modalLoading: false,
  error: null,
  success: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PORTION_GET_LIST:
      return { ...state, loading: true };
    case PORTION_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
        totalRecordCount: action.payload.totalRecordCount,
      };
    case PORTION_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------
    case PORTION_GETNAMEWITHID_LIST:
      return { ...state, loading: true };
    case PORTION_GETNAMEWITHID_LIST_SUCCESS:
      return { ...state, loading: false, records: action.payload };
    case PORTION_GETNAMEWITHID_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PORTION_GETNAMEWITHID_ONERECORD:
      return { ...state, loading: true };
    case PORTION_GETNAMEWITHID_ONERECORD_SUCCESS:
      return { ...state, loading: false, records: action.payload };
    case PORTION_GETNAMEWITHID_ONERECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PORTION_SET_SELECTED:
      return { ...state, modaLoading: true };
    case PORTION_SET_SELECTED_SUCCESS:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    case PORTION_SET_SELECTED_ERROR:
      return { ...state, modaLoading: false, error: action.payload };
    case PORTION_UPDATE_SELECTED:
      return { ...state, modaLoading: false, selectedRecord: action.payload };
    //----------------------------------------------------------------------------------
    case PORTION_ADD_RECORD:
      return { ...state, loading: false };
    case PORTION_ADD_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PORTION_ADD_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //----------------------------------------------------------------------------------
    case PORTION_UPDATE_RECORD:
      return { ...state, loading: false };
    case PORTION_UPDATE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PORTION_UPDATE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PORTION_DELETE_RECORD:
      return { ...state, loading: false };
    case PORTION_DELETE_RECORD_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case PORTION_DELETE_RECORD_ERROR:
      return { ...state, loading: true, error: action.payload };
    //------------------------------------------------------------------------------
    case PORTION_ISACTIVECHANGE:
      return { ...state, loading: false };
    case PORTION_ISACTIVECHANGE_SUCCESS: {
      let objIndex = state.records.findIndex(
        (x) => x.id === action.payload.singleData.id
      );
      state.records[objIndex] = action.payload.singleData;
      return { ...state, loading: false, records: state.records };
    }
    case PORTION_ISACTIVECHANGE_ERROR:
      return { ...state, loading: true, error: action.payload };
    case PORTION_RESET_STATUSES:
      return { ...state, success: null, error: null };
    //------------------------------------------------------------------------------
    default:
      return { ...state };
  }
};
